import React from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode'
import useWindowSize from 'react-use/lib/useWindowSize'
import modalService from '../../../../services/modalService'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import { translations } from '../../../../config'

const BarcodeContainer = styled.div`
  width: 100%;
  text-align: center;
`

const OrderBarcode = ({ orderNumber }) => {
  const { width } = useWindowSize()
  
  let barcodeWidth
  barcodeWidth = width < 500 ? 1.5 : undefined
  barcodeWidth = width < 400 ? 1 : barcodeWidth
  barcodeWidth = width < 300 ? 0.5 : barcodeWidth

  return (
    <BasicModalOverlay
      title='ORDER BARCODE'
      actions={[
        {
          text: translations('Close'),
          onClick: () => modalService.close()
        }
      ]}
    >
      <BarcodeContainer>
        <Barcode value={orderNumber} width={barcodeWidth} />
      </BarcodeContainer>
    </BasicModalOverlay>
  )
}

export default OrderBarcode
