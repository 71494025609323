import * as constants from './constants'
import _ from 'lodash'
import moment from 'moment'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as usersSelectors } from '../users'
import { apiUpdateSource } from '../../../config'

class AppointmentsActions {
  createAppointment = appointmentData => ({
    type: constants.CREATE_APPOINTMENT,
    promise: (dispatch, getState) => {
      console.log(appointmentData)
      return digitalStoreSdk.appointments.createAppointment({
        updateSource: apiUpdateSource,
        ...appointmentData
      })
    }
  })

  fetchAppointmentsFresh = ({ query }) => ({
    type: constants.FETCH_APPOINTMENTS_FRESH,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.appointments
        .fetchAppointments({
          startDateTime: { $gte: moment().startOf('day').format('YYYY-MM-DD[T]HH:mm:ss') },
          ...query,
          status: { $notIn: 'CANCELLED,COMPLETE' },
          sort: 'startDateTime',
          includes: 'user,customer,calendar'
        })
    }
  })

  fetchCustomerAppointments = ({ customerId }) => ({
    type: constants.FETCH_APPOINTMENTS_FRESH,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.appointments
        .fetchAppointments({
          customerId,
          status: { $notIn: 'CANCELLED,COMPLETE' },
          sort: 'startDateTime',
          includes: 'user,customer'
        })
    }
  })

  fetchAppointment ({ appointmentId, isConsultant }) {
    return {
      type: constants.FETCH_APPOINTMENT,
      appointmentId,
      isConsultant,
      promise: (dispatch, getState) => {
        const fetchAppointmentFn = isConsultant
          ? digitalStoreSdk.appointments.fetchAppointment
          : digitalStoreSdk.appointments.fetchAppointmentWithNoCustomerData
        return fetchAppointmentFn({ id: appointmentId })
      }
    }
  }

  updateAppointmentsFilters = filters => {
    return {
      type: constants.UPDATE_APPOINTMENTS_FILTERS,
      filters,
      promise: (dispatch, getState) => {
        return Promise.resolve(filters)
      }
    }
  }

  updateAppointment = appointmentData => ({
    type: constants.UPDATE_APPOINTMENT,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.appointments.updateAppointment({
        updateSource: apiUpdateSource,
        ...appointmentData
      }).then((newAppointment) => {
        const state = getState()
        const users = usersSelectors.getResults(state)
        const newAppointmentUser = _.find(users, ['id', newAppointment.userId])
        return {
          ..._.omit(newAppointment, ['userId', 'customerId']),
          user: newAppointmentUser
        }
      })
    }
  })

  cancelAppointment = id => ({
    type: constants.CANCEL_APPOINTMENT,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.appointments.cancelAppointment({
        id
      })
    }
  })

  fetchVideoToken ({ clientId, appointmentId }) {
    return {
      type: constants.FETCH_VIDEO_TOKEN,
      promise: (dispatch, getState) => {
        return digitalStoreSdk.appointments.fetchVideoToken({
          id: appointmentId,
          clientId
        })
      }
    }
  }
}

export default new AppointmentsActions()
