const styles = theme => ({
  rootMenu: {
    /* Allows content to fill the viewport and go beyond the bottom */
    height: '100%'
  },
  container: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
    '& > div': {
      flexShrink: 0
    }
  },
  header: {
    padding: 17,
    backgroundColor: theme.palette.lightGrey.main,
    borderBottom: `0.5px solid ${theme.palette.border.main}`,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listContainer: {
    flexShrink: '1 !important',
    minHeight: 0,
    overflow: 'auto',
    flex: 1
  },
  footer: {
    marginTop: 'auto',
    borderTop: `0.5px solid ${theme.palette.border.main}`,
    padding: 10
  },
  footerFullScreen: {
    marginTop: 'auto',
    borderTop: `2px solid ${theme.palette.border.main}`,
    backgroundColor: ' #F5F3F3'
  },
  buttonWithMargin: {
    marginTop: 10
  },
  buttonFullScreen: {
    marginTop: 10,
    '@media (min-width: 500px)': {
      margin: 10
    }
  },
  startConsultationButtonContainer: {
    padding: 10,
    borderBottom: `0.5px solid ${theme.palette.border.main}`
  },
  totalsContainer: {
    padding: '4px 7px 1px'
  },
  totalContainerText: {
    margin: '0 5px'
  },
  totalContainerTextCustomerFacing: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '0 5px'
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '1px 0'
  },
  totalsContainerFullScreen: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  },
  totalContainerCustomerFacing: {
    background: theme.palette.lightGrey.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '10px 40% 10px 40%'
  },
  buttonsContainer: {
    display: 'block'
  },
  buttonsContainerFullScreen: {
    borderTop: `2px solid ${theme.palette.border.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px 10px 10px',
    '@media (min-width: 500px)': {
      flexDirection: 'row',
      padding: 10
    }
  }
})

export default styles
