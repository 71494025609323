import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { getImage } from '../Images'
import { translations } from '../../config'
import Button from '../Button'

import style from './style'
import imageUploadService from '../../services/imageUploadService/imageUploadService'

class UploadImage extends Component {
  state = { image: this.props.image }
  fileSelectedWeb = (e) => {
    const { onPhotoAdded } = this.props
    const file = e.target.files[0]
    const blobUrl = window.URL.createObjectURL(file)
    this.setState && this.setState({ image: blobUrl })
    onPhotoAdded({ blobUrl, fileName: file.name })
  }
  fileSelectedCordova = (file) => {
    const { onPhotoAdded } = this.props
    this.setState && this.setState({ image: file })
    // cannot get filename here unfortunately
    onPhotoAdded({ blobUrl: file, fileName: translations('File attached') })
  }

  openImagePickerWeb = () => {
    const { filepicker } = this.refs
    filepicker.click()
  }
  _onDeleteClick = () => {
    const { onPhotoRemoved } = this.props
    const { filepicker } = this.refs
    this.setState && this.setState({ image: '' })
    onPhotoRemoved()
    filepicker.value = null
  }
  _renderDeleteFileButton = () => {
    return (
      <Button
        buttonType='primary'
        onClick={this._onDeleteClick}
      >
        {translations('Delete Screenshot')}
      </Button>
    )
  }
  _renderButtonCordova = () => {
    const { classes, buttonSpacing } = this.props
    if (this.state.image) {
      return this._renderDeleteFileButton()
    } else {
      const buttonClasses = cx(classes.submitButtonHalf, {
        [classes.buttonSpace]: buttonSpacing
      })
      return (
        <div className={classes.buttonContainer}>
          <Button
            className={buttonClasses}
            buttonType='primary'
            onClick={this._openImagePickerCamera}
          >
            <div className={classes.submitButtonLabelContainer}>
              <img className={classes.submitButtonLabelIcon} src={getImage('camera')} />
              <div className={classes.submitButtonLabelText}>
                {translations('Take Photo')}
              </div>
            </div>
          </Button>
          <Button
            className={classes.submitButtonHalf}
            buttonType='primary'
            onClick={this._openImagePickerLibrary}
          >
            <div className={classes.submitButtonLabelContainer}>
              <img className={classes.submitButtonLabelIcon} src={getImage('addFromGallery')} />
              <div className={classes.submitButtonLabelText}>
                {translations('Add from Gallery')}
              </div>
            </div>
          </Button>
        </div>
      )
    }
  }
  _openImagePickerLibrary = () => imageUploadService.openImagePickerLibrary(this.fileSelectedCordova)
  _openImagePickerCamera = () => imageUploadService.openImagePickerCamera(this.fileSelectedCordova)
  _renderButtonWeb = () => {
    if (this.state.image) {
      return this._renderDeleteFileButton()
    } else {
      return (
        <Button
          buttonType='primary'
          onClick={this.openImagePickerWeb}
        >
          {translations('Add Image Web')}
        </Button>
      )
    }
  }
  renderButton = () => {
    if (window.cordova) {
      return this._renderButtonCordova()
    } else {
      return this._renderButtonWeb()
    }
  }
  render () {
    const {
      classes,
      className
    } = this.props
    return (
      <div className={className}>
        {this.renderButton()}
        {!window.cordova
          ? <input onChange={this.fileSelectedWeb} className={classes.imagePicker} ref={'filepicker'} type='file' accept='image/*' />
          : null
        }
      </div>
    )
  }
}

export default withStyles(style)(UploadImage)
