import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { compose, withHandlers, withState } from 'recompose'
import _ from 'lodash'

import scanner from '../../services/barcodeScannerService'
import getSelectedCurrencyDiscount from '../../helpers/getSelectedCurrencyDiscount'
import getSelectedCurrencyPrice from '../../helpers/getSelectedCurrencyPrice'
import modalService from '../../services/modalService'
import digitalStoreSdk from '../../digitalStoreSdk'
import { translations } from '../../config'
import ScanButton from './ScanButton'
import EnterEANModal from './EnterEANModal'
import analyticsService from '../../services/analyticsService'
import { selectors as authSelectors } from '../../store/modules/auth'
import { selectors as appSelectors } from '../../store/modules/app'
import { selectors as customerDetailsSelectors, actions as customerDetailsActions } from '../../store/modules/customerDetails'
import { selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import util from '../Fields/ProductCarousel/util.js'

const searchProduct = (outputFunc, userRegionId, catalogue, selectedCurrency, dispatch, editProductCarouselType, productCarouselCurrentContent) => ({ ean, onError = _.noop, onSuccess = _.noop }) => {
  return digitalStoreSdk.variants.fetchVariants({
    barcodes: { $contains: [ean] },
    catalogue,
    regionId: userRegionId,
    includes: ['product']
  })
    .then(response => {
      const responseProduct = _.get(response, 'items.0', {})
      const product = {
        ..._.get(responseProduct, 'product', {}),
        price: getSelectedCurrencyPrice(responseProduct, selectedCurrency),
        discount: getSelectedCurrencyDiscount(responseProduct, selectedCurrency),
        variant: _.chain(response)
          .get('items.0')
          .omit('products')
          .value()
      }

      onSuccess({ ean, id: product.id })
      if (product.id) {
        // check if editing product carousel from customer full profile or messaging app
        // if yes then add product directly to wishlist, waitlist, recommendedProducts or craete-message
        // if not do searchProduct like normal
        const productCarouselType = [
          'wishlist',
          'waitlist',
          'recommendedProducts',
          'create-message',
          'consultationCustomerDetailsForm',
          'create-notification'
        ]
        if (productCarouselType.includes(editProductCarouselType)) {
          let currentContent = productCarouselCurrentContent
          const formName = editProductCarouselType
          let fieldName = editProductCarouselType
          if (editProductCarouselType === 'create-message') {
            fieldName = 'content'
          }
          else if (editProductCarouselType === 'create-notification'){
            fieldName = 'content'
            if (!currentContent){
              currentContent = [];
            }
          }
          
          util.addProductToField({ product, currentContent, dispatch, formName, fieldName })
        } else {
          // Search Product
          outputFunc({ product, ean })
        }
      } else {
        throw new Error('Item not found.')
      }
    })
    .catch(error => {
      onError({ error, ean })
    })
}

// `isLoading` here is about whether the scanner plugin is loading
export default compose(
  connect((state) => {
    const editProductCarouselType = customerDetailsSelectors.getProductCarouselType(state)

    let selector
    let productCarouselCurrentContent
    if (editProductCarouselType) {
      selector = formValueSelector(editProductCarouselType)
      if (editProductCarouselType === 'create-message' || editProductCarouselType === 'create-notification') {
        productCarouselCurrentContent = selector(state, 'content')
      } else if (editProductCarouselType === 'consultationCustomerDetailsForm') {
        productCarouselCurrentContent = currentAppointmentSelectors.getAppointmentProducts(state)
      } else {
        productCarouselCurrentContent = selector(state, editProductCarouselType)
      }
    } else {
      productCarouselCurrentContent = []
    }
    return {
      userRegionId: authSelectors.getUserSelectedRegionId(state),
      catalogue: _.get(authSelectors.getCurrentUser(state), 'catalogue', ''),
      selectedCurrency: appSelectors.getAppCurrency(state),
      editProductCarouselType: editProductCarouselType,
      productCarouselCurrentContent: productCarouselCurrentContent
    }
  }),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    onClick: ({ setIsLoading, onClick: onClickFromProps, userRegionId, catalogue, selectedCurrency, dispatch, editProductCarouselType, productCarouselCurrentContent }) => () => {
      const onConfirm = searchProduct(onClickFromProps, userRegionId, catalogue, selectedCurrency, dispatch, editProductCarouselType, productCarouselCurrentContent)
      if (window.cordova) {
        setIsLoading(true)
        scanner.scan(
          result => {
            analyticsService.sendCustomEvent({ type: 'eanProductSearch' })
            const { cancelled, text } = result
            if (!cancelled) {
              onConfirm({
                ean: text,
                onSuccess: () => {
                  setIsLoading(false)
                },
                onError: () => {
                  setIsLoading(false)
                  modalService.open({
                    component: EnterEANModal,
                    title: translations('Enter EAN No Results Title'),
                    onConfirm,
                    actions: [
                      {
                        success: true,
                        primary: true,
                        text: translations('Enter EAN Submit')
                      },
                      {
                        text: translations('Cancel'),
                        onClick: _.noop
                      }
                    ]
                  })
                }
              })
            } else {
              setIsLoading(false)
              modalService.open({
                component: EnterEANModal,
                title: translations('Enter EAN Title'),
                onBackdropClick: () => dispatch(customerDetailsActions.editProductCarousel('')),
                onConfirm,
                actions: [
                  {
                    success: true,
                    primary: true,
                    text: translations('Enter EAN Submit')
                  },
                  {
                    text: translations('Cancel'),
                    onClick: _.noop
                  }
                ]
              })
            }
          },
          () => {
            setIsLoading(false)
            modalService.open({
              component: EnterEANModal,
              title: translations('Enter EAN Scan Error Title'),
              onConfirm,
              actions: [
                {
                  success: true,
                  primary: true,
                  text: translations('Enter EAN Submit')
                },
                {
                  text: translations('Cancel'),
                  onClick: _.noop
                }
              ]
            })
          }
        )
      } else {
        modalService.open({
          component: EnterEANModal,
          title: translations('Enter EAN Title'),
          onConfirm,
          onBackdropClick: () => dispatch(customerDetailsActions.editProductCarousel('')),
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Enter EAN Submit')
            },
            {
              text: translations('Cancel'),
              onClick: () => dispatch(customerDetailsActions.editProductCarousel(''))
            }
          ]
        })
      }
    }
  })
)(ScanButton)
