import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Notification from '../Notification'
import { translations } from '../../../config'
import media from '../../../config/media'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { string } from '@redant/red-ant-analytics'
import { useCurrentStoreId } from '../../../hooks/usersHooks'

const PlaylistAddCheck = styled(PlaylistAddCheckIcon)`
  color: #fff;
  width: 20px;
  ${media.lessThan('md')`
    width: 15px;
  `}
`

const WaitlistProductBackInStockNotification = props => {
  const dispatch = useDispatch()
  const storeId = useCurrentStoreId()

  const {  store, product } = props.meta
  const { createdAt } = props

  const text = _.template(translations('Waitlist Product Back In Stock Notification Text'))({
    productFullName: product.productFullName
  })
  // hide notifications for items that are not relevant to the current store
  return storeId === store.id
    ? <Notification
        {...props}
        onClick={() => dispatch(push(`/product/${product.id}/waitlist`))}
        icon={<PlaylistAddCheck />}
        notificationText={text}
        createdAt={createdAt}
      />
    : null
}

WaitlistProductBackInStockNotification.propTypes = {
  meta: PropTypes.shape({
    product: {
      id: string,
      productName: string
    },
    store: {
      id: string
    }
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default WaitlistProductBackInStockNotification
