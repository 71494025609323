import React from 'react'
import _ from 'lodash'
import Barcode from 'react-barcode'

// Provide a modal to be displayed on checkout completion
// This will be expanded as the store sale, and endless aisle routes are added.  

export const getModalServiceConfig = async (dispatch, replace, translation, checkout) => {
  const orderNumber = _.get(checkout, 'orderNumber')
  const showBarcode = orderNumber && checkout.checkoutType === 'basketCheckout'
  if (!orderNumber) {
    console.log('No order number')
    return null
  }

  const distantSaleCheck = checkout.checkoutType === 'distantCheckout'
  const orderPath = `/orders/${orderNumber}${distantSaleCheck && `?actionsFor=/distant-sales`}`
  const getOrderSuccessTitle = () => {
    return translation('title-orderSuccessful')
  }

  const getOrderSuccessCloseButtonText = () => {
    return translation('cta-continue')
  }

  const getOrderSuccessModalText = () => {
    if (checkout.checkoutType === 'distantCheckout') {
      return translation('label-distantOrderSuccessfulText')
    }
    return translation('label-orderSuccessfulText')
  }

  const getOrderSuccessChildren = () => {
    return (
      <div
        style={{
          textAlign: showBarcode ? 'center' : 'left',
          display: 'flex',
          flexDirection: 'column',
          alignItems: showBarcode ? 'center' : 'left'
        }}
      >
        {_.template(getOrderSuccessModalText())({ orderNumber })}
        {showBarcode && <Barcode value={orderNumber} width={1.5} />}
      </div>
    )
  }

  const modalConfig = {
    title: getOrderSuccessTitle(),
    actions: [
      {
        text: getOrderSuccessCloseButtonText()
      },
      {
        primary: true,
        text: translation('cta-viewOrder'),
        onClick: () => dispatch(replace(orderPath))
      }
    ],
    children: getOrderSuccessChildren()
  }
  return modalConfig
}

export const confirmationModal = async (dispatch, replace, translation, checkout, modalService) => {
  const modalConfig = await getModalServiceConfig(dispatch, replace, translation, checkout)
  if (checkout.checkoutType === 'distantCheckout') {
    return modalService.action(modalConfig)
  }
  if (checkout.checkoutType === 'basketCheckout') {
    return modalService.continue(modalConfig)
  }
}
