import { compose, withHandlers } from 'recompose'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'

import withCheckoutFlow from '../withCheckoutFlow'
import ChalhoubPayment from './ChalhoubPayment'

import { selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'

export default compose(
  withCheckoutFlow,
  connect(state => {
    return {
      editMode: currentOrderSelectors.getCurrentOrderEditMode(state),
      currentOrderCustomer: currentOrderSelectors.getCurrentOrderCustomer(state),
      deliveryType: _.get(state, 'currentOrder.deliveryType')
    }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    }
  })
)(ChalhoubPayment)
