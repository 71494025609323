import _, { includes } from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import Form from '../Form'
import fileUploadService from '../../services/fileUploadService'


import Messenger from './Messenger'
import MessengerEditorRecipients from './components/MessengerEditorRecipients'
import MessengerEditorText from './components/MessengerEditorText'
import toastService from '../../services/toastService/toastService'
import { translations } from '../../config'

const isUploadableFiile = item => ['image', 'file'].includes(item.type)

export default compose(
  withHandlers({
    onSubmit: (props) => (message) => {
      props.setIsformLoading && props.setIsformLoading(true)
      // On Submit upload all attached media
      // and pass on message to next handler
      const content = _.get(message, 'content', [])
      return _.chain(content)
        .map(item => {
          if (isUploadableFiile(item)) {
            return fileUploadService.upload(item.url)
              .then(remoteFileUrl => {
                return {
                  ...item,
                  url: remoteFileUrl
                }
              })
          }
          return item
        })
        .thru(promises => {
          return Promise.all(promises)
            .catch((error) => {
              toastService.action({
                type: 'error',
                message: translations('Messaging Upload Image - Fail Message'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })

              throw error
            })
        })
        .value()
        .then(newContent => {
          const newMessage = {
            ...message,
            content: newContent
          }
          return props.onSubmit(newMessage)
        })
        .then(() => {
          props.setIsformLoading && props.setIsformLoading(false)
        })
        .catch(error => {
          props.setIsformLoading && props.setIsformLoading(false)
          return Promise.reject(error)
        })
    }
  }),
  withPropsOnChange(['formId'], ({ formId }) => ({
    Form: Form(formId)
  }))
)(Messenger)

export {
  MessengerEditorRecipients,
  MessengerEditorText
}
