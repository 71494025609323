import _ from 'lodash'
import { Modules, Services, Providers, Utilities } from '@redant/retailos-ui'

import { environment } from '../config/environments'
import { communicationOptions, groupedProductsEnabledPLP, titles } from '../config/general'
import { DistantCheckoutFunctions, BasketCheckoutFunctions } from './Checkout/custom.checkout.functions'
import currencyFormatter from '../formatters/currencyFormatter'

// Use window.sdk instead since the below imports config and has some dirty circular dependencies
// import sdk from '../digitalStoreSdk'
import store from '../store'
import { countries, languages, storeLocationsEnabled, distanceUnits } from '../config'
import { ChalhoubProductFunctions } from './ProductCatalogue/custom.product.functions'

// Overwrite RetailOS-UI Currency formatter with Chalhoub formatter
Utilities.CurrencyFormatter.format = currencyFormatter.format

export const pubSubService = new Services.CouchbasePubSubService()
export const httpClientService = new Services.AxiosHttpClientService()
export const fileUploadService = new Services.FileUploadService(httpClientService)
// TODO: consider moving this
export const scanditService = new Services.ScanditService()

// export const uiConfigStore = new Providers.UiConfigStore(httpClientService)

export const adminInterfaceFunctions = new Modules.AdminInterface.AdminInterfaceFunctions(httpClientService)
export let checkoutFunctions = new Modules.Checkout.Implementation.StripeCheckoutFunctions(httpClientService)
export let distantCheckoutFunctions = new DistantCheckoutFunctions(httpClientService)
export let basketCheckoutFunctions = new BasketCheckoutFunctions(httpClientService)
export let inStoreConsultationCheckoutFunctions = new Modules.Checkout.Implementation.StripeCheckoutFunctions(httpClientService)
export let virtualConsultationCheckoutFunctions = new Modules.Checkout.Implementation.StripeCheckoutFunctions(httpClientService)
export const messageDetailFunctions = new Modules.Messaging.Implementation.MessageDetailFunctions(httpClientService, fileUploadService)
export const composeMessageFunctions = new Modules.Messaging.Implementation.ComposeMessageFunctions(httpClientService, fileUploadService)
export let virtualConsultationFunctions = new Modules.VirtualConsultation.Implementation.VirtualConsultationFunctions(httpClientService)
export const consultationFunctions = new Modules.Consultation.Implementation.ConsultationFunctions()
export const customerSearchFunctions = new Modules.Abstract.Implementation.ResourceSearchFunctions(httpClientService, 'customers')
export let productCatalogueFunctions = new ChalhoubProductFunctions(httpClientService)
export const filePickerFunctions = new Modules.File.Implementation.FilePickerFunctions(httpClientService)
export const retailAnalyticsFunctions = new Modules.RetailAnalytics.Implementation.RetailAnalyticsFunctions()
export const followedCustomerFunctions = new Modules.CustomerSearch.Implementation.FollowedCustomerFunctions(httpClientService)
export const customerFunctions = new Modules.Customer.Implementation.CustomerFunctions()

const sdkGetConfig = () => {
    const auth = _.get(store.getState(), 'auth')
    const token = _.get(auth, 'user.token')
    const refreshToken = _.get(auth, 'user.refreshToken')
    const userId = _.get(auth, 'user.id')
  
    return { token, refreshToken, userId }
}

const sdkOnRefresh = (user) => {
    const { token, refreshToken, id } = user
    window.sdk.setConfig({ token, refreshToken, userId: id })
  
    // again to avoid circular dependencies with importing config in the auth module
    store.dispatch({ user, type: 'digitialStore/auth/UPDATE_AUTH_USER' })
    // uiConfigStore.refresh()
}

let hasInitialized = false

export const initializeDependencies = async (overrides = {}) => {

    if (hasInitialized) {
        console.debug('RetailOS platform dependencies have already initialized.')
        return
    }

    /**
     * Add the ability to override dependencies here
     */
    overrides.productCatalogueFunctions && (productCatalogueFunctions = overrides.productCatalogueFunctions)
    overrides.virtualConsultationFunctions && (virtualConsultationFunctions = overrides.virtualConsultationFunctions)
    overrides.checkoutFunctions && (checkoutFunctions = overrides.checkoutFunctions)
    overrides.distantCheckoutFunctions && (distantCheckoutFunctions = overrides.checkoutFunctions)
    overrides.basketCheckoutFunctions && (basketCheckoutFunctions = overrides.checkoutFunctions)
    overrides.inStoreConsultationCheckoutFunctions && (inStoreConsultationCheckoutFunctions = overrides.inStoreConsultationCheckoutFunctions)
    overrides.virtualConsultationCheckoutFunctions && (virtualConsultationCheckoutFunctions = overrides.virtualConsultationCheckoutFunctions)

    await pubSubService.initialize({
        database: 'appointments',
        host: environment.CLOUDANT_DATABASE_URL
    })
    
    await httpClientService.initialize({
        retailosApiBaseUrl: environment.API_URL,
        getConfig: sdkGetConfig,
        onRefresh: sdkOnRefresh
    })

    // It requires some api changes to support this
    // await uiConfigStore.initialize()

    if (!overrides.productCatalogueFunctions) {
        await productCatalogueFunctions.initialize({
          useProductGroups: groupedProductsEnabledPLP, // TODO: This needs to be reviewed as on core there needs to be a distintion between PLP and PDP product grouping
          storeLocationsEnabled,
          distanceUnits
        })
      }


    await composeMessageFunctions.initialize({
        channelOptionKeys: communicationOptions.channelOptionKeys,
        preferenceOptionKeys: communicationOptions.preferenceOptionKeys
    })
    
    await messageDetailFunctions.initialize({
        channelOptionKeys: communicationOptions.channelOptionKeys,
        preferenceOptionKeys: communicationOptions.preferenceOptionKeys
    })

    await scanditService.initialize({
        licenseKey: environment.SCANDIT_LICENSE_KEY
    })

    await customerFunctions.initialize({
        titleOptions: titles.map(str => ({ label: str, value: str })),
        countryOptions: countries.map(str => ({ label: str, value: str })),
        languageOptions: languages
    })

    hasInitialized = true
    
}

export const useConsultationSharedState = Modules.VirtualConsultation.Implementation.provideUseSharedState({
    pubSubService,
    virtualConsultationFunctions
  },
    'user'
)
  
export const useResourceDetails = Modules.Abstract.Implementation.provideUseResourceDetails({
    httpClientService
})
  
export const useConsultationLocalState = Modules.Consultation.Implementation.provideUseLocalState({
    httpClientService,
    consultationFunctions,
    useResourceDetails
})
  
