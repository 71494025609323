import { theme as rootTheme } from '../../config/theme'

const verticalCentering = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    minHeight: rootTheme.subheaderHeight,
    backgroundColor: theme.palette.lightGrey.main,
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 100
  },
  absolute: {
    position: 'absolute'
  },
  notFixed: {
    position: 'relative'
  },
  leftContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    ...verticalCentering
  },
  centerContent: {
    flex: 1,
    maxWidth: '74%',
    margin: '0 auto',
    ...verticalCentering,

    [theme.breakpoints.down('xs')]: {
      marginRight: 15,
      marginLeft: 40,
      maxWidth: '100%'
    }
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    ...verticalCentering
  },
  fake: {
    height: rootTheme.subheaderHeight,
    minHeight: rootTheme.subheaderHeight,
    width: '100%',
    flexShrink: 0
  },
  customerSearch: {
    height: rootTheme.customerSearchSubheaderHeight,
    minHeight: rootTheme.subheaderHeight,
    width: '100%',
    flexShrink: 0
  }
})

export default styles
