import React, { Fragment } from 'react'
import { compose } from 'recompose'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'

import currencyFormatter from '../../../../formatters/currencyFormatter'
import { TableRow, TableCell } from '../../../../components/Table'
import { getImage } from '../../../../components/Images'
import TextBubble from '../../../../components/TextBubble'
import { translations, dateFormat, apps } from '../../../../config'

import withMessageCustomer from './withMessageCustomer'
import style from './style'
import { getCustomerFullName } from '../../../../helpers'

let InFullList = props => {
  const {
    firstName,
    lastName,
    reporting,
    telephone,
    email,
    classes,
    messageCustomer,
    anonymised,
    currency
  } = props
  // name
  const nameText = getCustomerFullName(props)

  // lifetime spend
  const totalSpend = _.get(reporting, 'lifetime.profit.totalSpend') || { code: currency }
  const lifetimeSpendText = _.template(translations('Customer Total Spend Info'))({
    totalSpend: currencyFormatter.format(totalSpend)
  })

  // lapse date
  const lastPurchaseDate = _.get(reporting, 'lifetime.profit.lastPurchaseDate')
  const lastPurchaseDateText = _.template(translations('Customer Last Purchase Info'))({
    lastPurchase: lastPurchaseDate ? moment(lastPurchaseDate).format(dateFormat) : 'Never'
  })
  return (
    <Fragment>
      <Hidden xsDown>
        <TableRow className={classes.tableRow}>
          <TableCell sm={3}>
            <div className={classes.truncate}>{nameText}</div>
            <TextBubble>{email}</TextBubble>
          </TableCell>
          <TableCell sm={2}>{telephone}</TableCell>
          <TableCell sm={3}>{lifetimeSpendText}</TableCell>
          <TableCell sm={3}>{lastPurchaseDateText}</TableCell>
          {
            apps.MESSAGING && !anonymised
            ? <TableCell sm={1} className={classes.messageCell}>
              <IconButton
                aria-label={'Send message'}
                onClick={messageCustomer}
              >
                <img src={getImage('messageDarkIcon')} className={classes.messageIcon} />
              </IconButton>
            </TableCell>
            : null
          }
        </TableRow>
      </Hidden>
      <Hidden smUp>
        <div className={classes.mobileContainer}>
          <div className={classes.mobileRow}>
            <div className={classes.mobileCell}>
              {nameText}
            </div>
            {
              apps.MESSAGING
              ? <div className={`${classes.mobileCell} ${classes.messageCell}`}>
                <IconButton
                  aria-label={'Send message'}
                  onClick={messageCustomer}
                >
                  <img src={getImage('messageDarkIcon')} className={classes.messageIcon} />
                </IconButton>
              </div>
              : null
            }
          </div>
          <div className={classes.mobileRow}>
            <div className={classes.mobileCell}>
              <TextBubble noVerticalMargin>{email}</TextBubble>
            </div>
            <div className={classes.mobileCell}>
              {telephone}
            </div>
          </div>
          <div className={classes.mobileRow}>
            <div className={classes.mobileCell}>
              {lifetimeSpendText}
            </div>
            <div className={classes.mobileCell}>
              {lastPurchaseDateText}
            </div>
          </div>
        </div>
      </Hidden>
    </Fragment>
  )
}

InFullList = compose(
  withMessageCustomer,
  withStyles(style)
)(InFullList)

export { InFullList }
