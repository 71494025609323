import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'

import ListPage from '../../../layouts/ListPage'
import { translations } from '../../../config'
import { useResults, useIsLoading, useError } from '../../../hooks/storesHooks'
import { actions as storesActions } from '../../../store/modules/stores'

const StoresSearchScreen = () => {
  const results = useResults()
  const isLoading = useIsLoading()
  const error = useError()
  const dispatch = useDispatch()

  const onClickAddStore = React.useCallback(() => {
    dispatch(push(`/stores/new`))
  }, [])

  const onRowClick = React.useCallback((cell) => {
    const store = cell.row.original
    dispatch(push(`/stores/${store.id}`))
  }, [])

  React.useEffect(() => {
    dispatch(storesActions.fetchAllStores({ includes: ['region'], sort: 'name', limit: 100 }))
  }, [])

  const columns = React.useMemo(() => ([
    {
      Header: 'Name',
      accessor: 'name',
      onClick: onRowClick
    },
    {
      Header: 'External ID',
      accessor: 'externalStoreId'
    },
    {
      Header: 'Region',
      accessor: 'regionName'
    },
    {
      Header: 'Catalogue',
      accessor: 'catalogue'
    }
  ]), [])

  const mappedResults = React.useMemo(() => {
    return results.map(result => ({
      ...result,
      regionName: _.get(result, 'region.name')
    }))
  }, [results])

  const actionButtons = [
    {
      label: 'Create Store',
      onClick: onClickAddStore
    }
  ]

  return (
    <ListPage
      title={translations('Page Title - Stores')}
      onRowClick={onRowClick}
      columns={columns}
      isLoading={isLoading}
      results={mappedResults}
      error={error}
      actionButtons={actionButtons}
    />
  )
}

export default StoresSearchScreen
