import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import he from 'he'
import P from '../P'

import { translations } from '../../config'

import styles from './style'

import { truncateLengthDefault } from './index'

/*
  Buttons are passed in as an array of objects with the following keys:
    - name (Name of the button)
    - onClick (function to call )
*/

const ThreadItem = ({ classes, renderFrom, latest, threadItemContent, showButtons, buttons,
  link, truncateLength = truncateLengthDefault, truncate, itemId,
  contentClass, showReadMore = true, isFlagged, onContainerClick = _.noop }) => {
  const formattedThreadItemContent = truncate
    ? _.truncate(threadItemContent, { length: truncateLength })
    : threadItemContent

  const decodedThreadItemContent = he.decode(formattedThreadItemContent)

  const containerStyle = classNames({
    [classes.container]: true,
    [classes.multipleNoteSpacing]: !latest
  })
  const contentContainerStyles = classNames(classes.contentContainer, {
    [contentClass]: contentClass
  })
  return (
    <Grid container spacing={0} className={containerStyle} onClick={onContainerClick}>
      {isFlagged ? (
        <Grid item xs={12}>
          <div className={classes.flaggedContainer}>
            <ErrorOutlineIcon className={classes.icon} />
            <P value={'Message Reported As Inappropriate'} className={classes.flaggedText} type='textButton' />
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12} md={6} className={classes.fromContainer}>
        {renderFrom()}
      </Grid>
      <Grid item xs={12} md={6} className={contentContainerStyles}>
        <div className={classes.innerContentContainer}>
          <div className={classes.contentWrapper}>
            <P value={decodedThreadItemContent} />
            {/* Only display for the latest item */}
            {latest && link && showReadMore && threadItemContent.length > truncateLength && <Link className={classes.readMoreLink} to={link}>{translations('Read more')}</Link>}
          </div>
          {
            showButtons && buttons.length ? (
              <div className={classes.buttonContentWrapper}>
                {buttons.map(button => (
                  <div key={button.name} className={classes.textButtonContainer}>
                    <div className={classes.arrowRight} />
                    <P key={button.name} value={button.name} className={classNames(classes.textButton, {
                      [classes.disabledTextButton]: button.disabled
                    })} onClick={button.onClick} />
                  </div>
                ))}
              </div>
            ) : null
          }
        </div>
      </Grid>

    </Grid>
  )
}

ThreadItem.propTypes = {
  renderFrom: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  latest: PropTypes.bool,
  flip: PropTypes.bool,
  threadItemContent: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
  truncateLength: PropTypes.number,
  link: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  contentClass: PropTypes.string,
  showReadMore: PropTypes.bool,
  showButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func }))
}

export default withStyles(styles)(ThreadItem)
