import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import style from './style'
import SearchIcon from '@material-ui/icons/Search'
import Button from '../../components/Button'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'

const SearchInput = ({
  value = '',
  placeholder,
  onChange,
  classes,
  autoFocus = true,
  onClick,
  isSearchButton,
  customerSearch = false,
  disabled
}) => {
return (
    <div className={!customerSearch ? classes.container : classes.custSearchContainer}>
        <div className={classes.searchIconContainer}>
          <SearchIcon className={classes.icon} />
        </div>
        <input
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          type={'text'}
          className={classes.searchInput}
          autoCapitalize='none'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          autoFocus={autoFocus}
        />
        {isSearchButton &&
          <div className={classes.searchButton}>
            <Button
              buttonType='primary'
              onClick={onClick}
              disabled={disabled}
            >
              {translations('Search')}
            </Button>
          </div>
        }
      </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isSearchButton: PropTypes.bool,
  disabled: PropTypes.bool
}

export default withStyles(style)(SearchInput)
