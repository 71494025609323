import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import _ from 'lodash'

import { formId } from '../CustomerRegistrationForm'
import { selectors as authSelectors } from '../../../../../store/modules/auth'

import CommunicationOptions from './CommunicationOptions'

import { languages as languageOptions } from '../../../../../config'

const mapStateToProps = state => {
  const formValues = getFormValues(formId)(state)
  const isStoreMarketingSelected = _.get(formValues, 'storeMarketing')
  return {
    languageOptions,
    storesOptions: authSelectors.getUserStoreOptions(state),
    isStoreMarketingSelected
  }
}

export default connect(mapStateToProps)(CommunicationOptions)
