import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { actions as chatActions } from '../../store/modules/chat'
import { translations } from '../../config'
import ChatWindow from './ChatWindow'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import IconButton from '@material-ui/core/IconButton'
import MUIBadge from '@material-ui/core/Badge'

const Container = styled.div`
  position: fixed;
  z-index: 1300;

  top: ${({ open, theme }) => open ? `${theme.headerHeight}px` : 'auto'};
  bottom: ${({ open }) => open ? '0' : '110px'};
  right: ${({ open }) => open ? '0' : '40px'};

  width: ${({ open }) => open ? '100%' : '48px'};
  height: ${({ open }) => open ? 'auto' : '48px'};

  ${(props) => `${props.theme.breakpoints.up('sm')}
    {
      top: auto;
      bottom: 110px;
      right: 40px;
      width: auto;
    }`
  }
`
const ToggleChatButtonContainer = styled.div`
  display: ${({ open }) => open ? 'none' : 'block'};
  position: absolute;
  z-index: 1400;
  bottom: -20px;
  right: -20px;
  ${(props) => `${props.theme.breakpoints.up('sm')}
    {
      display: block;
      bottom: -20px;
      right: -20px;
    }`
  }
`

const ToggleChatButton = styled(IconButton)`
  background-color: #1a1a1a;
  &:hover {
    background-color: #1a1a1a;
  }
`

const ToggleChatIcon = styled(ChatBubbleIcon)`
  color: ${({ theme }) => `${theme.palette.primary.contrastText};`}
`

const Badge = styled(MUIBadge)`
  & > span {
    top: 6px;
    right: 6px;
  }
`

const Chat = ({ channelId, userIdentity, onChannelJoined, senderName, recipiantName }) => {
  const dispatch = useDispatch()
  const memberIdentity = `${channelId}:${userIdentity}`
  const [twilioChannel, setTwilioChannel] = useState({})
  const [messages, setMessages] = useState([])
  const [messagesMeta, setMessagesMeta] = useState(undefined)
  const [hasPrevPage, setHasPrevPage] = useState(false)
  const [messageInputText, setMessageInputText] = useState('')
  const [pendingMessage, setPendingMessage] = useState(false)
  const [fetchingPrevMessages, setFetchingPrevMessages] = useState(false)
  const [twilioConnectError, setTwilioConnectError] = useState(false)
  const [messageSendError, setMessageSendError] = useState('')
  const [connectionState, setConnectionState] = useState('connecting')
  const [open, setOpen] = useState(false)
  const [badgeCount, setBadgeCount] = useState(0)
  const [lastConsumedMessageIndex, setLastConsumedMessageIndex] = useState(0)

  useEffect(() => {
    const connectToChat = async () => {
      try {
        const { token } = await dispatch(chatActions.fetchChatToken({ channelId, userIdentity }))
        const Chat = require('twilio-chat')
        const client = await Chat.Client.create(token)

        client.on('channelJoined', async (channel) => {
          setTwilioChannel(channel)
          console.log('twilio channel joined', channel)
          const channelMessages = await channel.getMessages()
          setMessages(_.get(channelMessages, 'items', []))
          setHasPrevPage(_.get(channelMessages, 'hasPrevPage', false))
          setMessagesMeta(channelMessages)

          const members = await channel.getMembers()
          const member = _.find(members, (member) => member.state.identity === memberIdentity)
          if (member) {
            setLastConsumedMessageIndex(_.get(member, 'state.lastConsumedMessageIndex', 0))
          }

          if (onChannelJoined) {
            onChannelJoined({ userIdentity, channelId })
          }
        })

        client.on('messageAdded', async (message) => {
          const activeChannel = message.channel
          const channelMessages = await activeChannel.getMessages()
          const messageItems = _.get(channelMessages, 'items', [])

          // if message is mine - updateLastConsumedMessageIndex
          if (_.get(message, 'state.author') === memberIdentity) {
            await activeChannel.updateLastConsumedMessageIndex(_.get(message, 'state.index', 0))
          }

          setMessages(messageItems)
          setHasPrevPage(_.get(channelMessages, 'hasPrevPage', false))
          setMessagesMeta(channelMessages)
        })

        client.on('memberUpdated', (data) => {
          if (_.get(data, 'member.state.identity') === memberIdentity) {
            setLastConsumedMessageIndex(_.get(data, 'member.state.lastConsumedMessageIndex', 0))
          }
        })

        client.on('connectionStateChanged', (connectionState) => {
          setConnectionState(connectionState)
        })

        client.on('channelRemoved', (channel) => {
          if (_.get(channel, 'channelState.uniqueName') === channelId) {
            setConnectionState('disconnected')
          }
        })

        const { items: publicChannels } = await client.getPublicChannelDescriptors()
        const channelRef = _.find(publicChannels, (channel) => channel.uniqueName === channelId) 

        if (!channelRef) {
          // create the channel
          const channelToJoin = await client.createChannel({ uniqueName: channelId })
          await channelToJoin.join()
        } else {
          // join existing chanel (if not already joined)
          const channelToJoin = await channelRef.getChannel()
          if (channelToJoin.channelState.status !== 'joined') {
            await channelToJoin.join()
          }
        }
      } catch (error) {
        console.log('connect to chat error', error)
        setTwilioConnectError(true)
      }
    }

    connectToChat()
  }, [channelId, userIdentity])

  useEffect(() => {
    if (open) {
      try {
        const lastMessageIndex = _.get(_.last(messages), 'state.index', 0)
        if (_.isFunction(twilioChannel.updateLastConsumedMessageIndex)) {
          twilioChannel.updateLastConsumedMessageIndex(lastMessageIndex)
        }
      } catch (error) {
        console.log('update last consumer message index error', error)
      }
    }
  }, [open])

  useEffect(() => {
    const lastMessageIndex = _.get(_.last(messages), 'state.index', 0)
    const unreadMessagesCount = (open) ? 0 : lastMessageIndex - lastConsumedMessageIndex
    setBadgeCount(unreadMessagesCount)
  }, [lastConsumedMessageIndex, messages])

  const onInputChange = useCallback((e) => {
    setMessageInputText(e.target.value)
    setMessageSendError('')
  }, [])

  const onSendClick = useCallback(async () => {
    setPendingMessage(true)
    setMessageSendError('')
    try {
      await twilioChannel.sendMessage(messageInputText)
      setPendingMessage(false)
      setMessageInputText('')
    } catch (error) {
      console.log('message send error', error)
      setPendingMessage(false)
      setMessageSendError(translations('Chat - send message failed text'))
    }
  }, [messageInputText, twilioChannel])

  const onPrevPageClick = useCallback(async () => {
    setFetchingPrevMessages(true)
    try {
      const prevMessages = await messagesMeta.prevPage()
      const newMessages = [..._.get(prevMessages, 'items', []), ...messages]
      setMessagesMeta(prevMessages)
      setMessages(newMessages)
      setFetchingPrevMessages(false)
    } catch (error) {
      setFetchingPrevMessages(false)
      console.log('fetch prev messages error', error)
    }
  }, [messagesMeta, messages])

  return (
    <Container open={open}>
      <ToggleChatButtonContainer open={open}>
        <Badge badgeContent={badgeCount} color="secondary">
          <ToggleChatButton
            onClick={() => setOpen(!open)}
            aria-label={(open) ? translations('Chat - close chat window button text') : translations('Chat - open chat window button text')}
          >
            <ToggleChatIcon />
          </ToggleChatButton>
        </Badge>
      </ToggleChatButtonContainer>

      {open && (
        <ChatWindow
          userIdentity={userIdentity}
          senderName={senderName}
          recipiantName={recipiantName}
          onSendClick={onSendClick}
          onPrevPageClick={onPrevPageClick}
          messages={messages}
          messagesMeta={messagesMeta}
          messageInputText={messageInputText}
          pendingMessage={pendingMessage}
          fetchingPrevMessages={fetchingPrevMessages}
          twilioConnectError={twilioConnectError}
          messageSendError={messageSendError}
          connectionState={connectionState}
          onInputChange={onInputChange}
          onClose={() => setOpen(false)}
          hasPrevPage={hasPrevPage}
        />
      )}
    </Container>
  )
}

Chat.propTypes = {
  /* id of twilio channel to create/connect to */
  channelId: PropTypes.string.isRequired,
  /* join twilio channel as either 'consultant' or 'customer' */
  userIdentity: PropTypes.oneOf(['consultant', 'customer']).isRequired,
  /* callback when channel is joined */
  onChannelJoined: PropTypes.func,
  /* label to show for messages from the sender */
  senderName: PropTypes.string,
  /* label to show for messages from the recipiant */
  recipiantName:  PropTypes.string
}

export default Chat