export default `
\n## LEGAL MENTIONS
\n
\n### UAE
\nBy signing this data card I hereby consent to my personal data being used and processed by ALLIEDvENTERPRISES LLC (the "Company"), trading under the Versace trademark, in order to provide me with customer service functions, marketing activities and other legitimate purposes without limitation. I understand and acknowledge that the Company shall not share such personal data to third parties, other than to the Company’s trusted business partners, affiliates or service providers in order to provide me with various services and to perform marketing activities. I further acknowledge that if I have any queries about how my personal data is processed, or to exercise any rights I may have under applicable law, I may contact the Company to exercise such rights
\nIf you do not want to receive such information anymore, you can just click on the “UNSUBSCRIBE” link in our marketing emails or ask our sales representatives in store to assist you in order to unsubscribe. 
\nYou may also unsubscribe from other communication channels by either speaking to one of our sales representatives or via the specific communication channel itself (e.g. SMS, WhatsApp, Telephone).
\n
\n### KUWAIT
\nBy signing this data card I hereby consent to my personal data being used and processed by Habchi & Chalhoub Trading w.l.l. (the "Company"), trading under the Versace trademark, in order to provide me with customer service functions, marketing activities and other legitimate purposes without limitation. I understand and acknowledge that the Company shall not share such personal data to
third parties, other than to the Company’s trusted business partners, affiliates or service providers in order to provide me with various services and to perform marketing activities. I further acknowledge that if I have any queries about how my personal data is processed, or to exercise any rights I may have under applicable law, I may contact the Company to exercise such rights
\nIf you do not want to receive such information anymore, you can just click on the “UNSUBSCRIBE” link in our marketing emails or ask our sales representatives in store to assist you in order to unsubscribe. 
\nYou may also unsubscribe from other communication channels by either speaking to one of our sales representatives or via the specific communication channel itself (e.g. SMS, WhatsApp, Telephone).
\n
\n### KSA
\nBy signing this data card I hereby consent to my personal data being used and processed by Fitra International Saudi Limited Co. (the "Company"), trading under the Versace trademark, in order to provide me with customer service functions, marketing activities and other legitimate purposes without limitation. I understand and acknowledge that the Company shall not share such personal data to third parties, other than to the Company’s trusted business partners, affiliates or service providers in order to provide me with various services and to perform marketing activities. I further acknowledge that if I have any queries about how my personal data is processed, or to exercise any rights I may have under applicable law, I may contact the Company to exercise such rights
\nIf you do not want to receive such information anymore, you can just click on the “UNSUBSCRIBE” link in our marketing emails or ask our sales representatives in store to assist you in order to unsubscribe. 
\nYou may also unsubscribe from other communication channels by either speaking to one of our sales representatives or via the specific communication channel itself (e.g. SMS, WhatsApp, Telephone).
\n
\n## MUSE LOYALTY PROGRAM
\nPoints will be credited to your online statement within 30 days. To check your statement, download the MUSE App from the App Store or Google Play.
\n
\nMuse Thank You Campaign: Promotional codes are individual codes valid for one time use only.
\nIn case of missing Points, please contact MUSE Concierge as per the contact us number per country here [Loyalty that rewards you across the region](https://www.experience-muse.com/contactus/en-gb), Currently, Points can be redeemed in store only. Other MUSE T&C’s apply.
\nFor further information on the programme, FAQ’s and T&C’s – please visit: [Loyalty that rewards you across the region](https://www.experience-muse.com/contactus/en-gb)
`