import React, { useEffect } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Modules, i18n, useTranslation } from '@redant/retailos-ui'
// DEPENDENCIES
import { httpClientService, useResourceDetails, distantCheckoutFunctions, basketCheckoutFunctions } from '../dependencies'
// ACTIONS/SELECTORS
import { getSelectedRegionStripeKey, getSelectRegionExternalId } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { regionsCombinedSelectors } from '../../store/modules/combinedSelectors'
import { selectors as appSelectors } from '../../store/modules/app'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../store/modules/currentOrder'
import { selectors as authSelectors } from '../../store/modules/auth'
import { actions as customerDetailsActions } from '../../store/modules/customerDetails'
import { checkBasketContainsOrderedProducts } from '../../helpers'
// SERVICES
import modalService from '../../services/modalService'
import CustomerSearch from '../../containers/Customer/CustomerSearch'
// CONFIG
import { environment, addressLookupCountries, countryOptionsForDropdowns, countryCodesForAddressLookup, countryPhoneCodes } from '../../config'
import './uiConfig'
import { getUiConfig } from '../uiConfigStore'
import { confirmationModal } from './provideModalConfig'
import { defaultSchemaConfig, endlessAisleSchemaConfig, distantSalesSchemaConfig } from './addressFormSchema.custom'
import checkoutCityConfig from './config/cityConfig'

// TRANSLATIONS
import enCheckout from '../i18n/en/Checkout.json'
import enCities from '../i18n/en/Cities.json'
import enAreas from '../i18n/en/Areas.json'
import enCheckoutOrderBreakdown from '../i18n/en/CheckoutOrderBreakdown.json'
import arCheckout from '../i18n/ar/Checkout.json'
import arCities from '../i18n/ar/Cities.json'
import arAreas from '../i18n/ar/Areas.json'
import arCheckoutOrderBreakdown from '../i18n/ar/CheckoutOrderBreakdown.json'

const languageSelect = (language) => {
  switch (language) {
    case 'en':
      i18n.addResourceBundle('chalhoub:en', 'Checkout', enCheckout, true, true)
      i18n.addResourceBundle('chalhoub:en', 'Checkout', enCities, true, true)
      i18n.addResourceBundle('chalhoub:en', 'Checkout', enAreas, true, true)
      i18n.addResourceBundle('chalhoub:en', 'CheckoutOrderBreakdown', enCheckoutOrderBreakdown, true, true)
      i18n.changeLanguage('chalhoub:en')
      break
      case 'ar':
      i18n.addResourceBundle('chalhoub:ar', 'Checkout', arCheckout, true, true)
      i18n.addResourceBundle('chalhoub:ar', 'Checkout', arCities, true, true)
      i18n.addResourceBundle('chalhoub:ar', 'Checkout', arAreas, true, true)
      i18n.addResourceBundle('chalhoub:ar', 'CheckoutOrderBreakdown', arCheckoutOrderBreakdown, true, true)
      i18n.changeLanguage('chalhoub:ar')
      break
    default:
      i18n.changeLanguage('chalhoub:en')
      break
  }
}

const provideChalhoubImplConfig = () => {
  const { t } = useTranslation('Checkout')
  const selectedLanguage = useSelector(appSelectors.getAppLanguage)
  const brandCheckoutConfig = useSelector(regionsCombinedSelectors.getCheckoutConfigForBrand) || {}
  const currentRegionCurrencyConfig = useSelector(regionsCombinedSelectors.getCurrencyConfigForSelectedRegion) || []
  const isGiftNoteEnabled = _.get(brandCheckoutConfig, 'enableGiftNote', false)
  const currentCurrency = useSelector(appSelectors.getAppCurrency) || 'AED' // TODO:  Set default based on brand config
  const currentRegionExternalId = useSelector(getSelectRegionExternalId)
  const userCountry = currentRegionExternalId.substring(0, 2)
  let cityOptions = _.get(checkoutCityConfig, userCountry)

  const chalhoubCheckoutImplConfig = {
    currentCurrency,
    brandCheckoutConfig,
    currentRegionCurrencyConfig,
    currentRegionExternalId,
    userCountry,
    cityOptions,
    t,
    language: selectedLanguage,
    isGiftNoteEnabled,
    showConfirmationModal: true // Feels like UI Config but is used in a useEffect in provideUseBasketCheckout
  }
  return chalhoubCheckoutImplConfig
}

export const BasketCheckout = ({ basket, onBack, initialState }) => {
  const endlessAisleCheck = () => {
    const { products } = useSelector(currentOrderSelectors.getCurrentOrderRaw)
    const orderedProducts = products.length > 0 && checkBasketContainsOrderedProducts(products)
    return orderedProducts
  }

  const chalhoubCheckoutImplConfig = provideChalhoubImplConfig()
  const {userCountry, cityOptions, t} = chalhoubCheckoutImplConfig
  const paymentPublicApiKey = useSelector(getSelectedRegionStripeKey)
  basketCheckoutFunctions.useCheckoutScreenMount()

  const countryPhoneCodeOptions = countryPhoneCodes.map(label => ({ label, value: label.split(' ')[1] }))
  const giftNoteEnabledOrderedProducts = chalhoubCheckoutImplConfig.isGiftNoteEnabled && endlessAisleCheck()

  useEffect(() => {
    languageSelect(chalhoubCheckoutImplConfig.language)
  }, [])

  const useAddressDetailsForm = Modules.Checkout.Implementation.provideUseAddressDetailsForm({
    addressFieldsConfig: _.merge({}, defaultSchemaConfig(countryPhoneCodeOptions, t), endlessAisleSchemaConfig(userCountry, cityOptions, t)),
    countryOptions: countryOptionsForDropdowns.flat(),
    useAddressSearch: false,
    saveCustomerAddress: true,
    customerDetailsActions,
    currentOrderSelectors,
    hooks: {}
  })

  const basketCheckoutImplConfig = {
    ...chalhoubCheckoutImplConfig
  }

  // This provides state such as customer and items
  const useBasketCheckout = Modules.Checkout.Implementation.provideUseBasketCheckout({
    checkoutFunctions: basketCheckoutFunctions,
    currentOrderActions,
    currentOrderSelectors,
    CustomerSearch,
    modalService,
    checkoutType: 'basketCheckout',
    basketCheckoutImplConfig, // Pass general basket checkout config through to checkout
    confirmationModal
  })

  const implementation = Modules.Checkout.Implementation.provideUseRootScreen({
    checkoutFunctions: basketCheckoutFunctions,
    basket,
    initialState,
    paymentPublicApiKey: paymentPublicApiKey,
    modalService,
    hooks: {
      useAddressDetailsForm,
      useBasketCheckout,
      useResourceDetails
    }
  })

  const uiConfig = _.merge(
    {},
    { ...getUiConfig('Modules.Checkout.Screens.RootScreen') },
    {
      saveTransaction: {
        confirmOrderReview: true
      },
      features: {
        orderNotes: false
      },
      selectPaymentMethodScreen: false
    }
  )
  
  return <Modules.Checkout.Screens.RootScreen implementation={implementation} onBack={onBack} uiConfig={uiConfig} />
}

export const DistantCheckout = ({ basket, onBack, initialState }) => {
  const chalhoubCheckoutImplConfig = provideChalhoubImplConfig()
  const {userCountry, cityOptions, t} = chalhoubCheckoutImplConfig

  const paymentPublicApiKey = useSelector(getSelectedRegionStripeKey)
  distantCheckoutFunctions.useCheckoutScreenMount()

  const countryPhoneCodeOptions = countryPhoneCodes.map(label => ({ label, value: label.split(' ')[1] }))

  useEffect(() => {
    languageSelect(chalhoubCheckoutImplConfig.language)
  }, [])

  // If addressLookupCountries is set in config, use that, otherwise use countryCodesForAddressLookup which is all countries
  const addressLookup = addressLookupCountries.length > 0 ? addressLookupCountries : countryCodesForAddressLookup
  const useAddressLookup = Modules.Checkout.Implementation.provideUseAddressLookup({
    httpClientService,
    pcaKey: environment.PCA_KEY,
    locationLookupCode: addressLookup && addressLookup.join(',')
  })

  const useAddressDetailsForm = Modules.Checkout.Implementation.provideUseAddressDetailsForm({
    addressFieldsConfig: _.merge({}, defaultSchemaConfig(countryPhoneCodeOptions, t), distantSalesSchemaConfig(userCountry, cityOptions, t)),
    countryOptions: countryOptionsForDropdowns.flat(),
    useAddressSearch: false,
    saveCustomerAddress: true,
    customerDetailsActions,
    currentOrderSelectors,
    hooks: {
      useAddressLookup
    }
  })

  const basketCheckoutImplConfig = {
    ...chalhoubCheckoutImplConfig
  }

  // This provides state such as customer and items
  const useBasketCheckout = Modules.Checkout.Implementation.provideUseBasketCheckout({
    currentOrderActions,
    currentOrderSelectors,
    CustomerSearch,
    modalService,
    checkoutType: 'distantCheckout',
    basketCheckoutImplConfig, // Pass general basket checkout config through to checkout
    confirmationModal
  })

  const implementation = Modules.Checkout.Implementation.provideUseRootScreen({
    checkoutFunctions: distantCheckoutFunctions,
    basket,
    initialState,
    paymentPublicApiKey: paymentPublicApiKey,
    modalService,
    hooks: {
      useAddressDetailsForm,
      useBasketCheckout,
      useResourceDetails
    }
  })

  const uiConfig = _.merge(
    {},
    { ...getUiConfig('Modules.Checkout.Screens.RootScreen') },
    {
      selectPaymentMethodScreen: false,
      features: {
        orderNotes: chalhoubCheckoutImplConfig.isGiftNoteEnabled
      }
    }
  )

  return <Modules.Checkout.Screens.RootScreen implementation={implementation} onBack={onBack} uiConfig={uiConfig} />
}
