import React from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'

import ButtonLink from '../../components/ButtonLink'
import { theme } from '../../config/theme'
import media from '../../config/media'

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`

const StyledTableHead = styled.thead`
  display: none;
  background-color: ${theme.palette.lightGrey.main};
  ${media.greaterThan('md')`
    display: table-header-group;
  `}
`

const StyledRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(0,0,0,0.1);
  padding: 5px;
  ${media.greaterThan('md')`
    border: 0;
    padding: 0;
    display: table-row;
    '&:hover': {
      backgroundColor: ${theme.palette.tableHoverGrey.main}
    }
  `}
`

const StyledCell = styled.td`
  padding: 5px;
  display: block;
  width: ${({ mobileSize }) => mobileSize || '50%'};
  box-sizing: border-box;
  font-size: 12px;
  ${media.greaterThan('md')`
    padding: 15px 22px;
    display: table-cell;
    width: auto;
    font-size: inherit;
  `}
`

const StyledHeaderCell = styled(StyledCell)`
  font-weight: bold;
`

const CellHeader = styled.div`
  display: block;
  font-size: 10px;
  margin-bottom: 3px;
  ${media.greaterThan('md')`
    display: none;
  `}
`

const Table = (props) => {
  const {
    columns,
    data,
    onRowClick
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    }
  )

  const renderCell = (cell) => {
    const cellProps = cell.getCellProps()
    const handleRowClick = (e) => {
      e.stopPropagation()
      onRowClick && onRowClick(cell)
    }

    const handleCellClick = (e) => {
      e.stopPropagation()
      cell.column.onClick(cell)
    }

    return <StyledCell key={cellProps.key} {...cellProps} onClick={handleRowClick}>
      <CellHeader>
        {cell.column.Header}
      </CellHeader>
      {cell.column.onClick
        ? <ButtonLink onClick={handleCellClick}>{cell.value}</ButtonLink>
        : <span>{cell.render('Cell')}</span>}
    </StyledCell>
  }

  return (
    <StyledTable {...getTableProps()}>
      <StyledTableHead>
        {headerGroups.map((headerGroup, i) => {
          const headerGroupProps = headerGroup.getHeaderGroupProps()
          return (
            <tr key={headerGroupProps.key} {...headerGroupProps}>
              {headerGroup.headers.map(column => {
                const columnHeaderProps = column.getHeaderProps()
                return (
                  <StyledHeaderCell key={columnHeaderProps.key} {...columnHeaderProps}>
                    {column.render('Header')}
                  </StyledHeaderCell>
                )
              })}
            </tr>
          )
        })}
      </StyledTableHead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          const rowProps = row.getRowProps()
          return (
            <StyledRow key={rowProps.key} {...rowProps}>
              {row.cells.map(cell => renderCell(cell))}
            </StyledRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default Table
