import contentBoxStyle from '../../../components/ContentBox/style'

export default theme => ({
  container: {
    margin: '30px 7.5%'
  },
  contentBoxBody: {
    display: 'block',
    padding: 0
  },
  content: {
    ...contentBoxStyle(theme).content,
    display: 'block',
    borderTop: `0.5px solid ${theme.palette.contentBoxGrey.main}`,
    '&:first-child': {
      borderTop: 0
    }
  },
  meta: {
    display: 'grid',
    gridGap: '10px 20px',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'flex-start',
    '& > div:nth-child(2n-1)': {
      textTransform: 'uppercase'
    }
  },
  previewHeader: {
    borderTop: `0.5px solid ${theme.palette.contentBoxGrey.main}`,
    borderBottom: 0
  },
  previewBox: {
    padding: '33px 0 69px'
  },
  previewBoxUnclickable: {
    pointerEvents: 'none',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  emailBox: {
    backgroundColor: 'white',
    maxWidth: '800px',
    margin: '0 auto',
    boxShadow: '0 0 24px 0 rgba(0,0,0,0.22), 0 24px 24px 0 rgba(0,0,0,0.3)'
  },
  alignCenter: {
    textAlign: 'center'
  }
})
