const styles = theme => ({
  ringChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12

  },
  ringChartTitle: {
    textTransform: 'uppercase',
    marginBottom: 15
  },
  rechartsWrapper: {
  },
  dashBoardWidth: {
    width: 360
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
    height: '100%'
  },
  pieContainer: {
    flex: 3,
    position: 'relative'
  },
  legendContainer: {
    flex: 2,
    fontSize: 12,
    color: theme.palette.chartLegendsGrey.main,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 20
  },
  keyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 0'
  },
  keySquare: {
    width: 15,
    height: 15,
    marginRight: 10,
    flexShrink: 0
  },
  keyName: {
    color: theme.palette.chartLegendsGrey.main
  },
  keyValue: {
    color: 'black'
  },
  pieTotal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'black',
    textTransform: 'uppercase'
  }
})

export default styles
