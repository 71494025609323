import _ from 'lodash'

import { translations } from '../config'

export const getStockCheckErrors = (inStoreStock, ecommerceStock, quantitySelected) => {
    if(inStoreStock === 0 && ecommerceStock === 0) {
        return undefined
    }
    else if(quantitySelected > inStoreStock && quantitySelected > ecommerceStock) {
        return translations(`error-lessStockToOrderAndInStoreThanRequired`, { ecommerceStock, inStoreStock })
    }
    else if (quantitySelected > inStoreStock){
        return translations(`error-lessStockInStoreThanRequired`, { inStoreStock })
    }
}