import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'

import handleFollow from './handleFollow'
import ViewCustomerScreen from './ViewCustomerScreen'
import { selectors as customerDetailsSelectors, actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { selectors as storesSelectors } from '../../../store/modules/stores'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as productWaitlistSelectors, actions as productWaitlistActions } from '../../../store/modules/productWaitlist'
import { selectors as appointmentsSelectors } from '../../../store/modules/appointments'
import { getRegionBrand } from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getAppConfig } from '../../../config'
import { communicationOptions, translations, apps, followEnable } from '../../../config'
import analyticsService from '../../../services/analyticsService'
import imageUploadService from '../../../services/imageUploadService'
import handleNotifyMe from './handleNotifyMe'
import modalService from '../../../services/modalService'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { groupByDate } from '../../../helpers'
import handleAddContactIos from './handleAddContactIos' 
import { selectors as currentRouteSelectors} from '../../../store/modules/routing'

const reduceArrayToObject = (array, value) => array.reduce((o, key) => ({ ...o, [key]: value }), {})

const onCommunicationSubmit = ({ params, onSubmit }) => {
  const { customerMarketingChannels, customerMarketingPreferences, marketingStoreIds, preferredLanguage, details, id } = params
  const { channelKeys, channelKeysInDetails, preferenceKeys } = communicationOptions

  // all values as an object
  const allPreferences = reduceArrayToObject(preferenceKeys, false)
  const allChannels = reduceArrayToObject(channelKeys, false)
  const allChannelsInDetails = reduceArrayToObject(channelKeysInDetails, false)

  const customerMarketingChannelsInColumn = customerMarketingChannels
    .filter((trueRec) => {
      return trueRec === channelKeys.find((record) => 
        {return record === trueRec}
        ) });

  const customerMarketingChannelsInDetails = customerMarketingChannels
    .filter((trueRec) => {
      return trueRec === channelKeysInDetails.find((record) => 
        {return record === trueRec}
        ) })

  // truthy values as an object
  const newTruthyPreferences = reduceArrayToObject(customerMarketingPreferences, true)
  const newTruthyChanels = reduceArrayToObject(customerMarketingChannelsInColumn, true)
  const newTruthyChanelsInDetails = reduceArrayToObject(customerMarketingChannelsInDetails, true)

  // new values
  const newParams = {
    ...allPreferences,
    ...allChannels,
    ...newTruthyPreferences,
    ...newTruthyChanels,
    id
  }
  
  if (preferredLanguage) {
    newParams.details = {
      ...details,
      preferredLanguage,
    }
  }

  newParams.details = {
    ...newParams.details,
    ...allChannelsInDetails,
    ...newTruthyChanelsInDetails,
  }

  // only add storeIds if storeMarketing is selected
  if (newParams.storeMarketing) {
    newParams.marketingStoreIds = marketingStoreIds
  }

  return onSubmit(newParams)
}

const onCustomerDetailsSubmit = ({ params, onSubmit }) => {
  const customerId = _.get(params, ['id'])
  const details = _.omit(params, ['id'])
  return onSubmit({
    id: customerId,
    details
  })
}

const onCustomerLoyaltySubmit = ({ params, onSubmit }) => {
  const customerId = _.get(params, 'id')
  const details = {
    museId: _.get(params, 'museId'),
    museOptIn: _.get(params, 'museOptIn'),
    loyaltyType: _.get(params, 'loyaltyType')
  }
  return onSubmit({
    id: customerId,
    details
  })
}

const onCustomerListSubmit = ({ params, onSubmit, submitType }) => {
  const customerId = _.get(params, 'id')
  const list = _.get(params, submitType)

  if (submitType === 'wishlist') {
    analyticsService.sendCustomPDPEvent({ name: 'addToWishlist', wishlistLength: list.length, customerId })
  }

  const newListPromises = list.map(item => {
    if (item.type === 'image' && item.local) {
      return imageUploadService.upload(item.url)
        .then(remoteFileUrl => {
          return {
            ...item,
            local: false,
            url: remoteFileUrl
          }
        })
    }
    return item
  })

  return Promise.all(newListPromises)
    .then(newList => {
      return onSubmit({
        id: customerId,
        details: {
          [submitType]: newList
        }
      })
    })
}

const mapStateToProps = state => {
  const brandName = getRegionBrand(state)
  const shouldShowAddContactButton = window.cordova && getAppConfig('CUSTOMER_MANAGEMENT', 'saveToIosContactsEnabled', false, brandName)
  const customerDetails = customerDetailsSelectors.getCustomer(state)

  const isCustomerAnonymised = customerDetailsSelectors.getIsCustomerAnonymised(state)
  const isUserAdmin = authSelectors.getIsUserAdmin(state)
  const canEditPreferredSA = authSelectors.getEditPreferredSA(state)
  const customerWaitlist = productWaitlistSelectors.getProductWaitlistForCustomer(state)
  const isFollowing = customerDetailsSelectors.getIsCustomerFollowed(state)
  const shouldShowAnonymiseButton = isUserAdmin && !isCustomerAnonymised
  const shouldShowFollowButton = (!isCustomerAnonymised) || (isCustomerAnonymised && isFollowing)
  const isCustomerEditable = !_.get(customerDetails, 'anonymised')
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const shouldShowMessageButton = isMessagingAllowedForRole && !isCustomerAnonymised && apps.MESSAGING
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const customerCurrentlyInBasket = currentOrderCustomer && customerDetails && currentOrderCustomer.id === customerDetails.id
  const phoneMarketingOptIn =  _.includes(_.get(customerDetails, 'customerMarketingChannels', []), 'phoneMarketing') 
  const isConsultationMode = _.startsWith(currentRouteSelectors.getLocationPathname(state),'consultations', 1) 

  const groupedAppointments = groupByDate(
    appointmentsSelectors.getAppointments(state),
    'startDateTime',
    { today: translations("Today's Appointments"), tomorrow: translations("Tomorrow's Appointments"), upcoming: translations('Upcoming Appointments'), expired: translations('Previous Appointments') }
  )

  return {
    customer: customerDetails,
    isCustomerDetailLoading: customerDetailsSelectors.getInitialCustomerDetailIsLoading(state),
    isStoresLoading: storesSelectors.getStoresIsLoading(state),
    isWaitlistLoading: productWaitlistSelectors.getIsLoading(state),
    initialValues: customerDetails,
    waitlistInitialValues: customerWaitlist,
    shouldShowAnonymiseButton,
    canEditPreferredSA,
    shouldShowFollowButton,
    shouldShowAddContactButton,
    isCustomerEditable,
    userSelectedStoreId: authSelectors.getUserSelectedStoreId(state),
    customerFirstName: _.get(customerDetails, 'firstName'),
    shouldShowMessageButton,
    currentOrderCustomer,
    customerCurrentlyInBasket,
    appointments: groupedAppointments,
    phoneMarketingOptIn: phoneMarketingOptIn,
    isConsultationMode
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  onSubmit: params => {
    let newParams = _.omit(params, ['nationality', 'latestMessage', 'submitType'])
    newParams['details'] = {
      ..._.get(params, 'details', {}),
      nationality: params.nationality
    }

    return Promise.resolve(newParams)
      .then(finalParams => {
        return dispatch(customerDetailsActions.updateCustomer(finalParams))
      })
  },
  handleAddCustomerToOrder: ({ customer, currentOrderCustomer }) => {
    if (currentOrderCustomer) {
      modalService.action({
        title: translations('Add Customer To Basket Confirm Customer Overwrite Title'),
        text: translations('Add Customer To Basket Confirm Customer Overwrite Text', {
          customer: _.compact([currentOrderCustomer.firstName, currentOrderCustomer.lastName]).join(' ')
        }),
        actions: [
          {
            text: translations('Cancel')
          },
          {
            text: translations('Replace'),
            primary: true,
            onClick: () => {
              dispatch(currentOrderActions.clearOrderExceptBasket())
              dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
            }
          }
        ]
      })
    } else {
      dispatch(currentOrderActions.clearOrderExceptBasket())
      dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
    }
  },
  handleSubmitWaitlist: ({ storeId, products, customerId }) => {
    return dispatch(productWaitlistActions.addToCustomerProductWaitlist({
      replace: true,
      storeId,
      customerId,
      productIds: _.map(products, p => {
        return p.id
      })
    }))
  },
  handleActionClick: ({ ...customer }) => {
    return handleFollow({ ...customer, dispatch })
  },
  handleAddContact: async ({ customer }) => {
    handleAddContactIos(customer)
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onCommunicationSubmit: ({ dispatch, onSubmit }) => params => {
      return onCommunicationSubmit({ params, onSubmit })
    },
    onCustomerPreferencesSubmit: ({ dispatch, onSubmit }) => params => {
      return onCustomerDetailsSubmit({ params, onSubmit })
    },
    onCustomerLoyaltySubmit: ({ dispatch, onSubmit }) => params => {
      return onCustomerLoyaltySubmit({ params, onSubmit })
    },
    onSubmitWishlist: ({ dispatch, onSubmit }) => params => {
      return onCustomerListSubmit({ params, onSubmit, submitType: 'wishlist' })
    },
    onSubmitRecommendedProducts: ({ dispatch, onSubmit }) => params => {
      return onCustomerListSubmit({ params, onSubmit, submitType: 'recommendedProducts' })
    },
    onSubmitWaitlist: ({ dispatch, initialValues: customer, userSelectedStoreId, handleSubmitWaitlist }) => ({ waitlist }) => {
      return handleSubmitWaitlist({
        products: waitlist,
        customerId: customer.id,
        storeId: userSelectedStoreId,
        dispatch
      })
    },
    onSubmitAppointments: ({ onSubmit }) => params => {
      return onCustomerDetailsSubmit({ params, onSubmit })
    },
    onActionsClick: ({
      dispatch,
      customer,
      shouldShowFollowButton,
      shouldShowMessageButton,
      shouldShowAddContactButton,
      handleAddCustomerToOrder,
      customerCurrentlyInBasket,
      currentOrderCustomer,
      handleActionClick,
      handleAddContact,
    }) => () => {
      modalService.action({
        title: translations('Customer Actions'),
        buttonDirection: 'column',
        actions: [
          ...shouldShowFollowButton && followEnable ? [{
            text: translations(customer.following ? 'Unfollow' : 'Follow'),
            onClick: () => handleActionClick({ ...customer, dispatch }),
            primary: true,
            fullWidth: true
          }] : [],
          ...shouldShowMessageButton ? [{
            text: translations('Message'),
            onClick: () => dispatch(createMessageActions.updateReceipient(customer)),
            primary: true,
            fullWidth: true
          }] : [],
          ...shouldShowFollowButton ? [{
            text: translations('Follow Up'),
            onClick: () => handleNotifyMe({ dispatch, customer }),
            primary: true,
            fullWidth: true
          }] : [],
          ...shouldShowAddContactButton ? [{
            text: translations('Save Contact'),
            onClick: () => handleAddContact({ customer, currentOrderCustomer }),
            primary: true,
            fullWidth: true
          }] : [],
          {
            text: translations('Add Customer To Basket'),
            onClick: () => handleAddCustomerToOrder({ customer, currentOrderCustomer }),
            primary: true,
            fullWidth: true,
            disabled: customerCurrentlyInBasket
          }
        ]
      })
    }
  }),
  withPropsOnChange(
    ['isCustomerDetailLoading', 'isStoresLoading'],
    ({ isCustomerDetailLoading, isStoresLoading }) => ({
      isLoading: isCustomerDetailLoading || isStoresLoading
    })
  )
)(ViewCustomerScreen)
