import { connect } from 'react-redux'
import { compose } from 'recompose'
import { matchPath } from 'react-router'

import { selectors as categorySelectors } from '../../../store/modules/categories'
import { actions as productsActions } from '../../../store/modules/products'
import withFullScreenLoader from '../../../components/FullScreenLoader'

import CategoryScreen from './CategoryScreen'

const mapStateToProps = (state, props) => {
  const catId = props.catId
  const isLoading = categorySelectors.getIsLoading(state)
  const category = categorySelectors.getCategoryById(state, catId)
  const matchRouterPath = props.location && props.location.pathname ? !!matchPath(props.location.pathname, '/products/cat/:catId/all') : null
  const matchModalPath = props.modalPath === '/products/cat/:catId/all'
  const isAllCategory = matchRouterPath || matchModalPath
  const shouldLoadProducts = isAllCategory || !categorySelectors.getHasChildCategoriesById(state, catId)
  return {
    shouldLoadProducts,
    category,
    isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: params => dispatch(productsActions.searchProductsFresh(params)),
  fetchFilters: params => dispatch(productsActions.fetchFilters(params))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFullScreenLoader
)(CategoryScreen)
