import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { compose, withHandlers } from 'recompose'
import StockInOtherStores from './StockInOtherStores'
import modalService from '../../../../../services/modalService'

import * as variantsCombinedSelectors from '../../../../../store/modules/combinedSelectors/variantsCombinedSelectors'
import { selectors as ProductDetailSelectors } from '../../../../../store/modules/productDetails'
import { selectors as authSelectors } from '../../../../../store/modules/auth'

import { formNames } from '../../../../../config'
import StockAvailabilityModal from './StockAvailabilityModal'
import NoVariantSelected from './NoVariantSelected/NoVariantSelected'
 
const mapStateToProps = state => {
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')
  const currentStoreId = authSelectors.getUserSelectedStoreId(state)
  const allStock = variantsCombinedSelectors.matchStockWithStore(state)
  const dataStoreStock = _.filter(allStock, (stock) => _.get(stock, 'store.details.locationType') === 'dataStore') || []
  const ecommerceStock = variantsCombinedSelectors.getEcommerceStock(state)
  const storeStock = _.filter(allStock, (stock) => (
    _.get(stock, 'store.details.locationType') !== 'dataStore' &&
    _.get(stock, 'store.details.locationType') !== 'ecommerceStore'
  )) || []
  const product = ProductDetailSelectors.getProduct(state)
  const variant = _.find(product.variants, (o) => o.id === selectedVariantId)
  const selectedVariantStock = _.get(variant, 'stock')

  return {
    selectedVariantId,
    currentStoreId,
    storeStock,
    dataStoreStock,
    ecommerceStock,
    selectedVariantStock
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    openStoresList: ({ selectedVariantId, storeStock, dataStoreStock, ecommerceStock }) => () => {
      modalService.open({
        selectedVariantId,
        dataStoreStock,
        ecommerceStock,
        storeStock,
        component: StockAvailabilityModal,
        hideBottomBar: true,
        onBackClick: () => modalService.close()
      })
    },
    openNoVariantSelected: ({  }) => () => {
      return modalService.open({
        component: NoVariantSelected,
        hideBottomBar: true,
        onBackClick: () => modalService.close()
      })
    }
  })
)(StockInOtherStores)
