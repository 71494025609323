export default `
## PRIVACY POLICY

**PURPOSE** 

We respect the privacy of all of our users and are committed to protecting information collected. Personal information about you may be collected in several ways. 

We provide this Privacy Policy to help you to understand what we may do with any personal  information  that  we  obtain  from  you.  By  registering,  you  signify  your acceptance of this Privacy Policy and agree that we may collect, use and disclose your personal information as described in this Privacy Policy.   

**REGISTRATION** 

If you are aged under 18, or any such other minimum legal age as contained within the applicable laws of the Country of Operation (defined below) that you reside in, you must let your parent or guardian know about our Privacy Policy before you register. 

You agree to provide complete, accurate and current information about yourself, and to promptly update such information if there are any changes. We may change our registration or subscription requirements from time to time. 

We shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this paragraph. 

**PRIVACY AND SECURITY*** 

Any reference to "us", "our", or "we" means us (or our affiliates), operated by the following companies, in the following countries (the “Country(ies) of Operation”):  



|Country of Operation |Operated by: |
| - | - |
|Kingdom of Saudi Arabia (KSA) |Fitra International Saudi Company Limited, foreign limited liability company duly organized under the laws of the Kingdom of Saudi Arabia, with registered company number 4030119847 and registered address is at PO Box 8079 – Jeddah- Kingdom of Saudi Arabia |
|United Arab Emirates (UAE) |Allied Enterprises LLC, a limited liability company duly organized under the laws of the United Arab Emirates, under the license number 203385 whose registered address is at P.O. Box 30069 Dubai, United Arab Emirates |
|Kuwait |Habchi & Chalhoub Trading WLL, a company duly organized under the laws of Kuwait, under the Commercial License number 22814, registered address is at P.O. Box 21074 Safat 13071 Kuwait|

Personal information about you may be collected in several ways when you use our stores or any services. 

We provide this statement and our privacy policy ("**Privacy Policy**") to help you to understand what we may do with any personal information that we obtain from you.  

This Privacy Policy does not govern our collection or use of data about you through channels other than our stores, websites and social media accounts. 

**1 - What categories of Personal Information do we collect?** 

Our stores collect certain information when you interact with it, for example, our stores may collect "**Personal Information**", which is any information that can be used to identify, directly or indirectly, an individual. Our stores may also collect data that does not identify you specifically. The following is a list of Personal Information that we may collect and process about you: 

- Information that you provide by filling out forms online or in our stores, including when  you  register,  subscribe  to  any  services  including  but  not  limited  to interactive services (such as blogs, chat rooms, message boards, or other user forums);  enter  a  competition  or  promotion,  complete  a  survey  or  report  a problem with our stores: identification data, contact information, postal address, email address; 
- Your correct date of birth, which we may require that you submit accurately to ensure that you do not access our stores if you are not of a legal age to purchase products offered for sale on our stores; 
- Any  information  you  may  provide  when  you  contact  us  or  send  us  a correspondence; 
- Any preference of means of communication and of communication language, and of the preferred communication time; 
- Information necessary to identify you: your first and last name, email address, phone  numbers,  home  address,  date  of  birth,  nationality,  shipping  and credit/debit card billing address(es); 
- Details of any transactions, including the placement of any orders by you: payment information (for example credit card details, or other payment details which you must provide to receive products you have ordered from us) and your taste and preferences. 

You can access and review your Personal Information by approaching our sales personnel  in  store  or  by  contacting  our  authorized  representatives  at salesforce.crm@chalhoub.com who will be happy assist you in your request. You may also be able to access, review and edit your Personal Information from your personal account on our relevant brand website. 

If your Personal Information changes in any way or is incorrectly presented on our stores  you  should  immediately  update  or  correct  your  Personal  Information  (as applicable) by approaching our sales personnel who will be happy to assist you, or send an email to salesforce.crm@chalhoub.com. 

**2 - For which purposes do we use your Personal Information?** 

We collect the above mentioned Personal Information for the following purposes: 

(i)  For the performance of managing your account: 

- to manage your account and to serve your requests in general, 

(ii) On the basis of the data subject's consent: 

- to send you our newsletters as well as details of our products, special offers or promotional offers that may be of interest to you, 

(iii) For the purposes of the legitimate interests pursued by us: 

- to improve our understanding of your interests and concerns, to improve our understanding of your use of our products, for our internal marketing and demographic  studies:  we  may  use  your  information  to  make  our  data collection cards and products/services better, as we believe that it is also in our legitimate interest to better serve you and respond to your needs. 
- for security purposes: we may use information to protect our company and our clients against fraud, theft or any wrongdoing which may affect our activity as it is our legitimate interest to ensure the security of our activity. 

**3 - Who do we share your Personal Information with?** 

Personal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of that information as permitted by applicable law.  

Personal Information may also be shared with other divisions within our group of companies and our affiliates and business partners (including brand headquarters), on a need-to-know basis, for the above-mentioned purposes. 

Please be aware that if we are requested by any regulatory or government authority investigating any suspected activity to provide your Personal Information, we will be entitled to do so as permitted by applicable law. 

We may also disclose your Personal Information onto our carefully selected business partners or to our affiliated companies to enable them to send you information which may be of interest to you, subject to your consent. 

If you do not want to receive such information anymore, you can just click on the “UNSUBSCRIBE” link in our marketing emails or ask our sales representatives in store to assist you in order to unsubscribe. 

You may also unsubscribe from other communication channels by either speaking to one of our sales representatives or via the specific communication channel itself (e.g. SMS, WhatsApp, Telephone).

From time to time we may disclose Personal Information on a need-to-know basis: 

- To our service providers and subcontractors, including our affiliates, retained to perform  functions  on  our  behalf  or  to  provide  services  to  us,  such  as warehousing  and  delivery;  marketing  and  advertising;  data  processing; software  development;  information  technology  and  office  services;  legal, accounting, audit and other third party service providers; and further provided such service provider does not collect, use or disclose the personal information for any purpose other than to perform such functions or to provide services to us or as otherwise required by law; 
- To any prospective seller or buyer of our business or assets; 
- If we are under a duty to disclose or share your personal data in order to enforce or apply these terms and conditions and other agreements, to permit us to pursue available remedies or limit the damages that we may sustain, or protect our rights, property, safety or security and that of our employees, agents, contractors,  customers,  the  visitors  of  our  stores  or  others.  This  includes exchanging  information  with  other  companies  and  organizations  for  the purposes of fraud protection and credit risk reduction. 

**4 - Who do we transfer your Personal Information?** 

In certain cases, and for data hosting purposes Personal Information we collected about you may be transferred to affiliates, service providers, business partners located outside the EU to which Personal Information are disclosed in the GCC countries, including to entities that are located outside of the European Economic Area, which may not have an adequate level of protection.  

We have implemented appropriate safeguards with such data recipients by entering into data transfer agreements based on the European Commission standards clauses with such data recipients. You can ask for a copy of such appropriate safeguards by contacting us. 

**5 - How long do We keep your Personal Information for?** 

Personal Information collected for the purposes hereunder will be stored only as long as necessary for the purpose of your commercial relationship with us, or as required to comply with our legal obligations. 

If a judicial action is initiated, Personal Information may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by applicable law. 

Your Personal Information will not be kept in a form that allows you to be identified for any longer than is reasonably considered necessary by us for achieving the purposes for which it was collected or processed or as it is established in the applicable laws related to data retention periods. 

**6 - Your rights** 

Subject to applicable law, you may have the following rights:  

**(i)  Right of access** 

You have the right to confirm with us whether your Personal Information is processed, and if it is, to request access to that Personal Information including the categories of Personal Information processed, the purpose of the processing and the recipients or categories of recipients.  

**(ii)  Right to rectification**   

You may have the right to rectify inaccurate or incomplete your Personal Information.  **(iii)  Right to erasure**  

You may have the right to ask us to erase your Personal Information.  

**(iv)  Right to restriction of processing** 

In limited circumstances, you may have the right to request that we restrict processing of your Personal Information. 

**(v)  Right to data portability** 

You may have the right to receive your Personal Information, which you have provided to us, in a structured, commonly used and machine-readable format and you may have the right to transmit that data to another entity. 

**(vi)  Right to object and rights relating to automated decision-making** 

Under certain circumstances you may have the right to object, on grounds relating to your particular situation, at any time to the processing of your Personal Information by us and We can be required to no longer process your Personal Data.  

**(vii)  Right to provide instructions regarding the storage, deletion or disclosure of your Personal Information after your death.** 

You have the right to provide instructions on how we shall store, delete or disclose your Personal Information after your death. These instructions must be provided by e- mail and shall performed by us when reasonably possible to do so. 

When your Personal Information processing is based on your consent,  you may withdraw in writing any consent you previously provided to us at any moment. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. 

To exercise these rights, please liaise with our sales personnel in store or with our customer care, or send an email to salesforce.crm@chalhoub.com. 

You also have the right to lodge a complaint with the competent data protection supervisory authority in the Country of Operation that you reside in. 

**7 - Changes to this Policy** 

We reserve the right to modify this Privacy Policy at any time. Our Privacy Policy may change from time to time to reflect changes in our processing of your Personal Information. Any modifications of this Privacy Policy will be effective once published in our stores. We will notify you of any material changes as required by law. Please read the Privacy Policy and check back often.  

**8 – Contact** 

If you have any questions or queries related to this Privacy Policy, or would like to exercise your rights, please speak to one of our instore personnel who will be glad to assist you, alternatively you may contact[ salesforce.crm@chalhoub.com.](mailto:salesforce.crm@chalhoub.com) 

**9 - Miscellaneous** 

This Privacy Policy is reproduced in English and Arabic. If there is any inconsistency between the English text and the Arabic text, the English text will prevail. 

These terms shall be construed and governed by the laws of the relevant Country of Operation and the competent courts of the relevant Country of Operation shall have exclusive jurisdiction.  

## سياسة الخصوصية

**الغرض**

نحترم خصوصية جميع مستخدمينا ونلتزم بحماية المعلومات التي يتم جمعها. قد يتم جمع معلومات شخصية عنك بعدة طرق.

نحن نقدم سياسة الخصوصية الماثلة لمساعدتك على فهم ما قد نفعله بأي معلومات شخصية نحصل عليها منك. قيامك بالتسجيل يعني قبولك لسياسة الخصوصية الماثلة وموافقتك على أنه يجوز لنا جمع معلوماتك الشخصية واستخدامها والكشف عنها على النحو الموضَّح في سياسة الخصوصية الماثلة. 

**التسجيل**

إذا كان سنّك أقل من 18 عامًا، أو أقل من أي حد أدنى للسن القانوني المنصوص عليه في القوانين المعمول بها في بلد التشغيل (محدَّدة أدناه) التي تقيم فيها، فيجب عليك إخبار أحد والديك أو وليّ أمرك بسياسة الخصوصية الخاصة بنا قبل التسجيل.

توافق على تقديم معلومات كاملة ودقيقة ومحدَّثة عن نفسك، وتحديث هذه المعلومات على الفور إذا طرأت عليها أية تغييرات. قد نغير متطلبات التسجيل والاشتراك من وقتٍ لآخر.

لن نكون مسؤولين، بشكلٍ مباشر أو غير مباشر، بأي حال من الأحوال عن أي خسارة أو ضرر من أي نوع يتم تكبُّده نتيجة إخفاقك في الامتثال لهذه الفقرة أو فيما يتعلق بذلك.

**الخصوصية والأمان**

أي إشارة إلى "نحن" أو "خاصتنا" أو ضمائر المتكلمين تعنينا (أو الشركات التابعة لنا، التي تديرها الشركات التالية، في البلدان التالية ("بلد (بلدان) التشغيل"):

|بلد التشغيل|شركة الإدارة:|
| :-: | :-: |
|المملكة العربية السعودية|شركة فترا العالمية السعودية المحدودة، وهي شركة أجنبية ذات مسؤولية محدودة تخضع أصولاً لقوانين المملكة العربية السعودية، وتحمل رقم التسجيل 4030119847 ويقع عنوانها المسجَّل في ص. ب. 8079 - جدة - المملكة العربية السعودية.|
|الإمارات العربية المتحدة|شركة المشاريع المتضامنة ذ.م.م، وهي شركة ذات مسؤولية محدودة تخضع أصولاً لقوانين دولة الإمارات العربية المتحدة، وتحمل الرخصة رقم 203385، ويقع عنوانها المسجَّل في ص.ب. رقم 30069، دبي، الإمارات العربية المتحدة|
|الكويت|شركة حبشي وشلهوب، وهي شركة تخضع أصولاً لقوانين دولة الكويت، بموجب الرخصة التجارية رقم 22814، ويقع عنوانها المسجَّل في ص. ب. 21074 الصفاة 13071 الكويت|

قد يتم جمع معلومات شخصية عنك بعدة طرق عند استخدامك لمتاجرنا أو أي من خدماتنا.

نحن نقدم هذا البيان وسياسة الخصوصية الخاصة بنا (يُشار إليها فيما بعد بلفظ "سياسة الخصوصية") لمساعدتك على فهم ما قد نفعله بأي معلومات شخصية نحصل عليها منك.

لا تحكم سياسة الخصوصية الماثلة جمعنا أو استخدامنا للبيانات الخاصة بك من خلال قنوات أخرى غير متاجرنا ومواقعنا الإلكترونية وحساباتنا على مواقع التواصل الاجتماعي.

**1- ما هي فئات المعلومات الشخصية التي نجمعها؟**

تجمع متاجرنا معلومات معيّنة عند استخدامك لها، على سبيل المثال، قد تجمع متاجرنا "**معلومات شخصية**"؛ وهي أي معلومات يمكن استخدامها لتحديد هوية الفرد بشكل مباشر أو غير مباشر. وقد تجمع متاجرنا أيضًا بيانات لا تحدّد هويتك على وجه الخصوص. وفيما يلي قائمة بالمعلومات الشخصية التي يمكن أن نجمعها عنك ونقوم بمعالجتها:

- المعلومات التي تقدمها عن طريق استكمال النماذج عبر الإنترنت أو في متاجرنا، بما في ذلك عند التسجيل أو الاشتراك في أي خدمات والتي تشمل، على سبيل المثال لا الحصر، الخدمات التفاعلية (مثل المدونات أو غرف الدردشة أو لوحات الرسائل أو منتديات المستخدم الأخرى)، المشاركة في مسابقة أو عرض ترويجي أو استكمال استطلاع رأي أو الإبلاغ عن مشكلة في متاجرنا: بيانات التعريف، معلومات الاتصال، العنوان البريدي، عنوان البريد الإلكتروني؛
- تاريخ ميلادك الصحيح، والذي قد نطلب منك تقديمه بدقة لضمان عدم دخولك إلى متاجرنا إذا كنت دون السن القانونية لشراء المنتجات المعروضة للبيع في متاجرنا؛
- أي معلومات قد تقدمها عند الاتصال بنا أو مراسلتنا؛
- أي تفضيل بخصوص وسيلة الاتصال ولغة الاتصال ووقت الاتصال المفضّل؛
- المعلومات اللازمة لتحديد هويتك: الاسم الأول والأخير وعنوان البريد الإلكتروني وأرقام الهواتف وعنوان المنزل وتاريخ الميلاد والجنسية وعنوان الشحن وعنوان الفوترة عند الدفع بطاقة الائتمان/الخصم؛
- تفاصيل أي معاملات، بما في ذلك تسجيل أي طلبات من جانبك: معلومات الدفع (على سبيل المثال، تفاصيل بطاقة الائتمان أو تفاصيل دفع أخرى يجب عليك تقديمها لاستلام المنتجات التي طلبتها منا)، ميولك وتفضيلاتك.

يمكنك الوصول إلى معلوماتك الشخصية ومراجعتها من خلال التواصل مع موظفي المبيعات في المتجر أو عن طريق التواصل مع ممثلينا المفوَّضين على salesforce.crm@chalhoub.com والذين سيسعدون بمساعدتك في طلبك. قد تتمكّن أيضًا من الوصول إلى معلوماتك الشخصية ومراجعتها وتعديلها من حسابك الشخصي على الموقع الإلكتروني الخاص بعلامتنا التجارية ذات الصلة.

إذا طرأ تغيير على معلوماتك الشخصية بأي شكل من الأشكال أو تم تقديمها بشكل غير صحيح في متاجرنا، فيجب عليك على الفور تحديث أو تصحيح معلوماتك الشخصية (حسب الاقتضاء) من خلال التواصل مع موظفي المبيعات لدينا والذين سيسعدون بمساعدتك، أو من خلال مراسلتنا عبر البريد الإلكتروني على salesforce.crm@chalhoub.com.

**2- ما الأغراض التي نستخدم لأجلها معلوماتك الشخصية؟**

نجمع المعلومات الشخصية المذكورة أعلاه للأغراض التالية:

1) لممارسة مهام إدارة حسابك:
- لإدارة حسابك وخدمة طلباتك بشكل عام،
2) بناءً على موافقة صاحب البيانات:
- لنرسل إليك رسائلنا الإخبارية بالإضافة إلى تفاصيل منتجاتنا أو عروضنا الخاصة أو عروضنا الترويجية التي قد تهمك،
3) لأغراض المصالح المشروعة التي نسعى لتحقيقها:
- لتحسين فهمنا لاهتماماتك وشواغلك، ولتحسين فهمنا لاستخدامك لمنتجاتنا، ومن أجل التسويق الداخلي والدراسات الديموغرافية: قد نستخدم معلوماتك لتحسين بطاقات جمع البيانات الخاصة بنا ومنتجاتنا/خدماتنا، إذ أننا نعتقد أن تقديم خدمة أفضل للمستخدم وتلبية احتياجاته هو ضمن مصلحتنا المشروعة.
- لأغراض أمنية: قد نستخدم المعلومات لحماية شركتنا وعملائنا من الاحتيال أو السرقة أو أي مخالفات قد تؤثر على نشاطنا حيث أن ضمان أمن نشاطنا يعد ضمن مصلحتنا المشروعة.

**3-	مع مَن نشارك معلوماتك الشخصية؟**

قد يتم الكشف عن المعلومات الشخصية التي تقدمها إلى هيئة مرجع ائتماني أو هيئة منع الاحتيال، والتي قد تحتفظ بسجل لتلك المعلومات على النحو الذي يسمح به القانون المعمول به.

يمكن أيضًا مشاركة المعلومات الشخصية مع الأقسام الأخرى ضمن مجموعة شركاتنا والشركات التابعة لنا وشركاء الأعمال (بما في ذلك، مقر العلامة التجارية)، على أساس المعرفة على قدر الحاجة، للأغراض المذكورة أعلاه.

يُرجى العلم بأنه إذا طلبت منا أي سلطة تنظيمية أو حكومية تُحقق في أي نشاط مشتبه فيه، أن نقدم معلوماتك الشخصية، فسوف يحق لنا القيام بذلك على النحو الذي يسمح به القانون المعمول به.

قد نكشف أيضًا عن معلوماتك الشخصية لشركائنا التجاريين المختارين بعناية أو لشركاتنا التابعة لتمكينهم من أن يرسلوا إليك معلومات قد تهمك، رهنًا بموافقتك.

إذا كنت لا ترغب في تلقي مثل هذه المعلومات بعد الآن، فما عليك سوى الضغط على الرابط "إلغاء الاشتراك" الموجود في رسائل البريد الإلكتروني التسويقية الخاصة بنا أو طلب المساعدة من مندوبي المبيعات في المتجر لإلغاء الاشتراك.

يمكنك أيضًا إلغاء الاشتراك في قنوات الاتصال الأخرى إما عن طريق التحدث إلى أحد مندوبي المبيعات لدينا أو عبر قناة الاتصال نفسها (مثل الرسائل القصيرة أو تطبيق واتساب أو الهاتف).

قد نكشف من حينٍ لآخر عن معلومات شخصية على أساس المعرفة على قدر الحاجة:

- لمقدمي الخدمات والمتعاقدين من الباطن معنا، بما في ذلك الشركات التابعة لنا، المكلفة بأداء المهام نيابة عنا أو لتقديم الخدمات لنا، مثل التخزين والتوصيل؛ التسويق والدعاية، معالجة البيانات، تطوير البرمجيات؛ تقنية المعلومات والخدمات المكتبية؛ مقدمي الخدمات القانونية والمحاسبية ومراجعة الحسابات وغيرهم من مقدمي الخدمات الخارجيين؛ وشريطة ألا يقوم مقدم الخدمات هذا بجمع المعلومات الشخصية أو استخدامها أو الكشف عنها لأي غرض بخلاف أداء هذه المهام أو تقديم الخدمات لنا أو على النحو الذي يقتضيه القانون خلافًا لذلك؛

- لأي بائع أو مشترٍ محتمل لأعمالنا أو أصولنا؛

- إذا كنا ملزَمين بالكشف عن بياناتك الشخصية أو مشاركتها من أجل إنفاذ أو تطبيق هذه الشروط والأحكام والاتفاقيات الأخرى، للسماح لنا بمتابعة العلاجات المتاحة أو الحد من الأضرار التي قد نتعرض لها، أو حماية حقوقنا أو ممتلكاتنا أو سلامتنا أو أمننا وكذلك حماية حقوق أو ممتلكات أو سلامة أو أمن موظفينا ووكلائنا والمتعاقدين معنا وعملائنا وزوار متاجرنا أو غيرهم. ويشمل هذا تبادل المعلومات مع الشركات والمنظمات الأخرى لأغراض الحماية من الاحتيال والحد من مخاطر الائتمان.

**4- كيف ننقل معلوماتك الشخصية؟**

في حالات معينة، ولأغراض استضافة البيانات، قد يتم نقل المعلومات الشخصية التي جمعناها عنك إلى الشركات التابعة ومقدمي الخدمات وشركاء الأعمال المقيمين خارج الاتحاد الأوروبي الذين يتم الكشف عن المعلومات الشخصية إليهم في دول مجلس التعاون الخليجي، بما في ذلك الكيانات التي تقع خارج نطاق المنطقة الاقتصادية الأوروبية والتي قد لا تتمتع بمستوى كافٍ من الحماية.

**لقد قمنا بتطبيق الضمانات المناسبة مع متلقي البيانات من خلال إبرام اتفاقيات نقل البيانات استنادًا إلى بنود معايير المفوضية الأوروبية مع متلقي البيانات هؤلاء. ويمكنك طلب الحصول على نسخة من هذه الضمانات المناسبة عن طريق التواصل معنا.**

**5 - ما المدة التي نحتفظ فيها بمعلوماتك الشخصية؟**

سيتم تخزين المعلومات الشخصية التي يتم جمعها للأغراض الواردة أدناه فقط طالما كان ذلك ضروريًا لغرض علاقتك التجارية معنا، أو حسبما يقتضيه الامتثال لالتزاماتنا القانونية.

إذا تم اتخاذ إجراء قضائي، فقد يتم تخزين المعلومات الشخصية حتى نهاية هذا الإجراء؛ بما في ذلك أي فترات محتملة للاستئناف، ثم يتم حذفها أو أرشفتها على النحو الذي يسمح به القانون المعمول به.

لن يتم الاحتفاظ بمعلوماتك الشخصية بشكل يسمح بتحديد هويتك لفترة أطول مما نعتبره ضروريًا بشكل معقول لتحقيق الأغراض التي تم جمعها أو معالجتها من أجلها أو وفق ما هو منصوص عليه في القوانين المعمول بها المتعلقة بفترات الاحتفاظ بالبيانات.

**6 - الحقوق التي تتمتع بها**

رهنًا بالقانون المعمول به، قد تتمتع بالحقوق التالية:

(1)	حق الوصول

لديك الحق في أن تتحقّق معنا مما إذا كانت معلوماتك الشخصية يتم معالجتها، وإذا كان الأمر كذلك، يمكنك طلب الوصول إلى تلك المعلومات الشخصية بما فيها فئات المعلومات الشخصية التي يتم معالجتها والغرض من المعالجة والمتلقّين لتلك المعلومات أو فئات المتلقّين.

(2)	حق التصحيح   

قد يحق لك تصحيح معلوماتك الشخصية غير الدقيقة أو غير الكاملة.

(3)	الحق في المحو 

قد يحق لك مطالبتنا بمحو معلوماتك الشخصية.

(4)	حق تقييد المعالجة

في ظروف محدودة، قد يحق لك طلب تقييد معالجة معلوماتك الشخصية.

(5)	الحق في نقل البيانات

قد يكون لديك الحق في تلقّي معلوماتك الشخصية التي قدمتها إلينا بتنسيق منظم شائع الاستخدام ويمكن قراءته آليًا، وقد يكون لديك الحق في نقل تلك البيانات إلى جهة أخرى.

(6)	الحق في الرفض والحقوق المتعلقة باتخاذ القرارات المؤتمتة

في ظروف معينة، قد يكون لديك الحق في الاعتراض، لأسباب تتعلق بموقفك الخاص، في أي وقت على معالجة معلوماتك الشخصية من قِبلنا وقد يُطلب منا عدم معالجة بياناتك الشخصية بعد ذلك.

(7)	الحق في تقديم تعليمات فيما يتعلق بتخزين أو حذف أو الكشف عن معلوماتك الشخصية بعد وفاتك.

تتمتع بالحق في تقديم تعليمات حول كيفية تخزين معلوماتك الشخصية أو حذفها أو الكشف عنها بعد وفاتك. وينبغي تقديم هذه التعليمات عن طريق البريد الإلكتروني ونتولى تنفيذها عندما يكون ذلك ممكنًا بشكل معقول.

عندما تستند معالجة معلوماتك الشخصية إلى موافقتك، فبإمكانك سحب خطيًا أي موافقة قدمتها لنا مسبقًا في أي لحظة. عِلمًا بأن سحب الموافقة لا يؤثر على قانونية المعالجة بناءً على الموافقة قبل سحبها.

لممارسة هذه الحقوق، يُرجى التواصل مع موظفي المبيعات في المتجر أو التواصل مع ممثلي خدمة العملاء لدينا، أو مراسلتنا عبر البريد الإلكتروني على salesforce.crm@chalhoub.com.

ويمكنك أيضًا تقديم شكوى إلى السلطة الرقابية المختصة المعنية بحماية البيانات في بلد التشغيل التي تقيم فيها.

**7 - التغييرات على هذه السياسة**

نحتفظ بالحق في تعديل سياسة الخصوصية هذه في أي وقت، حيث قد تتغير سياسة الخصوصية الخاصة بنا من وقت لآخر لتعكس التغييرات في معالجتنا لمعلوماتك الشخصية. وتسري أي تعديلات على سياسة الخصوصية هذه ما إن يتم نشرها في متاجرنا. سوف نخطرك بأي تغييرات جوهرية وفق ما يقتضيه القانون. يُرجى قراءة سياسة الخصوصية وإعادة مراجعتها باستمرار.

**8- التواصل**

إذا كانت لديك أي أسئلة أو استفسارات تتعلق بسياسة الخصوصية الماثلة، أو كنتَ ترغب في ممارسة حقوقك، فيُرجى التحدث إلى أحد موظفينا في المتجر والذي سيَسعد بمساعدتك، أو يمكنك التواصل معنا عبر البريد الإلكتروني على <salesforce.crm@chalhoub.com>. 

**9- أحكام متنوعة**

سياسة الخصوصية الماثلة متوفرة باللغتين الإنجليزية والعربية. وفي حال وجود أي تعارض بين النص الإنجليزي والنص العربي، يسود النص الإنجليزي.

تُفسَّر هذه الشروط وتحكمها قوانين بلد التشغيل ذات الصلة، ويكون للمحاكم المختصة في بلد التشغيل الاختصاص القضائي الحصري.



`