import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { translations } from '../../../../../../config'
import Button from '../../../../../../components/Button'
import modalService from '../../../../../../services/modalService'

class NoVariantSelected extends PureComponent {
  render () {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.lineContainer}>
          <div className={classes.containterTitle}>
            {translations('Please select a size')}
            <div className={classes.buttonContainer}>
              <Button color='primary' fullWidth onClick={modalService.close}>
                {translations('Close')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(NoVariantSelected)