import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Slider from '@material-ui/core/Slider'
import Input from '@material-ui/core/Input'
import styled from 'styled-components'

import { theme } from '../../../config/theme'

const HelperText = styled(FormHelperText)`
  color: ${theme.palette.error.main};
`
const formatValue = (value, min, max) => {
  if (value) {
    const currentValue = value.replace(/[()]/g, '').split('..')
    return currentValue.map((value) => parseInt(value))
  }

  return [min, max]
}

const Label = styled(InputLabel)`
  &:focused: {
    color: ${theme.palette.accent.main};
  }
`

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`

const SliderContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 15px;
`

const InputContainer = styled.div``

const Time = ({
  label,
  value,
  onChange,
  meta: { error } = {},
  min = 0,
  max = 100
}) => {
  const handleChange = useCallback((e, value) => {
    onChange(`(${value.join('..')})`)
  })

  const handleInputChange = useCallback((e, index) => {
    const newValues = formatValue(value, min, max)
    newValues[index] = parseInt(e.target.value)
    onChange(`(${newValues.join('..')})`)
  }, [value, min, max])

  const formattedValue = useMemo(() => {
    return formatValue(value, min, max)
  }, [value, min, max])

  return <>
    <Container>
      <Label
        htmlFor={label}
        key='label'
        shrink
      >
        {label}
      </Label>
      <InputContainer>
        <Input
          value={formattedValue[0]}
          onChange={(e) => handleInputChange(e, 0)}
          inputProps={{
            min: min,
            max: max,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </InputContainer>
      <SliderContainer>
        <Slider
          value={formattedValue}
          onChange={handleChange}
          label={label}
          min={min}
          max={max}
        />
      </SliderContainer>
      <InputContainer>
        <Input
          value={formattedValue[1]}
          onChange={(e) => handleInputChange(e, 1)}
          inputProps={{
            min: min,
            max: max,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </InputContainer>
    </Container>
    <HelperText key='helper-text'>{error}</HelperText>
  </>
}

Time.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number
}

export default Time
