import { clientReporting } from "../../config"

const style = theme => ({
  container: {
    display: 'flex',
    height: 48,
    borderRadius: 2,
    backgroundColor: theme.searchInput.backgroundColor,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    width: '100%',
  },
  custSearchContainer: {
    display: 'flex',
    height: 48,
    borderRadius: 2,
    backgroundColor: theme.searchInput.backgroundColor,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    width: '100%',
    marginTop: '10px',
  },
  searchInput: {
    flex: 1,
    border: 0,
    outline: 0,
    padding: 10,
    fontSize: theme.searchInput.fontSize,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    color: theme.searchInput.textColor,
    fontFamily: theme.base.fontFamily,
    lineHeight: '20px',
    width: '100%'
  },
  searchIconContainer: {
    paddingLeft: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  searchButton: {
    paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  advancedSearchButton: {
    display: 'flex',
    height: 48,
    width: '100%',
    justifyContent: 'center',
  },
  icon: {
    color: theme.searchInput.iconColor,
    transform: 'scale(0.9)'
  }
})

export default style
