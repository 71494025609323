import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { communicationOptions } from '../../../config'
import { selectors as productDetailSelectors } from '../productDetails'

const formatMarketingValues = (result, keys) => {
  // remove keys which are false
  // leaves an array of keys that were true
  // this is the format which the checklist requires
  return _.chain(result)
    .pick(keys)
    .omitBy((value, key) => !value)
    .keys()
    .value()
}

export const getCurrencyCode = state => state.app.currencyCode

export const addMarketingValuesToCustomer = customer => {
  const customerMarketingChannels = [...formatMarketingValues(customer, communicationOptions.channelKeys), ...formatMarketingValues(customer, communicationOptions.channelKeysInDetails)]
  const customerMarketingPreferences = formatMarketingValues(customer, communicationOptions.preferenceKeys)
  const customerOther = _.omit(customer, [...communicationOptions.channelKeys, ...communicationOptions.channelKeysInDetails, ...communicationOptions.preferenceKeys])
  return {
    ...customerOther,
    customerMarketingChannels,
    customerMarketingPreferences
  }
}

const getResults = state => state.productWaitlist.results

export const getProductWaitlist = createSelector([
  getCurrencyCode,
  getResults
], (currency, results = []) => {
  return results.map(result => {
    return {
      ...result,
      product: productDetailSelectors.mapProductToCurrency(result.product, currency),
      customer: addMarketingValuesToCustomer(result.customer)
    }
  })
})

export const getIsLoading = state => state.productWaitlist.status === PENDING

const formatCustomerWaitlistEntry = entry => {
  return {
    type: 'product',
    ...entry.product,
    productVariants: [_.get(entry, 'variant', {})],
    selectedVariantId: _.get(entry, 'variantId', null)
  }
}

export const getProductWaitlistForCustomer = state => state.productWaitlist.results ? { waitlist: _.map(state.productWaitlist.results, entry => formatCustomerWaitlistEntry(entry)) } : {}
