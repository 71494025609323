import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const orderPaidIcon = getImage('orderPaid')

const OrderPaidNotification = props => {
  const dispatch = useDispatch()

  const { 
    orderNumber,
    customer,
   } = props.meta
  const { createdAt } = props

  const text = _.template(translations('Order Paid Notification Text'))({
    orderNumber: orderNumber,
    customerFullName: `${customer.firstName} ${customer.lastName}`
  })
  
  return (
    <Notification
      {...props}
      onClick={() => dispatch(push(`/orders/${orderNumber}`))
}
      iconSource={orderPaidIcon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

OrderPaidNotification.propTypes = {
  meta: PropTypes.shape({
    customer: PropTypes.shape({
      id: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    }).isRequired,
    orderNumber: PropTypes.string,
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default OrderPaidNotification
