import currencyFormatter from 'currency-formatter'

import { currencyCode } from '../../config'

const defaultFormattingOptions = {
  decimal: '.'
}

class CurrencyFormatter {
  format (...args) {
    let obj
    if (typeof args[0] === 'object') {
      obj = args[0]
    } else {
      let [ value, code, options ] = args
      obj = { value, code, options }
    }
    const { value, code = currencyCode, options = {} } = obj

    const allOptions = { ...defaultFormattingOptions, ...options }

    let formatted

    switch (code) {
      case 'EUR':
        formatted = currencyFormatter.format(value, {
          format: '%v',
          thousand: ',',
          ...allOptions
        })
        break
      default:
        formatted = currencyFormatter.format(value, {
          format: '%v',
          thousand: ',',
          ...allOptions
        })
        break
    }

    return formatted + " " + code
  }
}

export default new CurrencyFormatter()
