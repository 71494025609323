import React from 'react'
import styled from 'styled-components'
import P from '../../../../components/P'
import CircularProgress from '@material-ui/core/CircularProgress'

const SubTitle = styled(P)`
  text-align: center;
`

const Container = styled.div`
  background-color: #fffadb;
  border: 1px solid #ffe6db;
  padding: 1rem;
  text-align: center;
`

const Notice = ({
  loader = false,
  title
}) => (
  <Container>
    {loader && <CircularProgress size={25} />}
    {!!title && <SubTitle
      value={title}
    />}
  </Container>
)

export default Notice