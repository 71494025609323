import React, { Component, Fragment } from 'react'

import SearchBar from '../common/SearchBar'
import Results from '../common/Results'
import MultiSelectBar from '../common/MultiSelectBar'
import SubCategories from './SubCategories'
import ScrollContainer from '../../../components/ScrollContainer'

import { Helmet } from 'react-helmet'

class CategoryScreen extends Component {
  searchProducts = () => {
    const { searchProducts, category, shouldLoadProducts, multiSelect } = this.props
    if (shouldLoadProducts) {
      searchProducts({ category })
    }
  }

  fetchFilters = () => {
    const { fetchFilters, category, shouldLoadProducts, multiSelect } = this.props
    if (shouldLoadProducts) {
      fetchFilters({ category })
    }
  }

  componentDidMount() {
    this.searchProducts()
    this.fetchFilters()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category || prevProps.modalPath !== this.props.modalPath) {
      this.searchProducts()
      this.fetchFilters()
    }
  }

  render() {
    const { category, shouldLoadProducts, multiSelect, hideSearchBar } = this.props
    return (
      category
        ? (
          shouldLoadProducts
            ? <Fragment>
            <h1 className='visuallyhidden'>{'Products'}</h1>
            <Helmet><title>{'Products'}</title></Helmet>
            <SearchBar {...this.props} />
            <ScrollContainer>
              <Results {...this.props} />
            </ScrollContainer>
            {multiSelect && <MultiSelectBar {...this.props} />}
            </Fragment>
            : <SubCategories {...this.props} />
        )
        // if no category don't show anything
        // (should only happen if you manually go to invalid url)
        : <div>Invalid category.</div>
    )
  }
}

export default CategoryScreen
