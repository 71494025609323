import { connect } from 'react-redux'
import { selectors as createMessageSelectors } from '../../../../../store/modules/createMessage'
import { MessengerEditorText } from '../../../../../components/Messenger'

const mapStateToProps = state => {
  const currentRecipients = createMessageSelectors.getCurrentRecipients(state)
  const hasRecipients = !!currentRecipients.length
  const hasSingleRecipient = currentRecipients.length === 1
  const templateLanguage = createMessageSelectors.getCurrentTemplateLanguage(state)

  return {
    hasSingleRecipient,
    hasRecipients,
    templateLanguage
  }
}

export default connect(mapStateToProps)(MessengerEditorText)
