import _ from "lodash"
import { selectors as authSelectors } from "../store/modules/auth"

export const convertUrlWithUTMParams = (state, urlString) => {
    try {
        const url = new URL(urlString)
        const trackingParameters = authSelectors.getUserSelectedStoreTrackingParameters(state)
        const activeUserExternalId = authSelectors.getActiveUserExternalId(state)
        const currentStoreExternalId = authSelectors.getSelectedStoreExternalStoreId(state)
        const mapTrackingParameterValues = (value) => ({
            '{{storeId}}_{{userId}}': `${currentStoreExternalId}_${activeUserExternalId}`, // use lodash template
        })[value] || value
        _.map(
            trackingParameters,
            (value, key) => url.searchParams.set(key, mapTrackingParameterValues(value))
        )
        return url.toString()
    } catch (error) {
        console.log(`failed to convert: ${urlString}`)
        return null
    }
}
