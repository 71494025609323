import React from 'react'
import PrivateRoute from '../../../../components/PrivateRoute'
import { slugify } from '../../../../helpers'
import { customerReportingSchema as schema } from '../../../../config'

import CustomerReportingFullList from './CustomerReportingFullList'

// this only needs to be done once so it can be outside a render function
// because schema is static
const CustomerReportingScreens = (
  schema.filter(sch => !sch.disable)
    .map((report, i) => {
      const path = `/customers/reporting/${i}-${slugify(report.title || 'Some Report')}`
      return (
        report.fullList
        ? <PrivateRoute
          key={path}
          path={path}
          component={() => <CustomerReportingFullList reportSchema={report} />}
          exact
        />
        : null
      )
    })
    .filter(Boolean)
)

export default CustomerReportingScreens
