export const salutationArray = [
  'Miss',
  'Mr.',
  'Mrs.',
  'Ms.',
  'Prince',
  'Princess',
  'Sheikh',
  'Sheikha'
]
