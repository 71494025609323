import React, { useEffect } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import {
  actions as appointmentsActions,
  selectors as appointmentsSelectors,
} from '../../../store/modules/appointments'
import CoreConsultation from './CoreConsultation'
import ContentPanel from './Sections/ContentPanel'
import CustomerDetailsPanel from './Sections/CustomerDetailsPanel'
import ConsultationSummary from './ConsultationSummary'

const StoreConsultation = props => {
  const paramId = _.get(props, 'match.params.id')
  const appointmentByExternalId = useSelector(
    appointmentsSelectors.getAppointmentByExternalId(paramId)
  )
  const appointmentId = _.get(appointmentByExternalId, 'id') || paramId
  const dispatch = useDispatch()

  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)

  useEffect(() => {
    dispatch(appointmentsActions.fetchAppointment({ appointmentId, isConsultant: true }))
  }, [appointmentId])

  return (
    <CoreConsultation
      appointmentId={appointmentId}
      primaryComponent={isAppointmentComplete ? <ConsultationSummary /> : <ContentPanel />}
      secondaryComponent={isAppointmentComplete ? null : <CustomerDetailsPanel />}
      carouselComponent={null}
    />
  )
}

export default StoreConsultation
