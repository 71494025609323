import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { actions as appActions } from '../../../store/modules/app'
import BasketList from './BasketList'

const mapDispatchToProps = dispatch => ({
  goToProduct: product => e => {
    if (
      // allows quantity picker to work
      e.target instanceof HTMLElement &&
      !(e.target.getAttribute && e.target.getAttribute('role') === 'button')
    ) {
      dispatch(push(`/product/${product.productId || product.id}`))
      dispatch(appActions.toggleBasket({ isOpen: false }))
    }
  }
})

export default connect(null, mapDispatchToProps)(BasketList)
