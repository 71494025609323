import React, { useMemo } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import StockLevelIndicator from '../../../../../../../components/StockLevelIndicator'
import distanceFormatter from '../../../../../../../formatters/distanceFormatter'

import {
  translations,
  stockLevel,
  distanceUnits,
  storeLocationsEnabled
} from '../../../../../../../config'

const StockLevelIndicatorItem = ({
  stock,
  storeName,
  distanceFromCurrentStore
}) => {
  const distanceLabel = storeLocationsEnabled && translations('Distance From Current Store', {
    distance: distanceFormatter.format({
      distance: distanceFromCurrentStore,
      inputUnits: 'metres',
      outputUnits: distanceUnits,
      decimalPlaces: 2
    }),
    units: distanceUnits
  })
  switch (true) {
    case (stock >= stockLevel.inStockLevel):
      return <StockLevelIndicator 
        label={translations('In Stock In Other Store Message', { storeName })}
        icon='greenCheckIcon'
        sublabel={distanceLabel}
      />
    case (stock === stockLevel.lowStockHighLevel):
      return <StockLevelIndicator 
        label={translations('Low Stock In Other Store Message', { storeName })}
        icon='orangeCheckIcon'
        sublabel={distanceLabel}
      />
    case (stock === stockLevel.lowStockLowLevel):
      return <StockLevelIndicator 
        label={translations('Low Stock In Other Store Message', { storeName })}
        icon='orangeCheckIcon'
        sublabel={distanceLabel}
      />
    case (stock === stockLevel.outOfStockLevel):
      return <StockLevelIndicator 
        label={translations('Out Of Stock In Other Store Message', { storeName })}
        icon='redCrossIcon'
        sublabel={distanceLabel}
      />
    default:
      return <></>
  }
}

const StockAvailabilityModalItem = ({ stock, store, distanceFromCurrentStore }) => {
  const Container = styled.div`
    padding: 20px 0;
  `
  return (
    <Container>
      {useMemo(() => <StockLevelIndicatorItem
        stock={stock}
        storeName={_get(store, 'name')}
        distanceFromCurrentStore={distanceFromCurrentStore} /> 
      )}
    </Container>
  )
}
export default StockAvailabilityModalItem

