import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import CustomerSearchResults from './CustomerSearchResults'
import { actions as customersActions, selectors as customersSelectors } from '../../../../store/modules/customers'

const mapStateToProps = state => ({ state })

const mapDispatchToProps = dispatch => ({
  dispatch
})

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const results = customersSelectors.getCustomerListResults(state)
  const hasMore = customersSelectors.getCustomerListHasMore(state)
  const isLoading = customersSelectors.getCustomerListIsLoading(state)
  const hasSearched = customersSelectors.getCustomerHasBeenSearched(state)
  const error = customersSelectors.getCustomerListError(state)
  
  const noResults = !isLoading && !(results || []).length
  const searchCustomers = () => {
    const isLoading = customersSelectors.getCustomerListIsLoading(state)
    if (!isLoading) {
      dispatch(customersActions.searchCustomerNext())
    }
  }
  const onCustomerClick = (customer) => {
    const { id } = customer
    ownProps.onCustomerClick
      ? ownProps.onCustomerClick(customer)
      : dispatch(push(`/customers/${id}`))
  }

  return {
    ...ownProps,
    isLoading,
    hasMore,
    results,
    searchCustomers,
    onCustomerClick,
    noResults,
    hasSearched,
    error
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomerSearchResults)
