import toastService from '../services/toastService/toastService'
import { translations } from '../config'

const fallbackCopyTextToClipboard = (text, callback) => {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var err = successful ? undefined : 'Copy to clipboard unsuccessful'
    callback(err)
  } catch (err) {
    callback(err)
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text, callback) => {
  if (window.cordova) {
    window.cordova.plugins.clipboard.copy(
      text,
      () => callback(),
      (err) => callback(err)
    )
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback)
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    callback()
  }, function (err) {
    callback(err)
  })
}

export const copyToClipboard = (text) => {
  copyTextToClipboard(text, (err) => {
    if (err) {
      console.log(err)
      toastService.action({
        type: 'error',
        message: translations('Clipboard - copy error'),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
      return
    }
    toastService.action({
      type: 'success',
      message: translations('Clipboard - copy success'),
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  })
}
