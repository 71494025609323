import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { useGet } from 'react-pouchdb'
import { useDispatch, useSelector } from 'react-redux'

import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { actions as orderDetailsActions } from '../../../store/modules/orderDetails'
import { actions as appointmentsActions, selectors as appointmentsSelectors } from '../../../store/modules/appointments'
import { actions as checkoutFlowActions, selectors as checkoutFlowSelectors } from '../../../store/modules/checkoutFlow'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'

import CoreConsultation from './CoreConsultation'
import VideoPanel from './Sections/VideoPanel'
import ContentTabs from './Sections/ContentTabs'
import PouchDBHOC from '../../../components/PouchDB'
import ConsultationSummary from '../Consultation/ConsultationSummary'
import CustomerDetailsPanel from './Sections/CustomerDetailsPanel'

const VirtualConsultation = (props) => {
  const paramId = _.get(props, 'match.params.id')
  const appointment = useSelector(appointmentsSelectors.getAppointmentByExternalId(paramId))
  const appointmentId = _.get(appointment, 'id') || paramId
  const dispatch = useDispatch()
  const doc = useGet({ id: appointmentId })
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const stripePaymentSuccess = useSelector(currentOrderSelectors.getStripePaymentSuccess)
  const currentAppointmentStage = useSelector(currentAppointmentSelectors.getAppointmentStage)
  const order = useSelector(currentOrderCombinedSelectors.currentOrderSelector)
  const orderTotal = _.get(order, 'total.value')
  const orderId = useSelector(checkoutFlowSelectors.getOrderId)
  const initialSync = useRef(true)

  useEffect(() => {
    dispatch(appointmentsActions.fetchAppointment({ appointmentId, isConsultant: true }))
      .then((appointment) => {
        console.log({ appointment })
        if (appointment && appointment.status !== 'COMPLETE') {
          dispatch(currentAppointmentActions.updateCurrentAppointment({ id: appointmentId, status: 'IN_PROGRESS' }))
          setTimeout(() => {
            dispatch(currentAppointmentActions.setStage({ stage: null }))
          }, 300)
        }
      })
  }, [appointmentId])

  useEffect(() => {
    if (stripePaymentSuccess && appointmentId && orderId) {
      dispatch(currentOrderActions.setStripePaymentSuccess({ success: null }))
      dispatch(currentAppointmentActions.setStage({ stage: 'COMPLETE' }))
      dispatch(checkoutFlowActions.end())
    }
  }, [stripePaymentSuccess, appointmentId, orderId])

  useEffect(() => {
    const {
      currentAppointment,
      currentOrder,
      source
    } = _.get(doc, 'sharedViewState', {})
    if (doc && ((initialSync.current && currentAppointmentStage !== 'PAYMENT_PENDING') || source === 'public')) {
      if (initialSync.current) {
        console.log('Performing initialSync with Couchbase')
      }
      initialSync.current = false
      dispatch(currentAppointmentActions.setCurrentAppointment({ currentAppointment, source }))
      dispatch(currentOrderActions.setCurrentOrder({ currentOrder, source }))
    }
  }, [doc, initialSync.current])

  return (
    <CoreConsultation
      appointmentId={appointmentId}
      primaryComponent={<VideoPanel isConsultant />}
      secondaryComponent={isAppointmentComplete ? <ConsultationSummary stacked /> : <CustomerDetailsPanel fixedHeight />}
      carouselComponent={isAppointmentComplete ? null : <ContentTabs type={'virtual'} />}
    />
  )
}

export default PouchDBHOC(VirtualConsultation)({
  tableName: 'appointments'
})