import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { compose } from 'recompose'
import { get } from 'lodash'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import { selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import { getRegionBrand, getCurrencyConfigForSelectedRegion, getBrandConfig } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'
import { history } from '../../store'
import Header from './Header'

const mapStateToProps = (state) => {
  const isInsideConsultation = matchPath(history.location.pathname, { path: '/consultations/:any' })
  const isAppointmentComplete = isInsideConsultation && currentAppointmentSelectors.getIsAppointmentComplete(state)
  const brandName = getRegionBrand(state)
  const theme = getTheme(brandName)
  const currencyCodes = getCurrencyConfigForSelectedRegion(state)
  const brandConfig = getBrandConfig(state)
  const currencySelectorHidden = get(brandConfig, 'hideCurrencySelector')
  // Analytics includes its own independant currency filter
  const isViewingAnalytics = matchPath(history.location.pathname, { path: '/retail-analytics' }) 
  const hideCurrencySelector = currencySelectorHidden || isViewingAnalytics || (currencyCodes && currencyCodes.length === 1)

  return {
    customerModeUpdate: appSelectors.getCustomerModeStatus(state),
    isAppointmentActive: currentAppointmentSelectors.getIsAppointmentActive(state),
    isInsideConsultation,
    isAppointmentComplete,
    getCurrencyCode: appSelectors.getAppCurrency(state) || '',
    hideCurrencySelector,
    currencyCodes,
    theme
  }
}

const mapDispatchToProps = {
  customerModeToggle: () => appActions.customerModeToggle({ isOn: true }),
  openMenu: () => appActions.toggleSideMenu({ isOpen: true }),
  openCurrencyMenu: () => appActions.toggleCurrencyMenu({ isOpen: true }),
  setCurrency: ({ currencyCode }) => appActions.changeAppCurrency({ currencyCode })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
