import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIsLoading } from '../../../../../../hooks/appointmentsHooks'
import GroupList from '../../../../../../components/GroupList'
import LoaderContainer from '../../../../../../components/LoaderContainer'
import AppointmentsItem from '../AppointmentsItem'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const AppointmentsList = ({ appointments, noResultsText, editable  }) => {
  const isLoading = useIsLoading()
  return (
    <Container>
      {!isLoading ? (
        <GroupList
          sortBy='date'
          boxStyle
          noBorder
          noMargin
          noResultsText={noResultsText}
          ItemComponent={AppointmentsItem}
          data={appointments} 
          editable={editable} />
      ) : (
        <LoaderContainer isLoading />
      )}
    </Container>
  )
}

AppointmentsList.propTypes = {
  appointments: PropTypes.array.isRequired
}

export default AppointmentsList
