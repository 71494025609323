import React from 'react'
import { compose } from 'recompose'
import Filters from './Filters'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '../../../../../components/Button'
import style from './style'
import { translations } from '../../../../../config'

const enhancer = compose(withStyles(style))

const TitleBar = ({
  classes,
  width,
  onFilterButtonClick,
  totalSelectedFilters,
  ...props
}) => (
    <div>
      {useMediaQuery(theme => theme.breakpoints.up('lg')) ? (
        <div className={classes.desktopFiltersContainer}>
          <Filters {...props} />
        </div>
      ) : (
          <div className={classes.buttonContainer}>
            <Button big buttonType='primary' className={classes.button} onClick={onFilterButtonClick}>
              {translations('Filter')}{totalSelectedFilters ? ` - (${totalSelectedFilters})Selected` : null}
            </Button>
          </div>
        )}
    </div>
  )

export default enhancer(TitleBar)
