import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Dropdown from '../../../../../../components/Fields/Dropdown'
import DatePicker from '../../../../../../components/Fields/DatePicker'
import Checkbox from '../../../../../../components/Fields/Checkbox'
import { translations } from '../../../../../../config'
import { actions as appointmentsActions } from '../../../../../../store/modules/appointments'

import { useAppointmentsFilters, useAppointmentsQuery } from '../../../../../../hooks/appointmentsHooks'
import { useUsersOptions } from '../../../../../../hooks/usersHooks'
import { useStoreCalendarOptions } from '../../../../../../hooks/storeDetailsHooks'

const Container = styled.div`
  padding-bottom: 13px;
  z-index: 1;
`

const CustomFormControl = styled(FormControl)`
  width: 100%;
`
const DateSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end; 
`

const CheckboxGridItem = styled(Grid)`
  display: flex;
  align-items: flex-end;
`

const AppointmentsFilters = () => {
  const dispatch = useDispatch()
  const usersOptions = useUsersOptions()
  const filters = useAppointmentsFilters()
  const storeCalendarOptions = useStoreCalendarOptions()
  const appointmentSearchQuery = useAppointmentsQuery()

  const userFilterOptions = useMemo(() => {
    return [
      [{
        label: 'Unassigned',
        value: 'null'
      }],
      ...usersOptions
    ]
  }, [usersOptions])

  useEffect(() => {
    if (appointmentSearchQuery && storeCalendarOptions.length > 0) {
      dispatch(appointmentsActions.fetchAppointmentsFresh({ query: appointmentSearchQuery }))
        .catch((error) => {
          console.log({ error })
        })
    }
  }, [appointmentSearchQuery, storeCalendarOptions])

  const updateFilters = useCallback((newFilter) => {
    const removeEmpty = (obj) => {
      Object.keys(obj).forEach((key) => (!obj[key] || obj[key] === '') && delete obj[key])
      return obj
    }
    const newFilters = removeEmpty({
      ...filters,
      ...newFilter
    })
    dispatch(appointmentsActions.updateAppointmentsFilters(newFilters))
  })

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <CustomFormControl>
            <Dropdown
              label={translations('Calendar')}
              value={filters.calendarId}
              options={storeCalendarOptions}
              onChange={(e) => updateFilters({ calendarId: e.target.value })}
              noErrorTextLabel
              autocomplete
            />
          </CustomFormControl>
        </Grid>
        <CheckboxGridItem item xs={6} sm={6} md={1} lg={1}>
          <FormControl>
            <Checkbox
              label={translations('Virtual')}
              value={filters.isVirtual}
              checked={filters.isVirtual}
              onChange={() => updateFilters({ isVirtual: !filters.isVirtual })}
              noErrorTextLabel
            />
          </FormControl>
        </CheckboxGridItem>
        <CheckboxGridItem item xs={6} sm={6} md={1} lg={1}>
          <FormControl>
            <Checkbox
              label={translations('Store')}
              value={filters.isInStore}
              checked={filters.isInStore}
              onChange={() => updateFilters({ isInStore: !filters.isInStore })}
              updateFilters
            />
          </FormControl>
        </CheckboxGridItem>
        <Grid container item xs={12} sm={12} md={4} lg={4}>
          <DateSearchWrapper>
            <Grid item xs={12} sm={12} md={10} lg={6}>
              <CustomFormControl>
                <DatePicker
                  label={translations('Search date')}
                  onChange={(date) => updateFilters({ startDateTime: date })}
                  clearInput={() => updateFilters({ startDateTime: null })}
                />
              </CustomFormControl>
            </Grid>
          </DateSearchWrapper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AppointmentsFilters
