import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { translations } from '../../../../../../config'
import { Table, TableHeader, TableRow, TableCell } from '../../../../../../components/Table'
import { getColumns, getFormattedOrderRow } from '../helpers'
import style from './style'

const columns = getColumns()

const Header = () => {
  return (
    <TableHeader>
      {columns.map(({ key, cellWidth }) => {
        return <TableCell
          key={key}
          xs={cellWidth}
          children={translations(`Orders Table Header ${_.startCase(key)}`)}
        />
      })}
    </TableHeader>
  )
}

const Row = ({ orders, onOrderClick }) => {
  return orders.map((order = {}) => {
    const content = getFormattedOrderRow(order)

    return (
      <TableRow onClick={() => onOrderClick(order.orderNumber)} key={order.orderNumber}>
        {columns.map(({ key, cellWidth }) => {
          return <TableCell
            key={key}
            xs={cellWidth}
            children={key === 'status' ? translations(content[key]) : content[key]}
          />
        })}
      </TableRow>
    )
  })
}

const TableView = ({ orders, onOrderClick }) => (
  <Table
    header={<Header />}
    rows={<Row orders={orders} onOrderClick={onOrderClick} />}
  />
)

export default withStyles(style)(TableView)
