import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { deliveryEnabled } from '../../../config'
import CheckoutWrapper from '../common/CheckoutWrapper'
import DeliverySection from './sections/DeliverySection'
import BillingSection from './sections/BillingSection'
import style from './style'
import _ from 'lodash'

const checkoutHasDelivery = deliveryEnabled

const OrderSummary = (props) => {
  const {
    classes,
    customer,
    ...rest
  } = props

  const isInStoreSale = _.get(props, 'deliveryType') === 'inStoreSale'
  return (
    <CheckoutWrapper handleNext>
      {(props.customer && checkoutHasDelivery) ? <DeliverySection {...rest} /> : null} 
      {(props.customer && isInStoreSale) ? null : <BillingSection {...rest} /> }
    </CheckoutWrapper>
  )
}

export default withStyles(style)(OrderSummary)
