import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import { Dropdown, Checkbox } from '../../../../../components/Fields'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import P from '../../../../../components/P'
import H2 from '../../../../../components/H2'
import { communicationOptions, translations } from '../../../../../config'
import * as validators from '../../../../../validators'

import style from './style'

const CommunicationOptions = ({
  classes,
  isStoreMarketingSelected,
  storesOptions,
  languageOptions
}) => {
  const enabledCommChannelOptions = communicationOptions.channelOptionKeys.filter(option => option.enabled)  
  return (
    <ContentBox givenContentClass={classes.communicationContentContainer}>
      <ContentBoxHeader>
        <TextContent><H2 className={classes.contentBoxHeader} value={translations('Communication Options')} /></TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <Grid container spacing={2}>
          <div className={classes.communicationWrapper}>
            <FormControl row>
              <Dropdown
                name='preferredLanguage'
                label={translations('Preferred Language*')}
                options={languageOptions}
                validate={validators.required}
                required
                autocomplete
              />
            </FormControl>
          </div>
        </Grid>
        <Grid container spacing={2}>
          <P className={classes.formText} value={translations('sign up - contact')} />
          <div className={classes.communicationWrapper} >
            <FormGroup row>
              {enabledCommChannelOptions.map(channel => (
                <FormControlLabel
                  classes={{
                    label: classes.checkboxMargin
                  }}
                  control={
                    <Checkbox
                      name={channel.value}
                    />
                  }
                  label={translations(channel.label)}
                  className={classes.noMarginLeft}
                />
              ))}
            </FormGroup>
          </div>

          <P value={translations('sign up - type')} className={classes.formText} />
          <div className={classes.communicationWrapper} >
            <FormGroup row className={classes.fullWidth}>
              <div>
                <FormControlLabel
                  classes={{
                    label: classes.checkboxMargin
                  }}
                  control={
                    <Checkbox
                      name='generalMarketing'
                    />
                  }
                  label={translations('General marketing')}
                  className={classes.noMarginLeft}
                />
                <FormControlLabel
                  classes={{
                    label: classes.checkboxMargin
                  }}
                  control={
                    <Checkbox
                      name='storeMarketing'
                    />
                  }
                  label={translations('Store updates')}
                  className={classes.noMarginLeft}
                />
                <FormControlLabel
                  classes={{
                    label: classes.checkboxMargin
                  }}
                  control={
                    <Checkbox
                      name='thirdPartyMarketing'
                    />
                  }
                  label={translations('Third party')}
                  className={classes.noMarginLeft}
                />
              </div>
            </FormGroup>
          </div>
          {isStoreMarketingSelected && (
            <div className={classes.storesContainer}>
              <FormControl className={classes.fullWidth}>
                <Dropdown
                  strict={'true'}
                  options={storesOptions}
                  label={translations('Stores for marketing')}
                  name='marketingStoreIds'
                  multiple
                />
              </FormControl>
            </div>
          )}
        </Grid>
      </ContentBoxBody>
    </ContentBox>
  )
}

CommunicationOptions.propTypes = {
  classes: PropTypes.shape({}),
  isStoreMarketingSelected: PropTypes.bool.isRequired,
  storesOptions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(style)(CommunicationOptions)
