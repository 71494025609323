import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'

import HomeScreen from './HomeScreen'
import { dashboard, apps } from '../../../config'
import { actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as networkSelectors } from '../../../store/modules/network'
import analyticsService from '../../../services/analyticsService'
import { getRegionBrand } from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'

const mapStateToProps = state => {
  const user = _.get(state, 'auth.user')
  const widgets = dashboard
  const configName = getRegionBrand(state)
  return {
    user,
    hasUserManagement: authSelectors.getHasUserManagement(state),
    widgets,
    appsAllowedForRole: authSelectors.getAppsAllowedForRole(state),
    networkConnectivity: networkSelectors.isConnected(state),
    configName
  }
}

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onMenuItemClick: ({ dispatch }) => id => {
      if (id === 'USER_MANAGEMENT') {
        analyticsService.sendCustomEvent({ type: 'userAccountsVisit', route: 'hubTile' })
      }
      const item = apps[id]
      dispatch(item.action)
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    }
  })
)(HomeScreen)
