import React from 'react'
import { Modules } from '@redant/retailos-ui'

const GroupedProductSwatch = ({
  selectedOptionId,
  options,
  handleOptionSelect
}) => {
  const styleOverrides = {
    swatchTitle: `
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      line-height: 40px;`,
    swatch: {
      border: '2px solid #e9e9e9'
    }
  }
  return <Modules.Product.Components.GroupedProductSwatch
    selectedOptionId={selectedOptionId}
    options={options}
    handleOptionSelect={handleOptionSelect}
    styleOverrides={styleOverrides}
  />
}

export default GroupedProductSwatch
