import _ from 'lodash'
import { getGroupedProducts, getHasPreviewProducts, getNumberOfProducts, getHasDelivery, getHasTax } from '@redant/digital-store-prices-chalhoub'

import { getDeliveryPrice, getDeliveryText } from '../../helpers'
import store from '../../store'
import { apps } from '../../config'
// the distinction between orderFormatter.format and digital-store-prices.amendOrder:

// amendOrder:
// this is for taking an order, calculating the subtotal, tax, and total of the order
// and each of its products, and returning an order object which also contains those
// calculated values. this is used as a selector for the currentOrder redux store,
// so monetary information can be displayed during checkout. it is also used on the api
// to verify that the calculations are correct before creating the order in the
// database.

// format:
// this is for taking an order object, and adding some values to it that are useful
// for DISPLAYING the order. eg: groupedProducts, numberOfProducts, hasDelivery

class OrderFormatter {

  format(order = {}) {
    const currentCurrency = store.getState().app.currencyCode
    const products = order.products || []
    const deliveryOption = order.deliveryOption && order.deliveryOption.details && order.deliveryOption.details.prices ? {
      ...order.deliveryOption,
      price: {
        code: currentCurrency,
        value: order.deliveryOption.details.prices[currentCurrency]
      }
    } : {}

    const noCustomer = (!order.customerId && (_.isEmpty(order.customer) || !order.customer))

    const deliveryText = getDeliveryText(noCustomer ? null : deliveryOption)
    const deliveryPrice = getDeliveryPrice(deliveryOption)

    const groupedProducts = getGroupedProducts(products)
    const numberOfProducts = getNumberOfProducts(order)
    const numberOfPurchaseableProducts = _.chain(products)
      .reject(product => product.unsold)
      .size()
      .value()

    const hasPreviewProducts = getHasPreviewProducts(order)
    const hasDelivery = getHasDelivery(order)
    const hasTax = getHasTax(order)

    return {
      ...order,

      groupedProducts,
      numberOfProducts,
      numberOfPurchaseableProducts,
      hasPreviewProducts,
      deliveryText,
      deliveryPrice,
      hasDelivery,
      hasTax
    }
  }
}

export default new OrderFormatter()
