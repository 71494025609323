import * as validators from '../../../validators'
import {
  countryPhoneCodes,
  nationalitiesOptions
} from '../../../config'

import { salutationArray } from './salutationArray'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: 'Title',
    name: 'title',
    options: salutationArray,
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: 'First Name',
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: 'Last Name',
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'email',
  field: 'Email',
  props: {
    label: 'Email',
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: false,
    showEmailButton: true
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: 'Date of Birth',
    name: 'dob',
    validate: [validators.date, dobAgeValidator],
    placeholder: 'Date Of Birth - input placeholder'
  }
}, {
  id: 'telephone',
  field: 'PhoneNumber',
  props: {
    label: 'Phone Number',
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: true,
    required: true
  }
}, {
  id: 'nationality',
  field: 'Dropdown',
  props: {
    label: 'Nationality',
    name: 'nationality',
    required: false,
    multiple: false,
    autocomplete: true,
    options: [nationalitiesOptions]
  }
}]
