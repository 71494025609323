import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { fileHelper } from '../../helpers'
import { translations } from '../../config'
import P from '../P'
import Button from '../Button'
import style from './style'
import { getImage } from '../Images'

const ResourceItem = ({ Key, classes, viewResource, className, fileName }) => {
  // if none just use 'File'
  const fileType = fileHelper.getExtension(Key) || 'File'
  const image = getImage('fileIcon')
  const containerClass = classNames(classes.container, className)
  return (
    <div className={containerClass}>
      <div className={classes.leftContainer}>
        <img src={image} className={classes.icon} />
        <div className={classes.textContainer}>
          <P type='large' value={fileName} key={Key} className={classes.resourceKey} />
          <P value={fileType} className={classes.typeText} />
        </div>
      </div>
      <div>
        <Button color='primary' className={classes.button} onClick={viewResource}>
          {translations('View')}
        </Button>
      </div>
    </div>
  )
}

ResourceItem.propTypes = {
  Key: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  viewResource: PropTypes.func.isRequired
}

export default withStyles(style)(ResourceItem)
