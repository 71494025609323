const styles = theme => ({
  wrapper: {
    display: 'inline-flex',
    marginBottom: '-20px'
  },
  fieldWrapperDate: {
    width: '60%'
  },
  fieldWrapperTime: {
    width: '40%',
    marginLeft: 40
  },
  errorStyle: { color: theme.palette.error.main },
  floatingLabelFocusStyle: { color: theme.palette.accent.main }
})

export default theme => styles
