import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBoxBody from './ContentBoxBody'
import ContentBoxHeader from './ContentBoxHeader'
import ContentBoxFooter from './ContentBoxFooter'

import style from './style'

const ContentBox = ({ children, givenContentClass, givenContentContainerClass, classes }) => {
  const headerContent = children.find(child => child.type === ContentBoxHeader)
  const footerContent = children.find(child => child.type === ContentBoxFooter)
  const bodyContent = children.find(child => child.type === ContentBoxBody)
  return (
    <div className={`${classes.container} ${givenContentContainerClass}`}>
      {headerContent}
      <div className={`${classes.content} ${givenContentClass}`}>{bodyContent}</div>
      {footerContent}
    </div>
  )
}

ContentBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default withStyles(style)(ContentBox)
