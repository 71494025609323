import React from 'react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { translations } from '../../config'

const OrderLabel = ({ classes }) => {
    return (
        <Box className={classes.orderLabelContainer}>
            <Box>{translations('Ordered Product - Image Label')}</Box>
        </Box>
    )
}

export default withStyles(style)(OrderLabel)