import _ from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '@material-ui/core/Select'
import { MenuItem } from '@material-ui/core'
import { getAvailableLanguages } from '../../config'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'

const LanguageSelect = () => {
  const dispatch = useDispatch()
  const languages = getAvailableLanguages()
  const selectedLanguage = useSelector(appSelectors.getAppLanguage)

  const handleChange = useCallback((event) => {
    const language = event.target.value
    dispatch(appActions.changeAppLanguage({ language }))
  }, [languages])

  return languages.length > 1 ? (
    <Select
      native={false}
      onChange={handleChange}
      value={selectedLanguage}
    >
      {
        languages.map((lang) => {
          return (
            <MenuItem key={`lang-select-${lang}`} value={lang}>{_.toUpper(lang)}</MenuItem>
          )
        })
      }
    </Select>
  ) : null
}

export default LanguageSelect
