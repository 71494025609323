import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import * as validators from '../../../../../validators'
import { Input, Dropdown, Radiolist } from '../../../../../components/Fields'
import FormError from '../../../../../components/Form/FormError'
import List from '../../../../../components/Form//FormSections/List'
import P from '../../../../../components/P'
import { translations } from '../../../../../config'
import { getLanguage } from '../../../../../config/languages'

import style from './style'

const MessageEditorOptions = props => {
  const {
    classes,
    optedOutMarketingPreferences,
    optedOutMarketingChannels,
    contactDetail,
    communicationType,
    communicationTypeOther,
    communicationTypeOtherOptions,
    hasRecipients,
    hasSingleRecipient,
    messageTemplateDropdownOptions,
    smsDisabled,
    emailDisabled,
    communicationTypeOptions,
    currentRecipientPreferredLanguage,
    templateLanguage
  } = props
  const disabled = !hasRecipients
  const isArabicTemplate = templateLanguage === 'ar-AE'

  return (
    <div>
      <FormControl
        component='fieldset'
        disabled={disabled}
        required
        className={classes.formControl}
      >
        <Radiolist
          name='communicationType'
          label={translations('Communication Channels')}
          options={communicationTypeOptions}
          forceRerender={disabled}
          smallLabel
        />
        {communicationType === 'other' &&
          <div>
            <FormControl
              component='fieldset'
              required
              className={classes.formControl}
            >
              <Dropdown
                name='communicationTypeOther'
                options={communicationTypeOtherOptions}
                givenClasses={{ overridingRootClasses: classes.dropDownStyle }}
                label={translations('Type')}
                validate={[validators.required]}
              />
            </FormControl>
            <P
              value={translations('Other communication type note')}
              className={classes.noteContainer}
            />
          </div>
        }
        {hasSingleRecipient && <FormHelperText>{contactDetail}</FormHelperText>}
        {currentRecipientPreferredLanguage && <FormHelperText>{`${translations('Preferred Language')}: ${getLanguage(currentRecipientPreferredLanguage)}`}</FormHelperText>}
      </FormControl>
      {
        hasSingleRecipient
          ? <Fragment>
            {
              optedOutMarketingChannels.length > 0
                ? <div className={classes.messageOptionsContainer}>
                  <List
                    listTitle={translations('Opted Out Marketing Channels') + ':'}
                    items={optedOutMarketingChannels}
                  />
                </div>
                : null
            }
            {
              optedOutMarketingPreferences.length > 0
                ? <div className={classes.messageOptionsContainer}>
                  <List
                    listTitle={translations('Opted Out Marketing Preferences') + ':'}
                    items={optedOutMarketingPreferences}
                  />
                </div>
                : null
            }
          </Fragment>
          : null
      }
      {communicationType !== 'other' &&
        <div className={classes.dropDownTemplatesContainer}>
          <FormControl
            component='fieldset'
            required
            disabled={disabled}
            className={classes.formControl}
          >
            <Dropdown
              name='template'
              grouped
              options={messageTemplateDropdownOptions}
              givenClasses={{ overridingRootClasses: classes.dropDownStyle }}
              label={translations('Template')}
              forceRerender={disabled}
              validate={[validators.required]}
            />
          </FormControl>
          {
            communicationType === 'email'
              ? (
                <FormControl
                  component='fieldset'
                  required
                  disabled={disabled}
                  className={classes.formControl}
                >
                  <Input
                    label={translations('Email subject line')}
                    name='subject'
                    classes={{ underline: classes.inputInkbar }}
                    validate={[validators.required]}
                    forceRerender={disabled}
                    style={{ direction: isArabicTemplate ? 'rtl' : 'ltr' }}
                  />
                </FormControl>
              )
              : null
          }
          <FormError />
        </div>
      }
    </div>
  )
}

export default withStyles(style)(MessageEditorOptions)
