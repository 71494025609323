import _ from 'lodash'

import colors from './configFiles/theme/colors'
import colorsv2 from './configFiles/theme/colors-v2'
import base from './configFiles/theme/base'
import basev2 from './configFiles/theme/base-v2'
import text from './configFiles/theme/text'
import _theme from './configFiles/theme/theme'

const visibility = theme => ({
  hideForExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideForSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})

const recursivelySubstitute = (input, references) => {
  const referenceNames = Object.keys(references)
  return _.mapValues(input, value => {
    if (typeof value === 'object') {
      return recursivelySubstitute(value, references)
    } else if (typeof value === 'string') {
      let matchingReference
      referenceNames.forEach(referenceName => {
        if (value.includes(`${referenceName}.`)) {
          matchingReference = referenceName
        }
      })
      if (matchingReference) {
        return _.get(
          references[matchingReference],
          value.split('.').slice(1)
        )
      } else {
        return value
      }
    } else {
      return value
    }
  })
}

const theme = recursivelySubstitute(
  { base, ...text, ..._theme },
  { colors: colors.default, base: base.default }
)

theme.overrides = {
  MuiInput: {
    underline: {
      '&.MuiInput-formControl:after': {
        borderBottomColor: colors.accent
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&.Mui-focused': {
        color: colors.accent
      }
    }
  }
}

const addColorToMUITheme = colorKey => {
  const color = colors[colorKey]
  theme.palette[colorKey] = {
    main: color,
    light: color,
    dark: color
  }
}

theme.palette = {}
for (const property in colors) {
  addColorToMUITheme(property)
}

export function getTheme (config = 'default') {
  const coloursConfig = colorsv2[config] ? colorsv2[config] : colorsv2.default
  const baseConfig = basev2[config] ? basev2[config] : basev2.default
  const theme = recursivelySubstitute(
    { base: baseConfig, ...text, ..._theme },
    { colors: coloursConfig, base: baseConfig }
  )

  theme.overrides = {
    MuiInput: {
      underline: {
        '&.MuiInput-formControl:after': {
          borderBottomColor: colors.accent
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: colors.accent
        }
      }
    }
  }

  theme.palette = {}
  for (const colorKey in coloursConfig) {
    const color = coloursConfig[colorKey]
    theme.palette[colorKey] = {
      main: color,
      light: color,
      dark: color
    }
  }

  return theme
}

export { theme, colors, visibility }
export default theme
