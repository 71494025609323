import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import _ from 'lodash'
import InStoreOverallStock from './InStoreOverallStock'
import { translations, stockLevel as stockConstants, formNames } from '../../../../../config'
import { selectors as ProductDetailSelectors } from '../../../../../store/modules/productDetails'

const mapStateToProps = (state) => {
  const product = ProductDetailSelectors.getProduct(state)

  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId', null)
  const selectedVariant = _.first(_.filter(product.variants, { id: selectedVariantId }))

  const productStoreStock = _.sumBy(product.variants, (variant) => variant.variantStoreStock.stock)
  const variantStoreStock = _.get(selectedVariant, 'variantStoreStock.stock', 0)

  const storeStock = selectedVariantId ? variantStoreStock : productStoreStock

  let stockProps = {}
  if (storeStock >= stockConstants.inStockLevel) {
    stockProps = {
      stockStatus: translations('Available In-Store'),
      statusIcon: 'greenCheckIcon'
    }
  } else if (storeStock <= stockConstants.lowStockHighLevel && storeStock >= stockConstants.lowStockLowLevel) {
    stockProps = {
      stockStatus: translations('Low Availability In-Store'),
      statusIcon: 'orangeCheckIcon'
    }
  } else if (storeStock === stockConstants.outOfStockLevel) {
    stockProps = {
      stockStatus: translations('Unavailable In-Store'),
      statusIcon: 'redCrossIcon'
    }
  } else if (storeStock === stockConstants.itemNotStocked) {
    stockProps = {
      stockStatus: translations('Item not stocked'),
      statusIcon: 'redCrossIcon'
    }
  }

  return {
    product,
    stockProps
  }
}

export default connect(mapStateToProps, null)(InStoreOverallStock)
