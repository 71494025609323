import customerService from './services'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'

import digitalStoreSdk from '../../../digitalStoreSdk'

import { customerRegionEnabled,  customerSearchPageSize} from '../../../config'

class CustomerActions {
  searchPostcode = searchTerm => ({
    type: constants.SEARCH_POSTCODE,
    promise: () => {
      return customerService.searchPostcode(searchTerm)
    }
  })

  searchFullAddress = id => ({
    type: constants.SEARCH_FULL_ADDRESS,
    promise: () => customerService.searchFullAddress(id)
  })

  searchCustomerFresh = ({ query }) => ({
    type: constants.SEARCH_CUSTOMER_FRESH,
    query,
    promise: (dispatch, getState) => {
      const state = getState()
      const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined
      // remove leading '0' from query, for telephone seach
      const modifiedQuery = _.head(query) === '0' ? query.substring(1) : query
      return digitalStoreSdk.customers.searchCustomers({ query: modifiedQuery, page: 1, size: customerSearchPageSize, loggedInStoreId })
      .then(({ total, items, totalRecords }) => ({ total, results: items, query, totalRecords }))
      
    }
  })
  searchCustomerNext = () => ({
    type: constants.SEARCH_CUSTOMER_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const page = selectors.getCustomerListPage(state)
      const query = selectors.getCustomerListQuery(state)
      const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined
      return digitalStoreSdk.customers
        .searchCustomers({ query, page: page + 1, size: customerSearchPageSize, loggedInStoreId })
        .then(({ total, items, totalRecords }) => ({ total, results: items, query, totalRecords }))
    }
  })

  updateCustomerInList = ({ customer }) => ({
    type: constants.UPDATE_CUSTOMER_IN_LIST,
    customer
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  searchCustomerClearResults = () => ({
    type: constants.SEARCH_CLEAR_RESULTS
  })
}

export default new CustomerActions()
