import { lighten } from 'polished'

const styles = theme => ({
  rootMenu: {
    /* Allows content to fill the viewport and go beyond the bottom */
    height: '100%'
  },
  container: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
    '& > div': {
      flexShrink: 0
    }
  },
  header: {
    padding: 17,
    backgroundColor: theme.palette.lightGrey.main,
    borderBottom: `0.5px solid ${theme.palette.border.main}`,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    margin: 0
  },
  listContainer: {
    flexShrink: '1 !important',
    minHeight: 0,
    overflow: 'auto',
    flex: 1
  },
  footer: {
    marginTop: 'auto',
    borderTop: `0.5px solid ${theme.palette.border.main}`,
    padding: 10
  },
  buttonWithMargin: {
    marginTop: 10
  },
  startConsultationButtonContainer: {
    padding: 10,
    borderBottom: `0.5px solid ${theme.palette.border.main}`
  },
  totalsContainer: {
    padding: '4px 7px 1px'
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '1px 0'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: 10
  },
  customerNameContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.border.main}`
  },
  customerName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 14
  },
  removeButton: {
    fontWeight: 700
  },
  formControl: {
    width: '100%'
  },
  buttonGroup: {
    marginTop: '10px',
    marginBottom: '5px',
    marginLeft: '30px',
    marginRight: '30px'
  },
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  emptyWishListMessage: {
    textAlign: 'center',
    margin: '15px 17px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  closeButton: {
    padding: 4,
  }
})

export default styles
