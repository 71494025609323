import _ from 'lodash'
import { createSelector } from 'reselect'

import { selectors as authSelectors } from '../auth'
import { selectors as configSelectors } from '../config'
import { selectors as regionsSelectors } from '../regions'

export const getSelectedRegion = createSelector([
  regionsSelectors.getRegions,
  authSelectors.getUserSelectedRegionId
], (regions, selectedRegionId) => {
  const regionsArray = regions || []
  return _.find(regionsArray, region => region.id === selectedRegionId)
})

export const getRegionBrand = createSelector([
  getSelectedRegion
], (region) => {
  return _.get(region, 'details.brandName')
})

export const getVatPercentAndCurrency = createSelector(
  getSelectedRegion,
  region => {
    return { vatPercent: _.get(region, 'details.vatPercent'), currencyCode: _.get(region, 'currencyCode') }
  }
)

export const getSelectedRegionName = createSelector(
  getSelectedRegion,
  region => _.get(region, 'name')
)

export const getSelectedRegionLocationLookupCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'locationLookupCode')
)

export const getCTBasketAPIStoreCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'details.ctBasketAPIStoreCode')
)

export const getSelectedRegionStripeKey = createSelector(
  getSelectedRegion,
  region => _.get(region, 'details.stripePublicKey')
)

export const getSelectRegionExternalId = createSelector(
  getSelectedRegion,
  region => _.get(region, 'externalRegionId')
)

export const getRegionCurrencyCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'currencyCode')
)

export const getCurrencyConfigForSelectedRegion = createSelector(
  [getSelectRegionExternalId, configSelectors.getConfig],
  (regionExtId, config) => {
    return _.get(config, `currency[${regionExtId}]`) || []
  }
)
export const getBrandConfig = createSelector(
  [getRegionBrand, configSelectors.getConfig],
  (brand, config) => {
    return _.get(config, `brandConfig[${brand}]`)
  }
)
export const getCheckoutConfigForBrand = createSelector(
  getBrandConfig,
  (config) => {
    return _.get(config, `checkout`)
  }
)
export const getProductCatalogueConfigForBrand = createSelector(
  getBrandConfig,
  (config) => {
    return _.get(config, `productCatalogue`)
  }
)
