import React from 'react'
import styled from 'styled-components'
import List from '../../../../../../components/List'
import Button from '../../../../../../components/Button'
import modalService from '../../../../../../services/modalService'
import StockAvailabilityModalItem from './StockAvailabilityModalItem'
import { translations } from '../../../../../../config'

const StockAvailabilityModal = ({
  selectedVariantId,
  dataStoreStock,
  ecommerceStock,
  storeStock
}) => {

  const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  `

  const SubTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0 0 20px;
    text-transform: uppercase;
  `

  const ButtonContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
  `

  const NoVariantSelectedComponent = () => (
    <>
      <Title>{translations('Please select a size')}</Title>
      <ButtonContainer>
        <Button color='primary' fullWidth onClick={modalService.close}>
          {'close'}
        </Button>
      </ButtonContainer>
    </>
  )
  const StockUnavailableComponent = () => (
    <>
      <Title>{translations('Item not available')}</Title>
      <ButtonContainer>
        <Button color='primary' fullWidth onClick={modalService.close}>
          {'close'}
        </Button>
      </ButtonContainer>
    </>
  )

  const AvailableStockComponent = () => (
    <>
      <Title>{translations('Find in other locations')}</Title>
      {(dataStoreStock.length > 0) &&
        <>
          <SubTitle>{translations('Find in other locations - Data')}</SubTitle>
          <List items={dataStoreStock} ItemComponent={StockAvailabilityModalItem} />
        </>
      }
      {(ecommerceStock.length > 0) &&
        <>
          <SubTitle>{translations('Find in other locations - E-Commerce')}</SubTitle>
          <List items={ecommerceStock} ItemComponent={StockAvailabilityModalItem} />
        </>
      }
      {(storeStock.length > 0) &&
        <>
          <SubTitle>{translations('Find in other locations - Store')}</SubTitle>
          <List items={storeStock} ItemComponent={StockAvailabilityModalItem} />
        </>
      }
    </>
  )

  if (!selectedVariantId) return <NoVariantSelectedComponent />

  return (storeStock.length <= 0 && dataStoreStock.length <= 0 && ecommerceStock.length <= 0)
    ? <StockUnavailableComponent />
    : <AvailableStockComponent />

}

export default StockAvailabilityModal