import _ from 'lodash'

const calculateTaxValue = ({ taxPercentage, value }) => {
  return (value / (100 + parseFloat(taxPercentage))) * taxPercentage
}

export const calculateOrderTotals = ({ products, total, shipping, currentCurrency, remoteConfig }) => {
  const pricePaid = _.get(total, 'value', 0)
  const taxPercentage = remoteConfig.filter(item => item.id === currentCurrency)[0].taxPercentage
  const shippingValue = shipping && shipping.price ? parseFloat(shipping.price.value) : 0

  const discount = products.reduce((accum, item) => {
    accum += item.discount ? parseFloat(item.discount.value) : 0
    return accum
  }, 0)

  const originalPriceTotal = products.reduce((accum, item) => {
    accum += parseFloat(item.price.value) || 0
    return accum
  }, 0)

  return {
    retailPrice: originalPriceTotal.toFixed(2),
    discount: discount.toFixed(2),
    pricePaid: pricePaid.toFixed(2),
    shipping: shippingValue.toFixed(2),
    currencyTax: parseFloat(calculateTaxValue({ taxPercentage, value: pricePaid })).toFixed(2),
    discountTax: parseFloat(calculateTaxValue({ taxPercentage, value: discount })).toFixed(2),
    shippingTax: parseFloat(calculateTaxValue({ taxPercentage, value: shippingValue })).toFixed(2)
  }
}
