import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import Button from '../../../../../components/Button'
import P from '../../../../../components/P'
import ActivityList from '../../../../../components/ActivityList'
import CustomerSnapshopActivityItem from '../CustomerSnapshopActivityItem'
import { translations } from '../../../../../config'

const CustomerSnapshotLatest = ({ classes, activities, seeAllActivities }) => (
  <section className={classes.listItemsContainer}>
    <div className={classes.titleWrapper}>
      <P type='textButton' value={translations('Latest Activity')} />
      { activities.length
        ? (<Button
          classes={{ root: classes.button }}
          color='primary'
          onClick={seeAllActivities}
        >{translations('See All Activity')}</Button>)
        : null
    }
    </div>
    {activities.length
      ? (
        <ActivityList
          activities={activities.slice(0, 3)}
          render={({ activity, ActivityContainer }) => {
            const ActivityComponent = ActivityContainer(CustomerSnapshopActivityItem)
            return <ActivityComponent key={activity.id} activity={activity} />
          }}
        />
      )
    : translations('No activity for customer')}
  </section>
)

export default withStyles(style)(CustomerSnapshotLatest)
