import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'

import toastService from '../../../services/toastService/toastService'
import Container from '../../../components/Container'
import SubHeader from '../../../components/SubHeader'
import LoaderContainer from '../../../components/LoaderContainer'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import StoreDetailsForm from '../StoreDetailsForm'
import { translations } from '../../../config'
import * as offlinePasswordHandler from '../../../helpers/offlinePasswordHandler'

import { actions as productCataloguesActions } from '../../../store/modules/productCatalogues'
import { actions as storeDetailsActions } from '../../../store/modules/storeDetails'
import { actions as regionActions } from '../../../store/modules/regions'
import { actions as calendarsActions } from '../../../store/modules/calendars'
import { useResults as useRegionResults, useIsLoading as useIsRegionLoading } from '../../../hooks/regionsHooks'
import { useResults as useStoreResults, useIsFetchStoreLoading } from '../../../hooks/storeDetailsHooks'
import { useResults as useProductCatalogueResults, useIsLoading as useIsProductCataloguesLoading } from '../../../hooks/productCataloguesHooks'
import { useResults as useCalendarsResults, useIsLoading as useIsCalendarsLoading } from '../../../hooks/calendarsHooks'

// import { useIsLoading } from '../../../hooks/storesHooks'

const StoreDetailsScreen = ({ match }) => {
  const dispatch = useDispatch()
  const storeId = _.get(match, 'params.id')
  const regions = useRegionResults()
  const calendars = useCalendarsResults()
  const storeDetails = useStoreResults()
  const productCatalogues = useProductCatalogueResults()
  const isRegionLoading = useIsRegionLoading()
  const isStoreLoading = useIsFetchStoreLoading()
  const isCalendarsLoading = useIsCalendarsLoading()
  const isProductCataloguesLoading = useIsProductCataloguesLoading()
  const isDependenciesLoading = isRegionLoading || isProductCataloguesLoading || isCalendarsLoading
  const isLoading = storeId ? isStoreLoading || isDependenciesLoading : isDependenciesLoading
  const formMode = storeId ? 'edit' : 'create'
  const title = storeId ? _.get(storeDetails, 'name') : translations('Create Store')
  const [latitude, longitude] = _.trim(_.get(storeDetails, 'details.latLng')).split(',')
  const city = _.get(storeDetails, 'details.city')

  const mapInitialValues = (values = {}) => {
    return {
      ..._.pick(values, [
        'name',
        'contactEmail',
        'externalStoreId',
        'regionId',
        'catalogue'
      ]),
      currentStorePin: _.get(values, 'details.storeCode'),
      locationType: _.get(values, 'details.locationType'),
      latitude,
      longitude,
      city
    }
  }

  const mapForDB = (values) => {
    return {
      ..._.pick(values, [
        'name',
        'contactEmail',
        'externalStoreId',
        'regionId',
        'catalogue'
      ]),
      details: {
        ..._.get(values, 'storeCode') ? { storeCode: offlinePasswordHandler.hash({ password: values.storeCode }) } : {},
        ...{ locationType: _.get(values, 'locationType') },
        latLng: `${values.latitude}, ${values.longitude}`,
        city: values.city
      }
    }
  }

  const regionOptions = React.useMemo(() => {
    return regions.map(region => ({
      value: region.id,
      label: region.name
    }))
  }, [regions])

  const calendarOptions = React.useMemo(() => {
    return calendars.map(calendar => ({
      value: calendar.id,
      label: calendar.name
    }))
  }, [calendars])

  const locationTypeOptions = [
    { value: 'dataStore', label: 'Data'},
    { value: 'ecommerceStore', label: 'E-Commerce' }
  ]

  const initialValues = React.useMemo(() => ({
    ...mapInitialValues(storeDetails),
    calendarIds: [
      ..._.map(_.get(storeDetails, 'calendars', []), calendar => {
        return calendar.id
      })
    ]
  }), [storeDetails])

  const submitCreate = (values) => {
    const detailsValues = mapForDB(values)
    const calendarIds = _.get(values, 'calendarIds')
    return dispatch(storeDetailsActions.createStore(detailsValues))
      .then((res) => {
        if (calendarIds) {
          return dispatch(storeDetailsActions.setStoreCalendars({
            id: res.id,
            calendarIds
          }))
        } else {
          return res
        }
      })
      .then(() => {
        dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Store successfully created'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const submitEdit = (values) => {
    const detailsValues = mapForDB(values)
    const calendarIds = _.get(values, 'calendarIds')
    return dispatch(storeDetailsActions.updateStore({ id: storeId, fields: detailsValues }))
      .then((res) => {
        if (calendarIds) {
          return dispatch(storeDetailsActions.setStoreCalendars({
            id: storeId,
            calendarIds
          }))
        } else {
          return res
        }
      })
      .then(() => {
        dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Store successfully updated'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  useEffect(() => {
    if (storeId) {
      dispatch(storeDetailsActions.fetchStore({ id: storeId, includes: 'calendars' }))

      return () => {
        dispatch(storeDetailsActions.clear())
      }
    }
  }, [storeId])

  useEffect(() => {
    dispatch(regionActions.fetchAllRegions())
    dispatch(productCataloguesActions.fetchProductCatalogues())
    dispatch(calendarsActions.fetchCalendarsFresh())
  }, [])

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(title)} />
        )}
      />
      <Container>
        <LoaderContainer isLoading={isLoading}>
          <p>{translations('required fields')}</p>
          <StoreDetailsForm
            regions={regionOptions}
            catalogues={productCatalogues}
            calendars={calendarOptions}
            locationTypes={locationTypeOptions}
            initialValues={initialValues}
            mode={formMode}
            onSubmit={storeId ? submitEdit : submitCreate}
          />
        </LoaderContainer>
      </Container>
    </div>
  )
}

export default StoreDetailsScreen
