import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MenuGrid from '../../../components/MenuGrid'
import MenuWidget from '../../../components/MenuWidget'

import style from './style'

const HomeScreen = ({
  widgets,
  onMenuItemClick,
  onClick,
  classes,
  user,
  hasUserManagement,
  appsAllowedForRole,
  networkConnectivity,
  configName
}) => {
  const filteredWidgets = widgets.filter(({ id, allowOffline, ...others }) => {
    return appsAllowedForRole[id] && (networkConnectivity || allowOffline)
  })

  if(!configName) return null
  return (
    <MenuGrid widgets={filteredWidgets} renderWidget={(widget) => (
      <MenuWidget
        onClick={() => onMenuItemClick(widget.id)}
        id={widget.id} {...widget}
      />
    )} />
  )
}

HomeScreen.defaultProps = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    action: PropTypes.object,
    title: PropTypes.string
  }))
}

export default withStyles(style)(HomeScreen)
