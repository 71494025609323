import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'connected-react-router'

import { actions as customersActions, selectors as customersSelectors } from '../../../store/modules/customers'
import { selectors as followingSelectors } from '../../../store/modules/following'
import { selectors as activitiesSelectors } from '../../../store/modules/activities'
import * as regionsCombinedSelectors from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import ClientBookScreen from './ClientBookScreen'

const mapStateToProps = state => {
  const query = customersSelectors.getCustomerListQuery(state)
  const following = followingSelectors.getFollowing(state)
  const isLoading = followingSelectors.getFetchFollowingIsLoading(state)
  const activities = activitiesSelectors.getActivities(state)
  const tabValue = appSelectors.getClientBookTab(state)
  const regionCurrency = regionsCombinedSelectors.getRegionCurrencyCode(state)
  return {
    query,
    following,
    isLoading,
    activities,
    tabValue,
    regionCurrency
  }
}

const mapDispatchToProps = dispatch => {
  const searchCustomer = (query) => {
    dispatch(customersActions.searchCustomerFresh(query))
  }
  const goToCustomer = (customerId) => {
    dispatch(push(`/customers/${customerId}`))
  }
  const handleTabChange = (event, value) => {
    dispatch(appActions.changeClientBookTab({ clientBookTab: value }))
  }
  return {
    searchCustomer,
    goToCustomer,
    handleTabChange
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ClientBookScreen)
