import _ from 'lodash'

export default (product, selectedCurrency) => {
  if (!selectedCurrency) {
    return product.price
  }

  const defaultCurrencySelected = _.get(product, 'price.code') === selectedCurrency
  const selectedCurrencyPrice = _.get(product, `details.prices[${selectedCurrency}]`)

  if (selectedCurrencyPrice) {
    return {
      code: selectedCurrency,
      value: selectedCurrencyPrice
    }
  }

  if (defaultCurrencySelected) {
    return product.price
  }

  return {
    code: selectedCurrency,
    value: undefined
  }
}