const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: "93px" //spacer for underneath position:fixed "done" box
  },
  leftPanel: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  leftPanelHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 80
  },
  leftPanelHeaderMiddle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0.5rem'
  },
  leftPanelHeaderSide: {
    [theme.breakpoints.down('xs')]: {
      width: 41
    },
    [theme.breakpoints.up('sm')]: {
      width: 101
    }
  },
  categoryList: {
    overflowY: 'scroll'
  },
  rightPanel: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flex: 1.2,
    [theme.breakpoints.down('sm')]: {
      flex: 0,
      display: 'none'
    }
  },
  image: {
    width: '60%',
    height: '100%',
    top: 48,
    right: 0,
    position: 'fixed',
    objectFit: 'cover'
  }
})

export default styles
