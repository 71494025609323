import { setUiConfig } from '../uiConfigStore'

// Set universal checkout options
setUiConfig('Modules.Checkout.Screens.RootScreen', {
  paymentMethodOptions: [
    { id: 'CARD', label: 'Credit / debit card', checkoutType: ['virtualConsultation'] },
    { id: 'SKIP_PAYMENT', label: 'Skip Payment', checkoutType: ['inStoreConsultation', 'basketCheckout'] },
    { id: 'PAY_BY_LINK', label: 'Pay by link', checkoutType: ['virtualConsultation'] },
    { id: 'KLARNA', label: 'Klarna' }
  ],
  offsetTop: undefined,
  orderNotesMaxChars: 280,
  saveTransaction: false,
  features: {
    discounting: false,
    editUnitPrice: false,
    backdating: false,
    promoCodes: false,
    orderNotes: false,
    deliveryMethods: false
  },
  quantitySelector: {
    type: 'dropdown',
    max: 10,
  },
  subTitle: { // should be in rem 
    fontSize: 0.9
  }
})
