const style = theme => ({
  container: {
    background: theme.palette.lightGrey.main,
    padding: '10px 10px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 20px'
    }
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  attachmentIcon: {
    transform: 'rotate(-30deg)',
    margin: '0 5px 0 0'
  },
  attachmentLink: {
    textDecoration: 'none',
    fontSize: 14,
  },
  imageContainer: {
    minWidth: 35,
    height: 35,
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minWidth: 45,
      height: 45
    }
  },
  image: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 20
    }
  },
  receipt: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 10,
    height: 10
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between'
    }
  },
  notificationText: {
    fontSize: 13,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  textLastItem: {
    paddingBottom: 10
  },
  togglesContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0 5px auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      margin: '0 15px 0 auto',
      flexDirection: 'row'
    }
  },
  dateTime: {
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center'
    }
  },
  isReadState: {
    background: 'white'
  },
  userTextContainer: {
    order: 2,
    marginTop: 5,
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      order: 1,
      marginRight: 5
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      display: 'flex'
    }
  },
  toggles: {
    display: 'flex',
    order: 1,
    marginLeft: '-5px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginLeft: 0
    }
  },
  notificationTextStyle: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 5
    }
  },
  circleButtonStyle: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 11
    }
  },
  hideSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideMediumUp: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  notificationContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  }
})

export default style
