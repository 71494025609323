 import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import H2 from '../../../../components/H2'
import { AddressText } from '../../../../components/Address/util'

import style from './style'

let BillingSection = props => {
  const { classes, details = {} } = props

  return <div className={classes.container}>
    <div className={classes.inner}>
      <H2
        value={translations('Billing Details')}
        className={classes.title}
      />
      <AddressText
        address={details.billing}
      />
    </div>
  </div>
}

BillingSection = withStyles(style)(BillingSection)

export { BillingSection }
export default BillingSection
