import React, { Fragment } from 'react'
import { compose, pure } from 'recompose'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import style from './style'
import CheckoutWrapper from '../common/CheckoutWrapper'
import { translations, countriesOptions } from '../../../config'
import Form from '../../../components/Form'
import { Radiolist, Dropdown } from '../../../components/Fields'
import H2 from '../../../components/H2'
import Address from '../../../components/Address'
import P from '../../../components/P'
import Button from '../../../components/Button'

export const formId = 'billing-optdetailsions'

const BillingForm = Form(formId)

const typeOptions = [
  { value: 'inStoreSale', label: 'In-store Sale' },
  { value: 'home', label: 'Home Delivery' },
  { value: 'store', label: 'Collect from Store' }
]

const Billing = props => {
  const {
    classes,
    hasAddressPrefilled,
    onSubmit,
    initialValues,
  } = props
  const titleClass = classNames(classes.title, classes.deliveryTitle)
  return <>
    <BillingForm
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <CheckoutWrapper
        formId={formId}
        displayBasketSection={false}
      >
        <div className={classes.container}>
          <div className={classes.inner}>
            <H2
                value={translations('Billing Details')}
                className={titleClass}
              />
            <P
              value={translations('required fields')}
              className={classes.requiredInfo}
            />
            <Grid container spacing={2}>
              <Address
                fullWidth
                formName={formId}
                noContentBox
                required
                alwaysShowFields={hasAddressPrefilled}
                hideFindAddress
              />
            </Grid>
          </div>
        </div>
      </CheckoutWrapper>
    </BillingForm>
  </>
}

export default withStyles(style)(Billing)
