const styles = theme => ({
  container: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      width: '46%'
    }
  },
  noPadding: {
    padding: 0
  },
  smallModal: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '38%'
    }
  },
  wideModal: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    }
  },
  fixedWidthModal: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },
  zoomModal: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 750
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 900
    }
  },
  fullScreenContainer: {
    overflow: 'auto',
    paddingTop: 'env(safe-area-inset-top)'
  },
  root: {
    // this was added to fix https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
    // been removed as it was causing the modals to jump to the top of the screen
    // looks as though it has been fixed in iOS - need to check with a proper build though
    // position: 'absolute'
  }
})

export default theme => styles(theme)
