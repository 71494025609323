import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { Checklist, Dropdown } from '../../../../components/EditableFields'
import { communicationOptions, translations } from '../../../../config'
import * as validators from '../../../../validators'
import { getLanguage } from '../../../../config/languages'

import style from './style'

const CommunicationOptionsForm = FormContentBox('communicationOptions')

const WrappedCommunicationOptions = Editable(({
  languageOptions,
  initialValues,
  editing,
  toggleEdit,
  isSubmitting,
  boxName,
  onSubmit,
  formId,
  extraProps,
  allStoresOptions,
  classes,
  editable
}) => {
  const channelsDisplayValue = initialValues.customerMarketingChannels.map(value => translations(_.get(communicationOptions.channelOptionKeys.find(option => option.value === value), 'label'))).join(', ')
  const preferencesDisplayValue = initialValues.customerMarketingPreferences.map(value => translations(_.get(communicationOptions.preferenceOptionKeys.find(option => option.value === value), 'label'))).join(', ')
  const storesDisplayValue = allStoresOptions ? initialValues.marketingStoreIds.map(value => translations(_.get(allStoresOptions.find(option => option.value === value), 'label'))).join(', ') : ''
  const preferredLanguageDisplayValue = getLanguage(initialValues.preferredLanguage)
  const { storeMarketingValue } = extraProps
  const enabledCommChannelOptions = communicationOptions.channelOptionKeys.filter(option => option.enabled)
  // pass extra props into form so that it re-renders through the form body container
  return (
    <div>
      <CommunicationOptionsForm
        enableReinitialize
        initialValues={initialValues}
        editing={editing}
        editable={editable}
        toggleEdit={toggleEdit}
        isSubmitting={isSubmitting}
        boxName={translations(boxName)}
        onSubmit={onSubmit}
        formId={formId}
        extraProps={extraProps}
      >
        <FormError />
        <FormBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Checklist
                editing={editing}
                initialValues={initialValues}
                options={enabledCommChannelOptions}
                name='customerMarketingChannels'
                label={translations('Marketing Channels')}
                multiple
                displayValue={channelsDisplayValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checklist
                editing={editing}
                initialValues={initialValues}
                options={communicationOptions.preferenceOptionKeys}
                name='customerMarketingPreferences'
                label={translations('Marketing Preferences')}
                multiple
                displayValue={preferencesDisplayValue}
              />
            </Grid>
            {storeMarketingValue && (
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.fullWidth}>
                  <Dropdown
                    options={allStoresOptions}
                    name={'marketingStoreIds'}
                    label={translations('Stores for marketing')}
                    editing={editing}
                    initialValues={initialValues}
                    multiple
                    displayValue={storesDisplayValue}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.fullWidth}>
                <Dropdown
                  editing={editing}
                  initialValues={initialValues}
                  name='preferredLanguage'
                  label={translations('Preferred Language')}
                  options={languageOptions}
                  autocomplete
                  displayValue={preferredLanguageDisplayValue}
                  validate={validators.required}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormBody>
      </CommunicationOptionsForm>
    </div>
  )
})

export default withStyles(style)(WrappedCommunicationOptions)
