import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import { actions as appActions } from '../../../../store/modules/app'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { actions as currentOrderActions } from '../../../../store/modules/currentOrder'
import { actions as orderDetailsActions } from '../../../../store/modules/orderDetails'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import { actions as chatActions, selectors as chatSelectors } from '../../../../store/modules/chat'

import BasketRouter from '../../../Basket/BasketRouter'
import BottomBarComponent from '../../../../components/BottomBar'
import ButtonsContainer from '../../../../components/ButtonsContainer'
import Button from '../../../../components/Button'

const BottomBar = ({
  hasContents
}) => {
  const dispatch = useDispatch()
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const appointmentId = useSelector(currentAppointmentSelectors.getAppointmentId)
  const appointmentUserId = useSelector(authSelectors.getActiveUserId)
  const appointmentStoreId = useSelector(authSelectors.getUserSelectedStoreId)
  const appointmentCustomerId = useSelector(currentAppointmentSelectors.getAppointmentCustomerId)
  const isAppointmentEnded = useSelector(currentAppointmentSelectors.getIsAppointmentEnded)
  const appointmentType = useSelector(currentAppointmentSelectors.getAppointmentType)
  const chatChannelId = useSelector(chatSelectors.getChatChannelId)

  const onReview = useCallback(() => {
    dispatch(appActions.toggleCustomerReviewBasket({ isOpen: true }))
    dispatch(currentAppointmentActions.setStage({ stage: 'REVIEW' }))
    modalService.open({
      component: BasketRouter,
      initialPath: '/basket',
      fullScreen: true,
      modalIndex: 1,
      noRouting: true,
      silent: true,
      onDone: () => {
        dispatch(appActions.toggleCustomerReviewBasket({ isOpen: false }))
        dispatch(currentOrderActions.manageCustomerReviewBasketStatus({ seen: true }))
        dispatch(currentAppointmentActions.setStage({ stage: null }))
        return modalService.close({ modalIndex: 1 })
      }
    })
  })

  const completeConsultation = useCallback(() => {
    dispatch(appActions.toggleBasket({ isOpen: false }))
    return dispatch(currentOrderActions.clearOrder({
      isVirtualConsultation: true,
      clearAppointment: false
    }))
      .then(() => {
        return dispatch(currentOrderActions.saveConsultationOrderToProfile({
          userId: appointmentUserId,
          storeId: appointmentStoreId,
          customerId: appointmentCustomerId
        }))
          .then(async ({ id: orderId }) => {
            await dispatch(currentAppointmentActions.updateCurrentAppointment({
              id: appointmentId,
              orderId,
              status: 'COMPLETE'
            }))
            dispatch(orderDetailsActions.updateOrder({ id: orderId, status: 'complete' }))
            await dispatch(currentAppointmentActions.setStage({ stage: 'COMPLETE' }))
          })
          .then(() => {
            modalService.action({
              title: translations('Consultation Saved'),
              text: translations('Your consultation has been saved'),
              actions: [
                {
                  success: true,
                  text: translations('OK'),
                  onClick: () => {
                    modalService.close({ modalIndex: 1 })
                  },
                  primary: true
                }
              ]
            })
          })
          .catch(e => {
            console.error(e)
            modalService.action({
              title: translations('Error'),
              text: translations('Failed saving consultation.'),
              actions: [
                {
                  success: true,
                  text: translations('OK'),
                  primary: true,
                  onClick: () => {
                    modalService.close({ modalIndex: 1 })
                  }
                }
              ]
            })
          })
      })
  })

  const onComplete = useCallback(() => {
    if (isAppointmentComplete) {

      if (window.twilioRoom) {
        window.twilioRoom.disconnect()
        window.twilioRoom = null
      }
      dispatch(currentAppointmentActions.endAppointment())
      dispatch(push('/consultations'))
      if (chatChannelId) {
        // dispatch(chatActions.deleteChatChannel({ channelId: chatChannelId }))
        // NOTE: this is now handle by the apiEvent event:appointment:complete
      }

      // this is in a timeout to try to ensure
      // the endAppointment action above has synced with couchbase before the clearAppointment action is triggered
      // because once the appointment is cleared the updates to the store are no longer synced with couchbase
      setTimeout(() => {
        dispatch(currentAppointmentActions.clearAppointment())
        dispatch(currentOrderActions.clearOrder())
      }, 1000)

    } else {
      const endAppointmentText = appointmentType === 'VIRTUAL_CONSULTATION'
        ? translations('Virtual Consultation - end consultation warning text')
        : translations('Consultation - end consultation warning text')
      modalService.action({
        title: translations('Are you sure?'),
        text: endAppointmentText,
        actions: [
          {
            text: translations('Cancel')
          },
          {
            success: true,
            text: translations('End consultation'),
            onClick: () => {
              dispatch(currentOrderActions.clearOrder({ showToast: false, clearAppointment: false }))
              completeConsultation()
            },
            primary: true
          }
        ]
      })
    }
  })
  const endAppointmentButtonText = appointmentType === 'VIRTUAL_CONSULTATION' ? translations('Disconnect') : translations('Complete')
  const appointmentEndButtonText = isAppointmentComplete ? endAppointmentButtonText : translations('End consultation')
  return (
    <BottomBarComponent>
      <ButtonsContainer align='center'>
        {!isAppointmentComplete && (
          <Button
            buttonType='primary'
            big
            onClick={onReview}
            disabled={!hasContents}
          >
            {translations('Review')}
          </Button>
        )}
        <Button
          buttonType='primary'
          big
          onClick={onComplete}
        >
          {isAppointmentEnded ? translations('Back to appointments') : appointmentEndButtonText}
        </Button>
      </ButtonsContainer>
    </BottomBarComponent>
  )
}

export default BottomBar
