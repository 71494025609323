import _ from 'lodash'
import { connect } from 'react-redux'
import { withState, withHandlers, compose, mapProps } from 'recompose'
import { getGroupKeyFromProduct } from '@redant/digital-store-prices-chalhoub'
import { openAddDiscountModal } from '../addDiscountModal'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../../store/modules/currentOrder'
import { selectors as appSelectors } from '../../../../store/modules/app'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'

const mapStateToProps = state => {
  return {
    currencyCode: appSelectors.getAppCurrency(state),
    currentOrderDiscountType: currentOrderSelectors.getDiscountType(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addManualDiscount: params => dispatch(currentOrderActions.addManualDiscountToProduct(params)),
    setCurrentOrderDiscount: params => dispatch(currentOrderActions.setCurrentOrderDiscount(params)),
    removeManualDiscountFromOrder: () => dispatch(currentOrderActions.removeManualDiscountFromOrder())
  }
}

export const withDiscountMode = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('discountMode', 'doToggleDiscountMode', false),
  withState('selectedLines', 'setSelectedLines', {}), // { [lineIndex]: true }
  withHandlers({
    toggleDiscountMode: ({ setSelectedLines, doToggleDiscountMode }) => (bool) => {
      setSelectedLines({})
      setImmediate(() => { doToggleDiscountMode(bool) })
    },
    addSelection: ({ selectedLines, setSelectedLines }) => (index) => {
      selectedLines[index] = true
      setSelectedLines({ ...selectedLines, [index]: true })
    },
    removeSelection: ({ selectedLines, setSelectedLines }) => (index) => {
      setSelectedLines({ ...selectedLines, [index]: false })
    },
    toggleSelectAll: ({ products, selectedLines, setSelectedLines }) => (all) => {
      const selectedProductLines = getSelectedProductLines(products, selectedLines)
      const productsSanitized = _.filter(products, (product) => {
        return !product.service && !product.refund
      })

      if (all === false || products.length === selectedProductLines.length || productsSanitized.length === selectedProductLines.length) {
        setSelectedLines({})
      } else {
        let allSelected = {}

        products.forEach((p, index) => {
          if (!p.service && !p.refund) {
            allSelected[index] = true
          }
        })
        setSelectedLines(allSelected)
      }
    },
    applyDiscount: (props) => (orderDiscount) => {
      const {
        products,
        selectedLines,
        addManualDiscount,
        setSelectedLines,
        doToggleDiscountMode,
        setCurrentOrderDiscount,
        currentOrderDiscountType,
        currencyCode,
        removeManualDiscountFromOrder
      } = props
      // Work out which products to apply the disconunt to
      // const selectedItemsTotalPrice = getSelectedLinesTotal(products, selectedLines)
      const selectedProductLines = getSelectedProductLines(products, selectedLines)
      let observableProducts = orderDiscount ? products : selectedProductLines
      observableProducts = _.reverse(_.sortBy(observableProducts, ['refund']))
      console.log({ observableProducts })
      openAddDiscountModal({
        orderDiscount,
        products: observableProducts,
        currencyCode,
        success: ({ discountedProducts = [], category, value }) => {
          const discountConflict = _.some(discountedProducts, ({ product: discountedProduct }) => {
            const product = _.find(observableProducts, (observedProduct) => {
              if (orderDiscount && _.get(observedProduct, 'refund')) {
                return false
              }
              return observedProduct.id === discountedProduct.id
            })
            return _.get(product, 'manualDiscount.value', 0)
          })
          modalService.close()

          const _success = () => {
            if (orderDiscount) {
              if (currentOrderDiscountType === 'orderDiscount') {
                removeManualDiscountFromOrder()
                discountedProducts.map(({ product, discount, applyTo }) => {
                  addManualDiscount({ product: _.omit(product, ['manualDiscount']), discount, applyTo, orderDiscount })
                })
                setCurrentOrderDiscount({ discount: { type: 'orderDiscount' } })
              } else {
                discountedProducts.map(({ product, discount, applyTo }) => {
                  addManualDiscount({ product, discount, applyTo, orderDiscount })
                })
                setCurrentOrderDiscount({ discount: { type: 'orderDiscount', category, value } })
              }
            } else {
              if (currentOrderDiscountType === 'orderDiscount') {
                const orderDiscount = _.find(discountedProducts, ({ product }) => {
                  return _.get(product, 'refund')
                })
                if (orderDiscount) {
                  discountedProducts.map(({ product, discount, applyTo }) => {
                    addManualDiscount({ product, discount, applyTo, orderDiscount })
                  })
                  setCurrentOrderDiscount({ discount: { type: 'orderDiscount', category, value } })
                } else {
                  removeManualDiscountFromOrder()
                  discountedProducts.map(({ product, discount, applyTo }) => {
                    addManualDiscount({ product: _.omit(product, ['manualDiscount']), discount, applyTo, orderDiscount })
                  })
                  setCurrentOrderDiscount({ discount: { type: 'itemDiscount' } })
                }
              } else {
                discountedProducts.map(({ product, discount, applyTo }) => {
                  addManualDiscount({ product, discount, applyTo, orderDiscount })
                })
                setCurrentOrderDiscount({ discount: { type: 'itemDiscount' } })
              }
            }
            setSelectedLines({})
            doToggleDiscountMode(false)
          }

          if (discountConflict) {
            modalService.action({
              title: translations('This will override previous discounts'),
              text: translations('Applying this discount will override previous discounts. Are you sure?'),
              actions: [{
                success: true,
                text: translations('Yes'),
                onClick: _success,
                primary: true
              }, {
                text: translations('No')
              }]
            })
          } else {
            _success()
          }
        }
      })
    }
  }),
  withHandlers({
    discountAll: ({ toggleSelectAll, applyDiscount }) => () => {
      applyDiscount(true)
    },
    addDiscountItems: ({ toggleDiscountMode, setSelectedLines, products }) => (groupProduct) => {
      toggleDiscountMode(true)
      setImmediate(() => {
        let selected = {}
        const groupProductKey = getGroupKeyFromProduct(groupProduct)

        products.forEach((product, index) => {
          const thisProductKey = getGroupKeyFromProduct(product)

          // Find the indexes of the basket lines that match the product group
          if (thisProductKey === groupProductKey) {
            selected[index] = true
          }
        })

        setSelectedLines({ ...selected })
      })
    }
  }),
  mapProps(props => {
    const { selectedLines, products } = props
    const numSelected = getSelectedProductLines(products, selectedLines).length

    return { ...props, numSelected }
  })
)

const getSelectedProductLines = (all, selectedLines) =>
  _.chain(selectedLines)
    .map((value, prop) => ({ value, prop }))
    .filter(({ value }) => value)
    .map('prop')
    .map(index => all[index])
    .value()
