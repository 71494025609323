import { change } from 'redux-form'
import _ from 'lodash'
import uuid from 'uuid/v4'
import store from '../../../store'

import modalService from '../../../services/modalService'
import { selectors as productDetailsSelectors } from '../../../store/modules/productDetails'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'

import { translations } from '../../../config'
import ImageUploadModal from '../../ImageUploadModal'
import FileUploadModal from '../../FileUploadModal'
import { convertUrlWithUTMParams } from '../../../helpers/convertUrlWithUTMParams'

export default (() => {
  const addProductToField = ({ product, currentContent, dispatch, formName, fieldName }) => {
    const state = store.getState()
    const { id, name, brand, link, images, price, discount, externalProductId, details, variants, variant } = product

    if (_.get(price, 'value')) { price.value = _.toString(price.value) }
    if (_.get(discount, 'value')) { discount.value = _.toString(discount.value) }

    // variant is an object, product.variants is expecting an array
    const variantsArray = variant ? [variant] : variants 

    const modifiedProduct = {
      type: 'product',
      externalProductId,
      id,
      brand,
      link: convertUrlWithUTMParams(state, link), // include utm params on the product link
      images,
      name,
      price,
      details,
      displayPrice: productDetailsSelectors.getPriceDisplayForProduct(product),
      discount,
      variants: variantsArray
    }

    // Action of adding ean product in consultation is different from that in other places like Messaging, whishlist in customer profile page
    // In consultation, it adds content to appointment
    // In other places, it changes redux form
    if (formName === 'consultationCustomerDetailsForm') {
      modifiedProduct.variant = {
        ...variant,
        link: convertUrlWithUTMParams(state, variant.link) // include utm params on the product link
      }
      const productWithVariant = currentOrderSelectors.attachVariantToProduct({ product: modifiedProduct, variantId: variant.id, variantName: variant.name })
      dispatch(currentAppointmentActions.addContent({ type: 'product', details: productWithVariant }))
    } else {
      modifiedProduct.variants = modifiedProduct.variants && _.map(
        modifiedProduct.variants, 
        (variant) => ({
            ...variant,
            link: convertUrlWithUTMParams(state, variant.link) // include utm params on the product link
        }))
      const newContent = [modifiedProduct].concat(currentContent)
      dispatch(change(formName, fieldName, newContent))
    }
    dispatch(customerDetailsActions.editProductCarousel(''))
    modalService.close()
  }

  const addInspirationToField = ({ inspiration, currentContent, dispatch, formName, fieldName }) => {
    const { id, title, description, images, tags } = inspiration
    const modifiedInspiration = {
      type: 'inspiration',
      id,
      title,
      description,
      images,
      tags
    }
    const newContent = [modifiedInspiration].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addImageToField = ({ url, currentContent, dispatch, formName, fieldName, fileName }) => {
    const modifieldImage = {
      type: 'image',
      url,
      local: true,
      id: uuid(),
      fileName
    }
    const newContent = [modifieldImage].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addFileToField = ({ url, currentContent, dispatch, formName, fieldName, fileName }) => {
    const modifiedFile = {
      type: 'file',
      url,
      local: true,
      id: uuid(),
      fileName
    }
    const newContent = [modifiedFile].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const addCustomerToField = ({ customer, currentContent, dispatch, formName, fieldName }) => {
    const modifieldCustomer = {
      type: 'customer',
      id: customer.id,
      name: `${customer.firstName} ${customer.lastName}`,
      firstName: customer.firstName,
      lastName: customer.lastName
    }
    const newContent = [modifieldCustomer].concat(currentContent)
    dispatch(change(formName, fieldName, newContent))
    modalService.close()
  }

  const openProductsScreenModal = ({ dispatch, formName, fieldName, currentContent, productsModalComponent }) => {
    const currentContentIds = currentContent.map(c => c.id)    
        
    modalService.open({
      component: productsModalComponent,
      fullScreen: true,
      exit: () => {
        modalService.close()
        dispatch(customerDetailsActions.editProductCarousel(''))
      },
      onProductClick: product => {
        let variant = _.get(product, 'variant')
        const variants = _.get(product, 'variants')

        // When a product is selected a random variant is selected for the messange in the messaging app, so we want to pass null so that inthe email template the product.link gets selected
        if (variants) {
          _.forEach(variants, (variant => {
            variant.link = null
          }))
        }

        const variantsArray = variant ? [variant] : variants

        switch (formName) {
          case 'waitlist':
            return addProductToField({
              product: { ...product, variants: variantsArray, price: null },
              currentContent,
              dispatch,
              formName,
              fieldName
            })
          default:
            return addProductToField({
              product: { ...product, variants: variantsArray },
              currentContent,
              dispatch,
              formName,
              fieldName
            })
        }
      },
      onButtonClick: product => {
        // TODO: I removed the logic from here since it's being fired twice (onProductClick gets triggered too).
        // However, this fn can't be deleted , otherwise the "select product" modal is not closing (no time to figure out why!)
      },
      buttonValue: translations('Select Product'),
      productMapper: product => {
        const disabled = !!currentContentIds.find(currentContentItemId => currentContentItemId === product.id)
        return {
          ...product,
          disabled
        }
      },
      noRouting: true
    })
  }

  const deleteProduct = ({ dispatch, formName, fieldName, currentContent, id }) => {
    const newContent = currentContent.filter(product => id !== product.id)
    dispatch(change(formName, fieldName, newContent))
  }

  const openDeleteModal = ({ dispatch, formName, fieldName, currentContent, id, itemTypeName = 'Product' }) => {
    modalService.action({
      title: translations(`Delete ${itemTypeName}`),
      text: translations(`Delete ${itemTypeName} Text`),
      actions: [
        {
          success: true,
          text: translations('Delete'),
          onClick: () => deleteProduct({ dispatch, formName, fieldName, currentContent, id }),
          primary: true
        },
        {
          text: translations('Cancel'),
          primary: true
        }
      ]
    })
  }

  const openInspirationsScreenModal = ({ dispatch, formName, fieldName, currentContent, inspirationsModalComponent }) => {
    modalService.open({
      component: inspirationsModalComponent,
      fullScreen: true,
      goBack: () => modalService.close(),
      onInspirationClick: inspiration => addInspirationToField({ inspiration, currentContent, dispatch, formName, fieldName }),
      buttonValue: translations('Select Inspiration'),
      inModal: true
    })
  }

  const openImageUploadModal = ({ dispatch, formName, fieldName, currentContent, fileName }) => {
    modalService.open({
      component: ImageUploadModal,
      onPhotoAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        addImageToField({ url: blobUrl, currentContent, dispatch, formName, fieldName, fileName })
      }
    })
  }

  const openFileUploadModal = ({ dispatch, formName, fieldName, currentContent, fileName }) => {
    modalService.open({
      component: FileUploadModal,
      onFileAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        const fileName = _.get(url, 'fileName')
        addFileToField({ url: blobUrl, currentContent, dispatch, formName, fieldName, fileName })
      },
      onPhotoAdded: url => {
        const blobUrl = _.get(url, 'blobUrl')
        const fileName = _.get(url, 'fileName')
        addImageToField({ url: blobUrl, currentContent, dispatch, formName, fieldName, fileName })
      }
    })
  }

  const openCustomerModal = ({ dispatch, formName, fieldName, currentContent, customerModalComponent }) => {
    modalService.open({
      component: customerModalComponent,
      fullScreen: true,
      onCustomerClick: (customer) => {
        addCustomerToField({ customer, currentContent, dispatch, formName, fieldName })
      },
      onBackClick: () => {
        modalService.close()
      }
    })
  }

  return {
    openProductsScreenModal,
    openDeleteModal,
    openInspirationsScreenModal,
    openImageUploadModal,
    openFileUploadModal,
    openCustomerModal,
    addProductToField
  }
})()
