import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import _ from 'lodash'

import { getImage } from '../../../../../components/Images'
import { mobileCheck } from '../../../../../helpers'
import style from './style'

const phoneIcon = getImage('phoneIcon')
const smsIcon = getImage('smsIcon')

const PhoneIcons = props => {
  const { classes, showSmsButton, phoneMarketingOptIn, onClickCall, onClickSMS, currentCustomerDetails, isMessagingEnabled } = props
  const anonymised = _.get(currentCustomerDetails, 'anonymised')

  // note: phoneNumber(value) returns true if NOT a valid phone number
  if (isMessagingEnabled) {
    return (
      <div className={classes.iconContainer}>
        {
          mobileCheck && !anonymised && phoneMarketingOptIn
          ? <IconButton
            aria-label='Start call'
            className={classes.buttonContainer}
            onClick={onClickCall}
          >
            <img src={phoneIcon} className={classes.phoneIconPosition} />
          </IconButton>
          : null
        }
        {
          showSmsButton && !anonymised
          ? <IconButton
            aria-label='Send SMS'
            className={classes.buttonContainer}
            onClick={onClickSMS}
          >
            <img src={smsIcon} className={classes.smsIconPosition} />
          </IconButton>
          : null
        }
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(style)(PhoneIcons)
