import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import ProductsContainer from './ProductsContainer'
import OrderContentContainer from './OrderContentContainer'

import style from './style'

const Order = ({ width, orderNumber, orderDate, status, numberOfProducts,
  total, groupedProducts, classes, goToOrder, hideStatus, orderType, onClickEnabled }) => {
  const formattedOrderType = _.capitalize(orderType)
  return (
    <div className={classNames(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })} onClick={onClickEnabled ? goToOrder : null}>
      <ProductsContainer products={groupedProducts} />
      <OrderContentContainer
        orderDate={orderDate}
        orderNumber={orderNumber}
        numberOfProducts={numberOfProducts}
        total={total}
        status={status}
        hideStatus={hideStatus}
        orderType={formattedOrderType}
      />

    </div>
  )
}

Order.propTypes = {
  width: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  numberOfProducts: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  classes: PropTypes.shape({}),
  goToOrder: PropTypes.func.isRequired,
  hideStatus: PropTypes.bool
}

export default withStyles(style)(Order)
