import React from 'react'
import { i18n, Modules } from '@redant/retailos-ui'
import { httpClientService } from '../dependencies'
import { useDispatch } from 'react-redux'

import { uiConfig } from './uiConfig'
import en from './translations/en.json'
import ar from './translations/ar.json'
import { getCurrentLanguage as getAppLanguage } from '../../config'

i18n.addResourceBundle('chalhoub:en', 'Document', en)
i18n.addResourceBundle('chalhoub:ar', 'Document', ar)

export const DocumentScreen = ({ }) => {
  const dispatch = useDispatch()
  
  const appLanguage = getAppLanguage()
  i18n.changeLanguage('chalhoub:' + appLanguage)

  const implementation = Modules.Document.Implementation.provideUseDocumentScreen({
    httpClientService,
  })

  return (
    <Modules.Document.Screens.DocumentListScreen
      implementation={implementation}
      uiConfig={uiConfig}
    />
  )
}
