import { Modules, Utilities } from '@redant/retailos-ui'
import _ from 'lodash'
import { translations } from '../../config'
import uuidv4 from 'uuid/v4'
import { checkBasketContainsOrderedProducts, calculateOrderSummaryBreakdownTotals } from '../../helpers'
import moment from 'moment'

// Overriding the static checkout functions
Modules.Checkout.Implementation.CheckoutFunctions.setGroupItemsCM = (items) => {
  return _.chain(items)
        .map((item) => {
          const imageLabel = item.stock === 'N' ? { imageLabel: translations('Ordered Product - Image Label') } : {}
          return {
            ...item,
            ...imageLabel
          }
        })
        .sortBy(['stock', 'name', 'variant.name', 'variant', 'manualDiscount.value'])
        .value()
}

Modules.Checkout.Implementation.CheckoutFunctions.getGroupKeyCM = (item) => {
  const price = item.priceAfterItemAdjustments || item.nowPrice || item.price
  return `${item.itemId}/${price.value}/${item.stock}`
}

export class ChalhoubCheckoutFunctions extends Modules.Checkout.Implementation.StripeCheckoutFunctions {
  constructor(httpClientService) {
    super(httpClientService)
  }

  // Check if basket contains an endless aisle item.  Expects an array of item objects. 
   async endlessAisleOrderCheck (basketItems) {
      return checkBasketContainsOrderedProducts(basketItems)
   }


  async getSalesChannelCM(state) {
    switch (state.checkoutType) {
      case 'basketCheckout':
        return 'Store'
      case 'distantCheckout':
        return 'distant-sale'
      case 'inStoreConsultation':
        return 'InStoreConsultation'
      default:
        return 'VirtualConsultation'
    }
  }

  async getOrderTypeCM(state) {
    switch (state.checkoutType) {
      case 'basketCheckout':
        return 'standard'
      case 'distantCheckout':
        return 'standard'
      case 'inStoreConsultation':
        return 'consultation'
      default:
        return 'consultation'
    }
  }

  async getOrderStatusCM(state) {
    const endlessAisleCheck = await this.endlessAisleOrderCheck(_.get(state, 'items.data', []))
    if ((state.isSaveTransaction || endlessAisleCheck) && state.checkoutType === 'basketCheckout') {
      return 'awaiting_payment'
    }
    if (state.checkoutType === 'distantCheckout') {
      return 'awaiting_picking'
    }
    return 'pending'
  }

  async filterDeliveryOptionsCM(state, deliveryOptions) {
    const endlessAisleCheck = await this.endlessAisleOrderCheck(_.get(state, 'items.data', []))
    let filteredDeliveryOptions
    if(endlessAisleCheck) {
      filteredDeliveryOptions = deliveryOptions.filter(option => _.get(option, 'details.endlessAisle', false) === true)
    } else {
      filteredDeliveryOptions = deliveryOptions.filter(option => _.get(option, 'details.endlessAisle', false) === false)
    }
    return filteredDeliveryOptions
  }

  // Function to allow (endless Aisle and Distant) sales route to skip select payment step, creating an 'awaiting_payment' order.
  async confirmOrderSkipPaymentSelectionCM(state, storeId) {
    // Intial order upload
    const { id: orderId, orderNumber } = await this.uploadOrder(state, storeId)
    const updatedState = {
      orderId,
      orderNumber,
      payment: {
        completed: false,
        orderTotal: state.total,
        paymentIntent: null,
        paymentMethod: null
      },
      uiState: 'COMPLETE'
    }
    return updatedState
  }

  getOrderProductFieldsCM() {
    return [
      'brand',
      'name',
      'price',
      'externalProductId',
      'discount',
      'categoryId',
      'vatPercent',
      'link',
      'preview',
      'service',
      'clientId',
      'priceAdjustments',
      'stock'
    ]
  }

  getOrderVariantFieldsCM() {
    return [
      'name',
      'ean',
      'details',
      'id',
      'sku'
    ]
  }

  getProductIdCM(_product) {
    // When upload order each product is given a unique (id)
    return uuidv4()
  }

  mapOrderBreakdownCM(state) {
    const items = _.get(state, 'items.data') || []
    const { currentCurrency, currentRegionCurrencyConfig = [] } = _.get(state, 'basketCheckoutImplConfig') || {}
    const shipping = _.get(state, 'deliveryOption.data') || {}
    return calculateOrderSummaryBreakdownTotals({ items, currentCurrency, currentRegionCurrencyConfig, shipping, currencyFormatter: Utilities.CurrencyFormatter })
  }
}

// Store Sales Route Specific Functions (Store and Endless Aisle)
export class BasketCheckoutFunctions extends ChalhoubCheckoutFunctions {
  constructor(httpClientService) {
    super(httpClientService)
  }

  async initialiseCheckoutCM(items, initialState) {
    // TODO:  Confirm that this is required by chalhoub
    const totals = await this.calculateTotals(initialState, items)
    const deliveryMethods = await this.getDeliveryMethods(initialState)
    const itemsWithStockAttribute = items.map((item) => {
      return {
        ...item,
        stock: item.stock || 'Y'
      }
    })
    const updatedItemsWithStockCheck = await this.checkStockAndGetItems(initialState, itemsWithStockAttribute)
    return {
      items: {
        data: updatedItemsWithStockCheck
      },
      uiState: 'REVIEW',
      ...initialState,
      deliveryMethods,
      deliveryMethod: deliveryMethods[0],
      ...totals,
      basketSubTitle: this.checkItemsContainsErrorMessage(updatedItemsWithStockCheck) ? translations('subtitle-basket') : null,
      disableReviewScreenSubmitButton: this.checkItemsContainDisableSubmitButton(updatedItemsWithStockCheck)
    }
  }

  checkItemsContainsErrorMessage (items) {
    return items.some(({ errorMessage }) => errorMessage)
  }

  checkItemsContainDisableSubmitButton (items) {
    return items.some(({ disableReviewButton }) => disableReviewButton === true)
  }

  async checkStockAndGetItems(initialState, items) {
    try {
      const isEndlessAisleEnabled = _.get(initialState, 'basketCheckoutImplConfig.brandCheckoutConfig', false)
      if (isEndlessAisleEnabled) {
        const currentRegionExternalId = _.get(initialState, 'basketCheckoutImplConfig.currentRegionExternalId')
        const res = await this.httpClientService.post('/v2/stocks/check', { products: items, regionId: currentRegionExternalId })
        const returnedProducts = _.get(res, 'data.result.products', [])
        return this.updateItemsWithRelativeErrorMessages(returnedProducts)
      }
    } catch (e) {
      console.log(e)
    }
    return this.updateItemsWithRelativeErrorMessages(items)
  }

  isItemMatchedWithCurrentItemCM(item, currentItem = {}) {
    const itemPriceAdjustments = item.priceAfterItemAdjustments || {}
    const currentItemPriceAdjustments = currentItem.priceAfterItemAdjustments || {}
    return item.itemId === currentItem.itemId
      && itemPriceAdjustments.value === currentItemPriceAdjustments.value
      && item.stock === currentItem.stock
  }

  async removeItemCM (state, id) {
    const items = _.get(state, 'items.data', [])
    const currentItem = items.find(item => item.id === id)
    const newItems = items.filter(item => item.id !== id && !(
      this.isItemMatchedWithCurrentItemCM(item, currentItem)
    )) || []
    const totals = await this.calculateTotals(state, newItems)
    return {
      items: {
        data: newItems
      },
      ...totals,
      basketSubTitle: this.checkItemsContainsErrorMessage(newItems) ? translations('subtitle-basket') : null,
      disableReviewScreenSubmitButton: this.checkItemsContainDisableSubmitButton(newItems)
    }
  }

  async updateItemCM(state, id) {
    const items = _.get(state, 'items.data', [])
    const { itemId } = _.find(items, { id })
    const updatedItems = items.map((item) => {
      const { itemId: currentItemId } = item
      return {
        ...item,
        stock: itemId === currentItemId ? 'N' : item.stock
      }
    })
    const newItems = this.updateItemsWithRelativeErrorMessages(updatedItems)
    return {
      items: {
        data: newItems
      },
      disableReviewScreenSubmitButton: this.checkItemsContainDisableSubmitButton(newItems)
    }
  }

  updateItemsWithRelativeErrorMessages (items) {
    const itemsByProductId = _.groupBy(items, 'externalProductId')
    return items.map(item => {
      const { externalProductId, stock, storeStock, ecommStockSOH } = item
      const inStoreOrderQuantity = _.filter(itemsByProductId[externalProductId], { stock: 'Y' }).length
      const endlessOrderQuantity = _.filter(itemsByProductId[externalProductId], { stock: 'N' }).length
  
      let error = { errorMessage: undefined, itemActionButtonLabel: undefined, disableReviewButton: false }
      // In Store Order + Less store stock than quantity required
      if (stock === 'Y' && storeStock < inStoreOrderQuantity) {
        const canFulfillWithEcomm = inStoreOrderQuantity + endlessOrderQuantity <= ecommStockSOH
        error.errorMessage = canFulfillWithEcomm
          ? _.template(translations('Checkout - Change to Order with quantity'))({ totalStock: ecommStockSOH })
          : undefined
        error.itemActionButtonLabel = canFulfillWithEcomm
          ? translations('Checkout - Item Action Button Label')
          : undefined
      // Endless Aisle Order and Less ecomm stock than quantity required
      } else if (stock === 'N' && endlessOrderQuantity > ecommStockSOH) {
        const outOfEcommStock = ecommStockSOH <= 0
          error.errorMessage = outOfEcommStock
            ? translations('Checkout - Out of stock message')
            : _.template(translations('Checkout - Out of stock message with quantity'))({ totalStock: ecommStockSOH })
          error.disableReviewButton = true
      }
  
      return { ...item, ...error }
    })
  }
  

  updateItemQuantityWithAdditionalDetailsCM(items) {
    const finalItems = this.updateItemsWithRelativeErrorMessages(items)
    return {
      items: {
        data: finalItems
      },
      basketSubTitle: this.checkItemsContainsErrorMessage(finalItems) ? translations('subtitle-basket') : null,
      disableReviewScreenSubmitButton: this.checkItemsContainDisableSubmitButton(finalItems)
    }
  }

  // In store delivery options (endless aisle or store sale)
  async getStoreDeliveryMethods(state) {
  const endlessAisleCheck = this.endlessAisleOrderCheck(_.get(state, 'products', []))
    let storeDeliveryMethods
    if (endlessAisleCheck) {
      storeDeliveryMethods = [{ id: 'store-2', name: 'Endless Aisle', deliveryMethod: 'home', checkoutType: ['basketCheckout'] }]
    } else {
      storeDeliveryMethods = [{ id: 'store-1', name: 'Store Sale', deliveryMethod: 'store', checkoutType: ['basketCheckout'] }]
    }
    return storeDeliveryMethods
  }

  async showModalWithErrorMessage({ modalService, state, customerData, currentStoreId }) {
    return modalService.action({
      modalIndex: 1,
      text: translations('Checkout Modal Stock - Title'),
      noRouting: true,
      actions: [
        {
          text: translations('Checkout Modal Stock - Edit Basket'),
          onClick: () => {
            modalService.close({ modalIndex: 1 })
            return {}
          },
          success: true
        },
        {
          success: true,
          text: translations(`Checkout Modal Stock - Continue`),
          onClick: () => {
            modalService.close({ modalIndex: 1 })
            return this.confirmReview(state, customerData, currentStoreId)
          },
          primary: true
        }
      ]
    })
  }

  async reviewAndSaveTransactionCM(state, _storeId, modalService, customerData, currentStoreId, OrderReview) {
    const endlessAisleCheck = await this.endlessAisleOrderCheck(_.get(state, 'items.data', []))
    if (endlessAisleCheck) {
      const items = _.get(state, 'items.data', [])
      if(this.checkItemsContainsErrorMessage(items)) {
        this.showModalWithErrorMessage({ modalService, state, customerData, currentStoreId })
      } else {
        return this.confirmReview(state, customerData, currentStoreId)
      }
    } else {
      return modalService.open({
        // RetailOS screen config
        component: OrderReview,
        onConfirm: () => {
          modalService.close({ modalIndex: 1 })
          const orderDate = moment().toISOString()
          this.confirmSaveTransaction({ ...state, orderDate, customer: customerData }, currentStoreId)
        },
        onCancel: () => modalService.close({ modalIndex: 1 }),
        total: Utilities.CurrencyFormatter.format(_.get(state, 'total')),
        deliveryAddress: null,
        itemQty: _.get(state, 'items.data', []).reduce((acc, { quantity = 1 }) => acc + quantity, 0),
        customerName: `${_.get(state, 'customer.firstName')} ${_.get(state, 'customer.lastName')}`,
        // Modal config
        modalIndex: 1,
        // Routing config
        noRouting: true
      })
    }
  }

  /**
   *Store Sale/basketCheckout specific functions
   */

  async stateUpdatesForSaveTransactionCM(state) {
    return {}
  }

  async getAdditionalOrderUploadDataCM(state) {
    const endlessAisleCheck = await this.endlessAisleOrderCheck(_.get(state, 'items.data', []))
    const deliveryDetails = {
      title: state.customer.title,
      lastName: state.customer.lastName,
      firstName: state.customer.firstName,
      telephone: state.customer.telephone
    }
    const details = {
      billing: {
        // No longer reqiured for Chalhoub
      },
      discountTax: {
        // TODO:  Covered in Another Ticket
        code: 'KWD',
        value: '0.00'
      },
      shippingTax: {
        // TODO:  Covered in Another Ticket
        code: 'KWD',
        value: '0.00'
      },
      notes: state.orderNotes
    }
    const deliveryType = null

    const uploadData = {
      deliveryDetails: endlessAisleCheck ? deliveryDetails : null,
      details: details,
      deliveryType
    }

    return uploadData
  }
}

export class DistantCheckoutFunctions extends ChalhoubCheckoutFunctions {
  constructor(httpClientService) {
    super(httpClientService)
  }

  async initialiseCheckoutCM(items, initialState) {
    const totals = await this.calculateTotals(initialState, items)
    const deliveryMethods = await this.getDeliveryMethods(initialState)
    const updatedItems = items.map((item) => {
      return {
        ...item,
        errorMessage: '',
        itemActionButtonLabel: undefined,
        stock: item.stock || 'Y' // Add non endless aisle stock indicator
      }
    })

    return {
      items: {
        data: updatedItems
      },
      uiState: 'REVIEW',
      ...initialState,
      deliveryMethods,
      deliveryMethod: deliveryMethods[0],
      ...totals
    }
  }

  async getHomeDeliveryMethods() {
    const homeDeliveryMethods = [{ id: 'distant-1', name: 'Distant Sale', deliveryMethod: 'home', checkoutType: ['distantCheckout'] }]
    return homeDeliveryMethods
  }

  async continueToDelivery(state, customerData, currentStoreId) {
    const deliveryOptions = await this.fetchDeliveryOptions(state)
    const totals = deliveryOptions.length > 0 ? await this.calculateTotals(state, state.items.data || [], deliveryOptions[0]) : {}
    const storesList = await this.fetchStoresList(state, customerData, currentStoreId, deliveryOptions)
    return {
      uiState: 'DELIVERY',
      deliveryOptions,
      storesList,
      ...totals
    }
  }

  /**
   * Distant Sales Specific Custom Functions
   */

  // Add distant sale specific details to order
  async getAdditionalOrderUploadDataCM(state) {
    const uploadData = {
      deliveryDetails: {
        title: state.customer.title,
        lastName: state.customer.lastName,
        firstName: state.customer.firstName,
        telephone: state.customer.telephone
      },
      details: {
        billing: {
          // No longer reqiured for Chalhoub
        },
        discountTax: {
          // TODO:  Covered in Another Ticket
          code: 'KWD',
          value: '0.00'
        },
        shippingTax: {
          // TODO:  Covered in Another Ticket
          code: 'KWD',
          value: '0.00'
        },
        notes: state.orderNotes
      },
      deliveryType: null
    }
    return uploadData
  }
}
