import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, LabelList, Cell } from 'recharts'

import currencyFormatter from '../../../formatters/currencyFormatter'
import ChartWrapper from '../ChartWrapper'
import { theme } from '../../../config/theme'
import { translations } from '../../../config'

import style from './style'

const format = currencyFormatter.format

const barGap = 6

const BarGraph = props => {
  const {
    data,
    classes,
    chartTitle,
    currency,
    hideTotal,
    truncateLength = 8
  } = props

  const formattedData = data.map(entry => {
    return {
      ...(_.pick(entry, ['name', 'value'])),
      name: truncateLength > 0 ? translations(_.truncate(entry.name, { length: truncateLength })) : translations(entry.name)
    }
  })
  const dataSum = data.reduce(
    (acc, entry) => acc + entry.value,
    0
  )

  const formatter = value => (
    currency
    ? format({
      value,
      code: currency,
      options: {
        precision: 2
      }
    })
    : value
  )

  const highestValue = _.max(data.map(entry => entry.value))
  const highestValueStringLength = formatter(highestValue).toString().length
  const spacingMultiplier = 1 / (1 - (highestValueStringLength / 30))

  const domainLimit = Math.max(highestValue * spacingMultiplier, dataSum)

  return (
    <ChartWrapper title={chartTitle} className={classes.chartWrapper}>
      <ResponsiveContainer width={'100%'}>
        <BarChart
          data={formattedData}
          layout='vertical'
          barCategoryGap={barGap * 0.5}
          maxBarSize={30}
          margin={{ top: 30, right: 20, bottom: 5, left: 15 }}
        >
          <XAxis
            dataKey='value'
            type='number'
            domain={[0, domainLimit]}
            ticks={[]}
            tick={{fontSize: 12, display: 'none'}}
            tickCount={2}
            tickSize={0}
            tickMargin={8}
            tickFormatter={formatter}
          />
          <YAxis
            dataKey='name'
            type='category'
            tick={{fontSize: 12}}
            tickSize={0}
            tickMargin={15}
            padding={{ top: barGap * 1.5, bottom: barGap * 0.5 }}
          />
          <Bar
            dataKey='value'
            isAnimationActive={false}
          >
            {
              data.map((entry, index) => {
                return <Cell fill={data[index].color} key={`cell-${index}`} />
              })
            }
            <LabelList

              position='right'
              style={{fontSize: 12, fill: theme.palette.chartLegendsGrey.main}}
              formatter={formatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {!hideTotal && (
        <div className={classes.total}>
          {translations('total')} {formatter(dataSum)}
        </div>
      )}
    </ChartWrapper>
  )
}

export default compose(withStyles(style))(BarGraph)
