import logoStyles from '../../../components/MainLogo/style'

const styles = (theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.main,
    position: 'relative'
  },
  logo: {
    margin: 'auto',
    marginBottom: 40,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 55
    }
  },
  logoNoMargin: {
    margin: 'auto',
  },
  innerContainer: {
    position: 'relative',
    padding: '40px',
    width: '100%'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  recentUsers: {
    marginTop: 20,

    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginRight: 50
    }
  },
  formContainer: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  hyperlinkButton: {
    textTransform: 'initial',
    textDecoration: 'underline',
    fontSize: 13
  }
})

export default theme => ({
  ...styles(theme),
  ...logoStyles
})
