import uuid from 'uuid/v4'
import _ from 'lodash'
import moment from 'moment'
import { amendOrder } from '@redant/digital-store-prices-chalhoub'
import { constants as currentOrderConstants } from '../../store/modules/currentOrder'
import digitalStoreSdk from '../../digitalStoreSdk'
import { dateFormat, apiUpdateSource } from '../../config'
import { calculateOrderTotals } from './calculateOrderTotals'
import { getCurrencyConfigForSelectedRegion } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import store from '../../store'

const uploadOrder = params => {
  const currentState = store.getState()
  const currentCurrency = currentState.app.currencyCode
  const remoteConfig = getCurrencyConfigForSelectedRegion(currentState)
  const orderTotals = calculateOrderTotals({
    products: params.products,
    total: params.total,
    shipping: params.deliveryOption,
    currentCurrency,
    remoteConfig
  })

  const formattedOrderTaxValues = {
    discountTax: {
      value: orderTotals.discountTax,
      code: currentCurrency
    },

    shippingTax: {
      value: orderTotals.shippingTax,
      code: currentCurrency
    }
  }

  params = amendOrder(params)
  
  if(params.products.length === 0 && params.total.value === 0 ) {
    params = {
      ...params, 
      total: {
        value: params.total.value, 
        code: currentCurrency
      }
    }
  }

  const products = _.chain(params)
    .get('products', [])
    .map(product => {
      const vatPercent = parseInt(remoteConfig.filter(item => item.id === currentCurrency)[0].taxPercentage) || 0

      product = {
        ...product,
        vatPercent,
        tax: { code: currentCurrency, value: vatPercent ? (((parseFloat(product.price.value) - parseFloat(product.discount.value)) / (100 + parseFloat(vatPercent))) * 5).toFixed(2) : 0 }
      }

      const orderProduct = {
        ..._.pick(product, [
          'brand',
          'categoryId',
          'discount',
          'externalProductId',
          'images',
          'link',
          'manualDiscount',
          'name',
          'preview',
          'price',
          'subTotal',
          'total',
          'tax',
          'vatPercent',
          'unsold',
          'service',
          'clientId',
          'refund',
          'hasBeenRefunded'
        ]),
        id: uuid(),
        productId: product.id,
        variant: _.pick(product.variant, [
          'name',
          'ean',
          'link',
          'details',
          'id',
          'externalVariantId',
          'sku'
        ])
      }

      return orderProduct
    })
    .value()

  const status = params.status || 'pending'

  const salesChannel = params.salesChannel || 'Store'

  let orderDateISOString = moment(params.orderDate, dateFormat).toISOString()
  // if orderDate is today, set it to the exact time of right NOW
  if (moment(orderDateISOString).isSame(moment(), 'day')) {
    orderDateISOString = moment().toISOString()
  } else if (!params.orderDate) {
    orderDateISOString = moment().toISOString() // default to now
  }

  // Order types with no delivery options
  if (!_.get(params, 'customerId') || params.deliveryType === 'inStoreSale' || params.deliveryType === 'homeVisit') {
    params = { ...params, deliveryType: null, deliveryOption: null, deliveryDetails: null, deliveryAddress: null, deliveryPrice: null }
  }

  const details = { ...params.details, ...formattedOrderTaxValues }
  if (params.storeroomOrderId) details.storeroomOrderId = params.storeroomOrderId

  const uploadParams = {
    ..._.pick({ ...params, tax: { code: currentCurrency, value: orderTotals.currencyTax } }, [
      'customerId',
      'deliveryAddress',
      'deliveryDetails',
      'deliveryOption',
      'paymentDeviceId',
      'id',
      'orderDate',
      'orderType',
      'paymentToken',
      'storeId',
      'subTotal',
      'tax',
      'total',
      'totalDiscount',
      'userId'
    ]),
    products,
    status,
    salesChannel,
    orderDate: orderDateISOString,
    details
  }

  if (params.editMode) {
    let editOrder = digitalStoreSdk.orders.editOrder
    if (params.editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE) {
      editOrder = digitalStoreSdk.orders.exchangeOrder
    }
    if (params.editType === currentOrderConstants.EDIT_ORDER_TYPES.UPDATE) {
      editOrder = digitalStoreSdk.orders.updateOrder
    }
    return editOrder({ ...uploadParams, orderNumber: params.orderNumber, updateSource: apiUpdateSource })
      .then((res) => digitalStoreSdk.orders.fetchOrder({ id: res.orderNumber, includes: ['customer', 'user'] }))
  }
  return digitalStoreSdk.orders.createOrder({ ...uploadParams, updateSource: apiUpdateSource })
}

export default uploadOrder
