import * as authCombinedSelectors from './authCombinedSelectors'
import * as userDetailCombinedSelector from './userDetailCombinedSelector'
import * as variantsCombinedSelectors from './variantsCombinedSelectors'
import * as inspirationDetailsCombinedSelectors from './inspirationDetailsCombinedSelectors'
import * as regionsCombinedSelectors from './regionsCombinedSelectors'
export {
  authCombinedSelectors,
  userDetailCombinedSelector,
  variantsCombinedSelectors,
  inspirationDetailsCombinedSelectors,
  regionsCombinedSelectors
}
