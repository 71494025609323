import React from 'react'
import _ from 'lodash'

import ReportChart from '../../../Reporting/ReportChart'
import { slugify } from '../../../../helpers'
import { customerReportingSchema as schema } from '../../../../config'

const CustomerReportingCharts = ({
  Tile,
  customers,
  defaultCurrency
}) => {
  return (
    schema.filter(sch => !sch.disable).map((reportSchema, i) => {
      const link = `/customers/reporting/${i}-${slugify(reportSchema.title)}`
      const to = reportSchema.fullList && !(reportSchema.type === 'LIST') && link
      const seeAllLink = reportSchema.fullList && (reportSchema.type === 'LIST') && link

      return (
        <Tile
          to={to || undefined}
          key={`${reportSchema.title}-${i}`}
          chartCategory={_.get(reportSchema, 'chartCategory')}
          chartOptions={_.get(reportSchema, 'chartOptions')}
        >
          <ReportChart
            items={customers}
            reportSchema={reportSchema}
            seeAllLink={seeAllLink}
            defaultCurrency={defaultCurrency}
          />
        </Tile>
      )
    })
  )
}

export default CustomerReportingCharts
