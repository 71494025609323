import _ from 'lodash'
import { createSelector } from 'reselect'

import { parseLatLng, calculateHaversineDistance } from '../../../helpers'
import { selectors as variantsSelectors } from '../variantsStock'
import { selectors as storesSelectors } from '../stores'
import { selectors as authSelectors } from '../auth'

const getLatLngFromStore = (store) => parseLatLng(_.get(store, 'details.latLng'))

export const matchStockWithStore = createSelector([
  variantsSelectors.getVariantStock,
  storesSelectors.getStoresHashmap,
  authSelectors.getUserSelectedStoreId
], (variantStoreStocks, storesHashmap, currentStoreId) => {
  const currentStore = storesHashmap[currentStoreId]

  const currentStoreLatLng = getLatLngFromStore(currentStore)

  const stockByStore = _.chain(variantStoreStocks)
    .reject(vss => vss.storeId === currentStoreId)
    .map(vss => {
      const { storeId, variantId, stock } = vss

      const store = storesHashmap[storeId]

      const _store = { ...store }

      return { ...vss, _store }
    })
    .sort((left, right) => {
      const lName = _.get(left, '_store.name')
      const rName = _.get(right, '_store.name')


    })
    .value()

  return stockByStore
})

export const getEcommerceStock = createSelector([
  matchStockWithStore
], (stockByStore) => {
  const ecommerceStock = _.first(_.filter(stockByStore, (stock) => _.get(stock, 'store.details.locationType') === 'ecommerceStore'))
  return [ ecommerceStock ]
})