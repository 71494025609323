import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING, FAIL } from '../../middleware/redux-promise'
import { SEARCH_MESSAGES_FOR_USER_FRESH } from './constants'

export const getSize = state => _.get(state.userMessages, 'size')
export const getPage = state => _.get(state.userMessages, 'page')
export const getQuery = state => _.get(state.userMessages, 'query')
export const getAction = state => _.get(state.userMessages, 'action')
export const getStatus = state => _.get(state.userMessages, 'status')
export const getResults = state => _.get(state.userMessages, 'results')
export const getResultsForStore = state => _.filter(_.get(state.userMessages, 'results'), (msg) => { return msg.store.id === _.get(state, 'auth.selectedStore.id') })

export const getIsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === SEARCH_MESSAGES_FOR_USER_FRESH)
))

export const getHasMore = state => state.userMessages.total != null && state.userMessages.results.length < state.userMessages.total
export const getHasBeenSearchedWithNoResults = state => state.userMessages.status !== FAIL &&
  state.userMessages.results && state.userMessages.results.length === 0
export const getHasBeenSearchedWithError = state => {
  return state.userMessages.status === FAIL
} 
