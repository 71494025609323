import React from 'react'

import HybridRouter from '../../../components/HybridRouter'

import CategoryScreen from '../CategoryScreen'
import RootScreen from '../RootScreen'
import ProductDetailScreen from '../../ProductDetail/ProductDetailScreen'

const ProductsRouter = props => {
  const { noRouting, exit, multiSelect, initialPath, currentOrder, pathOverride, ...rest } = props

  const productsRoutes = [
    { path: '/products/cat/:catId', component: CategoryScreen, exact: true },
    { path: '/products/cat/:catId/all', component: CategoryScreen, exact: true },
    { path: '/products/', component: RootScreen, exact: false },
    { path: '/product/:id', component: ProductDetailScreen, exact: true },
  ]

  const passedProps = {
    multiSelect,
    searchBarPosition: noRouting ? 'absolute' : 'fixed',
    ...rest
  }
  // this wrapper div stops unwanted flex behaviour inside modal
  // (material modal has flex & flex-direction: column)

  return <HybridRouter
    pathOverride={pathOverride}
    routes={productsRoutes}
    noRouting={noRouting}
    exit={() => exit(currentOrder)}
    passedProps={passedProps}
    initialPath={initialPath || '/products'}
  />
}

export default ProductsRouter
