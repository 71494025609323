import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import _ from 'lodash'
import CurrencyMenu from './CurrencyMenu'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import modalService from '../../services/modalService'
import { translations } from '../../config'
import { actions as currentOrderActions } from '../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { getCurrencyConfigForSelectedRegion } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'


const mapStateToProps = (state) => {
  const isOpen = appSelectors.getIsCurrencyMenuOpen(state)
  const currencyCode = appSelectors.getAppCurrency(state)
  const currencyCodes = getCurrencyConfigForSelectedRegion(state)

  const productsInBasket = currentOrderCombinedSelectors.getCurrentOrderProducts(state)

  return {
    isOpen,
    currencyCode,
    currencyCodes,
    productsInBasket
  }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(appActions.toggleCurrencyMenu({ isOpen: false }))
  },

  clearOrder: () => {
    dispatch(currentOrderActions.clearOrder({ showToast: true }))
  },

  changeAppCurrency: (currencyCode) => {
    dispatch(appActions.changeAppCurrency({ currencyCode }))
  }
})

const mapHandlers = withHandlers({
  changeCurrency: ({ productsInBasket, clearOrder, changeAppCurrency }) => (currencyCode) => {
    if (productsInBasket && productsInBasket.length) {
      modalService.action({
        title: translations('Currency Change Warning Title'),
        actions: [
          {
            success: true,
            text: translations('Currency Change Confirm'),
            onClick: () => {
              clearOrder()
              changeAppCurrency(currencyCode)
            },
            primary: true
          },
          {
            text: translations('Currency Change Cancel'),
            onClick: () => {
              modalService.close()
            }
          }
        ]
      })
    } else {
      changeAppCurrency(currencyCode)
    }
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapHandlers,
  withState('tabValue', 'changeTabValue', 'basket')
)(CurrencyMenu)
