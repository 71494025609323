import React, { Fragment } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import { translations, noPrices } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import FormSubmit from '../../../../components/Form/FormSubmit'

import style from './style'

const format = currencyFormatter.format

const ConfirmSection = props => {
  const {
    submitText,
    handleNext,
    numberOfProducts,
    numberOfPurchaseableProducts,
    total,
    next,
    classes,
    invalid,
    retailPrice,
    discount,
    pricePaid,
    shipping,
    currencyTax,
    discountTax,
    shippingTax,
    currentCurrency
  } = props

  const createPriceObject = (value) => ({value, code: currentCurrency}) 

  return <div className={classes.section}>
    <div className={classes.inner}>
      <div className={classes.totalsContainer}>
        <P value={translations('Items')} />
        <P value={numberOfPurchaseableProducts || 0} />
        {
          noPrices
            ? null
            : <Fragment>
              <P value={translations('Checkout Confirmation - Retail Price')} />
              <P value={format(createPriceObject(retailPrice))} />
              {
                discount
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Discount')} />
                    <P value={format(createPriceObject(discount))} />
                  </Fragment>
                  : null
              }
              {
                pricePaid
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Price Paid')} />
                    <P value={format(createPriceObject(pricePaid))} />
                  </Fragment>
                  : null
              }
              {
                currencyTax
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Tax')} />
                    <P value={format(createPriceObject(currencyTax))} />
                  </Fragment>
                  : null
              }
              {
                discountTax
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Discount Tax')} />
                    <P value={format(createPriceObject(discountTax))} />
                  </Fragment>
                  : null
              }
              {
                shipping
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Shipping')} />
                    <P value={format(createPriceObject(shipping))} />
                  </Fragment>
                  : null
              }
              {
                shippingTax
                  ? <Fragment>
                    <P value={translations('Checkout Confirmation - Shipping Tax')} />
                    <P value={format(createPriceObject(shippingTax))} />
                  </Fragment>
                  : null
              }
              <H2 value={_.toUpper(translations('Total'))} />
              <H2 value={format(total)} />
            </Fragment>
        }
      </div>
      <FormSubmit
        onClick={handleNext ? next : undefined}
        buttonType='primary'
        big
        label={submitText}
        disabled={invalid}
      />
    </div>
  </div>
}

export default withStyles(style)(ConfirmSection)
