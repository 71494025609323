import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import Form from '../../../../../components/Form'
import FormBody from '../../../../../components/Form/FormBody'
import ButtonsContainer from '../../../../../components/ButtonsContainer'
import { Dropdown } from '../../../../../components/Fields'
import H2 from '../../../../../components/H2'
import Button from '../../../../../components/Button'

import { useAppointmentsFilters } from '../../../../../hooks/appointmentsHooks'
import { useStoreCalendarOptions } from '../../../../../hooks/storeDetailsHooks'

import { translations } from '../../../../../config'
import { required } from '../../../../../validators'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const ContentWrapper = styled.div`
  margin: 18px 0;
`

const CustomFormBody = styled(FormBody)`
  display: flex;
  flex-direction: column;
`
const CustomFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 18px;
`

const SelectCalendarForm = Form('select-calendar')

const SelectCalendarScreen = ({ onSelect }) => {
  const storeCalendarOptions = useStoreCalendarOptions()
  const appointmentFilters = useAppointmentsFilters()

  let initialValues = {}
  if (appointmentFilters.calendarId) {
    initialValues.calendarId = appointmentFilters.calendarId
  }

  const handleSubmit = (formData) => {
    if (onSelect) {
      onSelect(formData.calendarId)
    }
  }

  return (
    <Container>
      <H2 value={_.toUpper(translations('Select Calendar'))} />
      <ContentWrapper>
        <SelectCalendarForm initialValues={initialValues} onSubmit={handleSubmit}>
          <CustomFormBody>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <CustomFormControl required>
                  <Dropdown
                    label={translations('Calendar')}
                    name='calendarId'
                    validate={required}
                    options={storeCalendarOptions}
                  />
                </CustomFormControl>
              </Grid>
            </Grid>            
          </CustomFormBody>
          <ButtonsContainer align='right'>
            <Button
              type={'submit'}
              color='primary'
              buttonType='primary'>
              {`${translations('Choose')}`}
            </Button>
          </ButtonsContainer>
        </SelectCalendarForm>
      </ContentWrapper>
    </Container>
  )
}

SelectCalendarScreen.propTypes = {
  onSelect: PropTypes.func,
  calendarId: PropTypes.string
}

export default SelectCalendarScreen
