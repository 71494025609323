import _ from 'lodash'
import { push } from 'connected-react-router'
import { history } from '../store'

import appsConfig from './configFiles/apps'
import appsConfigV2 from './configFiles/apps-v2'
import { v1Schema, v2Schema } from './schemas/apps-schema'
import generalConfig from './configFiles/general'
import kanban from './configFiles/kanban/config.default.json'

const valid = v1Schema.validate(appsConfig)
const validV2 = v2Schema.validate(appsConfigV2)

if (valid.error) {
  throw new Error(valid.error)
}
if (validV2.error) {
  throw new Error(validV2.error)
}

// Default App Routes - can be override by specifying 'route' in the app config
const appActions = {
  CATALOG: '/products',
  CUSTOMER_MANAGEMENT: '/customers',
  RETAIL_ANALYTICS: '/retail-analytics',
  USER_MANAGEMENT: '/users',
  MESSAGING: '/messages',
  NOTIFICATIONS: '/notifications',
  RESOURCES: '/resources',
  ORDER_MANAGEMENT: '/orders',
  POLLS: '/polls',
  APP_FEEDBACK: '/feedback',
  STOREROOM_KANBAN: '/pick-from-store',
  INSPIRATIONS: '/inspirations',
  WAITLIST: '/waitlist',
  EVENTS: '/events',
  CONSULTATIONS: '/consultations',
  STORES: '/stores',
  VIRTUAL_CONSULTATIONS: '/virtual-consultations',
  DISTANT_SALES: '/distant-sales'
}

const getAppsConfig = (brandName = 'default') => {
  return _.find(appsConfigV2, { brandName })
}

const apps = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.enabled)
  .reduce((memo, next) => {
    const [id, config] = next
    const route = config.route
    const app = {
      id,
      route: route || appActions[id],
      action: route ? push(route) : push(appActions[id]) || _.noop,
      ..._.pick(config, ['title', 'image', 'icon', 'badgeType', 'config', 'allowOffline'])
    }
    return {
      ...memo,
      [id]: app
    }
  }, {})
  .value()

const dashboardEnabled = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.dashboard)
  .map(([id, config]) => id)
  .value()

const menuEnabled = _.chain(appsConfig.apps)
  .toPairs()
  .filter(([id, config]) => config.menu)
  .map(([id, config]) => id)
  .value()

const dashboard = _.chain(dashboardEnabled || [])
  .map(key => apps[key])
  .compact()
  .value()

const menu = [
  { id: 'DASHBOARD', action: push('/'), title: 'Dashboard', allowOffline: true },
  ...(
    _.chain(menuEnabled || [])
      .map(key => apps[key])
      .compact()
      .value()
  )
]

const { height, width } = generalConfig.tileDimensions

const tileRatio = (height / width) * 100

const getAppConfig = (app, config, defaultVal, brandName = 'default') => {

  const brandConfig = getAppsConfig(brandName) ? getAppsConfig(brandName) : getAppsConfig('default')

  const apps = _.chain(brandConfig.apps)
    .toPairs()
    .filter(([id, config]) => config.enabled)
    .reduce((memo, next) => {
      const [id, config] = next
      const route = config.route
      const app = {
        id,
        route: route || appActions[id],
        action: route ? push(route) : push(appActions[id]) || _.noop,
        ..._.pick(config, ['title', 'image', 'icon', 'badgeType', 'config', 'allowOffline', 'filters'])
      }
      return {
        ...memo,
        [id]: app
      }
    }, {})
    .value()

    if (!config) { return _.get(apps, `${app}.config`) }
  return _.get(apps, `${app}.config.${config}`, defaultVal)
}

const getAppConfigForCurrentRoute = (config, defaultVal) => {
  const path = history.location.pathname
  return getAppConfigForRoute(path, config, defaultVal)
}

const getAppConfigForRoute = (path, config, defaultVal, brandName = 'default') => {
  const brandConfig = getAppsConfig(brandName) ? getAppsConfig(brandName) : getAppsConfig('default')
  const app = Object.keys(brandConfig.apps).find(key => !!_.get(apps[key], 'route', '').startsWith(path))
  if (!app) return defaultVal
  return getAppConfig(app, config, defaultVal, brandName)
}

export {
  apps,
  dashboard,
  menu,
  kanban,
  tileRatio,
  getAppConfig,
  getAppConfigForCurrentRoute,
  getAppConfigForRoute
}
