import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import vf from '../../../formatters/valueFormatter'
import P from '../../../components/P'
import ChartWrapper from '../ChartWrapper'
import style from './style'
import { translations } from '../../../config'

const CountChart = ({
  chartTitle,
  data,
  classes,
  currency
}) => {
  if (data && data.length) {
    const formatter = (value, name) => vf.format({
      value,
      code: typeof currency === 'object' ? currency[name] : currency
    })

    const mappedData = data.map(entry => {
      return {
        ...entry,
        formattedValue: formatter(entry.value, entry.name)
      }
    })

    // handles changing size of text to fit
    const rows = Math.ceil(mappedData.length / 3)
    const longestStringLength = _.max(mappedData.map(entry => entry.formattedValue.toString().length))
    const stringLengthToCaterFor = Math.max(longestStringLength, [1,2,4][rows - 1] + 2)
    const valueFontMultiplier = (3.2 / stringLengthToCaterFor)

    return (
      <ChartWrapper title={chartTitle}>
        <div className={classes.gridContainer}>
          {
            (mappedData || []).map(entry => (
              <div
                className={classes.cellContainer}
                key={entry.name}
                style={{fontSize: `${12 * valueFontMultiplier}px`}}
              >
                <div className={classes.titleOuter}>
                  <P className={classes.cellTitle} value={translations(entry.name)} />
                </div>
                <div className={classes.valueOuter}>
                  <div className={classes.cellValue}>
                    {entry.formattedValue}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </ChartWrapper>
    )
  } else {
    return null
  }
}

export default withStyles(style)(CountChart)
