
import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as productWaitlistActions, constants as productWaitlistConstants } from '../productWaitlist'
import { selectors as authSelectors } from '../auth'
import { FETCH_FULL_CUSTOMER } from '../customerDetails/constants'
import { SUCCESS } from '../../middleware/redux-promise'
import { history } from '../..'
import { translations } from '../../../config'
import toastService from '../../../services/toastService/toastService'


const getFullName = customerResult => `${customerResult.firstName} ${customerResult.lastName}`

class ProductWaitlistMiddleware {
  fetchProductWaitlist = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/product/:id/waitlist', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      if (result && isLoggedIn) {
        const productId = result.params.id
        dispatch(productWaitlistActions.fetchProductWaitlist({
          productId,
          storeId,
          expand: 'customer,store,product,variant'
        }))
      }
    }
  }

  fetchProductWaitlistForWaitlistScreen = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/waitlist', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      if (result && isLoggedIn) {
        dispatch(productWaitlistActions.fetchProductWaitlist({
          storeId,
          expand: 'product',
          group: 'product'
        }))
      }
    }
  }

  fetchProductWaitlistForCustomerScreen = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === FETCH_FULL_CUSTOMER && action.status === SUCCESS) {
      const currentPathname = history.location.pathname
      const match = matchPath(currentPathname, { path: '/customers/:id/full' })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())

      if (match && isLoggedIn) {
        const customerId = _.get(action, 'result.customer.id')
        dispatch(productWaitlistActions.fetchProductWaitlist({
          customerId,
          storeId,
          expand: ['product', 'variant']
        }))
      }
    }
  }

  clearWaitlistOnFreshFetch = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.type === productWaitlistConstants.FETCH_WAITLIST &&
      action.status === 'PENDING'
    ) {
      dispatch(productWaitlistActions.clearWaitlist())
    }

    next(action)
  }

  updateListOnDelete = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.type === productWaitlistConstants.DELETE_FROM_WAITLIST &&
      action.status === 'SUCCESS' &&
      action.result && action.result.deleted
    ) {
      dispatch(productWaitlistActions.deleteEntryFromWaitlistLocal(action.result.deleted))
    }

    next(action)
  }

  updateCustomerWailistSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === productWaitlistConstants.ADD_TO_CUSTOMER_WAITLIST && action.status === SUCCESS) {
      const fullName = getFullName(action.result)
      toastService.action({
        type: 'success',
        message: translations('Customer Updated'),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
  }
}

export default new ProductWaitlistMiddleware()
