import _ from 'lodash'

import { translations } from '../config'

export const getFulfillmentLocation = (products) => {
  const hasEndlessAisleProducts = products.some(product => product.stock === 'N')
  const hasStoreProducts = products.some(product => product.stock === 'Y')

  if (hasEndlessAisleProducts && hasStoreProducts) {
    return translations('text-partiallyFulfilledLocation')
  } else if (hasEndlessAisleProducts) {
    return translations('text-fulfilledLocation')
  } else {
    return translations('text-fulfilledInStoreLocation')
  }
}
