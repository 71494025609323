import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import menuItemStyle from '../../components/SideMenu/style'

export const CurrencyMenuItem = withStyles(menuItemStyle)(
  ({ id, classes, onClick }) => {
    return (
      <ButtonBase onClick={onClick} classes={{ root: classes.menuItem }}>
          {id}
      </ButtonBase>
    )
  }
)
  