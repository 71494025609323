import React from 'react'
import { Route } from 'react-router'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import AppointmentsDashboardScreen from './Consultation/AppointmentsDashboardScreen'
import StoreConsultation from './Consultation/StoreConsultation'
import NewStoreConsultation from './Consultation/NewStoreConsultation'
import VirtualConsultation from './Consultation/VirtualConsultation'
import PublicConsultation from './Consultation/PublicConsultation'

export default [
  <PrivateRoute key='/consultations' path='/consultations' exact component={TimedLogoutComponent(AppointmentsDashboardScreen)} allowOffline />,
  // store consultations
  <PrivateRoute key='/consultations/store/new' path='/consultations/store/new' exact component={TimedLogoutComponent(NewStoreConsultation)} allowOffline />,
  <PrivateRoute key='/consultations/store/:id' path='/consultations/store/:id' exact component={TimedLogoutComponent(StoreConsultation)} allowOffline />,
  // virtual consultations
  <PrivateRoute key='/consultations/virtual/:id' path='/consultations/virtual/:id' exact component={TimedLogoutComponent(VirtualConsultation)} />,
  // public customer vc screen
  <Route key='/consultations/virtual/:id/customer' path='/consultations/virtual/:id/customer' exact component={TimedLogoutComponent(PublicConsultation)} />
]
