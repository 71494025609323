import React, { useEffect, useCallback, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { selectors as routingSelectors } from '../../store/modules/routing'
import modalService from '../../services/modalService'
import { showSelfAudio, removeSelfAudio } from './Consultation/Sections/VideoPanel'
import { translations } from '../../config'

import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import PhoneIcon from '@material-ui/icons/Phone'

const HiddenAudioDiv = styled.div`
  display: none;
`

const VCAudioWrapper = styled.div`

`

const AudioControls = styled.div`
  position: fixed;
  top: 7px;
  right: 75px;
  background-color: #F9F6F6;
  z-index: 101;
  height: 35px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  border: 1px solid #340c0c;
`

const ControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background-color: ${({ active }) => !active
    ? '#1a1a1a'
    : '#f44336'
};
  color: white;
  margin: 4px;
  cursor: pointer;
`

const VCAudioContainer = () => {
  const pathname = useSelector(routingSelectors.getLocationPathname)
  const [muteAudio, setMuteAudio] = useState(_.isBoolean(window.twilioMute) ? window.twilioMute : false)
  const [twilioRoom, setTwilioRoom] = useState(window.twilioRoom)
  const isVirtualConsultationScreen = _.includes(pathname, 'consultations/virtual')

  useEffect(() => {
    setMuteAudio(window.twilioMute)
  }, [window.twilioMute])

  useEffect(() => {
    setTwilioRoom(window.twilioRoom)
  }, [window.twilioRoom])

  const onEndCall = useCallback(() => {
    if (window.twilioRoom) {
      modalService.action({
        title: translations('End call'),
        text: translations('Are you sure you want to disconnect?'),
        actions: [
          {
            success: true,
            text: translations('Yes'),
            onClick: () => {
              window.twilioRoom.disconnect()
              window.twilioRoom = null
              setTwilioRoom(null)
            },
            primary: true
          },
          {
            text: translations('Cancel'),
            primary: false
          }
        ]
      })
    }
  }, [window.twilioRoom])

  const onMute = useCallback(() => {
    if (window.twilioRoom) {
      if (window.twilioMute) {
        showSelfAudio(window.twilioRoom.localParticipant)
      } else {
        removeSelfAudio()
        window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
        })
      }
      setMuteAudio(!window.twilioMute)
      window.twilioMute = !window.twilioMute
    }
  }, [window.twilioRoom, window.twilioMute])

  const renderAudioControls = () => (
    <AudioControls>
      <ControlButton active={muteAudio} onClick={onMute}>
        {window.twilioMute ? <MicOffIcon fontSize={'inherit'} /> : <MicIcon fontSize={'inherit'} />}
      </ControlButton>
      <ControlButton active onClick={onEndCall}>
        <PhoneIcon fontSize={'inherit'} />
      </ControlButton>
    </AudioControls>
  )

  return (
    <VCAudioWrapper>
      <HiddenAudioDiv id="self-audio-panel"></HiddenAudioDiv>
      <HiddenAudioDiv id="participant-audio-panel"></HiddenAudioDiv>
      {twilioRoom && !isVirtualConsultationScreen && renderAudioControls()}
    </VCAudioWrapper>
  )
}

export default VCAudioContainer
