import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import FormBody from '../Form/FormBody'
import FormSubmit from '../Form/FormSubmit'
import Button from '../Button'
import ButtonsContainer from '../ButtonsContainer'
import BottomBar from '../BottomBar'
import ContentBox from '../ContentBox'
import ContentBoxHeader from '../ContentBox/ContentBoxHeader'
import ContentBoxBody from '../ContentBox/ContentBoxBody'
import TextContent from '../Form/TextContent'
import H2 from '../H2'
import Heading from '../Heading'
import { translations } from '../../config'
import style from './style'
import InspirationsSearchScreen from '../../containers/Inspirations/InspirationsSearchScreen'
import CustomerSelect from './components/CustomerSelect'
import ProductPicker from './components/ProductPicker'

const CreateNewMessageForm = props => {
  const {
    classes,
    onSubmit,
    onClickPreview,
    onClickExportPdf,
    initialValues,
    submitDisabled,
    hideSMS,
    hideEmail,
    formId,
    renderRecipients,
    renderOptions,
    renderText,
    noPreview,
    contentOptions,
    maxContent,
    sendButtonText,
    Form,
    formCommunicationType
  } = props

  return (
    <div id='create-new-message-container'>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <FormBody>
          <ContentBox givenContentClass={classes.communicationOptionsBodyContainer}>
            <ContentBoxHeader smLeft={0} smText={12} smRight={0} >
              <TextContent>
                {renderRecipients()}
              </TextContent>
            </ContentBoxHeader>
            <ContentBoxBody>
              {
                !hideSMS || !hideEmail
                  ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={5} className={classes.leftGridItem}>
                        <div className={classes.leftContentContainer}>
                          {renderOptions()}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={7}>
                        {renderText()}
                      </Grid>
                    </Grid>
                  )
                  : <div className={classes.hiddenContainer}>{translations('The customer has either opted out of both communication channels or not provided a telephone number')}</div>
              }
            </ContentBoxBody>
          </ContentBox>
          {
            (!hideSMS || !hideEmail) && formCommunicationType !== 'other'
              ? (
                <ContentBox givenContentClass={classes.communicationOptionsBodyContainer}>
                  <ContentBoxHeader smLeft={0} smText={12} smRight={0} >
                    <TextContent>
                      <Heading component={'h2'}>{translations('Message Attachments Title')}</Heading>
                    </TextContent>
                  </ContentBoxHeader>
                  <ContentBoxBody>
                    <ProductPicker
                      customerModalComponent={CustomerSelect}
                      inspirationsModalComponent={InspirationsSearchScreen}
                      formName={formId}
                      editing
                      options={contentOptions}
                      maxContent={maxContent}
                    />
                  </ContentBoxBody>
                </ContentBox>
              )
              : null
          }
        </FormBody>
        <BottomBar>
          <ButtonsContainer align='center' innerClassName={classes.buttonsContainer}>
            {!noPreview && (
              <Button
                buttonType='white'
                onClick={onClickPreview}
                big
                disabled={submitDisabled}
              >
                {translations('Preview')}
              </Button>
            )}
            {!noPreview && (
              <Button
                buttonType='white'
                onClick={onClickExportPdf}
                big
                disabled={submitDisabled}
              >
                {translations('Save as PDF')}
              </Button>
            )}
            <FormSubmit
              label={translations(sendButtonText) || translations('Send Now')}
              big
              noContainer
              disabled={submitDisabled}
            />
          </ButtonsContainer>
        </BottomBar>
      </Form>
    </div>
  )
}

export default withStyles(style)(CreateNewMessageForm)
