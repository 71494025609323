import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../../config'
import Container from '../../../../../components/Container'
import P from '../../../../../components/P'

const SizeGuide = props => {
  const { product } = props
  const sizeGuideHTML = _.get(product, 'details.productSizeGuide', '')

  if (sizeGuideHTML) {
    return (
      <Container inList>
        <div dangerouslySetInnerHTML={{ __html: sizeGuideHTML }} />
      </Container>
    )
  } else {
    return (
      <Container inList>
        <P value={translations('No size guides')} />
      </Container>
    )
  }
}

export default SizeGuide
