import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import Button from '../Button'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'

const SearchInputAdvanced = ({
  classes,
  onClick,
  disabled
}) => {

  return (
    <div className={classes.advancedSearchButton}>
      <Button
        buttonType='secondary'
        onClick={onClick}
        big
        disabled={disabled}
      >
        {translations('Advanced Search')}
      </Button>
    </div>
  )
}

SearchInputAdvanced.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isSearchButton: PropTypes.bool,
  disabled: PropTypes.bool
}

export default withStyles(style)(SearchInputAdvanced)
