export default `
\n## PRIVACY POLICY
\n  
\n### PURPOSE  
\nWe respect the privacy of all of our users and are committed to protecting information collected. Personal information about you may be collected in several ways.  
\n  
\nWe provide this Privacy Policy to help you to understand what we may do with any personal information that we obtain from you. By registering, you signify your acceptance of this Privacy Policy and agree that we may collect, use and disclose your personal information as described in this Privacy Policy.  
\n  
\n### REGISTRATION
\nIf you are aged under 18, or any such other minimum legal age as contained within the applicable laws of the Country of Operation (defined below) that you reside in, you must let your parent or guardian know about our Privacy Policy before you register.
\n
\nYou agree to provide complete, accurate and current information about yourself, and to promptly update such information if there are any changes. We may change our registration or subscription requirements from time to time.
\nWe shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this paragraph.
\n### PRIVACY AND SECURITY
\nAny reference to "us", "our", or "we" means us (or our affiliates), operated by the following companies, in the following countries (the “Country(ies) of Operation”): 

| Country of Operation | Operated by: |
| :--- | :------ |
| Kingdom of Saudi Arabia (KSA)	| Farouk Trading and Contracting Company Ltd |
|                               | Radwa Trading Company LLC## |
|                               | Arabian Luxury Gifts Limited LLC |
| United Arab Emirates (UAE)    | Allied Enterprises LLC |
| Kuwait                        | Habchi & Chalhoub Trading WLL |
| Bahrain                       | Ashraf BGDC Co for Perfumes & Cosmetics WLL |
| Egypt                         | MAC For Promoting Commercial Business WLL |
| Qatar                         | Qatar Luxury Company WLL |
| Jordan                        | Luxury Development Company Trading Clothes, Perfumes and Accessories Ltd |

\nPersonal information about you may be collected in several ways when you use our stores or any services.
\nWe provide this statement and our privacy policy ("Privacy Policy") to help you to understand what we may do with any personal information that we obtain from you. 
\nThis Privacy Policy does not govern our collection or use of data about you through channels other than our stores, websites and social media accounts.
\n#### 1 - What categories of Personal Information do we collect?
\nOur stores collect certain information when you interact with it, for example, our stores may collect "Personal Information", which is any information that can be used to identify, directly or indirectly, an individual. Our stores may also collect data that does not identify you specifically. The following is a list of Personal Information that we may collect and process about you:
\n*	Information that you provide by filling out forms online or in our stores, including when you register, subscribe to any services including but not limited to interactive services (such as blogs, chat rooms, message boards, or other user forums); enter a competition or promotion, complete a survey or report a problem with our stores: identification data, contact information, postal address, email address;
\n*	Your correct date of birth, which we may require that you submit accurately to ensure that you do not access our stores if you are not of a legal age to purchase products offered for sale on our stores;
\n*	Any information you may provide when you contact us or send us a correspondence;
\n*	Any preference of means of communication and of communication language, and of the preferred communication time;
\n*	Information necessary to identify you: your first and last name, email address, phone numbers, home address, date of birth, nationality, shipping and credit/debit card billing address(es);
\n*	Details of any transactions, including the placement of any orders by you: payment information (for example credit card details, or other payment details which you must provide to receive products you have ordered from us) and your taste and preferences.
\nYou can access and review your Personal Information by approaching our sales personnel in store or by contacting our authorized representatives at salesforce.crm@chalhoub.com who will be happy assist you in your request. You may also be able to access, review and edit your Personal Information from your personal account on our relevant brand website.
\nIf your Personal Information changes in any way or is incorrectly presented on our stores you should immediately update or correct your Personal Information (as applicable) by approaching our sales personnel who will be happy to assist you, or send an email to salesforce.crm@chalhoub.com.
\n#### 2 - For which purposes do we use your Personal Information?
\nWe collect the above mentioned Personal Information for the following purposes:
\n(i)	For the performance of managing your account:
\n*	to manage your account and to serve your requests in general,
\n(ii)	On the basis of the data subject's consent:
\n*	to send you our newsletters as well as details of our products, special offers or promotional offers that may be of interest to you,
\n(iii)	For the purposes of the legitimate interests pursued by us:
\n*	to improve our understanding of your interests and concerns, to improve our understanding of your use of our products, for our internal marketing and demographic studies: we may use your information to make our data collection cards and products/services better, as we believe that it is also in our legitimate interest to better serve you and respond to your needs.
\n*	for security purposes: we may use information to protect our company and our clients against fraud, theft or any wrongdoing which may affect our activity as it is our legitimate interest to ensure the security of our activity.
\n#### 3 - Who do we share your Personal Information with?
\nPersonal Information that you provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of that information as permitted by applicable law. 
\nPersonal Information may also be shared with other divisions within our group of companies and our affiliates and business partners (including brand headquarters), on a need-to-know basis, for the above-mentioned purposes.
\nPlease be aware that if we are requested by any regulatory or government authority investigating any suspected activity to provide your Personal Information, we will be entitled to do so as permitted by applicable law.
\nWe may also disclose your Personal Information onto our carefully selected business partners or to our affiliated companies to enable them to send you information which may be of interest to you, subject to your consent.
\nIf you do not want to receive such information anymore, you can just click on the “UNSUBSCRIBE” link in our marketing emails or ask our sales representatives in store to assist you in order to unsubscribe. 
\nYou may also unsubscribe from other communication channels by either speaking to one of our sales representatives or via the specific communication channel itself (e.g. SMS, WhatsApp, Telephone).
\nFrom time to time we may disclose Personal Information on a need-to-know basis:
\n*	To our service providers and subcontractors, including our affiliates, retained to perform functions on our behalf or to provide services to us, such as warehousing and delivery; marketing and advertising; data processing; software development; information technology and office services; legal, accounting, audit and other third party service providers; and further provided such service provider does not collect, use or disclose the personal information for any purpose other than to perform such functions or to provide services to us or as otherwise required by law;
\n
\n*	To any prospective seller or buyer of our business or assets;
\n
\n*	If we are under a duty to disclose or share your personal data in order to enforce or apply these terms and conditions and other agreements, to permit us to pursue available remedies or limit the damages that we may sustain, or protect our rights, property, safety or security and that of our employees, agents, contractors, customers, the visitors of our stores or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
\n4 - Who do we transfer your Personal Information?
\nIn certain cases, and for data hosting purposes Personal Information we collected about you may be transferred to affiliates, service providers, business partners located outside the EU to which Personal Information are disclosed in the GCC countries, Egypt, Jordan, Lebanon, the United States of America, Japan, Germany and the United Kingdom, including to entities that are located outside of the European Economic Area, which may not have an adequate level of protection. 
\nWe have implemented appropriate safeguards with such data recipients by entering into data transfer agreements based on the European Commission standards clauses with such data recipients. You can ask for a copy of such appropriate safeguards by contacting us.
\n#### 5 - How long do We keep your Personal Information for?
\nPersonal Information collected for the purposes hereunder will be stored only as long as necessary for the purpose of your commercial relationship with us, or as required to comply with our legal obligations.
\nIf a judicial action is initiated, Personal Information may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by applicable law.
\nYour Personal Information will not be kept in a form that allows you to be identified for any longer than is reasonably considered necessary by us for achieving the purposes for which it was collected or processed or as it is established in the applicable laws related to data retention periods.
\n#### 6 - Your rights
\nSubject to applicable law, you may have the following rights: 
\n(i)	Right of access
\nYou have the right to confirm with us whether your Personal Information is processed, and if it is, to request access to that Personal Information including the categories of Personal Information processed, the purpose of the processing and the recipients or categories of recipients. 
\n(ii)	Right to rectification  
\nYou may have the right to rectify inaccurate or incomplete your Personal Information. 
\n(iii)	Right to erasure 
\nYou may have the right to ask us to erase your Personal Information. 
\n(iv)	Right to restriction of processing
\nIn limited circumstances, you may have the right to request that we restrict processing of your Personal Information.
\n(v)	Right to data portability
\nYou may have the right to receive your Personal Information, which you have provided to us, in a structured, commonly used and machine-readable format and you may have the right to transmit that data to another entity.
\n(vi)	Right to object and rights relating to automated decision-making
\nUnder certain circumstances you may have the right to object, on grounds relating to your particular situation, at any time to the processing of your Personal Information by us and We can be required to no longer process your Personal Data. 
\n(vii)	Right to provide instructions regarding the storage, deletion or disclosure of your Personal Information after your death.
\nYou have the right to provide instructions on how we shall store, delete or disclose your Personal Information after your death. These instructions must be provided by e-mail and shall performed by us when reasonably possible to do so.
\nWhen your Personal Information processing is based on your consent, you may withdraw in writing any consent you previously provided to us at any moment. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
\nTo exercise these rights, please liaise with our sales personnel in store or with our customer care, or send an email to salesforce.crm@chalhoub.com.
\nYou also have the right to lodge a complaint with the competent data protection supervisory authority in the Country of Operation that you reside in.
\n#### 7 - Changes to this Policy
\nWe reserve the right to modify this Privacy Policy at any time. Our Privacy Policy may change from time to time to reflect changes in our processing of your Personal Information. Any modifications of this Privacy Policy will be effective once published in our stores. We will notify you of any material changes as required by law. Please read the Privacy Policy and check back often. 
\n#### 8 – Contact
\nIf you have any questions or queries related to this Privacy Policy, or would like to exercise your rights, please speak to one of our instore personnel who will be glad to assist you, alternatively you may contact salesforce.crm@chalhoub.com.
\n#### 9 - Miscellaneous
\nThis Privacy Policy is reproduced in English and Arabic. If there is any inconsistency between the English text and the Arabic text, the English text will prevail.
\n### MUSE LOYALTY PROGRAM
\nPoints will be credited to your online statement within 30 days. To check your statement, download the MUSE App from the App Store or Google Play.
\n
\nMuse Thank You Campaign: Promotional codes are individual codes valid for one time use only.
\nIn case of missing Points, please contact MUSE Concierge as per the contact us number per country here [Loyalty that rewards you across the region](https://www.experience-muse.com/contactus/en-gb), Currently, Points can be redeemed in store only. Other MUSE T&C’s apply.
\nFor further information on the programme, FAQ’s and T&C’s – please visit: [Loyalty that rewards you across the region](https://www.experience-muse.com/contactus/en-gb)
\nThese Terms shall be construed and governed by the laws of the relevant Country of Operation and the competent courts of the relevant Country of Operation shall have exclusive jurisdiction. 
`