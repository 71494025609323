import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  status: undefined,
  product: {},
  variants: [],
  productGroupProducts: [],
  productTab: constants.PRODUCT_DETAILS_EXPANDED,
  mediaIndex: 0,
  isCheckingAvailability: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_ACTIVE_MEDIA_INDEX:
      return reducers.resetActiveMediaIndex(state, action)
    case constants.RESET_PRODUCT_DETAILS_EXPANDED:
      return reducers.resetBottomTabs(state, action)
    case constants.FETCH_PRODUCT:
      return reducers.fetchProduct(state, action)
    case constants.GET_PRODUCT_AVAILABILITY:
      return reducers.getProductAvailability(state, action)
    case constants.FETCH_PRODUCT_GROUP_PRODUCTS:
      return reducers.fetchProductGroupProducts(state, action)
    case constants.CHANGE_PRODUCT_TAB:
      return reducers.changeProductBottomTab(state, action)
    case constants.PUT_ACTIVE_MEDIA_INDEX:
      return reducers.putActiveMediaIndex(state, action)
    default:
      return state
  }
}
