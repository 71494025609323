export default theme => ({
  listContainer: {
    width: '100%',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  itemContainer: {
    listStyle: 'none',
    backgroundColor: 'white',
    '&:first-child > div': {
      borderTop: 0
    }
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tableHoverGrey.main
    }
  },
  itemInner: {
    margin: '0 23px',
    borderTop: `1px solid ${theme.palette.border.main}`
  },
  narrow: {
    margin: '0 6px'
  },
  noMargin: {
    margin: 0
  },
  noBorder: {
    border: 'none'
  },
  boxInner: {
    border: `1px solid ${theme.palette.contentBoxGrey.main}`,
    borderBottom: 0
  },
  gutter: {
    margin: '10px 0'
  }
})
