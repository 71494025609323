import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import MessageEditorForm from './MessageEditorForm'
import style from './style'
import { translations } from '../../../config'

const MessageEditorScreen = ({ classes }) => {
  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={(
          <Heading uppercase component={'h1'}>{translations('Customer Communications')}</Heading>
        )}
      />
      <div className={classes.contentContainer}>
        <P
          value={translations('required fields')}
          className={classes.requiredFieldsText}
        />
        <MessageEditorForm />
      </div>
    </div>
  )
}

export default withStyles(style)(MessageEditorScreen)
