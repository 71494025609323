import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'
import _ from 'lodash'
import { compose } from 'recompose'

import TabbedContent from '../../../../../components/TabbedContent'
import MobileSelect from '../../../../../components/MobileSelect'
import { translations } from '../../../../../config'

import style from './style'
import { SNAPSHOT_LIFETIME, SNAPSHOT_THIS_MONTH, SNAPSHOT_THIS_YEAR } from '../../../../../store/modules/app/constants'

import DonutChart from '../../../../../components/Charts/DonutChart'

const CustomerSnapshotSpendMetrics = (props) => {
  const { classes, customerReporting, getData, handleTabChange, tabValue } = props

  const renderChart = period => {
    const data = getData(period) || []
    const totalPrice = _.get(customerReporting, `${period}.totalSpend.value`)
    const currencyCode = _.get(customerReporting, `${period}.totalSpend.code`)
    const outerRadius = useMediaQuery(theme => theme.breakpoints.up('sm')) ? undefined : 90

    return (
      <div className={classes.chartContainer}>
        <DonutChart currency={currencyCode} data={data} pie={false} outerRadius={outerRadius} totalPrice={totalPrice} />
      </div>
    )
  }

  const renderDesktop = () => {
    const tabs = [
      {
        label: translations('This Month'),
        content: renderChart('thisMonth.profit'),
        value: SNAPSHOT_THIS_MONTH
      },
      {
        label: translations('This Year'),
        content: renderChart('thisYear.profit'),
        value: SNAPSHOT_THIS_YEAR
      },
      {
        label: translations('Lifetime'),
        content: renderChart('lifetime.profit'),
        value: SNAPSHOT_LIFETIME
      }
    ]
    return (
      <TabbedContent
        tabs={tabs}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        noDivider
        mediumTabs
      />
    )
  }

  const renderMobile = () => {
    const options = [
      {
        text: translations('This Month'),
        value: SNAPSHOT_THIS_MONTH
      },
      {
        text: translations('This Year'),
        value: SNAPSHOT_THIS_YEAR
      },
      {
        text: translations('Lifetime'),
        value: SNAPSHOT_LIFETIME
      }
    ]
    const option = options.find(o => o.value === tabValue)
    const period = `${_.camelCase(_.get(option, 'text'))}.profit`
    return (
      <Fragment>
        <MobileSelect
          onChange={handleTabChange}
          value={tabValue}
          options={options}
        />
        {renderChart(period)}
      </Fragment>
    )
  }

  return (
    <section className={classes.listItemsContainer}>
      <Hidden smUp>
        {renderMobile()}
      </Hidden>
      <Hidden xsDown>
        {renderDesktop()}
      </Hidden>

    </section>
  )
}

export default compose(
  withStyles(style)
)(CustomerSnapshotSpendMetrics)
