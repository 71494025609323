import _ from 'lodash'
import countriesConfig from './configFiles/countries'
import { sortWithPriority, sortWithTopItems } from '../helpers'

const { allCountries, prioritized } = countriesConfig

const orderedNumByNameList = sortWithPriority(allCountries, prioritized, 'name')
const orderedNumByCodeList = sortWithPriority(allCountries, prioritized, 'code')
const orderedByNameWithTopItems = sortWithTopItems(allCountries, prioritized, 'name')

export const countryPhoneCodes = _.chain(orderedNumByCodeList)
  .map(country => country.dialCode ? `${country.code} ${country.dialCode}` : null)
  .reject(_.isEmpty)
  .value()

export const countryPhoneDictionary = orderedNumByNameList.reduce((memo, next) => ({ ...memo, [next.dialCode]: next }), {})

export const countries = orderedNumByNameList.map(country => country.name)

export const countriesOptions = orderedNumByNameList.map(country => ({ label: country.name, value: country.code }))

export const nationalitiesOptions = orderedNumByCodeList.map(country => ({ label: `${country.nationality}, ${country.name}`, value: country.code }))

export const countryOptionsForDropdowns = orderedByNameWithTopItems.map(group => {
  return group.map(country => ({
    label: country.name,
    value: country.name,
    code: country.code
  }))
})

export const countryCodesForAddressLookup = _.map(orderedNumByCodeList, 'code')
