import _ from 'lodash'

const styles = theme => {

  return ({
    p: {
      ...theme.p.p,
      margin: 0,
      color: theme.palette.text.main
    },
    large: {
      ...theme.p.large,
      color: theme.palette.text.main
    },
    hubTileText: {
      ...theme.p.hubTileText,
      color: _.get(theme, 'palette.hubTileText.main') || _.get(theme, 'palette.whiteText.main')
    },
    promoText: {
      ...theme.p.promoText,
      color: theme.palette.whiteText.main,
      padding: '0 5px',
      margin: 0,
      textTransform: 'uppercase'
    },
    textButton: {
      ...theme.p.textButton,
      color: theme.palette.text.main
    },
    alert: {
      ...theme.p.alert,
      color: theme.palette.error.main
    },
    inline: {
      ...theme.p.inline,
      color: theme.palette.text.main
    },
    bold: {
      ...theme.p.p,
      color: theme.palette.text.main,
      fontWeight: 'bold'
    },
    greenText: {
      ...theme.p.p,
      color: theme.palette.actionedGreen.main
    },
    small: {
      ...theme.p.small,
      color: theme.palette.text.main
    },
    tag: {
      ...theme.p,
      color: theme.palette.tagGrey.main,
      fontSize: 13,
      fontStyle: 'italic',
      textDecoration: 'underline'
    }
  })
}

export default styles
