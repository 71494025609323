/* eslint-disable react/prop-types */

// ** IMPORTANT - READ THIS IF YOU PLAN ON CHANGING ANY OF THE TWILIO VIDEO INTEGRATION **
// https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/497

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'

import { getImage } from '../../../../components/Images'

import { actions as appointmentsActions } from '../../../../store/modules/appointments'
import { selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import toastService from '../../../../services/toastService'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import CircularProgress from '@material-ui/core/CircularProgress'

const VideoPanelContainer = styled.div`
  background-color: ${() => window.cordova ? 'transparent' : 'black'};
  display: flex;
  flex: 1 0 65%;
  position: relative;
  min-height: ${({ fullHeight }) => !fullHeight ? 'calc(100vh - 75px - 75px - 48px - 180px - env(safe-area-inset-top))' : '100%'};
`

const SelfVideoPanel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100%;
  height: 300px;
  max-width: 33%;
  max-height: 50%;
  z-index: 10;

  video {
    max-width: 100%;
    max-height: 100%;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg);
    z-index: -1;
    position: absolute;
  }
`

const ParticipantVideoPanel = styled.div`
  display: flex;
  flex: 1;
  z-index: 9;
  visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};

  video {
    width: 100%;
    height: 100%;
    z-index: -2;
    position: absolute;
  }
`

const VideoControls = styled.div`
  display: flex;
  position: absolute;
  bottom: ${({ isConsultant }) => isConsultant ? 25 : 80}px;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const VideoControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  background-color: ${({ active }) => !active
    ? '#1a1a1a'
    : '#f44336'
};
  color: white;
  margin: 0 10px;
  cursor: pointer;
`

const WaitingRoom = styled.div`
  color: #340C0C;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0 20px;
  flex-direction: column;
`

const VideoOverlay = styled.div`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`

const VideoOverlayHeading = styled.p`
  font-weight: bold;
  color: white;
`

const VideoOverlayText = styled.p`
  color: white;
`

const getPlatformBrowserSupportText = () => {
  return `
    Your browser is unsupported. Please use the following browser dependent on your device:\n
    iOS - Safari
    Android - Chrome
    Mac - Chrome/Firefox
    Windows - Chrome/Firefox
    Linux - Chrome/Firefox
  `
}

const triggerNoPermissionsModal = () => {
  modalService.action({
    title: translations('Video/audio permission denied modal title'),
    text: translations('Video/audio permission denied modal text'),
    actions: [
      {
        success: true,
        text: 'Try again',
        onClick: () => window.location.reload(),
        primary: true
      }
    ]
  })
}

const getSelfVideoPanel = () => {
  return document.getElementById('self-video-panel')
}

const getSelfAudioPanel = () => {
  return document.getElementById('self-audio-panel')
}

const getParticipantVideoPanel = () => {
  return document.getElementById('participant-video-panel')
}

const getParticipantAudioPanel = () => {
  return document.getElementById('participant-audio-panel')
}

const removeSelfVideo = () => {
  const selfVideoPanel = getSelfVideoPanel()
  if (!selfVideoPanel) {
    console.log('DEBUG: could not find self-video-panel')
    return
  }

  while (selfVideoPanel.hasChildNodes()) {
    selfVideoPanel.removeChild(selfVideoPanel.lastChild)
  }
}

export const removeSelfAudio = () => {
  const selfAudioPanel = getSelfAudioPanel()
  if (!selfAudioPanel) return
  while (selfAudioPanel.hasChildNodes()) {
    selfAudioPanel.removeChild(selfAudioPanel.lastChild)
  }
}

const removeParticipantVideo = () => {
  const participantVideoPanel = getParticipantVideoPanel()
  if (!participantVideoPanel) return
  while (participantVideoPanel.hasChildNodes()) {
    participantVideoPanel.removeChild(participantVideoPanel.lastChild)
  }
}

const removeParticipantAudio = () => {
  const participantAudioPanel = getParticipantAudioPanel()
  if (!participantAudioPanel) return
  while (participantAudioPanel.hasChildNodes()) {
    participantAudioPanel.removeChild(participantAudioPanel.lastChild)
  }
}

const showSelfVideo = (localParticipant) => {
  removeSelfVideo()
  const selfVideoPanel = getSelfVideoPanel()
  localParticipant.videoTracks.forEach(publication => {
    publication.track.enable()
    if (selfVideoPanel) {
      selfVideoPanel.appendChild(publication.track.attach())
    }
  })
}

export const showSelfAudio = (localParticipant) => {
  removeSelfAudio()
  const selfAudioPanel = getSelfAudioPanel()
  localParticipant.audioTracks.forEach(publication => {
    publication.track.enable()
    if (selfAudioPanel) {
      selfAudioPanel.appendChild(publication.track.attach())
    }
  })
}

const showParticipantVideo = (participant) => {
  removeParticipantVideo()
  const participantVideoPanel = getParticipantVideoPanel()
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      if (participantVideoPanel && publication.kind === 'video') {
        participantVideoPanel.appendChild(publication.track.attach())
      }
    }
  })
}

const showParticipantAudio = (participant) => {
  removeParticipantAudio()
  const participantAudioPanel = getParticipantAudioPanel()
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      if (participantAudioPanel && publication.kind === 'audio') {
        participantAudioPanel.appendChild(publication.track.attach())
      }
    }
  })
}

// const addVideo = (publication) => {
//   console.log(`VIDEO PANEL: Add video:`, { publication })
//   removeParticipantVideo()
//   const participantVideoPanel = getParticipantVideoPanel()
//   if (publication.isSubscribed) {
//     if (participantVideoPanel && publication.kind === 'video') {
//       participantVideoPanel.appendChild(publication.track.attach())
//     }
//   }
// }

// const addAudio = (publication) => {
//   console.log(`VIDEO PANEL: Add audio:`, { publication })
//   removeParticipantAudio()
//   const participantAudioPanel = getParticipantAudioPanel()
//   if (publication.isSubscribed) {
//     if (participantAudioPanel && publication.kind === 'audio') {
//       participantAudioPanel.appendChild(publication.track.attach())
//     }
//   }
// }

const VideoPanel = ({ isConsultant }) => {
  const dispatch = useDispatch()
  const [videoToken, setVideoToken] = useState()
  const [connectOptions, setConnectOptions] = useState()
  const [room, setRoom] = useState()
  const [showWaitingRoom, setShowWaitingRoom] = useState(true)
  const [hideVideo, setHideVideo] = useState(true)
  const [hideParticipantVideo, setHideParticipantVideo] = useState(true)
  const [muteAudio, setMuteAudio] = useState(false)
  const appointment = useSelector(currentAppointmentSelectors.getCurrentAppointment)
  const isAppointmentEnded = useSelector(currentAppointmentSelectors.getIsAppointmentEnded)
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const isAppointmentExpired = useSelector(currentAppointmentSelectors.getIsAppointmentExpired)
  const currentAppointmentStage = useSelector(currentAppointmentSelectors.getAppointmentStage)
  const appointmentId = _.get(appointment, 'id')
  const clientId = isConsultant ? 'consultant' : 'customer'

  useEffect(() => {
    console.log(`VIDEO PANEL: fetching video token for ${clientId} appointment ID: ${appointmentId}`)
    if (!clientId || !appointmentId || isAppointmentEnded || isAppointmentExpired) return
    const fetchVideoToken = async () => {
      const { token } = await dispatch(appointmentsActions.fetchVideoToken({
        clientId,
        appointmentId
      }))
      setVideoToken(token)
    }
    fetchVideoToken()
  }, [clientId, appointmentId, isAppointmentEnded, isAppointmentExpired])

  useEffect(() => {
    if (!appointmentId || isAppointmentEnded || isAppointmentExpired) return
    console.log(`VIDEO PANEL: set connection options for ${appointmentId}`)
    setConnectOptions({
      name: appointmentId,
      audio: true,
      video: {
        width: 640
      },
      sdpSemantics: 'plan-b',
      bundlePolicy: 'max-compat'
    })
  }, [appointmentId, isAppointmentEnded, isAppointmentExpired])

  useEffect(() => {
    const connectTwilioVideo = async () => {
      if (window.twilioRoom || !videoToken || !connectOptions) return
      console.log(`VIDEO PANEL: connecting to room`)
      const twilioVideo = require('twilio-video')
      try {
        const room = await twilioVideo.connect(videoToken, connectOptions)
        setRoom(room)
      } catch (error) {
        console.error(`VIDEO PANEL: error: ${error.message}`)
        if (twilioVideo.isSupported) {
          triggerNoPermissionsModal()
        } else {
          modalService.action({
            title: 'Browser unsupported',
            text: getPlatformBrowserSupportText(),
            actions: [
              {
                success: true,
                text: 'Try again',
                onClick: () => window.location.reload(),
                primary: true
              }
            ]
          })
        }
      }
    }
    connectTwilioVideo()
  }, [videoToken, connectOptions])

  useEffect(() => {
    if (!room) return
    console.log('VIDEO PANEL: succesfully joined room')

    if (!window.twilioRoom) { window.twilioRoom = room }

    const addCurrentParticipant = (participant) => {
      console.log(`VIDEO PANEL: add current participant ${participant.identity}`)

      // if (participant.identity === 'customer') return

      const onTrackSubscribed = (track) => {
        // if (hideParticipantVideo) return

        console.log(`VIDEO PANEL: add current participant: onTrackSubscribed`, { identity: participant.identity, participant, hideParticipantVideo })

        showParticipantVideo(participant)
        showParticipantAudio(participant)

        toastService.action({
          type: 'success',
          message: `Successfully connected with ${participant.identity}`,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      }

      const onTrackUnsubscribed = (track) => {
        console.log(`VIDEO PANEL: add current participant: onTrackUnsubscribed`, { identity: participant.identity, track })
      }

      participant.on('trackSubscribed', onTrackSubscribed)

      participant.on('trackUnsubscribed', onTrackUnsubscribed)
    }

    const onParticipantConnected = (participant) => {
      console.log(`VIDEO PANEL: a remote participant connected`, { identity: participant.identity })

      const onTrackSubscribed = (track) => {
        console.log(`VIDEO PANEL: a remote participant connected: trackSubscribed`, { identity: participant.identity, hideParticipantVideo })

        showParticipantVideo(participant)
        showParticipantAudio(participant)

        toastService.action({
          type: 'success',
          message: `Successfully connected with ${participant.identity}`,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      }

      const onTrackPublished = (publication) => {
        console.log(`VIDEO PANEL: a remote participant connected: onTrackPublished`, { publication })
        showParticipantVideo(participant)
        showParticipantAudio(participant)
      }

      participant.on('trackSubscribed', onTrackSubscribed)

      participant.on('trackPublished', onTrackPublished)
    }

    const onParticipantDisconnected = (participant) => {
      console.log(`VIDEO PANEL: a participant disconnected`, { identity: participant.identity })

      removeParticipantVideo()
      removeParticipantAudio()

      toastService.action({
        type: 'info',
        message: `${_.capitalize(participant.identity)} disconnected from virtual consultation`,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }

    // add current participants
    room.participants.forEach(addCurrentParticipant)

    // add joining participants
    room.on('participantConnected', onParticipantConnected)

    // remove leaving participants
    room.on('participantDisconnected', onParticipantDisconnected)

    // add self video & audio (only for customer on initial load)
    // if (room.localParticipant.identity === 'customer') {
    //   showSelfVideo(room.localParticipant)
    //   showSelfAudio(room.localParticipant)
    // }

    // Remove video publication of customer on initial load
    // NOTE: ideally this should not connect on initialisation.
    if (room.localParticipant.identity === 'customer') {
      console.log(`VIDEO PANEL: Hiding ${room.localParticipant.identity} video`)
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop()
        publication.unpublish()
      })
      removeSelfVideo()
    }

    // Remove video publication of consultat on initial load
    // NOTE: ideally this should not connect on initialisation.
    if (room.localParticipant.identity === 'consultant') {
      console.log(`VIDEO PANEL: Hiding ${room.localParticipant.identity} video`)
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop()
        publication.unpublish()
      })
      removeSelfVideo()
    }
  }, [room])

  useEffect(() => {
    console.log(`VIDEO PANEL: connecting to twilioRoom: ${appointmentId} as ${clientId}`)
    if (appointmentId && !isAppointmentEnded) {
      setShowWaitingRoom(false)
      const cordova = window.cordova
      if (cordova && cordova.plugins && cordova.plugins.iosrtc) {
        // Expose WebRTC and GetUserMedia SHIM as Globals (Optional)
        // Alternatively WebRTC API will be inside cordova.plugins.iosrtc namespace
        cordova.plugins.iosrtc.registerGlobals()
        // Patch MediaStreamTrack with clone
        MediaStreamTrack.prototype.clone = function () {
          return this
        }
        // Enable iosrtc debug (Optional)
        cordova.plugins.iosrtc.debug.enable('*', false)
      }
      MediaStreamTrack.prototype.clone = function () {
        return this
      }

      window.twilioMute = window.twilioMute || false

      if (!videoToken || !room) return
    }

    return () => {
      if (window.twilioRoom) {
        window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable()
        })

        // moved this to VCAudioContainer for consultant
        if (!isConsultant) {
          window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable()
          })

          window.twilioRoom.disconnect()
          window.twilioRoom = null
        }
      }
    }
  }, [appointmentId, videoToken, room])

  useEffect(() => {
    if (window.twilioRoom && isAppointmentEnded) {
      window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop()
        publication.unpublish()
      })
      removeSelfVideo()
    }
  }, [isAppointmentEnded])

  useEffect(() => {
    if (isAppointmentExpired) {
      console.log(`Appointment has already expired.`)
    }
  }, [isAppointmentExpired])

  const toggleParticipantVideo = useCallback((identity) => {
    console.log(`toggleParticipantVideo`)
    window.twilioRoom.participants.forEach(participant => {
      if (participant.identity === identity) {
        participant.videoTracks.forEach(publication => {
          if (hideParticipantVideo) {
            showParticipantVideo(participant)
            showParticipantAudio(participant)
          } else {
            removeParticipantVideo()
          }
        })
        setHideParticipantVideo(prevHideParticipantVideo => !prevHideParticipantVideo)
      }
    })
  }, [hideParticipantVideo])

  const toggleVideo = useCallback(() => {
    if (window.twilioRoom) {
      const twilioVideo = require('twilio-video')
      console.log(`VIDEO PANEL: toggle video: hide state: ${hideVideo}`)
      if (hideVideo) {
        twilioVideo.createLocalVideoTrack().then(localVideoTrack => {
          return window.twilioRoom.localParticipant.publishTrack(localVideoTrack)
        }).then(publication => {
          showSelfVideo(window.twilioRoom.localParticipant)
        })
      } else {
        window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
          publication.track.stop()
          publication.unpublish()
        })
        removeSelfVideo()
      }
      setHideVideo((prevHideVideo) => !prevHideVideo)
    }
  }, [hideVideo, window.twilioRoom])

  const toggleAudio = useCallback(() => {
    if (window.twilioRoom) {
      if (window.twilioMute) {
        showSelfAudio(window.twilioRoom.localParticipant)
      } else {
        window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
        })
        removeSelfAudio()
      }
      setMuteAudio(!window.twilioMute)
      window.twilioMute = !window.twilioMute
    }
  }, [window.twilioMute, window.twilioRoom])

  const videoOverlayMessage = useMemo(() => {
    if (!isAppointmentComplete && currentAppointmentStage === 'CHECKOUT') {
      return {
        heading: translations('Customer consultation - video unavailable'),
        loader: false
      }
    }

    return null
  }, [currentAppointmentStage, isAppointmentEnded, isAppointmentComplete])

  if (isAppointmentEnded) {
    return (
      <WaitingRoom>
        <p style={{ fontWeight: 'bold' }}>{translations('Appointment completed - headline')}</p>
      </WaitingRoom>
    )
  }

  const renderVideoOverlay = ({ heading, text = [] }) => (
    <VideoOverlay>
      <VideoOverlayHeading>{heading}</VideoOverlayHeading>
      {text.length > 0 && text.map((txt) => <VideoOverlayText>{txt}</VideoOverlayText>)}
    </VideoOverlay>
  )

  return (
    <VideoPanelContainer fullHeight={!isConsultant || isAppointmentComplete}>
      {
        !isConsultant && showWaitingRoom
          ? (
            <WaitingRoom>
              <CircularProgress size={25} />
              <p style={{ fontWeight: 'bold' }}>{translations('Waiting room - headline')}</p>
              <p>{translations('Waiting room - line 1')}</p>
              <p>{translations('Waiting room - line 2')}</p>
            </WaitingRoom>
          )
          : (
            <>
              {videoOverlayMessage && renderVideoOverlay(videoOverlayMessage)}
              <ParticipantVideoPanel id="participant-video-panel" hidden={hideParticipantVideo && isConsultant}/>
              <SelfVideoPanel id="self-video-panel" />
              <VideoControls isConsultant={isConsultant}>
                {isConsultant && (
                  <VideoControlButton active={hideParticipantVideo} onClick={() => toggleParticipantVideo('customer')}>
                    {hideParticipantVideo ? <img src={getImage('participantVideoOffIcon')} /> : <img src={getImage('participantVideoOnIcon')} />}
                  </VideoControlButton>
                )}
                <VideoControlButton active={hideVideo} onClick={toggleVideo}>
                  {hideVideo ? <VideocamOffIcon /> : <VideocamIcon />}
                </VideoControlButton>
                <VideoControlButton active={muteAudio} onClick={toggleAudio}>
                  {window.twilioMute ? <MicOffIcon /> : <MicIcon />}
                </VideoControlButton>
              </VideoControls>
            </>
          )
      }

    </VideoPanelContainer>
  )
}

export default VideoPanel
