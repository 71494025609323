import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import StoreroomKanbanScreen from './StoreroomKanbanScreen'

export default [
  <PrivateRoute key='/pick-from-store' path='/pick-from-store' exact component={TimedLogoutComponent(StoreroomKanbanScreen)} allowOffline />,
  <PrivateRoute key='/distant-sales' path='/distant-sales' exact component={TimedLogoutComponent(StoreroomKanbanScreen)} allowOffline />,
]
