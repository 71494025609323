import { connect } from 'react-redux'
import { compose } from 'redux'
import { isInvalid } from 'redux-form'
import _ from 'lodash'

import CheckoutWrapper from './CheckoutWrapper'
import withCheckoutFlow from '../../withCheckoutFlow'
import { getModulesFromCheckoutType } from '../../../../helpers'
import * as currentOrderCombinedSelectors from '../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { withDiscountMode } from './discountMode'
import { mapProps } from 'recompose'
import { addPrices } from '@redant/digital-store-prices-chalhoub/dist/handlePrices'
import { selectors as appSelectors } from '../../../../store/modules/app'
import { calculateOrderTotals } from '../../calculateOrderTotals'
import { getCurrencyConfigForSelectedRegion } from '../../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { regionsCombinedSelectors } from '../../../../store/modules/combinedSelectors'

export default compose(
  withCheckoutFlow,
  withDiscountMode,
  connect((state, props) => {
    const current = _.get(props, 'current')
    const checkoutType = _.get(props, 'checkoutType')
    const deliveryType = _.get(state, 'currentOrder.deliveryType')
    const basketType = currentOrderCombinedSelectors.getBasketType(state)
    const currentModule = getModulesFromCheckoutType(checkoutType, deliveryType).find(m => m.name === current)
    const currentCurrency = appSelectors.getAppCurrency(state)
    const remoteConfig = getCurrencyConfigForSelectedRegion(state)
    const checkoutConfig = regionsCombinedSelectors.getCheckoutConfigForBrand(state)
    const isGiftNoteEnabled = _.get(checkoutConfig, 'enableGiftNote', false)

    const orderTotals = calculateOrderTotals({
      products: props.products, 
      total: props.subTotal,
      currentCurrency,
      remoteConfig,
      shipping: props.deliveryOption
    })

    return {
      ...orderTotals,
      basketType,
      invalid: !!(props.formId && isInvalid(props.formId)(state)) || 
      props.formId === 'delivery-options' && isInvalid('notes')(state),
      displayOrderDate: _.get(currentModule, 'orderDate'),
      submitText: _.get(currentModule, 'submitButtonText'),
      currentCurrency: currentCurrency,
      isGiftNoteEnabled
    }
  }),
  mapProps(props => {
    const { subTotal, totalDiscount } = props
    const subtotalBeforeDiscount = addPrices(subTotal, totalDiscount)
    
    return { ...props, subtotalBeforeDiscount }
  })
)(CheckoutWrapper)
