import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { selectors as customerDetailsSelectors } from '../../../../../../store/modules/customerDetails'
import { selectors as currentAppointmentSelectors } from '../../../../../../store/modules/currentAppointment'
import { selectors as orderDetailsSelectors } from '../../../../../../store/modules/orderDetails'
import P from '../../../../../../components/P'
import currencyFormatter from '../../../../../../formatters/currencyFormatter'
import { translations } from '../../../../../../config'

const Label = styled(P)`
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const DetailText = styled(P)`
  margin-bottom: 1rem;
`

const DetailItem = styled.div`
  border-bottom: 1px solid #D1D1D1;
  margin: 12px;
`

const SummaryDetails = ({ stacked, basketTotal }) => {
  const customerName = useSelector(customerDetailsSelectors.getCustomerFullName)
  const appointmentOrder = useSelector(currentAppointmentSelectors.getAppointmentOrder)
  const orderDetailsOrder = useSelector(orderDetailsSelectors.getOrder)
  const order = appointmentOrder || orderDetailsOrder
  const deliveryType = _.get(order, 'deliveryType')
  const gridItemWidth = stacked ? 12 : 6
  const showTotal =
    basketTotal &&
    basketTotal.value &&
    basketTotal.value !== 0 &&
    order.products &&
    order.products.length > 0

  return (
    <Grid container>
      <Grid item xs={gridItemWidth}>
        <DetailItem>
          <Label value={translations('Customer Name')} />
          <DetailText value={customerName} />
        </DetailItem>
      </Grid>

      {deliveryType && (
        <Grid item xs={gridItemWidth}>
          <DetailItem>
            <Label value={translations('Delivery method - label')} />
            <DetailText value={translations(`Delivery method - ${deliveryType}`)} />
          </DetailItem>
        </Grid>
      )}

      <Grid item xs={gridItemWidth}>
        <DetailItem>
          <Label value={translations('Order status - label')} />
          <DetailText value={translations(`Order Status - ${order.status}`)} />
        </DetailItem>
      </Grid>

      {showTotal && (
        <Grid item xs={gridItemWidth}>
          <DetailItem>
            <Label value={translations('Total cost')} />
            <DetailText value={currencyFormatter.format(basketTotal)} />
          </DetailItem>
        </Grid>
      )
      }
    </Grid>
  )
}

export default SummaryDetails
