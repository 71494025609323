import React, { Component } from 'react'
import { push, goBack, replace } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

// this component lets you create a multi screen journey that can either use
// proper browser routing or state depending on the use case.
// eg: this lets our product catalogue journey work with actual urls OR work
// inside a modal

// props:
// noRouting (boolean) [eg, true if in modal]
// exit (function) [triggered by back button on intial screen ONLY IF `noRouting` is true]
// fallbackComponent (Component) [displayed if no paths matched]
// initialPath (string) [inital path state (only used if `noRouting` true)]
// passedProps (object) [some props to be passed to whatever component is shown]
// routes (array: { path: string, component: Component, exact: boolean })

class HybridRouter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      path: props.initialPath,
      history: []
    }
  }

  push = path => {
    const { noRouting, dispatch } = this.props

    if (noRouting) {
      const { history, path: prevPath } = this.state
      history.push(prevPath)
      this.setState({
        path,
        history
      })
    } else {
      dispatch(push(path))
    }
  }

  replace = path => {
    const { noRouting, dispatch } = this.props

    if (noRouting) {
      this.setState({
        path
      })
    } else {
      dispatch(replace(path))
    }
  }

  goBack = () => {
    const { noRouting, dispatch, exit } = this.props

    if (noRouting) {
      const { history } = this.state
      const path = history.pop()
      if (path) {
        this.setState({
          path,
          history
        })
      } else if (exit) {
        exit()
      } else {
        console.warn('HybridRouter hasn\'t been passed an exit function')
      }
    } else {
      dispatch(goBack())
    }
  }

  render () {
    const { noRouting, routes, fallbackComponent, passedProps, pathOverride } = this.props
    const path = pathOverride || (
      noRouting
        ? this.state.path
        : this.props.location.pathname
    )

    let Comp = fallbackComponent
    let match = null

    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      match = matchPath(path, { path: route.path, exact: route.exact })
      if (match) {
        Comp = route.component
        break
      }
    }

    const matchProps = {
      ..._.get(match, 'params', {}),
      modalPath: _.get(match, 'path')
    }
    return <Comp
      {...passedProps}
      {...matchProps}
      push={this.push}
      goBack={this.goBack}
      replace={this.replace}
    />
  }
}

HybridRouter.defaultProps = {
  routes: [],
  fallbackComponent: () => null,
  noRouting: false,
  initialPath: ''
}

export default HybridRouter
