import { colors } from '../../../config'

const style = theme => ({
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridRowGap: '10px',
    gridColumnGap: '25px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'auto 1fr'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr auto'
    }
  },
  rowLabel: {
    fontWeight: 'bold',
    lineHeight: '40px'
  },
  dropdownAndSizeGuideContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  swatchContainer: {
    display: 'flex',
    flexDirection: 'row',
    gridColumn: '1 / 3'
  },
  variantDropdownContainer: {
    marginTop: -14
  },
  field: {
    width: 160
  },
  submitButtonContainer: {
    width: '100%'
  },
  submitButton: {
    width: '100%',
    maxWidth: '100%'
  },
  errorContainer: {
    gridColumn: 'span 2',
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    color: colors.error,
    fontSize: '2rem',
    paddingRight: '0.5rem'
  },
  errorStyle: { 
    fontSize: '0.9rem',
    color: colors.error 
  }
})

export default theme => style(theme)
