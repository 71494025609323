import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import productDetailActions from './actions'
import { selectors as authSelectors } from '../auth'
import { actions as storeActions } from '../stores'
import { selectors as productDetailSelector, constants } from './index'

import { history } from '../..'

class ProductDetailsMiddleware {
  loadProductDetailsOnRouteMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/product/:id', exact: true })
      const isRoutingToProductDetailsView = result
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      if (isRoutingToProductDetailsView && isLoggedIn) {
        const productId = result.params.id
        dispatch(productDetailActions.fetchProduct({ id: productId, storeId }))
      }
    }
  }

  loadProductGroupProductsOnProductLoadedMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === constants.FETCH_PRODUCT) {
      // const result = matchPath(history.location.pathname, { path: '/product/:id', exact: true })
      // const isRoutingInProductDetailsView = result
      // const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      // if (isRoutingInProductDetailsView && isLoggedIn) {
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      // check if is valid product group
      if (_.isString(action.result.productGroup) && action.result.productGroup.trim()) {
        dispatch(productDetailActions.fetchProductGroupProducts({
          productGroup: action.result.productGroup,
          productId: action.result.id,
          storeId
        }))
      }
    }
  }

  loadStoresOnRouteMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const result = matchPath(action.payload.location.pathname, { path: '/product/:id', exact: true })
      const isRoutingToProductDetailsView = result
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      if (isRoutingToProductDetailsView && isLoggedIn ) {
        const productCatalogue = authSelectors.getUserSelectedProductCatalogue(state)
        dispatch(storeActions.fetchAllStores({ catalogue: {$eq: productCatalogue}, limit: 100})) 
      }
    }
  }

  resetMediaIndex = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const productDetailResults = matchPath(action.payload.location.pathname, { path: '/product/:id', exact: true })
      const putMediaIndex = productDetailSelector.getActiveMediaIndex(getState())
      if (productDetailResults && putMediaIndex !== 0) {
        dispatch(productDetailActions.resetActiveMediaIndex())
      }
    }
  }

  restProductTab = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const productDetailResults = matchPath(action.payload.location.pathname, { path: '/product/:id', exact: true })
      const bottomTabSelected = productDetailSelector.getBottomTab(getState())
      if (productDetailResults && bottomTabSelected !== constants.PRODUCT_DETAILS_EXPANDED) {
        dispatch(productDetailActions.resetBottomTabs())
      }
    }
  }
}

export default new ProductDetailsMiddleware()
