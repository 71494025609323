import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'

import style from './style'

export const InputField = ({
  type,
  value,
  label,
  onChange,
  onBlur,
  passedProps,
  meta: { error, touched } = {},
  classes,
  shrink,
  autoFocus,
  noErrorTextLabel,
  noLabel,
  getRef,
  multiline,
  rows,
  rowsMax,
  disabled
}) => {
  return ([
    (
      noLabel
        ? null
        : <InputLabel
          classes={{
            focused: classes.floatingLabelFocusStyle
          }}
          htmlFor={label}
          key='label'
          shrink={shrink}
        >
          {label}
        </InputLabel>
    ),
    <Input
      inputRef={getRef}
      id={label}
      error={!!(touched && error)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      disabled={disabled}
      {...passedProps}
      key='input'
      type={
        type && type !== 'number' && type !== 'price'
          ? type
          : 'text'
      }
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      autoComplete="new-password"
    />,
    (
      noErrorTextLabel
        ? null
        : <FormHelperText
          className={classes.errorStyle}
          key='helper-text'
        >
          {touched && error && error}
        </FormHelperText>
    )
  ])
}

InputField.propTypes = {
  /** Should be a valid HTML5 input type */
  type: PropTypes.string,
  /** The value of the input element */
  value: PropTypes.string,
  /** Callback fired when the value is changed */
  onChange: PropTypes.func,
  /** Props spread to the [MUI Input](https://material-ui.com/api/input/) element */
  passedProps: PropTypes.object,
  /** Set be redux forms, and will show the error state when error and touched are truthy */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }),
  classes: PropTypes.string,
  /** If true the label is shrunk */
  shrink: PropTypes.bool,
  /**	If true, the input element will be focused during the first mount. */
  autoFocus: PropTypes.bool,
  /** If true, will hide the form error, regardless of meta values */
  noErrorTextLabel: PropTypes.bool,
  /** If true, a textarea element will be rendered. */
  multiline: PropTypes.bool,
  /** Number of rows to display when multiline option is set to true. */
  rows: PropTypes.number,
  /** 	Maximum number of rows to display when multiline option is set to true. */
  rowsMax: PropTypes.number
}

export default withStyles(style)(InputField)
