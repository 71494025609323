import { connect } from 'react-redux'

import { selectors as customerDetailSelectors } from '../../../../../store/modules/customerDetails'
import OrderList from './OrderList'

const mapStateToProps = state => ({
  lifetimeSpend: customerDetailSelectors.getCustomerTotalSpend(state),
  loyaltyTier: customerDetailSelectors.getCustomerLoyaltyType(state),
  crmTier: customerDetailSelectors.getCrmTier(state),
  expirationDate: customerDetailSelectors.getExpirationDate(state),
  availablePoints: customerDetailSelectors.getAvailablePoints(state)
})

export default connect(mapStateToProps)(OrderList)
