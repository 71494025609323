import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import LoadMoreButton from '../../../../../components/LoadMoreButton'
// import ScrollToTopButton from '../../../../../components/ScrollToTopButton'
import Grid from '@material-ui/core/Grid'

import ProductsGridItem from './ProductsGridItem'
import style from './style'

const defaultProductMapper = product => product

class ProductsGrid extends PureComponent {
  renderGridItems = () => {
    const {
      products,
      onProductClick,
      onButtonClick,
      buttonValue,
      productMapper = defaultProductMapper,
      multiSelect,
      modalIndex,
      disablePrice,
      push,
      goBack
    } = this.props

    return (products || [])
      .map((product) => {
        return (
          <ProductsGridItem
            key={product.id}
            modalIndex={modalIndex}
            component={'li'}
            product={product}
            onProductClick={onProductClick}
            onButtonClick={onButtonClick}
            buttonValue={buttonValue}
            multiSelect={multiSelect}
            disablePrice={disablePrice}
            push={push}
            goBack={goBack}
          />
        )
      })
  }

  render () {
    const { isLoading, handleLoadMore, hasMore, classes } = this.props

    return (
      <div>
        <Grid className={classes.listContainer} container spacing={0} component={'ul'}>
          {this.renderGridItems()}
        </Grid>
        {/* <ScrollToTopButton /> */}
        <div className={classes.loadMoreContainer}>
          <LoadMoreButton
            hasMore={hasMore}
            isLoading={isLoading}
            loadNext={handleLoadMore}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ProductsGrid)
