import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../config'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import FullScreenProgress from '../../../components/FullScreenLoader'
import Waitlist from '../../../components/Waitlist'

import style from './style'

class ProductWaitlistScreen extends PureComponent {
  render () {
    const { classes, loadMore, hasMore, waitlist = [], onClickDelete, onClickSendMessage } = this.props
    return (
      <div className={classes.root}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <Heading uppercase component={'h1'}>{translations('Waitlist')}</Heading>
          )}
        />
        <Waitlist
          loadMore={loadMore}
          hasMore={hasMore}
          waitlist={waitlist}
          onClickDelete={onClickDelete}
          onClickSendMessage={onClickSendMessage}
        />
      </div>
    )
  }
}

ProductWaitlistScreen.propTypes = {
  getProductWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(ProductWaitlistScreen)
)
