import React from 'react'
import _ from 'lodash'
import StockLevelIndicator from '../../../../../components/StockLevelIndicator'
import { stockLevel, translations } from '../../../../../config'

const EcommerceStock = (props) => {
  const { ecommerceStock } = props
  const ecommerceStore = ecommerceStock && ecommerceStock.length > 0 ? ecommerceStock[0] : {}
  const storeName = _.get(ecommerceStore, 'store.name')
  switch (true) {
    case ecommerceStock >= stockLevel.inStockLevel:
      return <StockLevelIndicator label={translations('Available to Order', { storeName })} icon='greenCheckIcon' />
    case ecommerceStock === stockLevel.lowStockHighLevel:
      return <StockLevelIndicator label={translations('Low Availability to Order', { storeName })} icon='orangeCheckIcon' />
    case ecommerceStock === stockLevel.lowStockLowLevel:
      return <StockLevelIndicator label={translations('Low Availability to Order', { storeName })} icon='orangeCheckIcon' />
    case ecommerceStock === stockLevel.outOfStockLevel:
      return <StockLevelIndicator label={translations('Not Available to Order', { storeName })} icon='redCrossIcon' />
    default:
      return <></>
  }
}

export default EcommerceStock
