import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ImageBox from '../../../components/ImageBox'
import { getImage } from '../../../components/Images'
import P from '../../../components/P'

import { translations } from '../../../config'
import currencyFormatter from '../../../formatters/currencyFormatter'

import style from './style'

const noImagePlaceholder = getImage('imageNotAvailableImg')

const format = currencyFormatter.format
const trashIcon = getImage('trashIcon')
const basketIcon = getImage('basketIcon')

const _Text = props => {
  const { bold, underline, strikethrough, classes, className, onClick, ...rest } = props
  const pClassName = classNames(
    className,
    classes.text,
    { [classes.bold]: bold },
    { [classes.underline]: underline },
    { [classes.strikethrough]: strikethrough },
    { [classes.pointer]: onClick }
  )

  return <P {...rest} onClick={onClick} className={pClassName} />
}
const Text = withStyles(style)(_Text)

class WishListItem extends Component {
  onClickTrash = e => {
    e.stopPropagation()

    const { currentOrderCustomerId, currentOrderCustomerWishList, id, currentCustomerId, selectedVariantId } = this.props
    let wishlistUpdated = []

    if (selectedVariantId) {
      wishlistUpdated = currentOrderCustomerWishList.filter(prod => prod.selectedVariantId !== selectedVariantId)
    } else {
      wishlistUpdated = currentOrderCustomerWishList.filter(prod => prod.id !== id)
    }
    this.props.removeWishListProduct({
      id: currentOrderCustomerId,
      details: { wishlist: wishlistUpdated }
    }, currentCustomerId)
  }

  onClickAddToBasket = e => {
    e.stopPropagation()

    const { products, productVariants, categoryId, selectedVariantId, id } = this.props
    const productObj = products.find(ele => ele.id === id)
    productObj.categoryId = categoryId
    productObj.variant = productVariants.find(ele => ele.id === selectedVariantId)

    this.props.addWishListProductToBasket({
      product: productObj,
      quantity: 1
    })
  }

  renderPrice = () => {
    const {
      groupNowPrice,
      textType,
      classes,
      customerFacing
    } = this.props

    if (customerFacing) {
      return (
        <div className={classNames(classes.unitPriceEdit, classes.priceLine)}>
          <Text value={`${translations('Price')}:`} type={textType} />
          <Text value={`${format(groupNowPrice)}`} type={textType} className={classes.totalPrice} />
        </div>
      )
    }
  }

  render () {
    const {
      name,
      classes,
      images,
      textType,
      gap,
      price,
      hideUnpurchasedItems,
      unsold,
      selectedVariantId,
      productVariants,
      currencyCode,
      details
    } = this.props
    const derivedDiscount = details.discounts && details.discounts[currencyCode] ? parseFloat(details.discounts[currencyCode]) : 0
    const calculatedPrice = { code: currencyCode, value: parseFloat(price.value) - derivedDiscount }

    if (hideUnpurchasedItems && unsold) {
      return null
    }

    const variantName = selectedVariantId ? productVariants.find(ele => ele.id === selectedVariantId).name : null
    const image = _.get(images, '0') || noImagePlaceholder
    const trashIconClasses = { root: classes.trashIconButton }
    const hasSelectedVariant = !!productVariants && !!selectedVariantId
    return (
      <div>

        <div
          className={classNames(classes.container)}
        >
          <div className={classes.topContent}>
            <div className={classes.leftCol}>
              <ImageBox className={classNames(classes.image)} src={image} contain />

            </div>
            <div className={classes.rightCol}>
              <div className={classes.top}>
                <div className={classNames(classes.topText)}>
                  <Text type={textType} value={name} bold />
                  {/* keep this div for styling purposes */}
                  <div />
                </div>
              </div>
              {gap && <div className={classes.gap} />}
              <div className={classes.bottom}>
                <div className={classNames(classes.bottomInner)}>

                  {variantName && <Text type={textType} value={variantName} className={classes.variant} />}
                  {/* <div className={classes.unitPriceEdit}>
                    <Text value={`${translations('Unit Price')}: `} type={textType} />
                    <Text
                      className={classes.price}
                      value={format(calculatedPrice)}
                    />
                  </div> */}

                  {/* {this.renderPrice()} */}
                </div>
              </div>
            </div>

            {hasSelectedVariant && <IconButton classes={trashIconClasses} onClick={this.onClickAddToBasket}>
              <img
                src={basketIcon}
                className={classes.trashIconImg}
                alt={translations('Add to basket')}
              />
            </IconButton>}
            {<IconButton classes={trashIconClasses} onClick={this.onClickTrash}>
              <img
                src={trashIcon}
                className={classes.trashIconImg}
                alt={translations('Basket - Remove Button')}
              />
            </IconButton>}

          </div>
        </div>
      </div>
    )
  }
}

WishListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  textType: PropTypes.string.isRequired,
  gap: PropTypes.bool.isRequired,
  price: PropTypes.object.isRequired,
  editablePrices: PropTypes.bool,
  nowPrice: PropTypes.object,
  refund: PropTypes.bool,
  hasRefundPermission: PropTypes.bool,
  checked: PropTypes.bool,
  service: PropTypes.bool,
  customerFacing: PropTypes.bool,
  hideUnpurchasedItems: PropTypes.bool,
  unsold: PropTypes.bool,
  groupNowPrice: PropTypes.object,
  currencyCode: PropTypes.string.isRequired,
  currentOrderCustomerWishList: PropTypes.array.isRequired,
  currentOrderCustomerId: PropTypes.string,
  order: PropTypes.object.isRequired,
  bold: PropTypes.string,
  underline: PropTypes.string,
  strikethrough: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  removeWishListProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired
}

WishListItem.defaultProps = {
  images: null,
  editablePrices: false,
  refund: false,
  hasRefundPermission: false,
  discounMode: false,
  checked: false,
  service: false,
  customerFacing: false,
  hideUnpurchasedItems: false,
  unsold: false,
  bold: null,
  underline: null,
  strikethrough: null,
  className: null,
  currentOrderCustomerId: null
}

export default withStyles(style)(WishListItem)
