import { createStore, applyMiddleware, combineReducers } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import promiseMiddleware from '../middleware/redux-promise'
import withReduxEnhancer from 'addon-redux/enhancer'
import { store as authStore, middleware as authMiddleware, constants as authConstants } from '../modules/auth'
import { middleware as routingMiddleware } from '../modules/routing'
import { middleware as googleAnalyticsMiddleware } from '../modules/googleAnalytics'
import { middleware as analyticsMiddleware } from '../modules/analytics'
import { middleware as customersMiddleware, store as customersStore } from '../modules/customers'
import { middleware as customerDetailsMiddleware, store as customerDetailsStore } from '../modules/customerDetails'
import { middleware as rolesMiddleware, store as rolesStore } from '../modules/roles'
import { middleware as categoriesMiddleware, store as categoriesStore } from '../modules/categories'
import { store as appStore, middleware as appMiddleware } from '../modules/app'
import { store as networkStore } from '../modules/network'
import { store as productsStore, middleware as productsMiddleware } from '../modules/products'
import { store as productDetailsStore, middleware as productDetailsMiddleware } from '../modules/productDetails'
import { store as usersStore, middleware as usersMiddleware } from '../modules/users'
import { store as userDetailsStore, middleware as userDetailsMiddleware } from '../modules/userDetails'
import { store as followingStore, middleware as followingMiddleware } from '../modules/following'
import { store as storesStore, middleware as storesMiddleware } from '../modules/stores'
import { store as activitiesStore, middleware as activitiesMiddleware } from '../modules/activities'
import { store as currentOrderStore, middleware as currentOrderMiddleware } from '../modules/currentOrder'
import { store as currentOrderSalesforceStore, middleware as currentOrderSalesforceMiddleware } from '../modules/currentOrderSalesforce'
import { store as currentAppointmentStore, middleware as currentAppointmentMiddleware } from '../modules/currentAppointment'
import { store as checkoutFlowStore, middleware as checkoutFlowMiddleware } from '../modules/checkoutFlow'
import { store as createMessageStore, middleware as createMessageMiddleware } from '../modules/createMessage'
import { store as customerMessagesStore, middleware as customerMessagesMiddleware } from '../modules/customerMessages'
import { store as userMessagesStore, middleware as userMessagesMiddleware } from '../modules/userMessages'
import { store as storeDetailsStore  } from '../modules/storeDetails'
import { store as notificationsStore, middleware as notificationsMiddleware, subscribers as notificationSubsribers } from '../modules/notifications'
import { store as resourcesStore, middleware as resourcesMiddleware } from '../modules/resources'
import { store as ordersStore, middleware as ordersMiddleware } from '../modules/orders'
import { store as orderDetailsStore, middleware as orderDetailsMiddleware } from '../modules/orderDetails'
import { store as variantsStockStore } from '../modules/variantsStock'
import { store as regionsStore, middleware as regionsMiddleware } from '../modules/regions'
import { store as departmentsStore, middleware as departmentsMiddleware } from '../modules/departments'
import { store as pollQuestionsStore, middleware as pollQuestionsMiddleware } from '../modules/pollQuestions'
import { store as pollResultsStore, middleware as pollResultsMiddleware } from '../modules/pollResults'
import { store as feedbackStore, middleware as feedbackMiddleware } from '../modules/feedback'
import { store as inspirationsStore, middleware as inspirationsMiddleware } from '../modules/inspirations'
import { store as inspirationDetailsStore, middleware as inspirationDetailsMiddleware } from '../modules/inspirationDetails'
import { store as messageDetailsStore, middleware as messageDetailsMiddleware } from '../modules/messageDetails'
import { store as productWaitlistStore, middleware as productWaitlistMiddleware } from '../modules/productWaitlist'
import { store as appointmentsStore, middleware as appointmentsMiddleware } from '../modules/appointments'
import { store as offlineStore } from '../modules/offline'
import { store as productCataloguesStore } from '../modules/productCatalogues'
import { store as calendarsStore } from '../modules/calendars'
import { store as productListsStore } from '../modules/productLists'
import { store as productListDetailsStore } from '../modules/productListDetails'
import { store as configStore } from '../modules/config'
import { store as chatStore } from '../modules/chat'

import { store as eventsStore, middleware as eventsMiddleware } from '../modules/events'
import { store as eventDetailsStore, middleware as eventDetailsMiddleware } from '../modules/eventDetails'
import { store as eventWaitlistStore, middleware as eventWaitlistMiddleware } from '../modules/eventWaitlist'

import syncOfflineData from './syncOfflineData'
import monitorNetworkConnection from './monitorNetworkConnection'
import monkeyPatchNetworkAwareFetch from './monkeyPatchNetworkAwareFetch'
import { environment } from '../../config'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import handleRefreshToken from './handleRefreshToken'

// Add all reducers here
const reducers = (history) => ({
  activities: activitiesStore,
  app: appStore,
  auth: authStore,
  categories: categoriesStore,
  checkoutFlow: checkoutFlowStore,
  currentOrder: currentOrderStore,
  currentOrderSalesforce: currentOrderSalesforceStore,
  currentAppointment: currentAppointmentStore,
  customerDetails: customerDetailsStore,
  customers: customersStore,
  departments: departmentsStore,
  feedback: feedbackStore,
  following: followingStore,
  form: formReducer,
  createMessage: createMessageStore,
  inspirationDetails: inspirationDetailsStore,
  inspirations: inspirationsStore,
  messageDetails: messageDetailsStore,
  customerMessages: customerMessagesStore,
  userMessages: userMessagesStore,
  network: networkStore,
  notifications: notificationsStore,
  orderDetails: orderDetailsStore,
  orders: ordersStore,
  pollQuestions: pollQuestionsStore,
  pollResults: pollResultsStore,
  productDetails: productDetailsStore,
  products: productsStore,
  regions: regionsStore,
  resources: resourcesStore,
  roles: rolesStore,
  router: connectRouter(history),
  storeDetails: storeDetailsStore,
  stores: storesStore,
  userDetails: userDetailsStore,
  users: usersStore,
  variantsStock: variantsStockStore,
  productWaitlist: productWaitlistStore,
  events: eventsStore,
  eventDetails: eventDetailsStore,
  eventWaitlist: eventWaitlistStore,
  appointments: appointmentsStore,
  offline: offlineStore,
  productCatalogues: productCataloguesStore,
  calendars: calendarsStore,
  productListDetails: productListDetailsStore,
  productLists: productListsStore,
  config: configStore,
  chat: chatStore
})

// Add all middleware here
const middleware = [
  routingMiddleware.loginSuccessRouteRedirectMiddleware,
  routingMiddleware.selectStoreRouteRedirectMiddleware,
  routingMiddleware.selectDepartmentRouteRedirectMiddleware,
  routingMiddleware.getSSOAuthContextSuccessMiddleware,
  // causing a bug with login
  // routingMiddleware.showLoginWithNoSelectedStore,

  authMiddleware.loginSuccessPreselectStoreMiddleware,
  authMiddleware.loginUsingRecentUserMiddleware,
  authMiddleware.selectStoreCacheSelectedStoreMiddleware,
  authMiddleware.selectDepartmentCacheSelectedStoreMiddleware,
  authMiddleware.updateAuthUserOnUserProfileUpdateMiddleware,
  authMiddleware.accountLockedMiddleware,
  authMiddleware.closeModalsOnLogoutMiddleware,
  authMiddleware.resetPassword,
  authMiddleware.setAuthContextWhenSelectingStoreOrDepartment,
  authMiddleware.ssoLoginSuccessMiddleware,
  authMiddleware.ssoLoginErrorMiddleware,
  authMiddleware.fetchSelectedStoreDetailsMiddleware,

  appMiddleware.logoutSuccessCloseSideMenuMiddleware,
  appMiddleware.appReadyMiddleware,
  appMiddleware.onOffLineApiFailMiddleware,
  appMiddleware.openBasketMiddleware,
  appMiddleware.setDefaultAppCurrencyOnSetAuthContext,

  productsMiddleware.loadInitialProductsMiddleware,
  productsMiddleware.resetStoreOnUserLogout,
  productsMiddleware.resetProductsOnProductPageLoad,
  productsMiddleware.resetFiltersOnCategoryChangeMiddleware,
  productsMiddleware.clearProductSearch,

  productDetailsMiddleware.loadProductDetailsOnRouteMiddleware,
  productDetailsMiddleware.loadProductGroupProductsOnProductLoadedMiddleware,
  productDetailsMiddleware.resetMediaIndex,
  productDetailsMiddleware.restProductTab,
  productDetailsMiddleware.loadStoresOnRouteMiddleware,
  // analytics
  analyticsMiddleware.login,
  analyticsMiddleware.productSearch,
  analyticsMiddleware.userSearch,
  analyticsMiddleware.customerSearch,
  analyticsMiddleware.viewProductDetails,
  analyticsMiddleware.addProductToBasket,
  analyticsMiddleware.viewMyAccount,
  analyticsMiddleware.viewBasket,
  analyticsMiddleware.registerCustomer,
  analyticsMiddleware.resetPassword,
  analyticsMiddleware.addToWishlist,
  analyticsMiddleware.addProductToMessage,
  analyticsMiddleware.productDetailsTabChange,
  analyticsMiddleware.changeProductQuantityInOrder,
  analyticsMiddleware.pdpSelectionAfterSearch,
  // customer
  customersMiddleware.createCustomerSuccessMiddleware,
  customersMiddleware.updateCustomerListOnCustomerUpdateMiddleware,
  customersMiddleware.resetStoreOnUserLogout,
  // customer detail
  customerDetailsMiddleware.loadCustomerMiddleware,
  customerDetailsMiddleware.updateCustomerNotesMiddleware,
  customerDetailsMiddleware.editCustomerNoteMiddleware,
  customerDetailsMiddleware.followCustomerMiddleware,
  customerDetailsMiddleware.unfollowCustomerMiddleware,
  customerDetailsMiddleware.updateCustomerWishlistMiddleware,
  customerDetailsMiddleware.loadUsersOnNavigateToFullCustomerProfile,
  customerDetailsMiddleware.checkAnonomiseCustomerHasCurrentOrder,
  customerDetailsMiddleware.updateCustomerSuccessMiddleware,
  customerDetailsMiddleware.appointmentContentAddToWishlistMiddleware,
  // users
  usersMiddleware.loadInitialUsersMiddleware,
  usersMiddleware.updateUserListOnUserProfileUpdateMiddleware,
  usersMiddleware.updateUserListOnUserProfileLoadMiddleware,
  usersMiddleware.resetStoreOnUserLogout,
  // roles
  rolesMiddleware.loadRolesOnRouteToUserMiddleware,
  rolesMiddleware.loadRolesOnRouteToUsersMiddleware,
  // categories
  categoriesMiddleware.loadCategoriesMiddleware,
  // user details
  userDetailsMiddleware.createUserSuccessMiddleware,
  userDetailsMiddleware.loadUserMiddleware,
  userDetailsMiddleware.showChangePasswordModalMiddleware,
  // following
  followingMiddleware.loadFollowingMiddleware,
  followingMiddleware.addToFollowingMiddleware,
  followingMiddleware.removeFromFollowingMiddleware,
  // stores
  storesMiddleware.fetchStoresMiddleware,
  // activities
  activitiesMiddleware.loadActivitiesMiddleware,
  activitiesMiddleware.loadClientBookActivities,
  // current order
  currentOrderMiddleware.addProductMiddleware,
  // currentOrderMiddleware.removeProductMiddleware,
  currentOrderMiddleware.restoreMiddleware,
  currentOrderMiddleware.setDeliveryOptionMiddleware,
  currentOrderMiddleware.updateOrderCustomerOnCustomerUpdateMiddleware,
  currentOrderMiddleware.updateOrderCustomerUserRelationshipMiddleware,
  currentOrderMiddleware.recordRequestOnAddRequestSuccess,
  currentOrderMiddleware.clearOrderMiddleware,
  currentOrderMiddleware.customerAssignedToOrderMiddleware,
  currentOrderMiddleware.salesforceSyncOrderMiddleware,

  // current order salesforce
  currentOrderSalesforceMiddleware.salesforceBasketMiddleware,

  // currentAppointment
  currentAppointmentMiddleware.syncConsultationMiddleware,
  currentAppointmentMiddleware.addCustomerMiddleware,
  currentAppointmentMiddleware.removeProductFromOrderMiddleware,
  currentAppointmentMiddleware.addContentCheckIsLovedMiddleware,
  currentAppointmentMiddleware.clearBasketMiddleware,
  currentAppointmentMiddleware.updateWishlistOnBehalfOfCustomer,
  currentAppointmentMiddleware.showToastWhenCustomerAddsToBasket,
  currentAppointmentMiddleware.setCustomerToCurrentAppointment,

  // checkout flow
  checkoutFlowMiddleware.nextMiddleware,
  checkoutFlowMiddleware.handleRouteChangeMiddleware,
  checkoutFlowMiddleware.createCustomerSuccessMiddleware,
  // message details
  createMessageMiddleware.getRecipientMiddleware,
  createMessageMiddleware.addProductToMessageMiddleware,
  createMessageMiddleware.sendMessageMiddleware,
  createMessageMiddleware.navigateToMessageMiddleware,
  // customerMessages
  customerMessagesMiddleware.loadCustomerMessagesMiddleware,
  // userMessages
  userMessagesMiddleware.searchMessagesMiddleware,
  // notifications
  notificationsMiddleware.loadNotificationsMiddleware,
  notificationsMiddleware.getTotalNotificationsNotViewed,
  notificationsMiddleware.clearNotificationsOnScreenLoad,
  notificationsMiddleware.removeUnflaggedNotificationFromList,
  notificationsMiddleware.removeActionedNotificationFromList,
  // resources
  resourcesMiddleware.loadResourcesMiddleware,
  resourcesMiddleware.resourcesLinkLoadMiddleware,
  // orders,
  ordersMiddleware.loadOrdersMiddleware,
  ordersMiddleware.clearOrderFilterMiddleware,
  // order details
  orderDetailsMiddleware.loadOrderMiddleware,
  orderDetailsMiddleware.createCustomerModalCloseOnSuccessMiddleware,
  orderDetailsMiddleware.redirectAfterRefundToRefundOrderMiddleware,
  // regions
  regionsMiddleware.loginSuccessFetchRegionsMiddleware,
  regionsMiddleware.ssoLoginSuccessFetchRegionsMiddleware,
  // departments
  departmentsMiddleware.fetchDepartmentsMiddleware,
  // poll questions
  pollQuestionsMiddleware.loadPollQuestionsMiddleware,
  pollQuestionsMiddleware.reloadPollQuestionsOnPollQuestionInstanceDeleteMiddleware,

  // poll results
  pollResultsMiddleware.loadPollResultsMiddleware,
  pollResultsMiddleware.loadPollResultsOnPollQuestionInstanceDeleteMiddleware,

  // feedback
  feedbackMiddleware.sendFeedbackSuccessMiddleware,

  // inspirations
  inspirationsMiddleware.searchInspirationsMiddleware,
  inspirationsMiddleware.fetchInspirationsOnTabChangeMiddleware,
  inspirationsMiddleware.searchInspirationTagsMiddleware,

  // inspirationDetails
  inspirationDetailsMiddleware.fetchInspirationMiddleware,
  inspirationDetailsMiddleware.deleteInspirationSuccessMiddleware,

  // message details
  messageDetailsMiddleware.loadMessageDetailsMiddleware,

  // product waitlist
  productWaitlistMiddleware.fetchProductWaitlist,
  productWaitlistMiddleware.updateListOnDelete,
  productWaitlistMiddleware.clearWaitlistOnFreshFetch,
  productWaitlistMiddleware.fetchProductWaitlistForWaitlistScreen,
  productWaitlistMiddleware.fetchProductWaitlistForCustomerScreen,
  productWaitlistMiddleware.updateCustomerWailistSuccessMiddleware,

  // events
  eventsMiddleware.searchEventsMiddleware,
  eventsMiddleware.searchEventTagsMiddleware,

  // eventDetails
  eventDetailsMiddleware.fetchEventMiddleware,
  eventDetailsMiddleware.deleteEventSuccessMiddleware,

  // event waitlist
  eventWaitlistMiddleware.fetchEventWaitlist,
  eventWaitlistMiddleware.updateListOnDelete,
  eventWaitlistMiddleware.clearWaitlistOnFreshFetch,
  eventWaitlistMiddleware.fetchEventWaitlistForWaitlistScreen,
  eventWaitlistMiddleware.fetchEventWaitlistForCustomerScreen,

  // google tag manager via google analytics
  googleAnalyticsMiddleware.pageViewMiddleware,
  googleAnalyticsMiddleware.loginEventMiddleware,
  googleAnalyticsMiddleware.selectStoreMiddleware,
  googleAnalyticsMiddleware.selectDepartmentMiddleware,
  googleAnalyticsMiddleware.createCustomerMiddleware,
  googleAnalyticsMiddleware.customerModeMiddleware,
  googleAnalyticsMiddleware.orderCompleteMiddleware,
  googleAnalyticsMiddleware.orderAbandonedMiddleware,
  googleAnalyticsMiddleware.addProductToBasketMiddleware,
  googleAnalyticsMiddleware.messageSentMiddleware,
  googleAnalyticsMiddleware.messageReportedMiddleware,

  appointmentsMiddleware.fetchCustomerAppointments
]

const subscribers = [
  syncOfflineData,
  monitorNetworkConnection,
  monkeyPatchNetworkAwareFetch,
  handleRefreshToken,

  // notifications
  notificationSubsribers.getTotalNotificationsNotViewed
]

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ([
    ...(environment.PERSIST_AUTH ? ['auth'] : []),
    'currentOrder',
    'currentOrderSalesforce',
    'currentAppointment',
    'app',
    'regions',
    'offline',
    'config'
  ]),
  blacklist: ['checkoutFlow', 'message']
}

const appReducer = (history) => combineReducers(reducers(history))

const rootReducer = (history) => (state, action) => {
  // On logout reset all state apart from:
  // recentUsers
  // network state
  // offline state
  // remote config
  if (action.type === authConstants.LOGOUT) {
    state = {
      auth: {
        recentUsers: state.auth.recentUsers
      },
      network: {
        connected: state.network.connected
      },
      offline: state.offline,
      config: state.config
    }
  }
  return appReducer(history)(state, action)
}

const registerSubscribers = (subscriberList, store) => {
  subscriberList.forEach(subscriber => {
    const { getState, dispatch } = store
    subscriber({ getState, dispatch })
  })
}

export default ({ initialState, history }) => {
  const enhancers = []

  if ((window.env.REACT_APP_CONFIG_ENV || process.env.REACT_APP_CONFIG_ENV || process.env.NODE_ENV) !== 'production') {
    enhancers.push(withReduxEnhancer)
  }

  const store = createStore(
    persistReducer(persistConfig, rootReducer(history)),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        promiseMiddleware,
        thunkMiddleware,
        routerMiddleware(history),
        // logger,
        ...middleware
      ),
      ...enhancers
    )
  )
  const persistor = persistStore(store)

  registerSubscribers(subscribers, store)

  return { store, persistor }
}
