import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import moment from 'moment'
import FormContentBox from '../../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../../components/Form'
import style from '../../../../../components/Form/style'

import OrderCarousel from '../../../../../components/OrderCarousel'
import { Input, Dropdown } from '../../../../../components/EditableFields'
import Editable from '../../../../../components/Editable'
import { Checkbox } from '../../../../../components/Fields'

import { translations } from '../../../../../config'

const LoyaltyForm = FormContentBox('loyalty')
const dateFormat = 'YYYY-MM-DD'

const WrappedOrderListForm = Editable(({
  initialValues,
  editing,
  toggleEdit,
  isSubmitting,
  boxName,
  onSubmit,
  formId,
  saveDisabled,
  classes,
  lifetimeSpend,
  loyaltyTier,
  crmTier,
  expirationDate,
  availablePoints,
  editable
}) => {
  return (
    <LoyaltyForm
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={translations('Loyalty Box Name')}
      onSubmit={onSubmit}
      formId={formId}
      editable={editable}
    >
      <FormError />
      <FormBody>
        <OrderCarousel onClickEnabled={editable} title={translations('Orders Title')} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.field}>
              <Input
                label={translations('Loyalty Tier')}
                name='loyaltyType'
                editable={false}
                displayValue={_.capitalize(loyaltyTier)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.field}>
              <Input
                label={translations('CRM Loyalty Tier')}
                name='crmTier'
                editable={false}
                displayValue={crmTier ? _.capitalize(translations(crmTier)) : '-'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.field}>
              <Input
                label={translations('Loyalty Points')}
                name='availablePoints'
                editable={false}
                displayValue={availablePoints ? availablePoints : '-'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.field}>
              <Input
                label={translations('Points expiry date')}
                name='expirationDate'
                editable={false}
                displayValue={expirationDate ? moment(expirationDate).format(dateFormat) : '-'}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl>
              <Checkbox
                name='museOptIn'
                checked={_.get(initialValues, 'museOptIn', false) || loyaltyTier}
                disabled={_.get(initialValues, 'museOptIn', false) || loyaltyTier || (editable && !editing)}
                label={translations('Edit Customer Enroll Loyalty program')} />
            </FormControl>
          </Grid>
        </Grid>
      </FormBody>
    </LoyaltyForm>
  )
})

WrappedOrderListForm.propTypes = {
  loyaltyTier: PropTypes.string,
  lifetimeSpend: PropTypes.shape({
    value: PropTypes.string,
    code: PropTypes.string
  })
}

export default withStyles(style)(WrappedOrderListForm)
