import React from 'react'
import { flatten } from 'lodash'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import RightButtonContent from '../../Form/RightButtonContent'
import LeftButtonContent from '../../Form/LeftButtonContent'
import CenterButtonContent from '../../Form/CenterButtonContent'
import TextContent from '../../Form/TextContent'
import { withStyles } from '@material-ui/core/styles'

import styles from './style'

const ContentBoxFooter = ({ children, classes, className, smLeft = 3, smText = 6, smRight = 3 }) => {
  // not always multiple children
  const flatChildren = flatten([children])
  const rightButtonContent = flatChildren.find(child => child.type === RightButtonContent)
  const leftButtonContent = flatChildren.find(child => child.type === LeftButtonContent)
  const centerButtonContent = flatChildren.find(child => child.type === CenterButtonContent)
  const textContent = flatChildren.find(child => child.type === TextContent)
  const leftButtonClassName = classNames({
    // only hide if left button doesnt exist
    [classes.hideForSmall]: !leftButtonContent,
    [classes.cancelButton]: leftButtonContent
  })
  const rightButtonClassName = classNames({
    [classes.buttonGridItem]: true,
    // only hide if left button doesnt exist
    [classes.hideForExtraSmall]: !rightButtonContent
  })
  const centerButtonClassName = classNames({
    [classes.centerButtonGridItem]: true,
    // only hide if left button doesnt exist
    [classes.hideForExtraSmall]: !centerButtonContent
  })
  const containerClassName = classNames(classes.container, className)
  return (
    <div className={containerClassName}>
      <div className={classes.inner}>
        <Grid container spacing={2}>
          <Grid className={leftButtonClassName} item xs={12} sm={smLeft}>{leftButtonContent}</Grid>
          {centerButtonContent && <Grid item xs={12} sm={smText} className={centerButtonClassName} >{centerButtonContent}</Grid>}
          {textContent || !centerButtonContent && <Grid item xs={12} sm={smText} className={classes.centerContent}>{textContent}</Grid>}
          <Grid item xs={12} sm={smRight} className={rightButtonClassName} >{rightButtonContent}</Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(ContentBoxFooter)
