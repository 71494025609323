import { selectors as productDetailsSelectors } from '../productDetails'

export const getCurrentProductDetails = (state) => ({
  product: productDetailsSelectors.getProductForSelectedCurrency(state),
  careInstructions: productDetailsSelectors.getProductCareInstructions(state),
  sizeGuide: productDetailsSelectors.getSizeGuide(state),
  productSummary: productDetailsSelectors.getProductDescriptionSummary(state),
  displayPrice: productDetailsSelectors.getDisplayPrice(state),
  displayDiscount: productDetailsSelectors.getDiscountPrice(state),
  discountValue: productDetailsSelectors.getDiscountValue(state),
  originalPrice: productDetailsSelectors.getOriginalPrice(state),
  hasDiscount: productDetailsSelectors.hasDiscount(state)
})
