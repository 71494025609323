import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import BasketGrid from './BasketGrid'

const mapStateToProps = (state) => {
  const isConsultationReviewScreenOpen = appSelectors.getIsCustomerReviewBasketOpen(state)
  return {
    isConsultationReviewScreenOpen
  }
}

const mapDispatchToProps = dispatch => ({
  goToProduct: (product, isConsultationReviewScreenOpen) => e => {
    if (!isConsultationReviewScreenOpen) {
      dispatch(push(`/product/${product.productId || product.id}`))
      dispatch(appActions.toggleCustomerReviewBasket({ isOpen: false }))
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(BasketGrid)
