import { clientReporting } from "../../config"

const style = theme => ({
  advancedSearchButton: {
    display: 'flex',
    height: 48,
    width: '100%',
    justifyContent: 'center',
  },
})

export default style
