import { connect } from 'react-redux'
import _, { update } from 'lodash'
import {
  actions as currentOrderActions,
  selectors as currentOrderSelectors
} from '../../../store/modules/currentOrder'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as appSelectors } from '../../../store/modules/app'
import { selectors as customerSelectors, actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import getSelectedCurrencyPrice from '../../../helpers/getSelectedCurrencyPrice'
import getSelectedCurrencyDiscount from '../../../helpers/getSelectedCurrencyDiscount'
import store from '../../../store'
import { actions as productDetailsActions } from '../../../store/modules/productDetails'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'

import WishListItem from './WishListItem'
import { compose, withHandlers, withProps } from 'recompose'

const mapStateToProps = state => {
  const currencyCode = appSelectors.getAppCurrency(state)
  const hasRefundPermission = authSelectors.getHasRefund(state)
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const currentOrderCustomerWishList = _.get(currentOrderCustomer, 'wishlist', [])
  const currentOrderCustomerId = _.get(currentOrderCustomer, 'id', '')
  const currentCustomerId = customerSelectors.getCustomerId(state) || ''

  return {
    currencyCode,
    hasRefundPermission,
    order,
    currentOrderCustomerWishList,
    currentOrderCustomerId,
    currentCustomerId
  }
}
const mapDispatchToProps = (dispatch) => ({
  removeWishListProduct: (params, currentCustomerId) => {
    const currentOrderCustomerId = params.id
    dispatch(currentOrderActions.updateOrderWishlist(params))

    if (currentOrderCustomerId === currentCustomerId) {
      dispatch(customerDetailsActions.updateCustomerWishlist(params))
    }
  },
  addWishListProductToBasket: (params) => {
    const storeId = store.getState().auth.selectedStore.id
    return dispatch(productDetailsActions.fetchProduct({ id: params.product.id, storeId, checkAvailability: true }))
      .then(result => {
        const currentCurrency = store.getState().app.currencyCode
        // when adding whislist item to basket,
        // it should read the price from variantDetails rather than product details
        const variantDetails = params.product.productVariants.find(ele => ele.id === params.product.selectedVariantId)
        const updatedParams = {
          ...params,
          product: {
            ...params.product,
            price: getSelectedCurrencyPrice(variantDetails, currentCurrency),
            discount: getSelectedCurrencyDiscount(variantDetails, currentCurrency)
          }
        }

        dispatch(currentOrderActions.addProduct(updatedParams))
      })
      .catch(() => {
        modalService.action({
          title: translations('Error'),
          text: translations('Item no longer available'),
          actions: [
            {
              success: true,
              text: translations('OK'),
              primary: true,
              onClick: () => modalService.close()
            }
          ]
        })
      })
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ itemIndex, selectedLines = {} }) => {
    return { checked: selectedLines[itemIndex] === true }
  }),
  withHandlers({
  })
)(WishListItem)
