import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Image from '../../../../../../components/Image'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Button from '../../../../../../components/Button'
import Favorite from '@material-ui/icons/Favorite'
import P from '../../../../../../components/P'
import _ from 'lodash'
import currencyFormatter from '../../../../../../formatters/currencyFormatter'
import Chip from '../../../../../../components/Chip'
import { translations } from '../../../../../../config'
import media from '../../../../../../config/media'
import StarRating from '../../../../../../components/StarRating'
import { actions as currentAppointmentActions } from '../../../../../../store/modules/currentAppointment'
import { actions as currentOrderActions } from '../../../../../../store/modules/currentOrder'
import toastService from '../../../../../../services/toastService/toastService'
import { theme } from '../../../../../../config/theme'
import SelectVariant from '../../../../../Products/common/Results/ProductsGrid/ProductsGridItem/SelectVariant'
import Dropdown from '../../../../../../components/Fields/Dropdown'
import modalService from '../../../../../../services/modalService'

const MainDetailsGridContainer = styled(Grid)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: white;
`

const ImageBox = styled(Image)`
  max-width: 100%;
  height: 100px !important;
  ${media.greaterThan('md')`
    height: auto;
  `}
`

const NameText = styled(P)`
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  ${media.greaterThan('md')`
    margin-right: 20px;
  `}
`

const VariantText = styled(P)`
  margin: 1rem 0;
  text-align: left;
`

const PriceText = styled(P)`
  font-weight: bold;
  text-align: left;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.5rem;
  ${media.greaterThan('md')`
    margin-left: 0.5rem;
    padding-top: 0;
  `}
`

const ImageContainer = styled.div`
  position: relative;
`

const ServiceChip = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
`

const FavoriteButton = styled(MuiButton)`
  justify-content: flex-start;
  text-transform: none;
  text-decoration: underline;
  width: max-content;
  margin-top: 12px;
`

const FavoriteIcon = styled(Favorite)`
  color: ${theme.palette.mediumGrey.main};
  margin-right: 12px;
`

const BasketButtonsContainer = styled(Grid)`
  padding: 1rem;
`

const QuantityLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`

const QuantityLabel = styled(P)`
  margin-right: 1rem;
  font-size: 1rem;
  color: ${props => props.disabled ? theme.palette.mediumGrey.main : "inherit"};
`

const SnapshotDetails = (props) => {
  const {
    type,
    title,
    name,
    image,
    images = [],
    variant,
    priceToDisplay,
    price,
    nowPrice,
    isLoved,
    service,
    showBasketButtons,
    unsold,
    variants,
    quantity,
    canEditBasket,
    groupName,
    showProductRating
  } = props

  const dispatch = useDispatch()
  const imageToUse = images ? _.get(images, '0') : image
  const nameText = groupName || title || name
  const variantName = groupName ? name : variant && variant.name

  const addToWishlist = useCallback(() => {
    dispatch(currentAppointmentActions.customerToggleContentIsLoved({ type, details: props }))
    toastService.action({
      type: 'success',
      message: translations('Wishlist - update message for customer'),
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  })

  const addToBasket = useCallback(() => {
    if (!unsold) {
      // remove product
      dispatch(currentOrderActions.removeProduct({ product: props }))
    } else {
      // add product
      if (variants && variants.length > 1) {
        modalService.open({
          component: SelectVariant,
          smallModal: true,
          variants: variants,
          variant,
          title: translations('Select A Variant'),
          modalIndex: 1,
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Done'),
              onClick: (productWithVariant) => dispatch(currentOrderActions.addProduct({ product: productWithVariant, quantity: 1 }))
            },
            {
              text: translations('Cancel'),
              onClick: _.noop
            }
          ]
        })
      } else {
        dispatch(currentOrderActions.addProduct({
          product: {
            ...props,
            unsold: false
          },
          quantity: 1
        }))
      }
    }
  }, [unsold, variants])

  const changeQuantity = useCallback((e) => {
    dispatch(currentOrderActions.changeProductQuantity({ product: props, quantity: e.target.value }))
  }, [quantity])

  const addToBasketButtonColor = 'primary'
  const addToBasketButtonVariant = !unsold ? 'outlined' : 'contained'

  return (
    <MainDetailsGridContainer container>
      <Grid item xs={5}>
        <ImageContainer>
          <ImageBox src={imageToUse} />
          {service &&
            <ServiceChip>
              <Chip type={'service'}>{translations('Service')}</Chip>
            </ServiceChip>
          }
        </ImageContainer>
      </Grid>
      <Grid item xs={7}>
        <DetailsContainer>
          <NameText value={nameText} />
          <VariantText value={variantName} />
          <PriceText value={`${priceToDisplay ? currencyFormatter.format(priceToDisplay) : currencyFormatter.format(nowPrice || price)}`} />
          {showProductRating && (<StarRating reviewsAmount={100} />)}
          <FavoriteButton
            onClick={addToWishlist}
          >
            <FavoriteIcon isLoved={isLoved} />
            {isLoved ? translations('Added to wishlist') : translations('Add to wishlist')}
          </FavoriteButton>
        </DetailsContainer>
      </Grid>
      {showBasketButtons && (
        <Grid item xs={12}>
          <BasketButtonsContainer>
            <QuantityLabelContainer>
              <QuantityLabel
                disabled={unsold || !canEditBasket}
                value={`${translations('QTY')}`}
              />
              <Dropdown
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                noNullOption
                noErrorTextLabel
                disabled={unsold || !canEditBasket}
                value={quantity || 1}
                onChange={changeQuantity}
              />
            </QuantityLabelContainer>
            <Button
              color={addToBasketButtonColor}
              variant={addToBasketButtonVariant}
              fullWidth
              disabled={!canEditBasket}
              onClick={addToBasket}
            >
              {!unsold ? translations('Remove from bag') : translations('Add this item to your bag')}
            </Button>
          </BasketButtonsContainer>
        </Grid>
      )}

    </MainDetailsGridContainer>
  )
}

export default SnapshotDetails
