export default (product, selectedCurrency) => {
  if (!selectedCurrency) {
    return product.discount
  }

  const defaultCurrencySelected = _.get(product, 'discount.code') === selectedCurrency
  const selectedCurrencyDiscount = _.get(product, `details.discounts[${selectedCurrency}]`)

  if (selectedCurrencyDiscount) {
    return {
      code: selectedCurrency,
      value: selectedCurrencyDiscount
    }
  }

  if (defaultCurrencySelected) {
    return product.discount
  }

  return {
    code: selectedCurrency,
    value: '0'
  }
}