import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Button from '../../../components/Button'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import H2 from '../../../components/H2'

import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { translations } from '../../../config'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 20px auto;
`

const Option = styled(Button)`
  margin: 0 0 15px 0;
`

const ChalhoubPayment = ({
  next,
  editMode,
  currentOrderCustomer,
  deliveryType
}) => {
  const dispatch = useDispatch()

  const handleDistantSalesClick = async (salesChannel) => {
    await dispatch(currentOrderActions.updateOrder({
      salesChannel: salesChannel,
      status: 'awaiting_picking'
    }))

    next()
  }

  const handleSendToTillClick = async () => {
    await dispatch(currentOrderActions.updateOrder({
      status: 'awaiting_payment'
    }))

    next()
  }

  const handleChangeDistantSalesClick = async () => {
    await dispatch(currentOrderActions.updateOrder({
      status: 'awaiting_picking'
    }))

    next()
  }

  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<H2 value={translations('Checkout Header')} />}
      />
      <OptionsContainer>
        {
          editMode && (
            <Option
              big
              fullWidth
              buttonType={'primary'}
              onClick={() => handleChangeDistantSalesClick()}>
              {translations('Checkout Payment - Confirm Changes')}
            </Option>
          )
        }
        {!editMode && deliveryType === 'inStoreSale' && <Option
          big
          fullWidth
          buttonType={'primary'}
          onClick={() => handleSendToTillClick()}>
          {translations('Checkout Payment - Send To Till')}
        </Option>}
        {!editMode && !!currentOrderCustomer && (deliveryType === 'store' || deliveryType === 'home' || deliveryType === 'homeVisit') && <Option
          big
          fullWidth
          buttonType={'primary'}
          onClick={() => handleDistantSalesClick('distant-sale')}>
          {translations('Checkout Payment - Distant Sale')}
        </Option>}
      </OptionsContainer>
    </div>
  )
}

export default ChalhoubPayment
