import _ from 'lodash'

export const checkBasketContainsOrderedProducts = (basketItems) => {
  return basketItems.some(({ stock }) => stock === 'N')
}

export const checkItemIsOrderedProduct = (item) => {
  return _.get(item, 'stock', '') === 'N'
}

export const endlessAisleZeroStockCheck = (items) => {
  return _.some(items, (item) => {
    const itemStock = _.get(item, 'totalStock', 0)
    const isItemEndlessAisle = checkItemIsOrderedProduct(item)
    const stockCheck = isItemEndlessAisle && itemStock <= 0
    return stockCheck
  })
}
