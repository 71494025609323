import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '../Fields/Input'
import H2 from '../H2'
import Button from '../Button'
import ButtonsContainer from '../ButtonsContainer'
import FormSubmit from '../Form/FormSubmit'
import style from './style'
import modalService from '../../services/modalService'

class TextInputModalOverlay extends Component {
  state = {
    value: ''
  }
  onCancel = () => {
    const { modalIndex = 0 } = this.props
    modalService.close({ modalIndex })
  }
  onSubmit = () => {
    const { modalIndex = 0, onSubmit } = this.props
    const { value } = this.state
    onSubmit && onSubmit(value)
    modalService.close({ modalIndex })
  }
  onChange = value => {
    this.setState({ value })
  }
  _renderButtons = () => {
    const { submitText = 'Submit' } = this.props
    return (
      <Fragment>
        <Button
          onClick={this.onCancel}
          buttonType={'white'}
        >
          {translations('Cancel')}
        </Button>
        <FormSubmit
          buttonType={'primary'}
          noContainer
          onClick={this.onSubmit}
        >
          {translations(submitText)}
        </FormSubmit>
      </Fragment>
    )
  }
  render() {
    const {
      title,
      classes,
      buttonDirection,
      inputLabel
    } = this.props
    const { value } = this.state
    return (
      <div className={classes.container}>
        {title ? <H2 value={title} className={classes.title} /> : null}
        <FormControl fullWidth>
          <Input multiline value={value} onChange={this.onChange} label={translations(inputLabel)} />
        </FormControl>
        <ButtonsContainer
          className={classes.buttonsContainer}
          align='right'
          isModal
          buttonDirection={buttonDirection}
        >
          {this._renderButtons()}
        </ButtonsContainer>
      </div>
    )
  }
}

TextInputModalOverlay.propTypes = {
  modalIndex: PropTypes.number,
  onSubmit: PropTypes.func

}

export default withStyles(style)(TextInputModalOverlay)
