import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { TextField, Paper as MUIPaper, Chip, CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import Button from '../Button'
import MessagesList from './MessagesList'
import { translations } from '../../config'

const Paper = styled(MUIPaper)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  ${(props) => `${props.theme.breakpoints.up('sm')}
    {
      width: 400px;
      height: 450px;
    }`
  }
`

const MessageInputContainer = styled.div`
  border-top: 1px solid #D1D1D1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`

const ScrollContainer = styled.div`
  overflow: scroll;
  border: 1px solid #D1D1D1;
  height: 250px;
  margin: 0 1rem 1rem 1rem;
  flex: 1 0 auto;
`

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const MessageTextField = styled(TextField)`
  flex: 1 0 auto;
  margin-right: 1rem;
`

const TitleBar = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleText = styled.h2`
  margin: 0;
  font-size: 1rem;
`

const ChatWindow = ({ userIdentity, senderName, recipiantName, onSendClick, onPrevPageClick, messages, messagesMeta, messageInputText, pendingMessage, fetchingPrevMessages, twilioConnectError, messageSendError, connectionState, onInputChange, onClose, hasPrevPage }) => {
  const [pressed, setPressed] = useState(false)
  const scrollEl = useRef(null)

  useEffect(() => {
    // if hasNextPage is true, then this messages update is from using the 'load more' button
    // do not want to force scroll position to bottom in that case
    if (!_.get(messagesMeta, 'hasNextPage')) {
      const el = scrollEl.current
      if (el) {
        // timeout is to be more sure that the scrollHeight is including the height of the element with the new message
        setTimeout(() => {
          el.scrollTop = el.scrollHeight
        }, 100)
      }
    }
  }, [messagesMeta])

  const onKeyUp = useCallback((event) => {
    if (event.key === 'Enter') setPressed(false)
  })

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') event.preventDefault()
    if (event.key === 'Enter' && !pressed && onSendClick && !_.isEmpty(messageInputText)) {
      setPressed(true)
      onSendClick()
    }
  }, [onSendClick])

  const renderConnectionFailed = () => {
    return (
      <ProgressContainer>
        <Chip label={translations('Chat - failed to connect text')} />
      </ProgressContainer>
    )
  }

  const renderContent = () => {
    switch (connectionState) {
      case 'connecting':
        return (
          <ProgressContainer>
            <CircularProgress />
            <p>{translations('Chat - connecting text')}</p>
          </ProgressContainer>)
      case 'connected':
        return (<MessagesList
          prevButtonDisabled={fetchingPrevMessages}
          hasPrevPage={hasPrevPage}
          messages={messages}
          onPrevPageClick={onPrevPageClick}
          userIdentity={userIdentity}
          senderName={senderName}
          recipiantName={recipiantName}
        />)
      default:
        return renderConnectionFailed()
    }
  }

  return (
    <Paper elevation={3}>
      <TitleBar>
        <TitleText>{translations('Chat - title')}</TitleText>
        <IconButton
          onClick={onClose}
          aria-label={translations('Chat - close chat window button text')}
        >
          <CloseIcon />
        </IconButton>
      </TitleBar>

      <ScrollContainer ref={scrollEl}>
        {!twilioConnectError ? renderContent() : renderConnectionFailed()}
      </ScrollContainer>
      <MessageInputContainer>
        <MessageTextField
          value={messageInputText}
          onChange={onInputChange}
          disabled={connectionState !== 'connected'}
          multiline
          rowsMax={3}
          helperText={messageSendError}
          error={!!messageSendError}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <Button
          onClick={onSendClick}
          color='primary'
          disabled={!messageInputText.length || connectionState !== 'connected' || pendingMessage}
        >
          {translations('Chat - send button text')}
        </Button>
      </MessageInputContainer>
    </Paper>
  )
}

ChatWindow.propTypes = {
  userIdentity: PropTypes.string,
  senderName: PropTypes.string,
  recipiantName: PropTypes.string,
  onSendClick: PropTypes.func,
  onPrevPageClick: PropTypes.func,
  messages: PropTypes.array,
  messageInputText: PropTypes.string,
  pendingMessage: PropTypes.bool,
  fetchingPrevMessages: PropTypes.bool,
  twilioConnectError:  PropTypes.bool,
  messageSendError: PropTypes.string,
  connectionState: PropTypes.string,
  onInputChange: PropTypes.func,
  onClose: PropTypes.func,
  hasPrevPage: PropTypes.bool,
  messagesMeta: PropTypes.object
}

export default ChatWindow