import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import qs from 'qs'
import { history } from '../../../../store'

import { regionsCombinedSelectors } from '../../../../store/modules/combinedSelectors'

import OrderHeader from './OrderHeader'
import OrderProductContent from './OrderProductContent'
import OrderActions from './OrderActions'
import OrderProducts from './OrderProducts.new'
import OrderHeaderNew from './OrderHeader.new'
import OrderNotes from './OrderNotes'
import OrderFooter from './OrderFooter'

import Heading from '../../../../components/Heading'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import Container from '../../../../components/Container'
import Form from '../../../../components/Form'

import FullScreenProgress from '../../../../components/FullScreenLoader'

import {
  translations,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  getAppConfigForRoute,
  kanban as defaultKanbanConfig,
  salesforceOCAPI
} from '../../../../config'

import style from './style'
import formId from './formId'

const OrderForm = Form(formId)

const OrderProductsComp = (
  newOrderDetailsScreenEnabled
    ? OrderProducts
    : OrderProductContent
)

const OrderHeaderComp = (
  newOrderDetailsScreenEnabled
    ? OrderHeaderNew
    : OrderHeader
)

const OrderDetail = (props) => {
  const {
    order,
    failedToLoad,
    isPartialRefundOrder,
    classes,
    regionBrand
  } = props
  const checkoutConfig = useSelector(regionsCombinedSelectors.getCheckoutConfigForBrand) || {}
  const isGiftNoteEnabled = _.get(checkoutConfig, 'enableGiftNote', false)

  if (!order) {
    return <div className={classes.orderNotFound}><Heading component={'h1'} uppercase>{translations('Order not found')}</Heading></div>
  }

  // use the 'actionsFor' param to determine if standard order actions should be replaced for an order 
  // based on where the user came from. Used by kanban boards
  const params = qs.parse(history.location.search.slice(1))
  const actionsFor = params.actionsFor

  // Get the app config for the route and iterate through config.actions to create each button
  const routeConfig = getAppConfigForRoute(actionsFor, undefined, {}, regionBrand)
  const config = actionsFor ? { ...defaultKanbanConfig, ...routeConfig } : {}
  const useGroupedProducts = _.isBoolean(config.groupProducts) ? config.groupProducts : true
  return (
    <OrderForm
      onSubmit={_.noop}
    >
      <SubHeader leftContent={(<BackBar />)} centerContent={(<Heading component={'h1'} uppercase>{translations('Order Details')}</Heading>)} />
      {
        failedToLoad
          ? <Container className={classes.errorMessage} withMarginTop={!newOrderDetailsScreenEnabled}>
            <Heading component={'h2'} uppercase>{translations('Load Order Error')}</Heading>
          </Container>
          : <Container withMarginTop={!newOrderDetailsScreenEnabled} withMarginBottom={!newOrderDetailsScreenEnabled}>
            <OrderHeaderComp {...order} />
            <OrderProductsComp
              refund={isPartialRefundOrder}
              products={useGroupedProducts ? order.groupedProducts : order.products}
              order={order}
              config={config}
              useGroupedProducts={useGroupedProducts}
            />
            {(orderNotesEnabled && isGiftNoteEnabled) ? <OrderNotes order={order} /> : null}
            {newOrderDetailsScreenEnabled ? <OrderFooter /> : null}
            {!salesforceOCAPI.basketEnabled && <OrderActions config={config} />}
          </Container>
      }
    </OrderForm>
  )
}

const LoaderOrderDetail = FullScreenProgress(OrderDetail)

export default withStyles(style)(LoaderOrderDetail)
