import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

import ContentBox from '../../ContentBox'
import ContentBoxBody from '../../ContentBox/ContentBoxBody'
import ContentBoxFooter from '../../ContentBox/ContentBoxFooter'
import RightButtonContent from '../../Form/RightButtonContent'
import LeftButtonContent from '../../Form/LeftButtonContent'
import CenterButtonContent from '../../Form/CenterButtonContent'
import { FormBody, FormError } from '../../Form'
import SubmittingButton from '../../SubmittingButton'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'

import styles from './style'


class AdvSearchFormContentBox extends PureComponent {

  onCloseButtonClick = () => {
    const { onClose } = this.props    
    onClose()
  }

  onCancelButtonClick = () => {
    const { destroyForm, resetQuery, initialize, reset } = this.props
    // Initialise to reset initial values in case user fills in phone number on main screen before entering advanced search
    // reset form so changes are not persisted on cancel
    initialize({})
    reset()
    resetQuery()
    if (destroyForm) {
      destroyForm()
    }
  }

  submit = () => {
    // destroy form will not always exist
    const { onSubmit, searchCustomer, editing, destroyForm, onClose } = this.props
    if (editing) {
      const submitResult = onSubmit()
      if (submitResult) {
        onClose()
        if (destroyForm) {
          destroyForm()
        }
      }
    } else {
      return submitResult
    }
  }

  onChange = (e) =>{
    const {onFormChange} = this.props
    onFormChange(e.target.value, e.target.name)
  }

  handleRenderChildren(children) {
    const { editing, initialValues, error, extraProps } = this.props
    return _.chain([children])
      .flatten()
      .map(child => {
        switch (child.type) {
          case FormBody:
            return React.cloneElement(child, { editing, initialValues, extraProps, key: 'body' })
          case FormError:
            return React.cloneElement(child, { error, key: 'error' })
          default:
            return child
        }
      })
      .value()
  }

  render() {
    const {
      showFooter = true,
      classes,
      children,
      isSubmitting,
      isValid,
      editing,
      handleSubmit,
      editable,
      givenContentBoxClass,
      givenContentContainerClass,
      saveDisabled = false
    } = this.props

    return (
      <form onChange={this.onChange} onSubmit={handleSubmit(this.submit)}>
        <ContentBox
          givenContentContainerClass={givenContentContainerClass}
          givenContentClass={givenContentBoxClass || classes.contentContainer}>
          <ContentBoxBody>{this.handleRenderChildren(children)}</ContentBoxBody>
          {showFooter && <ContentBoxFooter>
            <LeftButtonContent>
              {editing && editable &&
                (
                  <SubmittingButton
                    type={'button'}
                    color='primary'
                    className={classes.cancelButton}
                    onClick={this.onCloseButtonClick}
                  >
                    {translations('Cancel')}
                  </SubmittingButton>
                )
              }
            </LeftButtonContent>
             <CenterButtonContent>
              <Grid container spacing={0}>
                {/* Buttons are rendered this way to stop the auto submit bug */}
                {editing && editable &&
                  (
                    <Grid item xs={12} className={classes.centerButtonGridItem} >
                      <SubmittingButton
                        type={'button'}
                        color='primary'
                        className={classes.cancelButton}
                        onClick={this.onCancelButtonClick}
                      >
                        {translations('Clear Search')}
                      </SubmittingButton>
                    </Grid>
                  )
                }
              </Grid>
            </CenterButtonContent> 
            <RightButtonContent>
              <Grid container spacing={0}>
                {/* Buttons are rendered this way to stop the auto submit bug */}
                {editing && editable &&
                  (
                    <Grid item xs={12} className={classes.editButtonGridItem} >
                      <SubmittingButton
                        type={'submit'}
                        color='primary'
                        className={classes.editButton}
                        disabled={!isValid || saveDisabled}
                        isSubmitting={isSubmitting}
                        onClick={this.onClick}
                      >
                        {translations('Search')}
                      </SubmittingButton>
                    </Grid>
                  )
                }
              </Grid>
            </RightButtonContent>
          </ContentBoxFooter>}
        </ContentBox>
      </form>
    )
  }
}

AdvSearchFormContentBox.propTypes = {
  boxName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  editing: PropTypes.bool.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  destroyForm: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  saveDisabled: PropTypes.bool,
  extraProps: PropTypes.shape({})
}

export default withStyles(styles)(AdvSearchFormContentBox)
