import React, { Component } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import { Table, TableHeader, TableRow, TableCell } from '../../../../../components/Table'
import Form from '../../../../../components/Form'
import { translations } from '../../../../../config'
import OrderProduct from './OrderProduct'
import style from './style'

class OrderProducts extends Component {
  renderDesktop = () => {
    const { productInfoLayout } = this.props
    return <Table
      header={<TableHeader>
        <TableCell headerCell sm={4} children={translations('item')} />
        {productInfoLayout.map(([key, cellWidth]) => {
          return <TableCell
            headerCell
            key={key}
            children={translations(`Order Product ${_.startCase(key)}`)}
            sm={cellWidth}
          />
        })}
      </TableHeader>}
      rows={this.renderProducts()}
    />
  }

  renderMobile = () => {
    return this.renderProducts()
  }

  renderProducts = () => {
    const { products = [], order, productInfoLayout, config, useGroupedProducts } = this.props
    return products.map(product => {
      return <OrderProduct
        key={_.get(product, 'id')}
        product={product}
        order={order}
        productInfoLayout={productInfoLayout}
        config={config}
        useGroupedProducts={useGroupedProducts}
      />
    })
  }

  render () {
    return <div>
      <Hidden smUp>{this.renderMobile()}</Hidden>
      <Hidden xsDown>{this.renderDesktop()}</Hidden>
    </div>
  }
}

export default withStyles(style)(OrderProducts)
