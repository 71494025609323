import digitalStoreSdk from '../../../digitalStoreSdk'
import couchbaseService from '../../../services/couchbaseService'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'
import { logger } from '../../../helpers'
import { groupedProductsEnabledPLP, offlineCatalogueEnabled } from '../../../config'

class ProductsActions {
  searchProductsFresh = ({ query, sort, category, filters }) => {
    logger.trace(`searchProductsFresh`)
    logger.debug({ query, sort, category })
    return ({
      type: constants.SEARCH_PRODUCTS_FRESH,
      sort,
      query,
      category,
      filters,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)
        const region = authSelectors.getUserSelectedRegionId(getState())
        const catalogue = authSelectors.getUserSelectedProductCatalogue(getState())
        const searchProductsCategory = category === undefined ? selectors.getCategory(state) : category
        const searchProductsSort = sort === undefined ? selectors.getSort(state) : sort
        const searchProductsQuery = query === undefined ? selectors.getQuery(state) : query
        const searchProductsFilters = filters === undefined ? selectors.getActiveFilters(state) : filters
        const size = selectors.getSize(state)
        const storeId = authSelectors.getUserSelectedStoreId(state)

        if (isConnected) {
          return digitalStoreSdk.products
            .searchProducts({
              region,
              catalogue: catalogue || undefined,
              grouped: groupedProductsEnabledPLP,
              query: searchProductsQuery || null,
              sort: searchProductsSort,
              filters: searchProductsFilters,
              category: searchProductsCategory ? searchProductsCategory.id : undefined,
              page: 1,
              size,
              store: storeId
            })
            .then(result => ({
              query: searchProductsQuery,
              sort: searchProductsSort,
              category: searchProductsCategory,
              ...result
            }))
        }

        // offline mode flow
        if(offlineCatalogueEnabled) {
          return couchbaseService.searchProducts({
            region,
            query: searchProductsQuery,
            sort: searchProductsSort,
            category: searchProductsCategory ? searchProductsCategory.id : undefined,
            page: 1,
            size
          })
        }
      }
    })
  }

  searchProductsNext = () => ({
    type: constants.SEARCH_PRODUCTS_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const { products: { page, query, size, sort, category, filters } } = getState()
      const region = authSelectors.getUserSelectedRegionId(getState())
      const catalogue = authSelectors.getUserSelectedProductCatalogue(getState())
      const searchProductsCategory = category === undefined ? selectors.getCategory(state) : category
      const searchProductsSort = sort === undefined ? selectors.getSort(state) : sort
      const searchProductsQuery = query === undefined ? selectors.getQuery(state) : query
      const searchProductsFilters = filters === undefined ? selectors.getActiveFilters(state) : filters
      const storeId = authSelectors.getUserSelectedStoreId(state)
      return digitalStoreSdk.products
        .searchProducts({
          grouped: groupedProductsEnabledPLP,
          region,
          catalogue: catalogue || undefined,
          page: page + 1,
          size,
          query: searchProductsQuery || null,
          sort: searchProductsSort,
          filters: searchProductsFilters,
          category: searchProductsCategory ? searchProductsCategory.id : undefined,
          store: storeId
        })
        .then(result => ({
          query,
          sort,
          category,
          ...result
        }))
    }
  })

  fetchFilters = ({ query, category }) => {
    return ({
      type: constants.FETCH_FILTERS,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)
        const region = authSelectors.getUserSelectedRegionId(getState())
        const catalogue = authSelectors.getUserSelectedProductCatalogue(getState())
        const searchProductsCategory = category === undefined ? selectors.getCategory(state) : category
        const size = selectors.getSize(state)

        if (isConnected) {
          return digitalStoreSdk.products
            .searchProducts({
              region,
              catalogue: catalogue || undefined,
              category: searchProductsCategory ? searchProductsCategory.id : undefined,
              page: 1,
              size
            })
        }
      }
    })
  }

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ProductsActions()
