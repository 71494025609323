import { salutationArray } from '../../components/Form/commonSchemas/salutationArray'
import _ from 'lodash'

const commonOnChange = (e, formData, setFormData, cityOptions) => {
  const newFormData = { ...e.formData }
  const changedField = Object.keys(newFormData)
    .find(key => newFormData[key] !== formData[key])

  if (changedField === 'city') {
    const { city } = newFormData
    const areas = cityOptions[city]

    if (areas && areas.length === 1) {
      _.set(newFormData, 'area', areas[0])
    } else {
      _.unset(newFormData, 'area')
    }
  }

  return setFormData(newFormData)
}

export const defaultSchemaConfig = (countryPhoneCodeOptions, t) => {
  return {
    customProperties: [
      {
        title: {
          title: `${t('label-title')}`,
          $ref: '#/definitions/salutations'
        }
      },
      {
        telephone: {
          title: `${t('label-telephone')}`,
          type: 'string'
        }
      }
    ],
    customDefinitions: [
      {
        salutations: {
          type: 'string',
          title: `${t('label-title')}`,
          oneOf: salutationArray.map((salutation) => {
            return {
              const: salutation,
              title: salutation
            }
          })
        }
      }
    ],
    customUiSchema: [
      {
        telephone: {
          'ui:widget': 'phoneNumberWidget',
          'ui:options': {
            areaCodes: countryPhoneCodeOptions
          }
        },
        'ui:order': [
          'title',
          'firstName',
          'lastName',
          'telephone',
          'addressSearch',
          'findAddressWidget',
          'address1',
          'city',
          'county',
          'country',
          'postCode',
          '*'
        ]
      }
    ],
    customFormData: {},
    customRequired: ['title', 'telephone']
  }
}

export const endlessAisleSchemaConfig = (userCountry, cityOptions, t) => {
  const defaultProperty = {
    properties: {
      city: {
        const: ''
      },
      area: {
        type: 'string',
        enum: [''],
        enumNames: [''],
        title: t('label-area'),
        readOnly: true
      }
    }
  }
  const areaProperties = _.map(cityOptions, (areas, city) => {
    return {
      properties: {
        city: {
          const: city
        },
        area: {
          type: 'string',
          title: `${t('label-area')}`,
          oneOf: areas.map((area) => {
            return {
              const: area,
              title: `${t(area)}`
            }
          })
        }
      }
    }
  })

  const propertiesToUse = [defaultProperty, ...areaProperties]

  return {
    customProperties: [
      {
        email: {
          title: `${t('label-email')}`,
          type: 'string',
          format: 'email'
        }
      },
      {
        city: {
          title: `${t('label-city')}`,
          $ref: '#/definitions/cities'
        }
      }
    ],
    customDefinitions: [
      {
        cities: {
          type: 'string',
          title: `${t('label-country')}`,
          default: '',
          oneOf: _.map(cityOptions, (areas, city) => {
            return {
              const: city,
              title: `${t(city)}`
            }
          })
        }
      }
    ],
    customDependencies: [
      {
        city: {
          oneOf: propertiesToUse
        }
      }
    ],
    customUiSchema: [
      {
        country: {
          'ui:readonly': true
        },
        address2: {
          'ui:widget': 'hidden'
        },
        postCode: {
          'ui:widget': 'hidden'
        },
        email: {
          'ui:options': {
            inputType: 'email'
          }
        },
        'ui:order': [
          'title',
          'firstName',
          'lastName',
          'telephone',
          'email',
          'addressSearch',
          'findAddressWidget',
          'address1',
          'city',
          'area',
          'county',
          'country',
          '*'
        ]
      }
    ],
    customFormData: {
      country: userCountry
    },
    overrideRequired: ['title', 'firstName', 'lastName', 'address1', 'city', 'area', 'country', 'email', 'telephone'],
    customOnChange: (e, formData, setFormData) => commonOnChange(e, formData, setFormData, cityOptions)
  }
}

export const distantSalesSchemaConfig = (userCountry, cityOptions, t) => {
  const defaultProperty = {
    properties: {
      city: {
        const: ''
      },
      area: {
        type: 'string',
        enum: [''],
        enumNames: [''],
        title: 'Area',
        readOnly: true
      }
    }
  }
  const areaProperties = _.map(cityOptions, (areas, city) => {
    return {
      properties: {
        city: {
          const: city
        },
        area: {
          type: 'string',
          title: `${t('label-area')}`,
          oneOf: areas.map((area) => {
            return {
              const: area,
              title: `${t(area)}`
            }
          })
        }
      }
    }
  })

  const propertiesToUse = [defaultProperty, ...areaProperties]

  return {
    customProperties: [
      {
        email: {
          title: `${t('label-email')}`,
          type: 'string',
          format: 'email'
        }
      },
      {
        city: {
          title: `${t('label-city')}`,
          $ref: '#/definitions/cities'
        }
      }
    ],
    customDefinitions: [
      {
        cities: {
          type: 'string',
          title: `${t('label-country')}`,
          default: '',
          oneOf: _.map(cityOptions, (areas, city) => {
            return {
              const: city,
              title: `${t(city)}`
            }
          })
        }
      }
    ],
    customDependencies: [
      {
        city: {
          oneOf: propertiesToUse
        }
      }
    ],
    customUiSchema: [
      {
        country: {
          'ui:readonly': true
        },
        address2: {
          'ui:widget': 'hidden'
        },
        postCode: {
          'ui:widget': 'hidden'
        },
        email: {
          'ui:options': {
            inputType: 'email'
          }
        },
        'ui:order': [
          'title',
          'firstName',
          'lastName',
          'telephone',
          'email',
          'addressSearch',
          'findAddressWidget',
          'address1',
          'city',
          'area',
          'county',
          'country',
          '*'
        ]
      }
    ],
    customFormData: {
      country: userCountry
    },
    overrideRequired: ['title', 'firstName', 'lastName', 'address1', 'city', 'area', 'country', 'email', 'telephone'],
    customOnChange: (e, formData, setFormData) => commonOnChange(e, formData, setFormData, cityOptions)
  }
}
