import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import { translations } from '../../../../config'
import { Input } from '../../../../components/Fields'
import Button from '../../../../components/Button'
import SubHeader from '../../../../components/SubHeader'
import H2 from '../../../../components/H2'
import BackBar from '../../../../components/BackBar'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'

import Form, { FormBody, FormError } from '../../../../components/Form'

import * as validators from '../../../../validators'

import styles from './style'

const createNoteFormId = 'createNoteForm'

const CreateNoteForm = Form(createNoteFormId)

const CreateNote = ({ customer, onSubmit, submitForm, classes, isFormValid,
  title = translations('Create Note'), initialValues, noteValue }) => {
  const customerFullName = `${customer.firstName} ${customer.lastName}`
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(customerFullName)} />
        )}
      />
      <div className={classes.contentContainer}>
        <ContentBox>
          <ContentBoxHeader>
            <TextContent><H2 value={title} /></TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            <CreateNoteForm onSubmit={onSubmit} initialValues={initialValues}>
              <FormError />
              <FormBody>
                <FormControl className={classes.textFormControlContainer}>
                  <Input
                    label={`${translations('Note')}*`}
                    name='note'
                    multiline
                    rows={1}
                    rowsMax={10}
                    validate={validators.required}
                  />
                </FormControl>
              </FormBody>
            </CreateNoteForm>
          </ContentBoxBody>
        </ContentBox>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          className={classes.createNoteButton}
          onClick={() => submitForm(createNoteFormId)}
          disabled={!isFormValid || !noteValue}
        >
          {translations('Save Note')}
        </Button>
      </div>
    </div>
  )
}

CreateNote.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isFormValid: PropTypes.bool.isRequired,
  title: PropTypes.string,
  initialValues: PropTypes.shape({}).isRequired,
  noteValue: PropTypes.string
}

export default withStyles(styles)(CreateNote)
