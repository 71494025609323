import { compose, withHandlers } from 'recompose'
import SelectCustomer from './SelectCustomer'

export default compose(
  withHandlers({
    onAnonymousCustomerClick: (props) => () => {
      props.onAnonymousCustomerClick({ anonymousReason: 'No Reason' })
    },
    onUnassignedOrderClick: (props) => () => {
      props.onAnonymousCustomerClick({ anonymousReason: 'unassigned' })
    }
  })
)(SelectCustomer)
