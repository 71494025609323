const gutterPx = 20

const baseStyle = theme => ({
  container: {
    backgroundColor: theme.palette.lightGrey.main,
    borderTop: `1px solid ${theme.palette.border.main}`,
    // height: `calc(100vh - ${theme.headerHeight + theme.subheaderHeight + theme.bottomBarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    overflow: 'hidden',
    flex: 1
  },
  mobileContainer: {
    backgroundColor: theme.palette.lightGrey.main,
    padding: '0 18px 0',
    paddingTop: 10
  },
  mobileInner: {
    paddingBottom: '18px'
  },
  tabsAndReportsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  tabsContainer: {
    maxWidth: '740px',
    backgroundColor: 'white',
    maxHeight: '100%',

    // this makes tab content scroll as desired
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  reportsContainer: {
    flexBasis: '800px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    margin: `0 -${gutterPx / 2}px`,
    padding: `0 ${gutterPx}px`,
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      justifyContent: 'center'
    }
  },
  displayNone: {
    display: 'none'
  },
  noFollowingTextContainer: {
    textAlign: 'center',
    maxWidth: '33em',
    margin: '30px auto 0',
    textTransform: 'uppercase',
    '& > div': {
      marginBottom: '1em'
    }
  },
  subheader: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  noOverflow: {
    overflow: 'hidden'
  }
})

const reportingStyle = theme => {
  const base = baseStyle(theme)
  return {
    ...base,
    tabsAndReportsContainer: {
      ...base.tabsAndReportsContainer,
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'center'
    },
    tabsContainer: {
      ...base.tabsContainer,
      width: '240px',
      minWidth: '240px'
    }
  }
}

const noReportingStyle = theme => {
  const base = baseStyle(theme)
  return {
    ...base,
    tabsAndReportsContainer: {
      ...base.tabsAndReportsContainer
    },
    tabsContainer: {
      ...base.tabsContainer,
      flex: 1,
      margin: '0 auto'
    }
  }
}

export {
  reportingStyle,
  noReportingStyle
}

const style = reportingStyle

export default style
