
const styles = theme => {
  return {
    container: {
      width: '33.3%',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    ratioContainer: {
      width: '100%',
      position: 'relative'
    },
    heading: {
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 0,
      fontWeight: 'bold'
    },
    unavailableIndicatorWrapper: {
      left: '0px',
      right: '0px',
      top: '15px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      height: '39px',
      paddingTop: '30px'
    },
    unavailableIndicator: {
      background: theme.palette.lightGrey.main,
      color: 'black',
      padding: '6px 16px',
      borderRadius: '20px',
      fontSize: '14px'
    },
    brand: {
      minHeight: 20,
      textAlign: 'center',
      marginBottom: 10,
      marginTop: 0
    },
    price: {
      textAlign: 'center',
      marginTop: 4
    },
    promotionalBadge: {
      padding: 3,
      backgroundColor: theme.palette.warning.main
    },
    promotionalBadgeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 18,
      marginTop: 10,
      marginBottom: 10
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      padding:'10px',
      paddingTop: '50px'
    },
    mainButton: {
      width: '100%'
    },
    headingContainer:{
      padding: '15px 50px',
      textAlign: 'center'
    },
    displayPriceRange: {
      fontSize: '17px',
      marginBotton: '10px'
    }
}
}

export default theme => styles(theme)
