import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import style from './style'
import FixedRatioContainer from '../FixedRatioContainer'
import FloatingBadge from '../FloatingBadge'
import Image from '../Image'
import H3 from '../H3'
import P from '../P'
import Button from '../Button'
import { translations } from '../../config'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { compose } from 'recompose'

const ProductCard = ({
  classes,
  onButtonClick,
  buttonString = '',
  product = {}
}) => {
  const { images = [], brand, name, promotionalText, preview } = product
  const image = _.get(images, '0')
  const ratio = useMediaQuery(theme => theme.breakpoints.down('xs')) ? 0.75 : 1.1

  return (
    <div
      className={classes.container}
    >
      <FixedRatioContainer ratio={ratio} containerClass={classes.ratioContainer}>
        {preview &&
          <FloatingBadge label={translations('Preview')} position={'topRight'} />
        }
        <Image className={classes.image} src={image} />
      </FixedRatioContainer>
      <div className={classes.headingContainer}>
        <H3 value={name} className={classes.heading} />
        <P value={brand} className={classes.brand} type='large' />
        {promotionalText &&
          <div className={classes.promotionalBadgeContainer}>
            <div className={classes.promotionalBadge} ><P value={promotionalText} type='promoText' /></div>
          </div>
        }
      </div>
      <Button
        disabled={product.disabled}
        color='primary'
        className={classes.mainButton}
        onClick={() => onButtonClick && onButtonClick()}
      >
        {buttonString}
      </Button>
    </div>
  )
}

ProductCard.propTypes = {

}

export default compose(
  withStyles(style)
)(ProductCard)
