import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'

import { actions as userMessagesActions, selectors as userMessagesSelectors } from '../../../store/modules/userMessages'
import { actions as messageDetailsActions } from '../../../store/modules/messageDetails'
import MessagesScreen from './MessagesScreen'
import modalService from '../../../services/modalService';
import MessageDetailsScreen from '../MessageDetailsScreen';

export default compose(
  connect(state => ({
    query: userMessagesSelectors.getQuery(state),
    isLoading: userMessagesSelectors.getIsLoading(state),
    messages: userMessagesSelectors.getResultsForStore(state),
    hasMore: userMessagesSelectors.getHasMore(state),
    noResults: userMessagesSelectors.getHasBeenSearchedWithNoResults(state),
    error: userMessagesSelectors.getHasBeenSearchedWithError(state)
  })),
  withHandlers({
    // search is debounced inside SearchBar component
    searchMessages: ({ dispatch }) => ({ query }) => {
      dispatch(userMessagesActions.searchMessagesFresh({ query }))
    },
    searchNextMessages: ({ dispatch }) => () => {
      dispatch(userMessagesActions.searchMessagesNext())
    },
    goToMessage: ({ dispatch }) => ({ id }) => {
      dispatch(messageDetailsActions.resetStore())
      dispatch(messageDetailsActions.fetchMessageDetails({ id }))
      modalService.open({
        component: MessageDetailsScreen,
        fullScreen: true,
        onGoBackClick: () => modalService.close()
      })
    }
  })
)(MessagesScreen)
