import React, { PureComponent } from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import {Box, Drawer, FormControl} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { translations, consultationsEnabled } from '../../../config'
import P from '../../../components/P'
import Button from '../../../components/Button'
import SafeAreaSpacer from '../../../components/SafeAreaSpacer'
import EditMode from '../../../components/EditMode'
import Tabs from '../../../components/Tabs'
import ConsultationWizard from '../../ConsultationWizard'
import ScanButton from './ScanButton'
import FullScreenButton from './FullScreenButton'
import BasketList from '../BasketList'
import WishList from '../WishList'
import BasketButtons from '../BasketButtons'
import style from './style'
import Avatar from '@material-ui/core/Avatar'
import ButtonGroup from '../../../components/ButtonGroup'
import Switch from '../../../components/Switch'
import TabbedContent from '../../../components/TabbedContent'
import { apps } from '../../../config'


class BasketMenu extends PureComponent {
  state = {
    tabValue: 'basket'
  }

  changeTab = (tabValue) => {
    this.setState({ tabValue })
  }

  renderBasketList = () => {
    const {
      groupedProducts,
      classes,
      isConsultationMode,
      customerNameDetails,
      removeCustomer,
      exchangeMode,
      basketType,
      editMode,
      orderNumber,
      stopEditingOrder
    } = this.props

    return (
      <div>
        <div className={classes.listContainer}>
          <BasketList
            products={groupedProducts}
            editableQuantities
            textType='large'
            gap
            clickable
            hideUndo={isConsultationMode}
          />
        </div>
        {customerNameDetails && (
          <div className={classes.customerNameContainer}>
            <div className={classes.customerName}>
              <Avatar className={classes.avatar}>{customerNameDetails.initials}</Avatar>
              <p>{customerNameDetails.fullName}</p>
            </div>
            {(!isConsultationMode && !this.props.editMode) && (
              <Button hyperlink className={classes.removeButton} onClick={removeCustomer}>{translations('Remove')}</Button>
            )}
          </div>
        )}
        {editMode ? <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} /> : null}

        <BasketButtons />
      </div>
    )
  }

  renderWishList = () => {
    const {
      currentOrderCustomer,
      classes,
      customerNameDetails,
      isConsultationMode,
      removeCustomer
    } = this.props
    return (currentOrderCustomer
      ? <div>
        <WishList
          products={_.get(currentOrderCustomer, 'wishlist') || _.get(currentOrderCustomer, 'details.wishlist', [])}
          textType='large'
          gap
          clickable
        />
        {customerNameDetails && (
          <div className={classes.customerNameContainer}>
            <div className={classes.customerName}>
              <Avatar className={classes.avatar}>{customerNameDetails.initials}</Avatar>
              <p>{customerNameDetails.fullName}</p>
            </div>
            {(!isConsultationMode && !this.props.editMode) && (
              <Button hyperlink className={classes.removeButton} onClick={removeCustomer}>{translations('Remove')}</Button>
            )}
          </div>
        )}
      </div>
      : <div className={classes.emptyWishListMessage}>
        {translations('Select Customer First')}
      </div>
    )
  }

  renderContent = () => {
    const { classes } = this.props

    if (this.state.tabValue === 'basket') {
      return <div className={classes.contentContainer} >{this.renderBasketList()}</div>
    } else if (this.state.tabValue === 'wishlist') {
      return <div className={classes.contentContainererClass} >{this.renderWishList()}</div>
    }
  }

  render () {
    const {
      isOpen,
      onClose,
      classes,
      clearOrder
    } = this.props

    const buttons = [
      {
        text: translations('Shopping Bag'),
        onSelect: () => this.changeTab('basket'),
        value: 'basket'
      },
      {
        text: translations('Wish List'),
        onSelect: () => this.changeTab('wishlist'),
        value: 'wishlist'
      }
    ]

    return <Drawer
      open={isOpen}
      onClose={onClose}
      anchor='right'
      classes={{ paper: classes.rootMenu }}
    >
      <div className={classes.container}>
        <div>
          <SafeAreaSpacer inset={'top'} />
          <FullScreenButton onClose={onClose} />
        </div>
        <ScanButton />

        {<FormControl className={classes.formControl}>
          <div className={classes.buttonGroup}>
            <Switch buttons={buttons} currentTabValue={this.state.tabValue} />
          </div>
        </FormControl>}
        <Box textAlign="right" mx={4}>
          <Button hyperlink onClick={clearOrder} className={classes.closeButton}>{translations('Clear All')}</Button>
        </Box>
        {this.renderContent()}

      </div>
    </Drawer>
  }
}

export default withStyles(style)(BasketMenu)
