import { withHandlers, compose } from 'recompose'
import SelectCustomer from '../../Customer/SelectCustomerView'
import { connect } from 'react-redux'
import { selectors as followingSelectors } from '../../../store/modules/following'

const mapStateToProps = state => {
  return {
    following: followingSelectors.getFollowingCustomersWithoutAnonymised(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onAnonymousCustomerClick: ({ onCustomerClick }) => () => {
      onCustomerClick()
    }
  })
)(SelectCustomer)
