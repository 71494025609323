import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Iframe from 'react-iframe'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { translations, createAppointmentEnabled } from '../../../../config'
import { actions as appointmentsActions } from '../../../../store/modules/appointments'
import { actions as usersActions } from '../../../../store/modules/users'
import { actions as storeDetailsActions } from '../../../../store/modules/storeDetails'
import { selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'

import modalService from '../../../../services/modalService'
import SubHeader from '../../../../components/SubHeader'
import H2 from '../../../../components/H2'
import BackBar from '../../../../components/BackBar'
import BottomBar from '../../../../components/BottomBar'
import Button from '../../../../components/Button'
import ButtonsContainer from '../../../../components/ButtonsContainer'
import LoaderContainer from '../../../../components/LoaderContainer'

import AppointmentsListing from './AppointmentsListing'

import { useIsFetchStoreLoading, useStoreCalendarOptions, useStoreCalendars } from '../../../../hooks/storeDetailsHooks'
import { useAppointments, useAppointmentsQuery } from '../../../../hooks/appointmentsHooks'
import { useCurrentStoreId } from '../../../../hooks/usersHooks'
import NewAppointmentScreen from './NewAppointmentScreen'
import SelectCalendarScreen from './SelectCalendarScreen'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AppointmentsDashboardScreen = () => {
  const dispatch = useDispatch()
  const appointments = useAppointments()
  const appointmentId = useSelector(currentAppointmentSelectors.getAppointmentId)
  const appointmentCustomerId = useSelector(currentAppointmentSelectors.getAppointmentCustomerId)
  const startConsultationPath = appointmentId && appointmentCustomerId ? `/consultations/store/${appointmentId}` : '/consultations/store/new'
  const storeCalendarOptions = useStoreCalendarOptions()
  const storeCalendars = useStoreCalendars()
  const currentStoreId = useCurrentStoreId()
  const isLoading = useIsFetchStoreLoading()
  const appointmentSearchQuery = useAppointmentsQuery()

  useEffect(() => {
    dispatch(usersActions.searchUsersFresh({ includeDeactivated: false, storeIds: [currentStoreId] }))
    dispatch(storeDetailsActions.fetchStore({ id: currentStoreId, includes: 'calendars' }))
  }, [])
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!storeCalendars) {
        dispatch(storeDetailsActions.fetchStore({ id: currentStoreId, includes: 'calendars' }))
      }
    }, 1500)
    return () => clearTimeout(timer)
  }, [storeCalendars, currentStoreId])

  const reloadAppointments = useCallback(() => {
    dispatch(appointmentsActions.fetchAppointmentsFresh({ query: appointmentSearchQuery }))
      .catch((error) => {
        console.log({ error })
      })
  }, [appointmentSearchQuery])

  const openNewAppointmentScreen = useCallback((calendarId) => {
    const calendarDetails = _.find(storeCalendars, calendar => calendar.id === calendarId)
    const bookingURL = _.get(calendarDetails, 'details.bookingURL')
    if (bookingURL) {
      modalService.open({
        zoomModal: true,
        onBackdropClick: () => {
          reloadAppointments()
        },
        component: () => (
          <Iframe url={bookingURL}
            width="100%"
            height="600px"
          />
        )
      })
    } else {
      modalService.open({
        wideModal: true,
        component: NewAppointmentScreen,
        calendarId,
        onSave: () => {
          modalService.close()
          reloadAppointments()
        },
        onCancelClick: () => {
          modalService.close()
        }
      })
    }
  }, [storeCalendars])

  const handleCreateNewAppointment = useCallback(() => {
    if (storeCalendarOptions.length > 1) {
      modalService.open({
        component: SelectCalendarScreen,
        onSelect: (calendarId) => {
          modalService.close()
          openNewAppointmentScreen(calendarId)
        }
      })
    } else {
      openNewAppointmentScreen(storeCalendarOptions[0].value)
    }
  }, [storeCalendarOptions])

  return (
    <Container>
      <h1 className='visuallyhidden'>{translations('Appointments Dashboard')}</h1>
      <Helmet><title>{translations('Appointments Dashboard')}</title></Helmet>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(translations('Appointments Dashboard'))} />
        )}
      />
      <LoaderContainer
        isLoading={isLoading}
        error={storeCalendarOptions.length ? null : translations('Your store is not subscribed to any calendars, please speak with a manager.')}>
        <AppointmentsListing
          hasMore={false}
          appointments={appointments} />
        {createAppointmentEnabled && (
          <BottomBar>
            <ButtonsContainer align='center'>
              <Button
                buttonType='primary'
                big
                onClick={handleCreateNewAppointment}
              >
                {translations('Create Appointment')}
              </Button>
              <Button
                buttonType='primary'
                big
                onClick={() => {
                  dispatch(push(startConsultationPath))
                }}
              >
                {translations('Start consultation')}
              </Button>
            </ButtonsContainer>
          </BottomBar>
        )}
      </LoaderContainer>
    </Container>
  )
}

export default AppointmentsDashboardScreen
