import _ from 'lodash'

import couchbaseService from '../../services/couchbaseService'
import { offlineCatalogueEnabled } from '../../config'

const syncOfflineData = ({ dispatch }) => {
  if(offlineCatalogueEnabled) {
    const couchbaseConnections = couchbaseService.getConnections()
    console.info(`CouchbaseService successfuly started with ${_.size(couchbaseConnections)} connections => ${_.join(_.keys(couchbaseConnections), ', ')}`)
  }
}

export default syncOfflineData
