import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import style from './style'

const SubHeader = props => {
  const {
    classes,
    className,
    leftContent,
    centerContent,
    rightContent,
    isLoading,
    absolute,
    customerSearch
  } = props

  const notFixed = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const containerClassName = classNames({
    [classes.container]: true,
    [classes.absolute]: absolute,
    [classes.notFixed]: notFixed,
    [className]: true
  })

  return (
    <Fragment>
      <div className={containerClassName}>
        <div className={classes.leftContent}>
          {leftContent}
        </div>
        <div className={classes.centerContent}>
          {centerContent}
        </div>
        <div className={classes.rightContent}>
          {rightContent}
        </div>
        {
          isLoading
            ? <div className={classes.loadingBar}>
              <LinearProgress mode='indeterminate' style={{ height: 2 }} />
            </div>
            : null
        }
      </div>
      {
        notFixed
          ? null
          : <div className={customerSearch ? classes.customerSearch : classes.fake} />
      }
    </Fragment>
  )
}

SubHeader.propTypes = {
  leftContent: PropTypes.node,
  centerContent: PropTypes.node,
  rightContent: PropTypes.node
}

export default withStyles(style)(SubHeader)
