import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import FormValueProvider from '../../../FormValueProvider'

import Wishlist from './Wishlist'

export default compose(
  FormValueProvider('wishlist'),
  withHandlers({
    onSubmit: ({ onSubmit }) => data => {
      const updatedData = {
        ...data,
        waitlist: data && data.wishlist ? data.wishlist.map((item) => {
          return {
            ...item,
            price: {
              ...item.price,
              value: parseFloat(_.get(item, 'price.value', 0)) - (parseFloat(_.get(item, 'details.discounts[item.price.code]', 0)))
            }
          }
        }) : []
      }
      const newData = {
        ...updatedData,
        submitType: 'wishlist'
      }
      return onSubmit(newData)
    }
  }),
  withPropsOnChange(
    ['formValues'],
    ({ formValues, initialFormValues }) => {
      const updatedFormValues = {
        ...formValues,
        waitlist: formValues && formValues.wishlist ? formValues.wishlist.map((item) => {
          return {
            ...item,
            price: {
              ...item.price,
              value: parseFloat(_.get(item, 'price.value', 0)) - (parseFloat(_.get(item, 'details.discounts[item.price.code]', 0)))
            }
          }
        }) : []
      }

      const wishlistValue = _.get(updatedFormValues, 'wishlist', [])
      const wishlistInitialValue = _.get(initialFormValues, 'wishlist', [])
      // do not allow a user to save an empty form the first time
      // They can save an empty form if the form had values in it before however (acts as delete)
      const saveDisabled = wishlistValue.length === 0 && wishlistInitialValue.length === 0
      return {
        saveDisabled
      }
    }
  )
)(Wishlist)
