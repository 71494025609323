import React from "react"
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import _ from "lodash"
import { withStyles } from '@material-ui/core/styles'

import { selectors as currentOrderSelectors } from './../../../../store/modules/currentOrder'

import Form, { FormBody } from '../../../../components/Form'
import style from './style'

import * as validators from '../../../../validators'

import { translations } from '../../../../config'


export const formId = 'notes'

const GiftNoteForm = Form(formId)

const smallTextLengthValidator = validators.maxLength(240, 'Text')

const GiftNoteSection = ({
  classes,
  editable,
  notesFormValues
}) => {
  const notes = useSelector(currentOrderSelectors.getCurrentOrderNotes)

  return <div className={classNames(classes.section)}>
    <div className={classNames(classes.inner)}>
    <GiftNoteForm
      initialValues={notesFormValues || { notes }}
      editing={editable}>
      <FormBody
        schema={[
          {
            id: 'notes',
            field: 'Input',
            props: {
              label: translations('Checkout - Gift Note'),
              name: 'notes',
              validate: [smallTextLengthValidator],
            }
          },
        ]}
        fullWidthFields />
    </GiftNoteForm>
    </div>
  </div>
}

export default withStyles(style)(GiftNoteSection)
