import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckboxComponent = ({
  label,
  value,
  onChange,
  givenClass,
  containerClass,
  disabled,
  checked,
  name
}) => {
  return <FormControlLabel
    classes={{ root: containerClass }}
    control={
      <Checkbox
        disabled={disabled}
        color={'primary'}
        checked={checked || value}
        value={String(value)}
        label={label}
        name={name}
        onChange={onChange}
        className={givenClass}
      />
    }
    label={label}
  />
}

CheckboxComponent.propType = {
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  givenClass: PropTypes.string,
  containerClass: PropTypes.string
}

export default CheckboxComponent
