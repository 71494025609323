import { compose } from 'recompose'
import { connect } from 'react-redux'
import MenuWidget from './MenuWidget'
import { getRegionBrand } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'

const mapStateToProps = state => {
  const brandName = getRegionBrand(state)
  const hubTileImages = getTheme(brandName).base.hubTileImage
  return {
    hubTileImages
  }
}

export default compose(
  connect(mapStateToProps)
)(MenuWidget)
