import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modules, i18n } from '@redant/retailos-ui'
import { productCatalogueFunctions } from '../dependencies'
import { selectors as appSelectors } from '../../store/modules/app'
import enProductCatalogue from '../i18n/en/ProductCatalogue.json'
import arProductCatalogue from '../i18n/ar/ProductCatalogue.json'


const languageSelect = (language) => {
  switch (language) {
    case 'en':
      i18n.addResourceBundle('chalhoub:en', 'ProductCatalogue', enProductCatalogue, true, true)
      i18n.changeLanguage('chalhoub:en')
      break
      case 'ar':
      i18n.addResourceBundle('chalhoub:ar', 'ProductCatalogue', arProductCatalogue, true, true)
      i18n.changeLanguage('chalhoub:ar')
      break
    default:
      i18n.changeLanguage('chalhoub:en')
      break
  }
}
const FindInOtherLocationsSidebar = (props) => {

  const selectedLanguage = useSelector(appSelectors.getAppLanguage)

  useEffect(() => {
    languageSelect(selectedLanguage)
  }, [selectedLanguage])

  const { selectedVariantId, currentStoreId, drawerOpen, closeDrawer } = props
  const implementation = Modules.Product.Implementation.provideUseVariantStoreStock({
    productFunctions: productCatalogueFunctions,
    currentVariantId: selectedVariantId,
    currentStoreId
  })
  return (
    <Modules.Product.Components.FindInOtherLocationsSidebar 
      implementation={implementation} 
      drawerOpen={drawerOpen} 
      closeDrawer={closeDrawer}
    />
  )
}

export default FindInOtherLocationsSidebar
