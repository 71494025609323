const style = theme => ({
  root: {
    boxShadow: 'none',
    fontWeight: 500,
    minWidth: 0,
    transition: 'color .01s', // solution for iOS14.6 issue here: https://github.com/mui-org/material-ui/issues/26251
    whiteSpace: 'nowrap',
    '&:active': {
      boxShadow: 'none'
    },
    'a > &': {
      // has to be inline-block to stop underline in <a>
      textDecoration: 'none',
      display: 'inline-block'
    },
    ...theme.button
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  fullWidth: {
    display: 'block',
    width: '100%'
  },
  big: {
    width: '100%',
    maxWidth: 380,
    display: 'block',
    padding: '13px 8px'
  },
  icon: {
    display: 'flex'
  },
  rightIcon: {
    paddingRight: 33
  },
  hyperlink: {
    textTransform: 'initial',
    textDecoration: 'underline',
    fontWeight: 400
  },
  hyperlinkIcon: {
    verticalAlign: 'text-top',
    display: 'block'
  },
  flexGrow: {
    flex: 1
  },
  wrapper: {
    background: 'none',
    border: 0,
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  }
})

export default style
