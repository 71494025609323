import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ReactMarkdown from 'react-markdown'

import * as validators from '../../../../validators'
import Form, { FormBody, FormError } from '../../../../components/Form'
import ButtonsContainer from '../../../../components/ButtonsContainer'
import Button from '../../../../components/Button'
import personalDetailsSchema from '../../../../components/Form/commonSchemas/registerCustomerPersonalDetails'

import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import Address from '../../../../components/Address'
import BottomBar from '../../../../components/BottomBar'
import CommunicationOptions from './CommunicationOptions'
import CustomerRegistrationTerms from '../CustomerRegistrationTerms/CustomerRegistrationTerms'

import { Checkbox } from '../../../../components/Fields'
import modalService from '../../../../services/modalService'

import { useFormSyncErrors } from '../../../../hooks/formHooks'


import { translations, registerCustomerTermsCheckboxEnabled } from '../../../../config'

import { formStyle } from './style'

import './style.css'

export const formId = 'customer-registration'

const CustomerRegistrationForm = Form(formId)

export const termsRequired = (value) => {
  return !value ? translations('Please accept terms and conditions') : undefined
}


export const museEnrollValidation = (value, formValues) => {
  const checked = value
  const hasEmail = _.get(formValues, 'email')
  if (checked && !hasEmail) {
    return translations('Email is required to enroll customer to MUSE loyalty programme')
  }
}

const renderTerms = (props) => {
  const { classes, brandName, formError } = props

  const openLink = (e, link) => {
    e.stopPropagation()
    if (link === 'https://app.retailos/open-terms') {
      return modalService.open({
        component: CustomerRegistrationTerms
      })
    }
    if (window.cordova) {
      return window.cordova.InAppBrowser.open(link, '_blank')
    }
    return window.open(link, '_blank')
  }
  const renderLink = (link) => <span onClick={(e) => openLink(e, link.href)}>{link.children}</span>

  const text = <ReactMarkdown components={{ a: renderLink }}>
    {translations(brandName ? `Register Customer Terms Markdown - ${brandName}` : 'Register Customer Terms Markdown')}
    </ReactMarkdown>

  if (registerCustomerTermsCheckboxEnabled) {
    const formControlLabelClasses = {
      root: classes.termsLabelRoot,
      label: classes.termsLabelLabel
    }
    return <FormGroup>
      <FormControl>
        <FormError error={_.get(formError, 'terms')} />
        <FormControlLabel
          classes={formControlLabelClasses}
          control={<Checkbox
            name='terms'
            validate={termsRequired}
          />}
          label={text}
        />
      </FormControl>
      <FormControl>
        <FormError error={_.get(formError, 'museOptIn')} />
        <FormControlLabel
          classes={formControlLabelClasses}
          control={<Checkbox 
            name='museOptIn'
            validate={museEnrollValidation}
            />}
          label={translations('Register Customer Enroll Loyalty program')}
        />
      </FormControl>
    </FormGroup>
  } else {
    return <div className={classes.termsText}>{text}</div>
  }
}

const CustomerRegistrationFormWrapper = ({
  onSubmit,
  classes,
  customerModeUpdate,
  brandName,
  onBackClick,
  isModal
}) => {
  const formError = useFormSyncErrors(formId)
  // props on the form allow the initial values to be updated
  return (
    <div id='customer-registration-container'>
      <CustomerRegistrationForm onSubmit={onSubmit} submitLabel={translations('Create Customer')} initialValues={{ marketingStoreIds: [], preferredLanguage: 'en-GB' }}>
        <FormBody>
          <div>
            <PersonalDetails formId={formId} schema={personalDetailsSchema} />
            <Address
              formName='customer-registration'
              align='right'
              hideFindAddress
            />
            <CommunicationOptions />
            {renderTerms({ classes, brandName, formError })}
          </div>
        </FormBody>
        <FormError />
        {!customerModeUpdate &&
          <BottomBar>
            <ButtonsContainer align='center'>
              <Button
                disabled={!_.isEmpty(formError)}
                type={'submit'}
                color='primary'
                big
                buttonType='primary'>
                {`${translations('Create Customer')}`}
              </Button>
              {isModal &&
                <Button
                  buttonType='primary'
                  big
                  onClick={onBackClick}>
                  {`${translations('Cancel')}`}
                </Button>
              }
            </ButtonsContainer>
          </BottomBar>
        }
      </CustomerRegistrationForm>
    </div>
  )
}

CustomerRegistrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
}

export default withStyles(formStyle)(CustomerRegistrationFormWrapper)
