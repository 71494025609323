import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import { Table, TableHeader, TableRow, TableCell } from '../../../../../../components/Table'
import Image from '../../../../../../components/Image'
import ButtonLink from '../../../../../../components/ButtonLink'
import P from '../../../../../../components/P'
import OrderLabel from '../../../../../../components/OrderLabel'
import { translations } from '../../../../../../config'
import style from './style'

class OrderProduct extends Component {
  renderDataLine = ([key, content]) => {
    const { classes } = this.props
    const keyText = translations(`Order Product ${_.startCase(key)}`)
    const text = (
      keyText
        ? `${keyText}: ${content}`
        : content
    )
    const lineClass = cx(classes.itemDetail, {
      [classes.notPurchasedReasonTextMobile]: key === 'notPurchasedReason'
    })
    return <P className={lineClass} value={text} />
  }

  renderItemDetails = (linesData = []) => {
    const { product, goToProduct, classes } = this.props
    const { stock } = product || {}
    return <div className={classes.itemDetailsContainer}>
      <div>
        <Image
          src={_.get(product, 'images.0')}
          className={classes.itemDetailsImage}
          box
          contain
          onClick={goToProduct}
        />
        { stock === 'N' && <OrderLabel />}
      </div>
      <div className={classes.itemDetailsText}>
        <ButtonLink
          className={classes.itemName}
          onClick={goToProduct}
        >
          {_.get(product, 'name')}
        </ButtonLink>
        {linesData.map(this.renderDataLine)}
      </div>
    </div>
  }

  _getProductLinesForAllScreenSizes = () => {
    const { product } = this.props
    const variantSku = _.get(product, 'variant.details.sku')
    const externalProductId = _.get(product, 'externalProductId')
    const binNumber = _.get(product, 'variant.details.binNumber')
    const quantity = _.get(product, 'quantity', 1)

    const lines = []
    lines.push(['externalId', externalProductId])
    lines.push(['quantity', quantity])

    if (binNumber) lines.push(['binNumber', binNumber])
    if (variantSku) lines.push(['variantSku', variantSku])

    return lines
  }

  renderDesktop = () => {
    const { productInfo, productInfoLayout, faded, classes } = this.props
    const quantity = _.get(productInfo, 'quantity', 1)
    productInfo.quantity = quantity
    return <TableRow
      className={faded ? classes.faded : undefined}
    >
      <TableCell sm={4} className={classes.cell}>
        {this.renderItemDetails(this._getProductLinesForAllScreenSizes())}
      </TableCell>
      {productInfoLayout.map(([key, cellWidth]) => {
        return <TableCell
          key={key}
          children={productInfo[key]}
          sm={cellWidth}
          className={classes.cell}
        />
      })}
    </TableRow>
  }

  renderMobile = () => {
    const { classes, productInfoLayout, productInfo, faded } = this.props

    const linesData = [...this._getProductLinesForAllScreenSizes()]

    let rightContent = null
    const newProductInfoLayout = productInfoLayout.map(([key], index) => {
      if(key === 'itemStatus') return null 
      return productInfoLayout[index]
    }).filter(item => item !== null)
    newProductInfoLayout.forEach(([key]) => {
      const content = productInfo[key]
      // this is so that stuff like the YesNo picker is pushed to the right
      // but other stuff can just be rendered as lines of text
      if (typeof content === 'string' || typeof content === 'number') {
        linesData.push([key, content])
      } else {
        rightContent = content
      }
    })
    return (
      <Box className={classes.containerMobile}>
        <Box className={classes.containerMobileInner}>
          <Box className={cx(classes.innerMobile, {
            [classes.faded]: faded
          })}>
            {this.renderItemDetails(linesData)}
            {rightContent}
          </Box>
        </Box>
        <Box className={classes.itemStatusContainer}>
          <Box className={classes.itemStatusText}>{translations(`Order Product ${_.startCase('itemStatus')}`)}</Box>
          <Box fontWeight={700}>{productInfo['itemStatus']}</Box>
        </Box>
      </Box>
    )
  }

  render () {
    return <div>
      <Hidden smUp>{this.renderMobile()}</Hidden>
      <Hidden xsDown>{this.renderDesktop()}</Hidden>
    </div>
  }
}

export default withStyles(style)(OrderProduct)
