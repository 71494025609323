import _ from 'lodash'
import { createSelector } from 'reselect'
import { selectors as authSelectors } from '../auth'
import { PENDING, SUCCESS } from '../../middleware/redux-promise'
import { translations } from '../../../config/translations'
export const getIsLoading = state => state.users.status === PENDING
export const getResults = state => _.get(state.users, 'results', [])
export const getHasMore = state => state.users.total != null &&
state.users.results.length < state.users.total
export const getUsersHasBeenSearched = state => {
  return (
    state.users.query ||
    !_.isEmpty(state.users.roleIds) ||
    !_.isEmpty(state.users.storeIds) ||
    state.users.status === SUCCESS
  )
}
export const getError = state => state.users.error
export const getIsInitial = state => !state.users.status
export const getQuery = state => state.users.query
export const getSize = state => state.users.size
export const getPage = state => state.users.page
export const getRoleIds = state => state.users.roleIds
export const getStoreIds = state => state.users.storeIds
export const getIncludeDeactivated = state => state.users.includeDeactivated

export const getUsersAsOptions = createSelector([
  getResults,
  authSelectors.getActiveUserId,
  authSelectors.getCurrentUser
], (users = [], activeUserId, currentUser) => {
  const hasMe = users.find(user => user.id === activeUserId)
  if (!hasMe) {
    users.push(currentUser)
  }

  const sorted = _.sortBy(users, 'firstName')
  const options = _.reduce(sorted, (arr, user) => {
    const labelValue = `${user.firstName} ${user.lastName}`
    if (user.id === activeUserId) {
      const userOption = {
        value: user.id,
        label: _.template(translations('ME ()'))({ me: labelValue })
      }
      return [userOption].concat(arr)
    }

    arr.push({
      value: user.id,
      label: labelValue
    })

    return arr
  }, [])

  console.log({ options })

  return options
})