const styles = {
  container: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  ratioContainer: {
    width: '100%',
    position: 'relative'
  },
  heading: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0
  },
  brand: {
    minHeight: 20,
    textAlign: 'center',
    marginBottom: 10
  },
  price: {
    textAlign: 'center',
    marginTop: 4
  },
  promotionalBadge: {
    padding: 3,
    backgroundColor: '#FF9400'
  },
  promotionalBadgeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 18,
    marginTop: 10,
    marginBottom: 10
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    padding: '10px',
    paddingTop: '50px'
  },
  mainButton: {
    width: '100%'
  },
  headingContainer: {
    padding: '15px 50px',
    textAlign: 'center',
    minHeight: '140px'
  }
}

export default theme => styles
