import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { compose } from 'recompose'

import {
  actions as currentOrderActions,
  selectors as currentOrderSelectors
} from '../../../../../../store/modules/currentOrder'
import { actions as currentAppointmentActions } from '../../../../../../store/modules/currentAppointment'
import { translations, plpAddToBasketEnabled } from '../../../../../../config'
import modalService from '../../../../../../services/modalService'
import FixedRatioContainer from '../../../../../../components/FixedRatioContainer'
import P from '../../../../../../components/P'
import Button from '../../../../../../components/Button'
import Image from '../../../../../../components/Image'
import FloatingBadge from '../../../../../../components/FloatingBadge'

import ProductDiscount from '../../../../../../components/ProductDiscount'

import SelectVariant from './SelectVariant'
import style from './style'

const plpProductKeys = [
  'id',
  'name',
  'variants',
  'brand',
  'link',
  'price',
  'discount',
  'images',
  'externalProductId',
  'binNumber',
  'preview',
  'service',
  'clientId',
  'details',
  'vatPercent',
  'category'
]

export const ProductsGridItem = ({
  product = {},
  classes,
  dispatch,
  onProductClick,
  onButtonClick,
  hasAddToBasket,
  displayPrice,
  saveAmount,
  originalPrice,
  discountValue,
  selected,
  multiSelect,
  disablePrice,
  buttonValue,
  onRequestItemClick,
  modalIndex,
  selectedCurrency,
  variantId,
  activeFilter
}) => {

  const [variant, setVariant] = useState(_.get(product, 'variants.0.id'))
  const [shouldAddVariantToBasket, setShouldAddVariantToBasket] = useState(false)
  const productObj = _.pick(product, plpProductKeys)
  const _formatProductForBasket = ({ product, variantId }) => {
    productObj.categoryId = _.get(product, 'category.id')
    return currentOrderSelectors.attachVariantToProduct({ product: productObj, variantId })
  }

  const productWithVariant = _formatProductForBasket({ product: productObj, variantId: variant })
  const canAddToBasket = plpAddToBasketEnabled && hasAddToBasket && (!!_.get(product, 'price.value') || !!_.get(productWithVariant, 'variant.price.value'))

  const _sendToStoreroom = (product) => {
    onRequestItemClick &&
      modalService.textInput({
        title: 'Add notes',
        inputLabel: 'Notes',
        submitText: 'Submit Request',
        modalIndex: modalIndex >= 0 ? modalIndex + 1 : 0,
        onSubmit: (value) => {
          onRequestItemClick && onRequestItemClick({ product, notes: value })
          modalService.close({ modalIndex })
        }
      })
  }

  const _addProductToBasket = () => {
    if (product.storeroomOnly) {
      _sendToStoreroom(productWithVariant)
    } else if (multiSelect) {
      dispatch(
        currentAppointmentActions.addContent({ type: 'product', details: productWithVariant })
      )
    } else {
      dispatch(
        currentOrderActions.addProduct({
          product: {
            ...productWithVariant,
            stock: 'Y'
          },
          quantity: 1
        })
      )
    }
  }

  const _getButtonString = () => {
    let buttonString = ''
    buttonString = buttonValue || translations('Add to basket')
    if (product.disabled) buttonString = translations('Already selected')
    if (multiSelect && selected) buttonString = translations('Deselect')
    if (product.storeroomOnly) buttonString = translations('Request')
    return buttonString
  }

  const onClickAddToBasket = (e) => {
    e.stopPropagation()
    if (multiSelect && selected) {
      dispatch(currentAppointmentActions.removeContent({ type: 'product', details: product }))
      dispatch(currentOrderActions.removeProduct({ product: productWithVariant }))
    }
    if (!selected) {
      if (product.variants.length > 1) {
        modalService.open({
          component: SelectVariant,
          smallModal: true,
          variants: product.variants,
          product,
          variant,
          onChange: (variant) => setVariant(variant),
          title: translations('Select A Variant'),
          modalIndex: 1,
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Done'),
              onClick: () => setShouldAddVariantToBasket(true)
            },
            {
              text: translations('Cancel'),
              onClick: _.noop
            }
          ]
        })
      } else {
        _addProductToBasket()
      }
    }
  }

  const handleProductClick = (e) => {
    if (multiSelect && plpAddToBasketEnabled && !isDisabled) {
      return onClickAddToBasket(e)
    }

    if (product && !product.disabled && !product.storeroomOnly) {
      onProductClick(e)
    }
  }

  const renderPrice = () => {
    if (!shouldShowPrice) return <h2>{translations('Unavailable')}</h2>

    if (product._isPriceRange) {
      return (
        <P
          value={`${product.displayPriceFrom} - ${product.displayPriceTo}`}
          className={classes.displayPriceRange}
        />
      )
    }

    return (
      <ProductDiscount
        isPreviewProduct={preview}
        originalPrice={originalPrice}
        displayDiscount={saveAmount}
        displayPrice={displayPrice}
        discountValue={discountValue}
        center
      />
    )
  }

  const { images = [], brand, name, promotionalText, discount, preview } = product || {}
  const image = _.get(images, '0')
  const buttonString = _getButtonString()
  const ratio = useMediaQuery((theme) => theme.breakpoints.down('xs')) ? 0.75 : 1.1
  const shouldShowPrice = ( product.price.value || displayPrice ) && disablePrice !== true && product.service !== true
  
  const isHideUnavailable = _.get(activeFilter, 'available_store')
  const isSomeSizeUnavailable = !!_.find(product.variants, variant => variant.variantStoreStock.stock === 0)
  React.useEffect(() => {
    // NOTE: Solution for ticket DIST-4640
    // ModalService component has NO access the state of selected variant from this component.
    // Therefore, the _addProductToBasket functions has to be called within this component in order to pass the right variant.
    // We used shouldAddVariantToBasket to determine when the variant will be added to the basket.
    if (shouldAddVariantToBasket && variant) {
      _addProductToBasket()
      setShouldAddVariantToBasket(false)
    }
  }, [shouldAddVariantToBasket])
  const isDisabled = product.disabled || (canAddToBasket && !variant)
  return (
    <Grid
      className={classes.container}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={handleProductClick}
      role='listitem'
    >
      {isHideUnavailable && <div className={classes.unavailableIndicatorWrapper}>
        {isSomeSizeUnavailable && 
          <span className={classes.unavailableIndicator}>
            {translations('Some sizes unavailable')}
          </span>
        }
      </div>}
      <FixedRatioContainer ratio={ratio} containerClass={classes.ratioContainer}>
        <Image className={classes.image} src={image} />
        {product.preview && <FloatingBadge label={translations('Preview')} position={'topRight'} />}
      </FixedRatioContainer>
      <div className={classes.headingContainer}>
        <P value={name} className={classes.heading} />
        {brand && brand.trim().length > 0 && (
          <P value={brand} className={classes.brand} />
        )}
        {renderPrice()}
        {promotionalText && promotionalText.trim().length > 0 && (
          <div className={classes.promotionalBadgeContainer}>
            {promotionalText && (
              <div className={classes.promotionalBadge}>
                <P value={promotionalText} type='promoText' />
              </div>
            )}
          </div>
        )}
      </div>
      {(canAddToBasket || onButtonClick) && (
        <Button
          disabled={isDisabled}
          color={multiSelect && selected ? 'white' : 'primary'}
          className={classes.mainButton}
          onClick={isDisabled ? null : (onButtonClick ? onButtonClick : onClickAddToBasket)}
        >
          {buttonString}
        </Button>
      )}
    </Grid>
  )
}

ProductsGridItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    images: PropTypes.array,
    price: PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string
    }),
    discount: PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string
    }),
    promotionalText: PropTypes.string
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  /** Redux dispatch */
  dispatch: PropTypes.func.isRequired,
  fetchProductDetails: PropTypes.func.isRequired,
  onProductClick: PropTypes.func.isRequired,
  hasAddToBasket: PropTypes.bool,
  displayPrice: PropTypes.string,
  saveAmount: PropTypes.string,
  originalPrice: PropTypes.string,
  selected: PropTypes.bool,
  multiSelect: PropTypes.bool,
  disablePrice: PropTypes.bool,
  onRequestItemClick: PropTypes.func,
  modalIndex: PropTypes.string,
  /** Translation key to show in the cta, defaults to 'Add to basket' */
  buttonValue: PropTypes.string,
  variantId: PropTypes.string.isRequired
}

export default compose(withStyles(style))(ProductsGridItem)
