import formStyles from '../../../../components/Form/style'

const styles = {
  dobWrapper: {
    width: '100%',
    position: 'relative',
    marginTop: '10px',
    display: 'inline-flex'
  },
  dobField: {
    width: '30%',
    marginRight: '5%'
  },
  lastDobField: {
    width: '30%'
  },
  formText: {
    fontSize: '13px'
  },
  communicationContentContainer: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingTop: '30px'
  },
  dobTitle: {
    position: 'absolute',
    // same place as the input title which is rendered when focussed or it has a value
    top: '0',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  selectMaxHeight: {
    maxHeight: '300px'
  },
  checkboxMargin: {
    marginLeft: -16
  }
}

export default styles

// slightly different setup here
// this file is relied upon by other JSS setups
// created a separate import for the CustomerRegistrationFormComponent itself

export const formStyle = theme => ({
  ...formStyles,
  checkboxMargin: styles.checkboxMargin,
  // i dont have a clue why this file is set up how it is but this is one of those
  // situations where i'm just gonna leave old code as it is :)
  termsLabelRoot: {
    marginLeft: 0
  },
  termsLabelLabel: {
    marginLeft: -16
  },
  termsLink: {
    padding: 0,
    fontWeight: 'bold',
    lineHeight: 'inherit',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  },
  termsText: {
    ...theme.typography.body1,
    textAlign: 'justify'
  }
})
