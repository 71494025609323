import _ from 'lodash'

import { checkoutModules, distantCheckoutModules, basketCheckoutModules, referralModules, storeroomRequestModules, consultationModules } from '../config'

export const modulesForCheckoutType = {
  referral: referralModules,
  storeroom: storeroomRequestModules,
  checkout: checkoutModules,
  consultation: consultationModules,
  distantCheckout: distantCheckoutModules,
  basketCheckout: basketCheckoutModules
}

export const pathForCheckoutType = {
  referral: 'referral',
  storeroom: 'storeroom-request',
  checkout: 'checkout',
  consultation: 'checkout',
  distantCheckout: 'checkout',
  basketCheckout: 'checkout'
}

export const getModulesFromCheckoutType = (checkoutType, deliveryType) => {
  const modules = modulesForCheckoutType[checkoutType]
  if (modules) {
    // Remove billing step for in store sale
    if (deliveryType === 'inStoreSale') {
      const filteredModules = _.filter(modules, (module) => module.name !== 'BILLING')
      return filteredModules
    }
    return modules
  } else {
    throw new Error(`cannot get modules for checkoutType '${checkoutType}'`)
  }
}

export const getPathFromCheckoutType = (checkoutType) => {
  const path = pathForCheckoutType[checkoutType]
  if (path) {
    return path
  } else {
    throw new Error(`cannot get path for checkoutType '${checkoutType}'`)
  }
}

const _getModulesHashmap = (modules) => {
  const modulesHashmap = _.reduce(modules, (result, someModule, index) => {
    const { name, ...rest } = someModule
    result[someModule.name] = { index, ...rest }
    return result
  }, {})
  return modulesHashmap
}
const _modulesHashmapsForCheckoutType = _.mapValues(modulesForCheckoutType, _getModulesHashmap)

export const getCheckoutModuleOptions = (moduleName, checkoutType) => {
  const options = _.get(_modulesHashmapsForCheckoutType, [checkoutType, moduleName], {})
  return options
}

// these are all checkout related screens, including email basket
// (referral), and storeroom request flow.
// why does this need to exist? why can't we just lowercase the
// module name to get the path? I'll tell you why. it's because
// a module can have multiple paths :O
export const pathsForCheckoutModule = {
  ORDER_SUMMARY: [
    'order-summary'
  ],
  SELECT_CUSTOMER: [
    'select-customer',
    'register-customer'
  ],
  DELIVERY: [
    'delivery'
  ],
  PAYMENT_DUMMY: [
    'payment-dummy'
  ],
  COUPON_CODE: [
    'coupon-code'
  ],
  EDIT_BASKET: [
    'edit-basket'
  ],
  PAYPAL_HERE: [
    'paypal-here'
  ],
  PAYMENT_SALESFORCE: [
    'payment'
  ],
  DEFERRED_MODULE: [
    'deferred'
  ],
  CHALHOUB_PAYMENT: [
    'chalhoub-payment'
  ],
  BILLING: [
    'billing'
  ],
  DISTANT_CHECKOUT: [
    'distant-checkout'
  ],
  BASKET_CHECKOUT: [
    'basket-checkout'
  ]
}

const _checkoutModulePathsInverse = _.transform(pathsForCheckoutModule,
  (acc, paths, moduleName) => {
    paths.forEach(path => { acc[path] = moduleName })
  }, {}
)
export const getCheckoutModuleNameFromPath = (path) => {
  return _checkoutModulePathsInverse[path]
}

export const calculateTaxValue = ({ taxPercentage, value }) => {
  return (value / (100 + parseFloat(taxPercentage))) * taxPercentage
}

export const calculateOrderSummaryBreakdownTotals = ({ items, currentCurrency, currentRegionCurrencyConfig = [], shipping, currencyFormatter }) => {
  const matchedCurrency = currentRegionCurrencyConfig.filter(item => item.id === currentCurrency)
  let taxPercentage = 0 
  if(matchedCurrency.length > 0) {
    taxPercentage = matchedCurrency[0].taxPercentage
  }
  const shippingValue = shipping && shipping.price ? parseFloat(shipping.price.value) : 0

  const discount = items.reduce((accum, item) => {
    accum += item.discount ? parseFloat(item.discount.value) : 0
    return accum
  }, 0)

  const originalPriceTotal = items.reduce((accum, item) => {
    accum += parseFloat(item.price.value) || 0
    return accum
  }, 0)

  const createPriceObject = (value, currentCurrency) => ({value, code: currentCurrency}) 

  const calculateTotal = ({ subTotalValue, discountValue, shippingValue }) => {
  return subTotalValue - discountValue + shippingValue
}

  return [{
    label: 'label-items',
    value: items.length
  }, {
    label: 'label-subtotal',
    value: originalPriceTotal.toFixed(2)
  }, {
    label: 'label-discounts',
    value: discount.toFixed(2)
  }, {
    label: 'label-shipping',
    value: shippingValue.toFixed(2)
  }, {
    label: 'label-tax',
    value: parseFloat(calculateTaxValue({ taxPercentage, value: originalPriceTotal })).toFixed(2)
  }, {
    label: 'label-discountTax',
    value: parseFloat(calculateTaxValue({ taxPercentage, value: discount })).toFixed(2)
  }, {
    label: 'label-shippingTax',
    value: parseFloat(calculateTaxValue({ taxPercentage, value: shippingValue })).toFixed(2)
  }, {
    label: 'label-breakdownTotal',
    value: currencyFormatter.format(createPriceObject(parseFloat(calculateTotal({ subTotalValue: originalPriceTotal, discountValue: discount, shippingValue })).toFixed(2),  currentCurrency)),
    bold: true
  }]
}