import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { selectors as customerDetailsSelectors } from '../../../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { phoneNumber } from '../../../../../validators'
import { apps } from '../../../../../config'

import PhoneIcons from './PhoneIcons'
import analyticsService from '../../../../../services/analyticsService'

const mapStateToProps = (state, ownProps) => {
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const isMessagingEnabled = isMessagingAllowedForRole && !phoneNumber(ownProps.value) && apps.MESSAGING
  return {
    currentCustomerDetails: customerDetailsSelectors.getCustomer(state),
    isMessagingEnabled
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onClickCall: ({ value }) => () => {
      const href = `tel:${value}`
      window.open(href, '_system')
    },
    onClickSMS: ({ currentCustomerDetails, dispatch }) => () => {
      analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'customerProfile' })
      dispatch(createMessageActions.updateReceipient(currentCustomerDetails))
      dispatch(createMessageActions.updateMessage({ communicationType: 'sms' }))
    }
  })
)(PhoneIcons)
