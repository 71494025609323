import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const ConfigProvider = ({ children, theme, title, favicon }) => {
  return (
    <Fragment>
      {children(theme)}
    </Fragment>
  )
}

ConfigProvider.propTypes = {
  children: PropTypes.func.isRequired,
  theme: PropTypes.object
}

export default ConfigProvider
