const styles = theme => ({
  container: {
    position: 'fixed',
    top: 0,
    left: '50%',
    zIndex: '1300',
    transform: 'translate3d(-50%, -100%, 0)',
    transition: 'transform .3s ease-in, opacity .3s ease-in',
    pointerEvents: 'none',
    opacity: 0
  },
  active: {
    transform: 'translate3d(-50%, 0, 0)',
    opacity: 1
  },
  content: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.errorLight.main,
    maxWidth: '500px',
    fontSize: '14px',
    fontWeight: '600',
    border: '1px solid',
    padding: '6px 15px',
    borderTop: '0',
    borderRadius: '0 0 5px 5px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginTop: 0
  }
})

export default theme => styles
