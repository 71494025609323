export const FETCH_PRODUCT = 'digitalStore/productDetails/FETCH_PRODUCT'
export const GET_PRODUCT_AVAILABILITY = 'digitalStore/productDetails/GET_PRODUCT_AVAILABILITY'
export const FETCH_PRODUCT_GROUP_PRODUCTS = 'digitalStore/productDetails/FETCH_PRODUCT_GROUP_PRODUCTS'
export const CHANGE_PRODUCT_TAB = 'digitialStore/product/CHANGE_PRODUCT_TAB'
export const PRODUCT_DETAILS_EXPANDED = 'digitialStore/product/PRODUCT_DETAILS_EXPANDED'
export const PRODUCT_CUSTOMER_REVIEWS = 'digitialStore/product/PRODUCT_CUSTOMER_REVIEWS'
export const PRODUCT_RELATED_PRODUCTS = 'digitialStore/product/PRODUCT_RELATED_PRODUCTS'
export const PRODUCT_SIZE_GUIDE = 'digitialStore/product/PRODUCT_SIZE_GUIDE'
export const PUT_ACTIVE_MEDIA_INDEX = 'digitialStore/product/PUT_ACTIVE_MEDIA_INDEX'
export const RESET_ACTIVE_MEDIA_INDEX = 'digitialStore/product/RESET_ACTIVE_MEDIA_INDEX'
export const RESET_PRODUCT_DETAILS_EXPANDED = 'digitialStore/product/RESET_PRODUCT_DETAILS_EXPANDED'
