import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useSelector } from 'react-redux'

import { getRegionBrand } from '../../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import Button from '../../../../components/Button/Button'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import terms from './terms'

const CustomerRegistrationTerms = ({ modalIndex }) => {
  const onClick = () => {
    return modalService.close({ modalIndex })
  }

  const brand = useSelector(getRegionBrand)
  const defaultTerms = translations('Register Customer - Full Terms Markdown')
  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
      >
        {brand ? (terms[brand] || defaultTerms) : defaultTerms}
      </ReactMarkdown>
      <Button
        onClick={onClick}
        buttonType={'primary'}
      >
        {translations('Register Customer - Full Terms - Confirm Button')}
      </Button>
    </>
  )
}

export default CustomerRegistrationTerms
