import sectionStyle from '../common/sectionStyle'

export default theme => ({
  ...sectionStyle(theme),

  radioInner: {
    maxWidth: 420,
    '& > div': {
      verticalAlign: 'top',
      width: '100%'
    },
    '& label': {
      flex: 1
    }
  },
  firstBoxInner: {
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 13,
      marginRight: 13
    }
  },
  secondBoxInner: {
    marginTop: 5,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 13,
      marginRight: 13
    }
  },
  deliveryTitle: {
    // marginBottom: 5
  },
  field: {
    width: '100%'
  },
  requiredInfo: {
    marginBottom: 10
  },
  labelList: {
    '& label': {
      flex: 'auto'
    }
  },
  radioList: {
    width: 40,
    height: 40,
  },
  saveAddressToProfileButton: {
    width: '100%',
    marginTop: 15
  }
})
