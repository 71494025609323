import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import * as currentOrderCombinedSelectors from '../../../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { selectors as orderDetailsSelectors } from '../../../../../../store/modules/orderDetails'
import ConsultationContentItem from '../../../../../Consultations/ConsultationContentItem'

const ContentGridContainer = styled(Grid)`
  margin-top: 2rem;
  margin-bottom: 5rem;
`

const ContentItem = styled.div`
  border: ${props => props.showBorder ? '1px solid #D1D1D1;' : 'none'};
  border-radius: ${props => props.showBorder ? '10px' : '0'};
  padding: 12px;
`

const SummaryContent = ({ stacked }) => {
  const appointmentOrderProducts = useSelector(currentOrderCombinedSelectors.getCurrentOrderGroupedProducts)
  const orderDetailProducts = useSelector(orderDetailsSelectors.getOrderProducts)
  const orderProducts = appointmentOrderProducts.length ? appointmentOrderProducts : orderDetailProducts
  const gridItemWidth = stacked ? 12 : 6

  return (
    <ContentGridContainer container>
      {orderProducts.map(item => {
        return (
          <Grid item xs={12} sm={6}>
            <ContentItem showBorder={item.service}>
              <ConsultationContentItem {...item} hideButtons purchased={!item.unsold} />
            </ContentItem>
          </Grid>
        )
      })}
    </ContentGridContainer>
  )
}

export default SummaryContent
