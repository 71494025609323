import { connect } from 'react-redux'
import _ from 'lodash'

import TitleBar from './TitleBar'
import SearchFilters from '../../SearchFilters'
import { actions as productsActions, selectors as productsSelectors } from '../../../../../store/modules/products'
import { selectors as appSelectors } from '../../../../../store/modules/app'
import { actions as currentOrderActions } from '../../../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import modalService from '../../../../../services/modalService'
import { productsFilters, translations } from '../../../../../config'

import { getRegionBrand } from '../../../../../store/modules/combinedSelectors/regionsCombinedSelectors'
const mapStateToProps = state => {
  const total = productsSelectors.getTotal(state)
  const query = productsSelectors.getResultsQuery(state)
  const sort = productsSelectors.getSort(state)
  const isLoading = productsSelectors.getIsLoading(state)
  const numberOfActiveFilters = productsSelectors.getNumberOfActiveFilters(state)
  const selectedCurrency = appSelectors.getAppCurrency(state)
  const currencySuffix = `_${selectedCurrency}`
  const regionName = getRegionBrand(state)

  const order = currentOrderCombinedSelectors.currentOrderSelector(state)

  return {
    sort,
    total,
    query,
    isLoading,
    numberOfActiveFilters,
    productsFilters: productsFilters.map((option) => ({
      ...option,
      label: translations(option.label),
      value: `${option.value}${currencySuffix}`
    })),
    ..._.pick(order, [
      'editMode',
      'orderNumber'
    ]),
    regionName
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: ({ sort }) => dispatch(productsActions.searchProductsFresh({ sort })),
  stopEditingOrder: () => { dispatch(currentOrderActions.stopEditingOrder()) },
  openFilters: (regionName) => {
      modalService.open({
        modalIndex: 2,
        component: SearchFilters,
        inModal: true,
        onClose: () => {
          modalService.close({ modalIndex: 2 })
        },
        regionName: regionName
      })
    }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TitleBar)
