import { translations } from '../../../config'
import _ from 'lodash'
import modalService from '../../../services/modalService'

// Check for phonenumber in ios contacts
const checkExistingContactIos = async (phoneNo) => {
    const searchOptions = new ContactFindOptions()
    searchOptions.filter = phoneNo
    searchOptions.multiple = true
    searchOptions.desiredFields = [navigator.contacts.fieldType.id, navigator.contacts.fieldType.phoneNumbers, navigator.contacts.fieldType.name]
    searchOptions.hasPhoneNumber = true
    const fields = [navigator.contacts.fieldType.phoneNumbers]
  
    const contactSearchPromise = new Promise((resolve, reject) => {
      navigator.contacts.find(fields, resolve, reject, searchOptions)
    })
    return contactSearchPromise.then((results) => results)
  }
  
  const checkWhatsAppOptIn = (customerMarketingChannels) => {
    const optIn = _.includes(customerMarketingChannels, 'whatsAppMarketing')
    return optIn
  }
  
  // Add contact to IOS phonebook
  const addContactIos = (customer) => {
    const contactSuccess = (contact) => {
      const text = translations('Contact Added Body', {
        customer: `${customer.firstName} ${customer.lastName}`
      })
      const title = translations('Contact Added Title')
      navigator.notification.alert(text, null, title)
    }
    
    const contactError = (message) => {
      navigator.notification.alert("Save contact failed becasuse: " + message, null, 'Error')
    }
  
    const myContact = navigator.contacts.create({
      "displayName": "temp"
    })
  
    var name = new ContactName()
    const phoneNumbers = []
    phoneNumbers[0] = new ContactField('mobile', customer.telephone)
    name.givenName = customer.firstName
    name.familyName = customer.lastName
    
    myContact.name = name
    myContact.displayName = name
    myContact.phoneNumbers = phoneNumbers
    
    myContact.save(contactSuccess(myContact), contactError)
    return null
  }
  
const handleAddContactIos = (customer) => {
    const customerMarketingChannels = _.get(customer, 'customerMarketingChannels')
    const customerPhone = _.get(customer, 'telephone')
    const whatsAppOptIn = checkWhatsAppOptIn(customerMarketingChannels)
    
    if (!whatsAppOptIn ) {
      modalService.continue({
        title: translations('Not opted in to whatsapp title'),
        text: translations('Not opted in to whatsapp', {
          customer: `${customer.firstName} ${customer.lastName}`
        })
      })
      return null
    }
      
    const checkExistingContact = checkExistingContactIos(customerPhone) // Returns array with customer object or empty array
  
    checkExistingContact
      .then((contacts) => {        
        const existingContact = contacts.length > 0 
        
        if (existingContact) {
          const text = translations('Customer Already In Phone Contacts', {
            customer: `${contacts[0].name.formatted}`
          })
          const title = translations('Customer Already In Phone Contacts Title')
          navigator.notification.alert(text, null, title)
          return null
        }    
        
        if (whatsAppOptIn && !existingContact) {
          addContactIos(customer)
        }
      })
      .catch((e) => {
        console.error(`ERROR Existing Contact Check: ${e.message}`)
      })
  
    return null
  }

export default handleAddContactIos