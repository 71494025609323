import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'
import media from '../../../config/media'

const personRemove = getImage('personRemove')
import PersonAdd from '@material-ui/icons/PersonAddRounded'

const PersonAddIcon = styled(PersonAdd)`
  color: #fff;
  width: 20px;
  ${media.lessThan('md')`
    width: 15px;
  `}
`

const PreferredSalesAssistantNotification = props => {
  const dispatch = useDispatch()

  const { 
    isNewPreferredSalesAssistant,
    customer,
    previousPreferredSalesAssistant,
    newPreferredSalesAssistant
   } = props.meta
  const { createdAt } = props

  const text = 
    isNewPreferredSalesAssistant
      ? _.template(translations('Preferred Sales Assistant (NEW) Notification Text'))({
          userName: `${newPreferredSalesAssistant.firstName}`,
          customerFullName: `${customer.firstName} ${customer.lastName}`
        })
      : _.template(translations('Preferred Sales Assistant (PREVIOUS) Notification Text'))({
          userName: `${previousPreferredSalesAssistant.firstName}`,
          customerFullName: `${customer.firstName} ${customer.lastName}`
        })
  
  return (
    <Notification
      {...props}
      onClick={() => dispatch(push(`/customers/${customer.id}`))}
      {...isNewPreferredSalesAssistant
        ? { icon: <PersonAddIcon />}
        : { iconSource: personRemove}
      }
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

PreferredSalesAssistantNotification.propTypes = {
  meta: PropTypes.shape({
    isNewPreferredSalesAssistant: PropTypes.bool.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    }).isRequired,
    previousPreferredSalesAssistant: PropTypes.shape({
      id: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    }).isRequired,
    newPreferredSalesAssistant: PropTypes.shape({
      id: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default PreferredSalesAssistantNotification
