import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import media from '../../../../config/media'
import modalService from '../../../../services/modalService'
import { translations, getAppConfig } from '../../../../config'
import {
  selectors as currentAppointmentSelectors,
  actions as currentAppointmentActions
} from '../../../../store/modules/currentAppointment'
import { selectors as customerDetailsSelectors } from '../../../../store/modules/customerDetails'

import AddContentButton from '../../../../components/AddContentButton'
import ImagePickerWrapper from '../../../../components/ImagePickerWrapper'
import Image from '../../../../components/Image'
import H3 from '../../../../components/H3'
import ContentItemsPanel from '../../../Consultations/ContentPanel'

const Block = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  ${(p) => p.borderBottom && 'border-bottom: 1px rgba(0,0,0,0.2) solid;'}
  ${(p) => p.borderTop && 'border-top: 1px rgba(0,0,0,0.2) solid;'}
  ${(p) => p.borderRight && 'border-right: 1px rgba(0,0,0,0.2) solid;'}
  ${(p) => p.borderLeft && 'border-left: 1px rgba(0,0,0,0.2) solid;'}
  ${(p) => p.pad && 'padding: 20px 20px 0 20px;'}
`

const ContentBlock = styled(Block)`
  min-height: ${({ beforeAfterPhotosEnabled }) => beforeAfterPhotosEnabled ? 'calc(100vh - 198px)' : 'auto'};
  ${media.lessThan('md')`
    border: none;
    border-bottom: 1px rgba(0,0,0,0.2) solid;
  `}
`

const Row = styled(Block)`
  flex-basis: auto;
  height: auto;
  flex-direction: row;
  border: none;
  ${media.lessThan('md')`
    margin-bottom: 1rem;
  `}
`

const ImageBox = styled(Image)`
  max-width: 100%;
`

const PhotoLable = styled(H3)`
  margin-bottom: 20px;
`

const ContentPanel = ({ type }) => {
  const dispatch = useDispatch()
  const beforeAfterPhotosEnabled = getAppConfig('CONSULTATIONS', 'beforeAfterPhotos') && type !== 'virtual'
  const appointmentContents = useSelector(currentAppointmentSelectors.getAppointmentContents)
  const consultationBeforePhoto = useSelector(currentAppointmentSelectors.getConsultationBeforePhoto)
  const consultationAfterPhoto = useSelector(currentAppointmentSelectors.getConsultationAfterPhoto)
  const customer = useSelector(customerDetailsSelectors.getCustomer)
  const photoOptIn = useSelector(customerDetailsSelectors.getCustomerPhotoOptIn)

  const appointmentContentsToPin = _.filter(appointmentContents, (content) => {
    const isPhoto = content.type === 'photo'
    const isBeforePhoto = content.details.type === 'beforePhoto'
    const isAfterPhoto = content.details.type === 'afterPhoto'
    return !isPhoto || (!isBeforePhoto && !isAfterPhoto)
  })

  const photoPermissionCheck = useCallback((callback) => {
    if (!photoOptIn) {
      modalService.action({
        title: translations('Before & After Photo'),
        text: translations('I am happy for my before and/or after photo(s) to be taken during my consultation and emailed to me afterwards.', {
          customer: _.get(customer, 'detail')
        }),
        actions: [
          {
            text: translations('No')
          },
          {
            text: translations('Yes'),
            primary: true,
            onClick: callback
          }
        ]
      })
    } else {
      callback()
    }
  })

  const onPhotoAdded = useCallback(({ file, type }) => {
    dispatch(currentAppointmentActions.addContent({
      type: 'photo',
      details: {
        type,
        file: file
      }
    }))
  })

  const setSelectedItem = (item) => {
    dispatch(currentAppointmentActions.toggleContentIsShowing({ ...item }))
    dispatch(currentAppointmentActions.setStage({ stage: null }))
  }

  const renderBeforeAfterPhotos = () => {
    return (
      <Row>
        <Block borderTop pad>
          <PhotoLable value={'Before photo'} />
          {
            consultationBeforePhoto.selected
              ? <ImageBox src={consultationBeforePhoto.blobUrl} />
              : (
                <ImagePickerWrapper
                  beforeOpen={photoPermissionCheck}
                  onPhotoAdded={(file) => onPhotoAdded({ file, type: 'beforePhoto' })}
                >
                  <AddContentButton />
                </ImagePickerWrapper>
              )
          }
        </Block>
        <Block borderTop borderLeft pad>
          <PhotoLable value={'After photo'} />
          {
            consultationAfterPhoto.selected
              ? <ImageBox src={consultationAfterPhoto.blobUrl} />
              : (
                <ImagePickerWrapper
                  beforeOpen={photoPermissionCheck}
                  onPhotoAdded={(file) => onPhotoAdded({ file, type: 'afterPhoto' })}
                >
                  <AddContentButton />
                </ImagePickerWrapper>
              )
          }
        </Block>
      </Row>
    )
  }

  return (
    <ContentBlock borderRight beforeAfterPhotosEnabled={beforeAfterPhotosEnabled}>
      <Row pad>
        <ContentItemsPanel
          horizontalScrollingLayout={type === 'virtual'}
          onItemClick={(item) => {
            if (type === 'virtual') {
              setSelectedItem(item)
            }
          }}
          items={appointmentContentsToPin}
        />
      </Row>
      {beforeAfterPhotosEnabled && renderBeforeAfterPhotos()}
    </ContentBlock>
  )
}

export default ContentPanel
