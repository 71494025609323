import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserTracing } from '@sentry/react'
import Routes from './Routes'
import * as SentryReact from '@sentry/react'

/**
 * Don't import from config in case those files have errors in them
 */
window.env = window.env || {}
const env = window.env.REACT_APP_CONFIG_ENV
  || process.env.REACT_APP_CONFIG_ENV
  || process.env.NODE_ENV
  || 'local'

SentryReact.init({
  dsn: "https://e173c8751fd341919f8dcf0aa3488075@o974057.ingest.sentry.io/5925367",
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: `ds-client-chalhoub-${env}`,
})


const startApp = () => {
  ReactDOM.render((
    <Routes />
  ), document.getElementById('root'))
}

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false)
} else {
  startApp()
}
