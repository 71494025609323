import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ProductDetailScreen from './ProductDetailScreen'
import ProductWaitlistScreen from './ProductWaitlistScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/product/:id/variant/:variantId' path='/product/:id/variant/:variantId' exact component={TimedLogoutComponent(ProductDetailScreen)} allowOffline />,
  <PrivateRoute key='/product/:id' path='/product/:id' exact component={TimedLogoutComponent(ProductDetailScreen)} allowOffline />,
  <PrivateRoute key='/product/:id/waitlist' path='/product/:id/waitlist' exact component={TimedLogoutComponent(ProductWaitlistScreen)} />
]
