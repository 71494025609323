import { visibility } from '../../../config/theme'

const styles = theme => {
  const { hideForExtraSmall } = visibility(theme)
  return {
    hideForExtraSmall,
    container: {
      fontSize: '19px',
      borderBottom: `0.5px solid ${theme.palette.contentBoxGrey.main}`,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      alignItems: 'stretch',
      fontWeight: 'bold',
      [theme.breakpoints.up('sm')]: {
        minHeight: '60px'
      }
    },
    inner: {
      margin: '0 8px',
      alignItems: 'center',
      textTransform: 'uppercase',
      position: 'relative',
      display: 'flex'
    },
    buttonGridItem: {
      display: 'flex'
    },
    centerButtonGridItem:{
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    cancelButton: {
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        order: 3,
        justifyContent: 'center',
        marginBottom: '10px'
      }
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}

export default styles
