import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../../config'
import KeyValueCard from '../../../../../components/KeyValueCard'

import style from './style'

class OrderInfoVertical extends Component {
  _translateKey = (key) => {
    return translations(`Order ${_.startCase(key)}`)
  }

  render () {
    const { info = [], classes, emphasisKey, fillSpace } = this.props
    const topKeys = ['channel', 'deliveryOption', 'user', 'fulfillmentLocation']
    const bottomKeys = ['subTotal', 'discount', 'deliveryCost']

    const topData = info
      .filter(([key]) => topKeys.includes(key))
      .map(([key, value]) => {
        return [
          this._translateKey(key),
          value,
          {
            className: cx({
              [classes.truncateOnDesktop]: true,
              [classes.hideOnDesktop]: key === emphasisKey,
              [classes.fillSpace]: fillSpace
            })
          }
        ]
      })

      const emphasisedInfo = info.find(([key]) => key === emphasisKey)
      const bottomData = info
        .filter(([key]) => bottomKeys.includes(key))
        .map(([key, value]) => [
          this._translateKey(key),
          value,
          {
            className: cx({
              [classes.truncateOnDesktop]: true,
              [classes.hideOnDesktop]: key === emphasisKey,
              [classes.fillSpace]: fillSpace,
            }),
          },
        ])
        .concat(
          emphasisedInfo
            ? [
                [
                  this._translateKey(emphasisedInfo[0]),
                  emphasisedInfo[1],
                  {
                    className: cx({
                      [classes.truncateOnDesktop]: true,
                      [classes.fillSpace]: fillSpace,
                    }),
                  },
                ],
              ]
            : []
        )
      
  
    return <div className={classes.container}>
      <KeyValueCard
        className={classes.top}
        data={topData}
      />
      <KeyValueCard
        className={classes.bottom}
        data={bottomData}
      />
    </div>
  }
}

export default withStyles(style)(OrderInfoVertical)
