import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Image from '../../../components/Image'
import ButtonBase from '@material-ui/core/ButtonBase'
import P from '../../../components/P'
import _ from 'lodash'
import currencyFormatter from '../../../formatters/currencyFormatter'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import { getImage } from '../../../components/Images'
import Chip from '../../../components/Chip'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { selectors as productDetailSelectors } from '../../../store/modules/productDetails'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import SelectVariant from '../../Products/common/Results/ProductsGrid/ProductsGridItem/SelectVariant'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import media from '../../../config/media'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
`

const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImageItem = styled(Image)`
  max-height: 116px;
`

const ProductName = styled(P)`
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  ${media.greaterThan('md')`
    margin-right: 20px;
  `}
`

const VariantName = styled(P)`
  margin: 0.5rem 0;
  text-align: left;
`

const PriceText = styled(P)`
  font-weight: bold;
  text-align: left;
`

const Icon = styled.img`
  width: 25px;
`

const Button = styled(IconButton)`
  padding: 6px;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1rem;
`

const DetailsContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const RemoveButton = styled(IconButton)`
  z-index: 1;
  margin-top: -12px;
`

const RmIcon = styled(RemoveIcon)`
  ${media.greaterThan('md')`
    width: 20px;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`

const ActiveChip = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}`

const ImageWrapper = styled.div`
  position: relative;
`

const ServiceChip = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
`

const PurchasedDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const QuantityText = styled(P)`
  margin-right: 1rem;
`

const ConsultationContentItem = (props) => {
  const {
    type,
    title,
    name,
    image,
    images,
    variant,
    priceToDisplay,
    price,
    isLoved,
    variants,
    onClick,
    selected,
    service,
    hideButtons,
    purchased,
    quantity
  } = props
  const dispatch = useDispatch()
  const variantName = variant && variant.name
  const imageToUse = images ? _.get(images, '0') : image
  const productName = title || name

  const addToWishlist = useCallback((e) => {
    e.stopPropagation()
    dispatch(currentAppointmentActions.toggleContentIsLoved({ type, details: props }))
  })
  const wishlistIcon = isLoved ? getImage('wishlistSelectedIcon') : getImage('wishlistIcon')

  const onClickAddToBasket = useCallback((e) => {
    e.stopPropagation()
    if (variants && variants.length > 1) {
      modalService.open({
        component: SelectVariant,
        smallModal: true,
        variants: variants,
        variant,
        title: translations('Select A Variant'),
        modalIndex: 1,
        actions: [
          {
            success: true,
            primary: true,
            text: translations('Done'),
            onClick: (productWithVariant) =>
              dispatch(currentOrderActions.addProduct({ product: productWithVariant, quantity: 1 }))
          },
          {
            text: translations('Cancel'),
            onClick: _.noop
          }
        ]
      })
    } else {
      dispatch(
        currentOrderActions.addProduct({
          product: props,
          quantity: 1
        })
      )
    }
  })

  const removeContent = useCallback(() => {
    const { type, ...details } = props
    dispatch(currentAppointmentActions.removeContent({ type, details, removeVariant: true }))
    dispatch(currentOrderActions.removeProduct({ product: props }))
  })

  return (
    <ButtonBase onClick={onClick}>
      <Container container selected={selected}>
        <ContainerItem>
          <ImageWrapper>
            <ImageItem src={imageToUse} />
            {service && (
              <ServiceChip>
                <Chip type={'service'}>{translations('Service')}</Chip>
              </ServiceChip>
            )}
          </ImageWrapper>
        </ContainerItem>
        <ContainerItem>
          <DetailsContainer>
            <DetailsContainerWrapper>
              <ProductName value={productName} />
              <VariantName value={variantName} />
              {type === 'product' && (
                <PriceText
                  value={productDetailSelectors.getDisplayPriceOfProduct(props)}
                />
              )}
            </DetailsContainerWrapper>
            <DetailsContainerWrapper>
              {!hideButtons && (
                <RemoveButton aria-label={translations('Remove')} onClick={removeContent}>
                  <RmIcon />
                </RemoveButton>
              )}
            </DetailsContainerWrapper>
          </DetailsContainer>
          {!hideButtons && (
            <ButtonsContainer>
              <Button
                onClick={addToWishlist}
              >
                <Icon src={wishlistIcon} />
              </Button>

              {type === 'product' && (
                <Button onClick={onClickAddToBasket}>
                  <Icon src={getImage('basketPlus')} />
                </Button>
              )}
              {selected && (
                <ActiveChip>
                  <Chip type={'success'} style={{ fontSize: 13, width: 95, height: 26 }}>
                    Active item
                  </Chip>
                </ActiveChip>
              )}
            </ButtonsContainer>
          )}
        </ContainerItem>

        {purchased && (
          <ContainerItem>
            <PurchasedDetails>
              <QuantityText value={`QTY: ${quantity}`} />
              <Chip type={'success'} style={{ fontSize: 13, width: 95, height: 26 }}>
                Purchased
              </Chip>
            </PurchasedDetails>
          </ContainerItem>
        )}
      </Container>
    </ButtonBase>
  )
}

export default ConsultationContentItem
