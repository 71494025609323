import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { translations } from '../../config'

const CurrencySelectButton = styled.button`
  position: absolute;
  display: flex;
  z-index: 10;
  background: #ffffff;
  border: 1px solid #777777;
  color: #777777;
  border-radius: 3px;
  font-size: 10px;
  right: 55px;
  top: 15px;
  cursor: pointer;
  padding: 5px;
`

const CurrencySelectIndicatoValue = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 3px;
`

const CurrencyMenuButton = ({
  currentCurrency,
  openCurrencyMenu
}) => {
  return <>
    <CurrencySelectButton onClick={openCurrencyMenu}>
      {translations(`label-currency`)}<CurrencySelectIndicatoValue>{currentCurrency}</CurrencySelectIndicatoValue>
    </CurrencySelectButton>
  </>
}

CurrencySelectIndicatoValue.propTypes = {
  currentCurrency: PropTypes.string,
  openCurrencyMenu: PropTypes.func.isRequired
}

export default CurrencyMenuButton