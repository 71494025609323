import * as constants from './constants'
import { constants as messageDetailConstants } from '../messageDetails'
import reducers from './reducers'
import { INITIAL } from '../../middleware/redux-promise'

const defaultState = {
  status: INITIAL,
  error: undefined,
  customer: undefined,
  editType: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_FULL_CUSTOMER:
      return reducers.fetchFullCustomer(state, action)
    case constants.CREATE_CUSTOMER:
      return reducers.createCustomer(state, action)
    case constants.UPDATE_CUSTOMER:
      return reducers.updateCustomer(state, action)
    case constants.UPDATE_CUSTOMER_NOTES:
      return reducers.updateCustomer(state, action)
    case constants.UPDATE_CUSTOMER_WISHLIST:
      return reducers.updateCustomer(state, action)
    case constants.DELETE_CUSTOMER_NOTE:
      return reducers.updateCustomer(state, action)
    case constants.EDIT_CUSTOMER_NOTE:
      return reducers.updateCustomer(state, action)
    case constants.FOLLOW_CUSTOMER:
      return reducers.followCustomer(state, action)
    case constants.UNFOLLOW_CUSTOMER:
      return reducers.unfollowCustomer(state, action)
    case constants.ANONYMISE_CUSTOMER:
      return reducers.anonymiseCustomer(state, action)
    case constants.EDIT_PRODUCT_CAROUSEL:
      return reducers.editProductCarousel(state, action)
    case messageDetailConstants.FLAG_MESSAGE_INAPPROPRIATE:
      return reducers.addFlagToMessageIfIdMatches(state, action)
    default:
      return state
  }
}
