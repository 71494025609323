import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import Button from '../../../components/Button'
import P from '../../../components/P'
import H2 from '../../../components/H2'
import style from './style'
import { translations } from '../../../config'


const AttachmentContainer = styled.div`
  margin-bottom: 30px;
`

const Title = styled(H2)`
  margin-bottom: 30px;
`

const AttachmentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`

const AttachmentButton = styled(Button)`
  white-space: break-spaces;
  text-align: left;
  flex: 1;
  justify-content: left;
  margin-left: 5px;
`


const ViewAttachmentModal = ({ attachments, onAttactmentClick }) => {
  return (
    <Fragment>
      <Title value={translations('Attachment(s)')} />
      {!_.isEmpty(attachments) && <AttachmentContainer>
        {attachments.map(attachment => {
          console.log('JF attachment: ' + JSON.stringify(attachment, null, 2))
          return (
          <AttachmentRow>
            {translations(_.startCase(attachment.type))}: 
            <AttachmentButton
              key={attachment.id}
              onClick={() => onAttactmentClick(attachment)}
              hyperlink
            >{attachment.name}</AttachmentButton>
          </AttachmentRow>
        )}
        )}
      </AttachmentContainer>}
    </Fragment>
  )
}


ViewAttachmentModal.propTypes = {
  attachments: PropTypes.array,
  onAttactmentClick: PropTypes.func
}

export default withStyles(style)(ViewAttachmentModal)
