import React from 'react'
import _ from 'lodash'

import ContinueOverlay from '../../components/ContinueOverlay'
import ActionModalOverlay from '../../components/ActionModalOverlay'
import VideoModalOverlay from '../../components/VideoModalOverlay'
import TextInputModalOverlay from '../../components/TextInputModalOverlay'
import { translations } from '../../config'

class ModalService {
  constructor () {
    this._deferred = this._deferred.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this._open = this._open.bind(this)
    this.continue = this.continue.bind(this)
    this.close = this.close.bind(this)

    // 3 = the number of modal layers we can handle.
    // if you want more, you will also need to add
    // more <Modal/>s to RootScreen.js
    this._modals = _.times(4, this._deferred)
  }

  _getModal = (modalIndex = 0) => {
    if (modalIndex >= this._modals.length) {
      throw new Error('modalIndex exceeds the number of modals set up')
    } else {
      return this._modals[modalIndex]
    }
  }

  connect ({ open, close, modalIndex }) {
    this._getModal(modalIndex).resolve({ open, close })
  }

  disconnect ({ modalIndex = 0 } = {}) {
    this._modals[modalIndex] = this._deferred()
  }

  close ({ modalIndex } = {}) {
    return this._getModal(modalIndex).promise
      .then(({ close }) => {
        close()
      })
  }

  _open ({
    component,
    props,
    fullScreen,
    smallModal,
    wideModal,
    zoomModal,
    fixedWidthModal,
    modalIndex,
    overflowHidden
  }) {
    return this._getModal(modalIndex).promise
      .then(({ open }) => {
        open({
          component,
          props,
          fullScreen,
          smallModal,
          wideModal,
          zoomModal,
          fixedWidthModal,
          overflowHidden
        })
      })
  }

  continue ({
    modalIndex,
    title,
    text,
    children,
    confirmButtonText = translations('Continue'),
    wideModal = false,
    success = () => {}
  }) {
    const confirmDeferred = this._deferred()
    const actions = [
      {
        success: true,
        primary: true,
        disabled: false,
        text: confirmButtonText,
        onClick: _.wrap(success, (fn, ...args) => {
          this.close({ modalIndex })
          const onClickResult = fn ? fn(...args) : undefined
          confirmDeferred.resolve(onClickResult)
        })
      }
    ]
    return this._open({
      modalIndex,
      component: ContinueOverlay,
      wideModal,
      props: {
        title,
        text,
        actions,
        children
      }
    })
    .then(() => confirmDeferred.promise)
  }

  action ({
    modalIndex,
    title,
    text,
    actions = [],
    ...rest
  }) {
    const { confirmDeferred, actions: wrappedActions } = this._wrapActions(actions, modalIndex)
    return this._open({
      modalIndex,
      component: ActionModalOverlay,
      props: {
        ...rest,
        title,
        text,
        actions: wrappedActions
      }
    })
    .then(() => confirmDeferred.promise)
  }

  video ({
    modalIndex,
    title,
    src,
    ...rest
  }) {
    return this._open({
      modalIndex,
      component: VideoModalOverlay,
      props: {
        ...rest,
        noPadding: true,
        title,
        src
      }
    })
  }

  textInput ({
    modalIndex = 0,
    title,
    inputLabel,
    submitText = 'Submit',
    ...rest
  }) {
    return this._open({
      modalIndex,
      component: TextInputModalOverlay,
      props: {
        ...rest,
        modalIndex,
        noPadding: true,
        title,
        inputLabel,
        submitText
      }
    })
  }

  open ({
    modalIndex = 0,
    component: Component,
    actions = [],
    fullScreen = false,
    smallModal = false,
    wideModal = false,
    zoomModal = false,
    fixedWidthModal = false,
    overflowHidden = false,
    ...rest
  }) {
    const { confirmDeferred, actions: wrappedActions } = this._wrapActions(actions, modalIndex)
    return this._open({
      modalIndex,
      component: Component,
      fullScreen,
      smallModal,
      overflowHidden,
      wideModal,
      zoomModal,
      fixedWidthModal,
      props: {
        ...rest,
        actions: wrappedActions,
        dismiss: () => this.close({modalIndex}),
        modalIndex
      }
    })
    .then(() => confirmDeferred.promise)
  }
  _wrapActions = (actions, modalIndex) => {
    const confirmDeferred = this._deferred()
    const wrappedActions = _.chain(actions)
      .map(action => {
        if (action.success) {
          return {
            ...action,
            onClick: action.onClick
              ? _.wrap(action.onClick, (fn, ...args) => {
                this.close({ modalIndex })
                confirmDeferred.resolve(fn(...args))
              })
              : action.onClick
          }
        } else {
          return {
            ...action,
            onClick: _.wrap(action.onClick, (fn, ...args) => {
              this.close({ modalIndex })
              const fnResult = fn ? fn(...args) : undefined
              confirmDeferred.reject(fnResult)
            })
          }
        }
      })
      .value()
    return {
      actions: wrappedActions,
      confirmDeferred
    }
  }

  _deferred () {
    let pResolve
    let pReject
    const promise = new Promise((resolve, reject) => {
      pResolve = resolve
      pReject = reject
    })
    return {
      resolve: pResolve,
      reject: pReject,
      promise: promise
    }
  }
}

export default new ModalService()
