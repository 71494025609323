import React from 'react'
import Barcode from 'react-barcode'
import { push, LOCATION_CHANGE, replace } from 'connected-react-router'
import _ from 'lodash'
import { matchPath } from 'react-router'

import { translations, getAppConfig } from '../../../config'
import modalService from '../../../services/modalService'
import {
  pathForCheckoutType,
  getModulesFromCheckoutType,
  getPathFromCheckoutType,
  pathsForCheckoutModule,
  getCheckoutModuleNameFromPath
} from '../../../helpers'
import uploadOrder from '../../../containers/Checkout/uploadOrder'
import {
  constants as currentOrderConstants,
  actions as currentOrderActions,
  selectors as currentOrderSelectors
} from '../currentOrder'
import { constants as customerDetailsConstants } from '../customerDetails'
import {
  actions as currentAppointmentActions,
  selectors as currentAppointmentSelectors
} from '../currentAppointment'
import { selectors as authSelectors } from '../auth'
import { selectors as checkoutFlowSelectors } from '../checkoutFlow'
import * as currentOrderCombinedSelectors from '../combinedSelectors/currentOrderCombinedSelectors'
import { history } from '../../../store'
import actions from './actions'
import * as constants from './constants'
import analyticsService from '../../../services/analyticsService'
import FormModal from '../../../components/FormModal'

// some notes:
// changing state.current to the correct module and handling the
// next module (routing to a component or any other function) are
// done separately so that the app can cope with back functionality
// (both via browser back button and our back button in subheader)

const getOrderSuccessModalText = (currentOrder) => {
  if (
    currentOrder.salesChannel === 'home-visit' ||
    currentOrder.salesChannel === 'distant-sale'
  ) {
    return translations('Distant Order Successful Text')
  }
  return translations('Order Successful Text')
}

const handleNext = ({ dispatch, getState }) => ({
  checkoutType,
  checkoutModuleName,
  moduleConfig
}) => {
  const serviceRequired = getAppConfig('CONSULTATIONS', 'serviceRequired')
  const state = getState()
  const paths = pathsForCheckoutModule[checkoutModuleName]
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  if (
    serviceRequired &&
    checkoutType === 'consultation' &&
    !_.get(order, 'groupedProducts', []).find((product) => product.service)
  ) {
    modalService.continue({
      title: translations('Service Required Title'),
      text: translations('Service Required Text')
    })
    // return out of function without moving forward
    return
  }
  if (paths) {
    dispatch(push(`/${getPathFromCheckoutType(checkoutType)}/${paths[0]}`))
  } else {
    switch (checkoutModuleName) {
      case 'COMPLETE_ORDER':
        dispatch(actions.setLoading(true))
        const currentOrder = currentOrderCombinedSelectors.currentOrderSelector(state)
        const customerId = _.get(currentOrder, 'customer.id')
        // For Email Basket (referral order) - orderstatus causes a bug as it is not required for Validation so needs to be removed.
        const currentOrderStatus = checkoutType === 'referral' ?  undefined : _.get(currentOrder, 'status')
        let orderType
        switch (true) {
          case checkoutType === 'referral':
            orderType = checkoutType
            break
          case checkoutType === 'storeroom':
            orderType = checkoutType
            break
          case checkoutType === 'consultation':
            orderType = 'consultation'
            break
          default:
            orderType = 'standard'
            break
        }
        let status
        switch (checkoutType) {
          case 'storeroom':
            status = 'awaiting_picking'
            break
          default:
            status = 'complete'
            break
        }
        uploadOrder({
          ...currentOrder,
          customerId,
          orderType,
          storeId: authSelectors.getUserSelectedStoreId(state),
          userId: authSelectors.getActiveUserId(state),
          status: currentOrderStatus || status
        })
          .then((resp) => {
            dispatch(actions.setLoading(false))
            const orderId = _.get(resp, 'id')
            if (orderId) {
              dispatch(actions.setOrderId(orderId))
            }
            dispatch(actions.setOrderNumber(resp.orderNumber))
            if (checkoutType === 'referral') {
              analyticsService.sendCustomEvent({ type: 'referralSent' })
            } else if (checkoutType === 'storeroom') {
              analyticsService.sendCustomEvent({ type: 'storeroomRequestSubmitted' })
            } else {
              analyticsService.sendCustomEvent({ type: 'eReceiptSent' })
            }
            dispatch(actions.next())
          })
          .catch((resp) => {
            dispatch(actions.setLoading(false))
            dispatch(actions.fail(resp.code))

            const integrationErrorCodes = ['RA-29-05', 'RA-29-06', 'RA-29-07']
            if (integrationErrorCodes.includes(resp.code)) {
              // The order was still saved on the server, so clear the basket.
              dispatch(currentOrderActions.clearOrder({}))
            }
          })
        break
      case 'CONFIRMATION_MODAL':
        const orderNumber = _.get(state, 'checkoutFlow.orderNumber')
        const customerEmail = _.get(state, 'currentOrder.customer.email')
        const currentOrderDetails = currentOrderCombinedSelectors.currentOrderSelector(state)
        // close the consultation review modal
        modalService.close({ modalIndex: 1 })
        const modalServiceContinueArguments = {
          title: getOrderSuccessTitle(),
          actions: [
            {
              text: getOrderSuccessCloseButtonText()
            }
          ]
        }

        const currentAppointmentId = currentAppointmentSelectors.getAppointmentId(state)
        const consultationMode = currentAppointmentSelectors.getIsAppointmentActive(state)
        const showBarcode = orderNumber && checkoutType === 'checkout' && currentOrderDetails.deliveryType === 'inStoreSale'
        modalServiceContinueArguments.children = <div style={{ 
          textAlign: showBarcode ? 'center' : 'left',
          display: 'flex',
          flexDirection: 'column',
          alignItems: showBarcode ? 'center' : 'left' 
        }}>
          {_.template(
            consultationMode
            ? translations('Consultation Successful Text', { orderNumber })
            : getOrderSuccessModalText(currentOrderDetails)
            )({ orderNumber, customerEmail })}
        {showBarcode && <Barcode value={orderNumber} width={1.5} />}
        </div>
        if (orderNumber) {
          const distantSaleCheck = currentOrderDetails.salesChannel === 'distant-sale'        
          const orderPath = `/orders/${orderNumber}${distantSaleCheck && `?actionsFor=/distant-sales`}`
          modalServiceContinueArguments.actions.push({
            primary: true,
            text: translations('View order'),
            onClick: () => dispatch(push(orderPath))
          })
        }
        // modalService.action(modalServiceContinueArguments)
        // dispatch(actions.end())
        if(orderNumber && (currentOrderDetails.salesChannel === 'home-visit' ||
        currentOrderDetails.salesChannel === 'distant-sale') ) {
          modalService.action(modalServiceContinueArguments)      
        } else {
          modalService.continue(modalServiceContinueArguments)        
        }
        if (currentAppointmentId) {
          const orderId = _.get(state, 'checkoutFlow.orderId')
          const currentStage = currentAppointmentSelectors.getAppointmentStage(getState())
          if (currentStage === 'PAYMENT_PENDING') {
            dispatch(currentAppointmentActions.updateCurrentAppointment({
              id: currentAppointmentId,
              orderId,
              status: 'COMPLETE'
            }))
              .then(() => {
                const previousPath = getState().checkoutFlow.previousPath
                dispatch(push(previousPath || '/'))
              })
          } else {
            dispatch(currentAppointmentActions.setStage({ stage: 'COMPLETE' }))
            dispatch(currentAppointmentActions.updateCurrentAppointment({
              id: currentAppointmentId,
              orderId,
              status: 'COMPLETE'
            }))
              .then(() => {
                dispatch(actions.end())
              })
          }
        } else {
          dispatch(actions.end())
        }
        break
      case 'CONFIRM_START_CHECKOUT_MODAL':
        modalService.action({
          title: translations('Confirm Start Checkout Modal Title'),
          text: translations('Confirm Start Checkout Modal Text'),
          actions: [
            {
              text: translations('Yes'),
              success: true,
              primary: true,
              onClick: () => dispatch(actions.next())
            },
            {
              text: translations('Cancel')
            }
          ]
        })
        break
      case 'NOTES_MODAL':
        // Default notes schema, can be overriden by brand config
        let schema = [
          {
            id: 'notes',
            field: 'Input',
            props: {
              label: translations('Notes'),
              name: 'notes',
              multiline: true,
              rows: 1,
              rowsMax: 10
            }
          }
        ]

        if (moduleConfig.fields) {
          schema = moduleConfig.fields.map(({ id, component, label, props }) => ({
            id,
            field: component,
            props: { label: translations(label), name: id, ...props }
          }))
        }
        modalService.open({
          component: FormModal,
          formId: 'notes',
          title: translations(moduleConfig.title || 'Add Notes'),
          onSubmit: ({ notes }) => {
            dispatch(currentOrderActions.updateOrder({ details: { notes } }))
            dispatch(actions.next())
            modalService.close()
          },
          schema
        })
        break
      default:
        break
    }
  }

  function getOrderSuccessTitle() {
    if (checkoutType === 'referral') {
      return translations('Referral Successful')
    } else if (checkoutType === 'storeroom') {
      return translations('Storeroom Request Submitted')
    } else {
      return translations('Order Successful')
    }
  }

  function getOrderSuccessCloseButtonText() {
    if (checkoutType === 'referral' || checkoutType === 'storeroom' ) {
      return translations('Exit')
    } else {
      return translations('Continue')
    }
  }
}

class CheckoutFlowMiddleware {
  nextMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === constants.START) {
      const state = getState()
      const checkoutType = action.checkoutType
      const deliveryType = _.get(state, 'currentOrder.deliveryType')
      const existingCheckoutType = _.get(state, 'checkoutFlow.checkoutType')
      if (existingCheckoutType && checkoutType !== existingCheckoutType) {
        dispatch(currentOrderActions.clearOrderExceptBasket())
      }
      if (
        action.checkoutType !== 'referral' &&
        currentOrderCombinedSelectors.getCurrentOrderHasPreviewProducts(getState())
      ) {
        modalService.continue({
          title: translations('There are preview products in your basket'),
          text: translations('Please remove these to enable checkout'),
          success: () => dispatch(actions.clear())
        })
      } else if (_.get(getState(), 'currentOrder.customerWasAnonymised', false)) {
        modalService.action({
          title: translations('The customer associated to this order was anonymised'),
          text: translations('Please chose from actions below:'),
          actions: [
            {
              text: translations('Close'),
              onClick: () => dispatch(actions.clear())
            },
            {
              text: translations('Clear basket'),
              onClick: () => dispatch(currentOrderActions.clearOrder({}))
            },
            {
              primary: true,
              text: translations('Re-assign'),
              onClick: () => {
                dispatch(currentOrderActions.resetCustomerWasAnonymised())
                dispatch(actions.start({ checkoutType: action.checkoutType }))
              }
            }
          ]
        })
      } else {
        // START
        const pathname = action.previousPath || history.location.pathname
        if (!_.values(pathForCheckoutType).find((path) => pathname.startsWith(path))) {
          dispatch(actions.setPreviousPath(pathname))
        }
        const startCheckoutFromBeginning = () => {
          const moduleConfig = _.get(getModulesFromCheckoutType(checkoutType, deliveryType), '0')
          const firstModuleName = _.get(moduleConfig, 'name')
          dispatch(currentOrderActions.clearOrderExceptBasket())
          dispatch(actions.setCurrent(firstModuleName))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: firstModuleName,
            moduleConfig
          })
        }

        const resumeCheckoutWithCustomer = () => {
          const modules = getModulesFromCheckoutType(checkoutType, deliveryType)
          const customerModuleIndex = _.findIndex(modules, (m) => m.name === 'SELECT_CUSTOMER')
          const nextModuleIndex = customerModuleIndex > -1 ? customerModuleIndex + 1 : 0
          const moduleConfig = _.get(modules, nextModuleIndex)
          const nextModuleName = _.get(moduleConfig, 'name')
          dispatch(actions.setCurrent(nextModuleName))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: nextModuleName,
            moduleConfig
          })
        }

        const currentOrder = getState().currentOrder

        if (checkoutType === 'storeroom' || currentOrder.startCheckoutWithCustomer) {
          resumeCheckoutWithCustomer()
        } else {
          if (currentOrder.customer) {
            resumeCheckoutWithCustomer()
          } else {
            startCheckoutFromBeginning()
          }
        }
        // when checkout starts, if there is a current appointment set the stage to 'CHECKOUT'
        const currentAppointmentId = currentAppointmentSelectors.getAppointmentId(state)
        if (currentAppointmentId) {
          dispatch(currentAppointmentActions.setStage({ stage: 'CHECKOUT' }))
        }
      }
    } else if (action.type === constants.NEXT) {
      // NEXT
      // workout what next module is and do it
      const state = getState()
      const currentModuleName = _.get(state, 'checkoutFlow.current')
      const checkoutType = _.get(state, 'checkoutFlow.checkoutType')
      const deliveryType = _.get(state, 'currentOrder.deliveryType')
      const moduleSteps = getModulesFromCheckoutType(checkoutType, deliveryType)
      if (currentModuleName) {
        const n = _.findIndex(moduleSteps, (m) => m.name === currentModuleName)
        if (n < 0) {
          dispatch(actions.start({ checkoutType }))
        } else if (n >= moduleSteps.length - 1) {
          dispatch(actions.end())
        }
        const customerEmail = _.get(state, 'currentOrder.customer.email')
        if (checkoutType === 'referral' && !customerEmail) {
          modalService.continue({
            title: translations('Warning'),
            text: translations('This customer does not have an email address.'),
            confirmButtonText: translations('Cancel')
          })
        } else {
          const moduleConfig = moduleSteps[n + 1]
          dispatch(actions.setCurrent(moduleConfig.name))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: moduleConfig.name,
            moduleConfig
          })
        }
      } else {
        dispatch(actions.start({ checkoutType }))
      }
    } else if (action.type === constants.END) {
      // END
      // go back to home screen
      const previousPath = getState().checkoutFlow.previousPath
      const isVirtualConsultation = currentAppointmentSelectors.getIsAppointmentVirtual(getState())
      if (!isVirtualConsultation) {
        dispatch(currentOrderActions.clearOrder({ clearAppointment: true }))
      }
      dispatch(push(previousPath || '/'))
    } else if (action.type === constants.FAIL) {
      // FAIL
      // go back to home screen and show modal saying failed
      const previousPath = getState().checkoutFlow.previousPath
      dispatch(push(previousPath || '/'))
      modalService.continue({
        title: translations('Error') + (action.code ? ` ${action.code}` : ''),
        text: translations('Order Fail Text'),
        success: () => {
          dispatch(currentOrderActions.clearOrder({}))
          modalService.close({ modalIndex: 1 })
        }
      })
    } else if (action.type === constants.BACK) {
      // BACK
      // workout what previous module is and do it
      const state = getState()
      const currentModuleName = _.get(state, 'checkoutFlow.current')
      const checkoutType = _.get(state, 'checkoutFlow.checkoutType')
      const deliveryType = _.get(state, 'currentOrder.deliveryType')
      const moduleSteps = getModulesFromCheckoutType(checkoutType, deliveryType)
      const isVirtualConsultation = currentAppointmentSelectors.getIsAppointmentVirtual(getState())
      if (currentModuleName) {
        const n = _.findIndex(moduleSteps, (m) => m.name === currentModuleName)
        const minimumCheckoutStep = isVirtualConsultation ? 2 : 0
        if (n <= minimumCheckoutStep) {
          // close checkoutflow
          modalService.close()
          const previousPath = getState().checkoutFlow.previousPath
          dispatch(push(previousPath || '/'))
        } else if (n >= minimumCheckoutStep + 1) {
          const moduleConfig = moduleSteps[n - 1]
          dispatch(actions.setCurrent(moduleConfig.name))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: moduleConfig.name,
            moduleConfig
          })
        }
      }
    }

    next(action)
  }

  handleRouteChangeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const currentModuleName = _.get(getState(), 'checkoutFlow.current')
      const checkoutType = _.get(getState(), 'checkoutFlow.checkoutType')
      const pathFromCheckoutType = checkoutType && getPathFromCheckoutType(checkoutType)
      const basketId = _.get(getState(), 'currentOrderSalesforce.basket.basketId')
      if (currentModuleName) {
        const string = path.replace(`/${pathFromCheckoutType}/`, '')
        const checkoutModule = getCheckoutModuleNameFromPath(string)
        if (checkoutModule) {
          dispatch(actions.setCurrent(checkoutModule))
        } else {
          // nb: this clears the checkoutFlow store, aka what is current module
          // but not the currentOrder store, aka customer, delivery, etc
          // dispatch(actions.clear())
        }
      }

      const isCheckoutDelivery = matchPath(path, { path: `/${checkoutType}/${pathsForCheckoutModule.DELIVERY[0]}`, exact: true })
      if (isCheckoutDelivery) {
        dispatch(actions.fetchDeliveryOptions(basketId))
      }
    }
  }

  createCustomerSuccessMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.type === currentOrderConstants.CREATE_CUSTOMER_FOR_ORDER &&
      action.status === 'SUCCESS'
    ) {
      dispatch(actions.next())
    } else if (
      action.type === customerDetailsConstants.CREATE_CUSTOMER &&
      action.status === 'SUCCESS'
    ) {
      const consultationMode = currentAppointmentSelectors.getIsAppointmentActive(getState())
      const customerId = _.get(action, 'result.id')
      if (consultationMode) {
        dispatch(currentOrderActions.addCustomer({ id: customerId }))
        dispatch(replace('/consultations'))
      }
    }
    next(action)
  }
}

export default new CheckoutFlowMiddleware()
