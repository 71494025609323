import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import { getFullName, getCustomerFullName, getProductFullName } from '../../helpers'
import cx from 'classnames'
import moment from 'moment'
import { translations, timeFormat, dateFormat } from '../../config'
import ButtonLink from '../ButtonLink'
import P from '../P'

const dateAndTimeFormat = `${dateFormat} - ${timeFormat}`

const summaryFields = (order, classes) => {
  const fields = {
    orderTime: {
      label: 'Time',
      value: moment(_.get(order, 'orderDate')).format(timeFormat),
      fullWidth: false
    },
    orderDate: {
      label: 'Date',
      value: moment(_.get(order, 'orderDate')).format(dateAndTimeFormat),
      fullWidth: false
    },
    productCount: {
      label: 'Number Of Items',
      value: _.chain(order)
        .get('products', [])
        .filter((p) => !p.unsold)
        .value().length
    },
    userName: {
      label: 'User',
      value: getFullName(_.get(order, 'user'))
    },
    customerName: {
      label: 'Customer',
      value: getCustomerFullName(_.get(order, 'customer')),
      fullWidth: true
    },
    orderNumber: {
      label: 'Number',
      value: _.get(order, 'orderNumber', '').split('-').pop(),
      clickHandler: true
    },
    productIDs: {
      label: 'Products',
      value: _.get(order, 'products', []).map(p => {
        return <P
          key={_.get(p, 'variant.ean')}
          value={_.get(p, 'variant.ean')}
          className={cx(classes.orderDataValue, classes.orderDataValueSku)}
        />
      }),
      list: true,
      fullWidth: true
    },
    productNames: {
      label: 'Products',
      value: _.get(order, 'products', []).map(p => {
        return <P
          key={_.get(p, 'variant.ean')}
          value={getProductFullName(p)}
          className={cx(classes.orderDataValue, classes.orderDataValueSku)}
        />
      }),
      list: true,
      fullWidth: true
    },
    status: {
      label: 'Status',
      value: translations(`Order Status - ${_.get(order, 'status')}`),
      fullWidth: true
    }
  }

  if (_.get(order, 'products', []).filter(p => _.get(p, 'variant.details.binNumber')).length > 0) {
    fields.binNumber = {
      label: 'Bin Numbers',
      value: _.get(order, 'products', []).map(p => {
        if (p.variant && p.variant.details && p.variant.details.binNumber) {
          return <div>{translations('BIN Number Entry (Storeroom Kanban)', {
            binNumber: p.variant.details.binNumber,
            variant: p.variant.name
          })}</div>
        }
      }),
      list: true,
      fullWidth: true
    }
  }

  return fields
}

const OrderKanbanCard = ({ fields, order, classes, handleAction }) => {
  const allFields = summaryFields(order, classes)

  const data = fields.reduce((ret, key) => {
    const field = allFields[key]
    if (field && field.value) ret.push(field)
    return ret
  }, [])

  const handleClick = e => {
    e.stopPropagation()
    handleAction && handleAction(_.get(order, 'orderNumber'))
  }

  return <div
    key={order.id}
    onClick={handleClick}
  >
    <dl className={classes.orderContainer}>
      {data.map(({ label, value, clickHandler, fullWidth, list }) => {
        const valueStyles = []
        if (fullWidth) valueStyles.push(classes.fullWidthLabelValueContainer)
        if (list) valueStyles.push(classes.listLabelValueContainer)
        valueStyles.push(classes.labelValueContainer)

        return <div
          className={cx(...valueStyles)}
          key={label}
        >
          <dt className={classes.orderDataLabel}>{translations(`Order ${label} (Storeroom Kanban)`) + ':'}</dt>
          <dd className={classes.orderDataValue}>
            {clickHandler ? <ButtonLink className={classes.orderNumberButton} onClick={handleClick}>{value}</ButtonLink> : value}
          </dd>
        </div>
      })}
    </dl>
  </div>
}

OrderKanbanCard.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.object,
  handleAction: PropTypes.func,
  classes: PropTypes.shape({})
}

export default withStyles(style)(OrderKanbanCard)
