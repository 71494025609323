import { createSelector } from 'reselect'
import _ from 'lodash'
import { selectors as categoriesSelectors } from '../categories'
import { SEARCH_PRODUCTS_FRESH, SEARCH_PRODUCTS_NEXT } from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { groupedProductsEnabledPLP } from '../../../config'
import parseProductGroups from './parseProductGroups'
import getSelectedCurrencyPrice from '../../../helpers/getSelectedCurrencyPrice'
import getSelectedCurrencyDiscount from '../../../helpers/getSelectedCurrencyDiscount'

export const getCurrencyCode = state => state.app.currencyCode
export const getIsInitial = state => !state.products.status
export const getTotal = state => state.products.total
export const getQuery = state => state.products.query
export const getCategory = state => state.products.category
export const getSort = state => state.products.sort
export const getPage = state => state.products.page
export const getStatus = state => state.products.status
export const getAction = state => state.products.action
export const getSize = state => state.products.size
export const getHasMore = state => {
  let totalProductCount = _.get(state, 'products.results', []).length
  if (groupedProductsEnabledPLP) {
    const groups = _.get(state, 'products.results.groups', [])
    totalProductCount = groups.reduce((count, group) => {
      count += group.children.length
      return count
    }, 0)
  }

  const hasMore = state.products.total != null && totalProductCount < state.products.total

  return hasMore
}

export const getResults = state => _.get(state, 'products.results', [])
export const getGroupedResults = state => parseProductGroups(_.get(state, 'products.results', []), _.get(state, 'app.currencyCode', 'AED'))
export const getResultsCategory = state => _.get(state, 'products.resultsMeta.category', {})
export const getResultsQuery = state => _.get(state, 'products.resultsMeta.query')
export const getAvailableFilters = state => _.get(state, 'products.availableFilters', [])
export const getActiveFilters = state => _.get(state, 'products.activeFilters')

export const getNumberOfActiveFilters = createSelector([
  getActiveFilters
], (activeFilters) => (
  activeFilters ? Object.keys(activeFilters).length : 0
))

export const getIsProductsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && (action === SEARCH_PRODUCTS_FRESH || action === SEARCH_PRODUCTS_NEXT))
))

// combine selectors here instead of dotting the same trivial calculation around the app
// there will never be a time where you want to check if products are loaded but don't
// care if categories are loaded
export const getIsLoading = createSelector(
  [getIsProductsLoading, categoriesSelectors.getIsLoading],
  (productsLoading, categoriesLoading) => productsLoading || categoriesLoading
)

export const getResultsForSelectedCurrency = createSelector([
  getResults,
  getCurrencyCode
], (products = [], currency) => {
  return products.map((product) => {
    const variants = product.variants || []
    return {
      ...product,
      currency,
      price: getSelectedCurrencyPrice(product, currency),
      discount: getSelectedCurrencyDiscount(product, currency),
      variants: variants.map((variant) => ({
        ...variant,
        price: getSelectedCurrencyPrice(variant, currency),
        discount: getSelectedCurrencyDiscount(variant, currency),
      }))
    }
  })
})
