import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import List from '../../components/List'
import SafeAreaSpacer from '../../components/SafeAreaSpacer'
import FullScreenButton from './FullScreenButton'
import { CurrencyMenuItem } from './CurrencyMenuItem.js'
import style from './style'
import styled from 'styled-components'
import { translations } from '../../config'


const CurrencyMenuTitle = styled.h2`
  position: relative;
  z-index: 10;
  font-weight: 700;
  font-size: 17px;
  margin: 0px auto 1.2em auto;
  text-transform: uppercase;
`

const CurrencyMenu = ({
  isOpen,
  currencyCodes,
  onClose,
  changeCurrency,
  classes
}) => {
  return <Drawer
    open={isOpen}
    onClose={onClose}
    anchor='right'
    classes={{ paper: classes.rootMenu }}
  >
    <nav
      aria-label={'TITLE'}
      className={classes.container}
    >
      <div className={classes.container}>
        <div>
          <SafeAreaSpacer inset={'top'} />
          <FullScreenButton onClose={onClose} />
        </div>
        <CurrencyMenuTitle style={{ root: classes.menuItem }}>{translations('Select a currency')}</CurrencyMenuTitle>
        <div className={classes.listContainer}>
          <List
            items={currencyCodes}
            onClickItem={
              (item) => {
                if (item.id) {
                  changeCurrency(item.id)
                  onClose()
                }
              }
            }
            ItemComponent={CurrencyMenuItem}
          />
        </div>
      </div>
    </nav>
  </Drawer>
}

export default withStyles(style)(CurrencyMenu)
