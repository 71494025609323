import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import WishList from './WishList'

const mapStateToProps = state => {
  const currentCurrency = appSelectors.getAppCurrency(state)

  return {
    currentCurrency
  }
}

const mapDispatchToProps = dispatch => ({
  goToProduct: product => e => {
    if (
      // allows quantity picker to work
      e.target instanceof HTMLElement &&
      !(e.target.getAttribute && e.target.getAttribute('role') === 'button')
    ) {
      if (product.selectedVariantId) {
        dispatch(push(`/product/${product.productId || product.id}/variant/${product.selectedVariantId}`))
      } else {
        dispatch(push(`/product/${product.productId || product.id}`))
      }
      dispatch(appActions.toggleBasket({ isOpen: false }))
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(WishList)
