import _ from 'lodash'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import { PENDING } from '../../middleware/redux-promise'
import getSelectedCurrencyPrice from '../../../helpers/getSelectedCurrencyPrice'
import getSelectedCurrencyDiscount from '../../../helpers/getSelectedCurrencyDiscount'
import currencyFormatter from '../../../formatters/currencyFormatter'
import {
  stockLevel as stockLevelConstants,
  showInStoreStockInVariantDropdown,
  translations,
  formNames,
  prioritizedVariants
} from '../../../config'
import { getAllVariantsFromProducts } from '../products/parseProductGroups'
import { sortWithPriority } from '../../../helpers'
import { getImage } from '../../../components/Images'

export const getCurrencyCode = state => state.app.currencyCode
export const getProduct = state => state.productDetails.product
export const getGroupedProducts = state => state.productDetails.productGroupProducts
export const getActiveMediaIndex = state => state.productDetails.mediaIndex
export const getProductCareInstructions = state => _.get(state.productDetails.product, 'details.productCareInstructions', '')
export const getSizeGuide = state => _.get(state.productDetails.product, 'details.productSizeGuide', '')
export const getProductDescriptionSummary = state => _.get(state.productDetails.product, 'details.summary', '')
export const getProductError = state => state.productDetails.error
export const getBottomTab = state => state.productDetails.productTab
export const getSelectedVariantId = state => formValueSelector(formNames.productDetails)(state, 'variantId')

const extractPriceDetails = (product) => ({
  price: _.get(product, 'price.value', 0),
  discount: _.get(product, 'discount.value', 0),
  currency: _.get(product, 'price.code', 'GBP')
})

export const mapProductToCurrency = (product, currency) => {
  const variants = _.get(product, 'variants', [])
  return {
    ...product,
    currency,
    price: getSelectedCurrencyPrice(product, currency),
    discount: getSelectedCurrencyDiscount(product, currency),
    variants: variants.map((variant) => ({
      ...variant,
      price: getSelectedCurrencyPrice(variant, currency),
      discount: getSelectedCurrencyDiscount(variant, currency),
    }))
  }
}

export const mapGroupedProductsToCurrency = (groupedProducts, currency) => {
  return _.map(groupedProducts, product => {
    const variants = product.variants || []
    return {
      ...product,
      currency,
      price: getSelectedCurrencyPrice(product, currency),
      discount: getSelectedCurrencyDiscount(product, currency),
      variants: variants.map((variant) => ({
        ...variant,
        price: getSelectedCurrencyPrice(variant, currency),
        discount: getSelectedCurrencyDiscount(variant, currency)
      }))
    }
  })
}

export const getProductForSelectedCurrency = createSelector([
  getProduct,
  getCurrencyCode
], (product, currency) => {
  return mapProductToCurrency(product, currency)
})

export const getGroupedProductsForSelectedCurrency = createSelector([
  getGroupedProducts,
  getCurrencyCode
], (products, currency) => {
  return mapGroupedProductsToCurrency(products, currency)
})

export const getUpdatedProductCurrencyDetails = (product, currency) => {
  if ((!product || !currency) || (product && !product.price)) return

  return {
    ...product,
    currency,
    price: getSelectedCurrencyPrice(product, currency),
    discount: getSelectedCurrencyDiscount(product, currency)
  }
}

export const getVariants = (state, enableProductGroupsPDP) => {
  // enableProductGroupsPDP variable set when showing product group and variants seperately on PDP, therefore only want to return variants for each product within product group.  Otherwise fall back to legacy functionality
  if (enableProductGroupsPDP) {
    return _.get(state.productDetails, 'product.variants', [])
  }
  // Legacy functionality is to return all variants from all grouped products or fallback to standard product variants if there aren't any product group variants.
  const productGroupVariants = getAllVariantsFromProducts(_.get(state.productDetails, 'productGroupProducts', []))
  return productGroupVariants.length ? productGroupVariants : _.get(state.productDetails, 'product.variants', [])
}

export const getVariantsForSelectedCurrency = createSelector([
  getVariants,
  getCurrencyCode
], (variants, currency) => {
  return variants.map((variant) => ({
    ...variant,
    price: getSelectedCurrencyPrice(variant, currency),
    discount: getSelectedCurrencyDiscount(variant, currency),
  }))
})

const _hasVariantPricing = (variants = []) => !!_.find(variants, (variant) => _.get(variant, 'price.value'))

export const getHasVariantPricing = createSelector([
  getVariantsForSelectedCurrency
], (variants) => {
  return _hasVariantPricing(variants)
})

export const getMinMaxVariantPrices = (product) => {
  const variants = product.variants || []
  if (!variants.length) {
    return { min: product, max: product }
  }
  const variantsWithPrice = _.map(variants, (v) => {
    let variant = v
    if (!_.get(v, 'price.value')) {
      variant = {
        ...v,
        price: product.price || 0,
        discount: product.discount || 0
      }
    }
    
    variant.pricePaid = parseInt(variant.price.value) - parseInt(variant.discount.value)
    return variant
  })
  const min = _.minBy(variantsWithPrice, (v) => parseInt(v.pricePaid))
  const max = _.maxBy(variantsWithPrice, (v) => parseInt(v.pricePaid))
  return { min, max }
}

export const getVariantRangeDisplay = (product) => {
  const variants = product.variants || []
  if (variants.length === 1) {
    return getDisplayPriceOfProduct(variants[0])
  }
  const { min, max } = getMinMaxVariantPrices(product)

  if (min.pricePaid === max.pricePaid) {
    return getDisplayPriceOfProduct(min)
  }
  return translations('Display Price - Variant Price Range', {
    min: getDisplayPriceOfProduct(min),
    max: getDisplayPriceOfProduct(max)
  })
}

export const getPriceDisplayForProduct = (product) => {
  const variants = product.variants || []
  const hasVariantPricing = _hasVariantPricing(variants)
  if (!hasVariantPricing) {
    return getDisplayPriceOfProduct(product)
  }

  return getVariantRangeDisplay(product)
}

export const getSelectedVariant = createSelector([
  getVariantsForSelectedCurrency, getSelectedVariantId
], (variants, selectedVariantId) => {
  if (selectedVariantId) {
    return _.find(variants, (variant) => variant.id === selectedVariantId)
  }
})

export const getDisplayPrice = createSelector([
  getProductForSelectedCurrency,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing) {
    if (selectedVariant) {
      return getDisplayPriceOfProduct(
        selectedVariant.price
          ? selectedVariant
          : product
      )
    }
    return getVariantRangeDisplay(product)
  }

  return getDisplayPriceOfProduct(product)
})

export const getOriginalPrice = createSelector([
  getProductForSelectedCurrency,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing && selectedVariant) {
    const { price, currency } = getFinalPriceDetailsForProduct(product, selectedVariant)
    return currencyFormatter.format(price, currency)
  }
  const { price, currency } = extractPriceDetails(product)
  return currencyFormatter.format(price, currency)
})

export const hasDiscount = createSelector([
  getProductForSelectedCurrency,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return false
  if (hasVariantPricing && selectedVariant) {
    return _.get(selectedVariant, 'discount.value', 0) > 0
  }
  return _.get(product, 'discount.value', 0) > 0
})

const getFinalPriceDetailsForProduct = (product, variant) => {
  const variantPrice = _.get(variant, 'price.value')
  if (variantPrice) {
    return extractPriceDetails(variant)
  }

  return extractPriceDetails(product)
}

export const getDiscountPrice = createSelector([
  getProductForSelectedCurrency,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing && selectedVariant) {
    const { discount, currency } = getFinalPriceDetailsForProduct(product, selectedVariant)
    return currencyFormatter.format(discount, currency)
  }
  const { discount, currency } = getFinalPriceDetailsForProduct(product)
  return currencyFormatter.format(discount, currency)
})

export const getDiscountValue = createSelector([
  getProductForSelectedCurrency,
  getHasVariantPricing,
  getSelectedVariant
], (product, hasVariantPricing, selectedVariant) => {
  if (!product) return ''
  if (hasVariantPricing) {
    if (selectedVariant) {
      const { discount } = getFinalPriceDetailsForProduct(product, selectedVariant)
      return discount
    }

    return 0
  }
  const { discount } = extractPriceDetails(product)
  return discount
})

export const getVariationAttributes = state => {
  const variationAttributes = _.get(state.productDetails, 'product.details.variationAttributes', [])
  return variationAttributes
}

export const getVariationAttributeNames = createSelector([
  getVariationAttributes
], (variationAttributes = []) => {
  return variationAttributes.map(attr => {
    return attr.id
  })
})

export const getIsLoading = state => state.productDetails.status === PENDING && !state.productDetails.isCheckingAvailability

const getVariantLabel = (variant = {}, noStock, hasVariantPricing, product = {}) => {
  const { name, variantStoreStock = {} } = variant
  const { stock } = variantStoreStock
  let result = name
  if (showInStoreStockInVariantDropdown && !noStock) {
    let stockText = ''
    const addStockNumberTostockText = () => {
      stockText += ` - ${stock}`
    }
    if (stock >= stockLevelConstants.inStockLevel) {
      stockText = translations('In Stock')
      addStockNumberTostockText()
    } else if (stock >= stockLevelConstants.lowStockLowLevel) {
      stockText = translations('Low Stock')
      addStockNumberTostockText()
    } else if (stock === stockLevelConstants.outOfStockLevel) {
      stockText = translations('Out Of Stock')
      addStockNumberTostockText()
    } else if (stock === stockLevelConstants.itemNotStocked) {
      stockText = translations('Not Stocked')
    } else {
      stockText = translations('Unknown Stock')
    }
    result = `${name} (${stockText})`
  }

  // Special case for product groups
  if (hasVariantPricing) {
    const variantPrice = _.get(variant, 'price.value')
    const price = getDisplayPriceOfProduct(
      variantPrice
        ? variant
        : product
    )
    result += ` (${price})`
  }

  return result
}

export const getSelectedVariantStock = ({ selectedVariantId, variants }) => {
  const selectedVariant = _.find(variants, ({ id }) => id === selectedVariantId)
  return _.get(selectedVariant, 'variantStoreStock.stock', 0)
}

export const getOptionsFromVariants = (variants, noStock, product) => {
  const hasVariantPricing = _hasVariantPricing(variants)
  const orderVariantList = _.without(sortWithPriority(variants, prioritizedVariants, 'name'), undefined)
  return _.chain(orderVariantList)
    .sortBy(variant => _.toNumber(variant.name))
    .map(variant => ({
      label: getVariantLabel(variant, noStock, hasVariantPricing, product),
      value: variant.id
    }))
    .value()
}

export const getOptionsFromProducts = (products) => {
  const orderedProducts = _.orderBy(products, [
    item => item.details && _.has(item.details, 'productGroupOrder') ? 0 : 1, // Prioritise items with productGroupOrder
    item => item.details ? item.details.productGroupOrder : null, // Sort by productGroupOrder where available
    item => _.toNumber(item.externalProductId) // Sort by externalProductId as fallback
  ], ['asc', 'asc', 'asc'])

  const mappedOptions = _.map(orderedProducts, product => {
    const productDisplayName = _.get(product, 'details.productGroupShortName') || product.name
    const swatchImage = _.get(product, 'details.swatchImage')
    const swatchHexCode = _.get(product, 'details.swatchHexCode')
    const productImage = _.get(product, 'images')[0]
    const defaultImage = getImage('imageNotAvailableImg')

    const checkSwatch = image => image && image.trim() !== ''
    const backgroundDisplay =
    checkSwatch(swatchImage)
      ? swatchImage
      : checkSwatch(swatchHexCode)
        ? null
        : checkSwatch(productImage)
          ? productImage
          : defaultImage

    return {
      label: productDisplayName,
      value: product.id,
      id: product.id,
      name: product.name,
      shortName: productDisplayName,
      swatchImage: backgroundDisplay,
      swatchHexCode
    }
  })
  return mappedOptions
}

export const getVariantsOptions = createSelector([
  getVariantsForSelectedCurrency,
  getProductForSelectedCurrency
], (variants, product) => {
  return getOptionsFromVariants(variants, false, product)
})

export const getProductGroupOptions = createSelector([
  getGroupedProductsForSelectedCurrency
], (products) => {
  return getOptionsFromProducts(products)
})

export const getDisplayPriceOfProduct = (product) => {
  if (!product || (product && !product.price)) return
  const { price, discount, currency } = extractPriceDetails(product)
  const displayPrice = price - discount

  return currencyFormatter.format(displayPrice, currency)
}

export const getProductHasVariantPricing = (product) => {
  return _hasVariantPricing(product.variants)
}

export const getProductHasPriceRange = (product) => {
  if (!_hasVariantPricing(product.variants)) return false

  const { min, max } = getMinMaxVariantPrices(product)
  return min !== max
}

export const getSaveAmount = (product) => {
  const { variants = [] } = product
  if (variants.length === 0) return 0
  if (!_hasVariantPricing(variants)) {
    const { discount, currency } = extractPriceDetails(product)
    return currencyFormatter.format(discount, currency)
  }

  const { min, max } = getMinMaxVariantPrices(product)
  if (min !== max) {
    return 0
  }

  const { discount, currency } = extractPriceDetails(min)

  if (discount === '0') {
    return 0
  }

  return currencyFormatter.format(discount, currency)
}

export const getOriginalProductPrice = (product) => {
  const { variants = [] } = product
  if (variants.length === 0) return 0

  if (!_hasVariantPricing(variants)) {
    const { price, currency } = extractPriceDetails(product)
    return currencyFormatter.format(price, currency)
  }

  const { min, max } = getMinMaxVariantPrices(product)
  if (min !== max) {
    return 0
  }

  const { price, discount, currency } = extractPriceDetails(min)
  if (discount === '0') {
    return 0
  }

  return currencyFormatter.format(price, currency)  
}

export const getDiscountValueOfProduct = (product) => {
  const { variants = [] } = product
  if (variants.length === 0) return 0

  if (!_hasVariantPricing(variants)) {
    const { discount }  = extractPriceDetails(product)
    return discount
  }

  const { min, max } = getMinMaxVariantPrices(product)
  if (min !== max) {
    return 0
  }

  const { price, discount, currency } = extractPriceDetails(min)
  if (discount === '0') {
    return 0
  }
}

export const getOnlineAvailabilty = createSelector([
  getVariantsForSelectedCurrency, getSelectedVariantId
], (variants, selectedVariantId) => {
  if (selectedVariantId) {
    return _.chain(variants)
      .find(variant => variant.id === selectedVariantId)
      .get('onlineStock')
      .value()
  } else {
    const defaultStockWeight = stockLevelConstants.stockStatusWeight[stockLevelConstants.ONLINE_UNAVAILABLE_STATUS]
    const bestStockStatusValue = _.reduce(variants, (memo, variant) => {
      const stockStatusValue = stockLevelConstants.stockStatusWeight[variant.onlineStock]
      return (stockStatusValue > memo) ? stockStatusValue : memo
    }, defaultStockWeight)

    const stockStatus = _.findKey(stockLevelConstants.stockStatusWeight, (weight) => weight === bestStockStatusValue)

    return stockStatus
  }
})

export const getRelatedProducts = createSelector([
  getProduct
], product => {
  return _.get(product, 'reporting.relatedProducts', [])
})
