import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import MultiSelectBar from './MultiSelectBar'
import * as currentOrderCombinedSelectors from '../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import { actions as customerDetailsActions } from '../../../../store/modules/customerDetails'

const mapStateToProps = state => {
  const selectedProducts = currentAppointmentSelectors.getAppointmentProducts(state)
  const res = {
    selectedProducts,
    totalSelectedProducts: selectedProducts.length,
    hasSelectedProducts: selectedProducts.length > 0
  }
  return res
}

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onClickDone: ({ dispatch, selectedProducts, dismiss }) => () => {
      dispatch(customerDetailsActions.editProductCarousel(''))
      dismiss && dismiss()
    }
  })
)(MultiSelectBar)
