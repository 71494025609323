import _ from 'lodash'
import { withRouter } from 'react-router'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import RootScreen from './RootScreen'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { actions as configActions } from '../../../store/modules/config'
import { apps, environment } from '../../../config'
import { matchPath } from 'react-router'
import withTranslations from '../../../components/withTranslations/withTranslations'
import modalService from '../../../services/modalService'
import StorePinEntry from '../../PinEntry/StorePinEntry'
import LoginScreen from '../../Login/LoginScreen'

const mapStateToProps = state => ({
  appsAllowedForRole: authSelectors.getAppsAllowedForRole(state),
  networkConnectivity: networkSelectors.isConnected(state),
  isLoggedIn: authSelectors.getIsLoggedIn(state),
  isCustomerMode: appSelectors.getCustomerModeStatus(state),
  loginMethod: authSelectors.getLoginMethod(state),
  storePin: authSelectors.getUserSelectedStorePin(state)
})

const isUnsecuredPage = (currentPath, paths) => {
  return !_.find(paths, path => {
    return matchPath(currentPath, { path: path })
  })
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withTranslations(),
  withPropsOnChange(['location'], ({ location }) => {
    const currentUrl = location.pathname
    console.log(location.pathname)
    return {
      isUnsecuredPage: isUnsecuredPage(currentUrl, ['/login', '/forgot-password', '/select-store', '/select-department', '/consultations/virtual/:id/customer'])
    }
  }),
  withPropsOnChange(['location', 'appsAllowedForRole'], ({ location, appsAllowedForRole }) => {
    // Find the app id we are on based on the current location
    const basePath = '/' + location.pathname.split('/')[1]
    const currentApp = Object.entries(apps).reduce((acc, [appId, appContent]) => {
      const path = _.get(appContent, 'action.payload.args.0')
      if (path === basePath) {
        return appId
      }
      return acc
    }, null)

    // If route does not match an app, then allow - as a fail-safe to not block all apps
    const appNotSpecified = typeof appsAllowedForRole[currentApp] === 'undefined'

    return {
      isAppAllowedForRole: appNotSpecified || appsAllowedForRole[currentApp]
    }
  }),
  withHandlers({
    fetchConfig: ({ dispatch }) => () => {
      dispatch(configActions.fetchConfig())
    },
    showPasswordModal: ({ dispatch }) => (loginMethod, storePin) => {
      const storePinEnabledMethods = environment.STORE_PIN_ENABLED || []
      const shouldShowPin = storePin && !!_.find(storePinEnabledMethods, (method) =>
        method === loginMethod
      )

      modalService.open({
        component: shouldShowPin ? StorePinEntry : LoginScreen,
        hideForgotPassword: true,
        hideRecentUsers: true,
        modalIndex: 1,
        success: () => {
          dispatch(appActions.customerModeToggle({ isOn: false }))
          modalService.close({ modalIndex: 1 })
        },
        disableBackdropClick: true
      })
    }
  })
)(RootScreen)
