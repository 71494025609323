import { connect } from 'react-redux'
import { compose } from 'recompose'

import ConfigProvider from './ConfigProvider'
import { getRegionBrand } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'

const mapStateToProps = state => {
  const brandName = getRegionBrand(state)
  const theme = getTheme(brandName)
  return {
    theme
  }
}

export default compose(
  connect(mapStateToProps)
)(ConfigProvider)
