import { colors } from '../../../../config/theme'

const styles = theme => ({
  selectInput: {
    minWidth: 48
  },
  selectInputUnderline: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.accent.main}`
    }
  },
  groupName: {
    backgroundColor: colors.offsetAccent
  }
})

export default styles
