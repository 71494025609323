import React from 'react'

import ProductsRouter from '../../../../containers/Products/ProductsRouter'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { SelectProduct } from '../../../../components/EditableFields'
import { translations } from '../../../../config'
import InspirationsSearchScreen from '../../../Inspirations/InspirationsSearchScreen'

const CustomerWaitlist = FormContentBox('waitlist')
const editButtonText = CustomerWaitlist ? 'Add/Edit' : 'Edit'

const WrappedCustomerWaitlist = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, editable }) => {
  const updatedInitalValues = {
    ...initialValues,
    waitlist: initialValues && initialValues.waitlist ? initialValues.waitlist.map((item) => {
      return { ...item, price: null }
    }) : []
  }
  return (
    <CustomerWaitlist
      enableReinitialize
      initialValues={updatedInitalValues}
      editing={editing}
      editable={editable}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={translations(boxName)}
      onSubmit={onSubmit}
      formId={formId}
      saveDisabled={saveDisabled}
      editButtonText={translations(editButtonText)}
    >
      <FormError />
      <FormBody>
        <SelectProduct
          usePassedValues
          options={['product']}
          label={translations('Waitlist')}
          editing={editing}
          name='waitlist'
          productsModalComponent={ProductsRouter}
          inspirationsModalComponent={InspirationsSearchScreen}
          onClickEnabled={editable}
        />
      </FormBody>
    </CustomerWaitlist>
  )
})

export default WrappedCustomerWaitlist
