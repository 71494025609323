import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { isInvalid, reduxForm, formValueSelector, change, getFormValues } from 'redux-form'
import { compose, withHandlers, withProps, withPropsOnChange, withState } from 'recompose'
import _ from 'lodash'

import { communicationOptions } from '../../../../config/general'
import { translations, nullEmailRegex, environment } from '../../../../config'
import { actions as createMessageActions, selectors as createMessageSelectors } from '../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import MessageEditorForm from './MessageEditorForm'
import modalService from '../../../../services/modalService'
import MessagePreviewScreen from '../../MessagePreviewScreen'
import imageUploadService from '../../../../services/imageUploadService'
import toastService from '../../../../services/toastService/toastService'

export const formId = 'create-message'
const { preferenceOptionKeys, preferenceKeys, channelOptionKeys, channelKeys, channelKeysInDetails } = communicationOptions

const messageEditorFormIsInvalidSelector = isInvalid(formId)
const messageEditorFormValueSelector = formValueSelector(formId)

const mapStateToProps = state => {
  const messageTemplateDropdownOptions = createMessageSelectors.getMessageTemplateDropdownOptions(state, { groupByLanguage: true })
  const messageTemplates = createMessageSelectors.getMessageTemplates(state)
  const isActionPendingState = createMessageSelectors.getMessageTemplatesAreLoading(state)
  const messageDetails = createMessageSelectors.getMessageDetails(state)
  const currentRecipients = createMessageSelectors.getCurrentRecipients(state)
  const currentRecipientPreferredLanguage = createMessageSelectors.getCurrentRecipientLanguage(state)
  const storeId = authSelectors.getUserSelectedStoreId(state)
  const isMessageSending = createMessageSelectors.getIsMessageSending(state)
  const messageContent = createMessageSelectors.getMessageContent(state)

  const formCommunicationType = messageEditorFormValueSelector(state, 'communicationType')
  const formCommunicationTypeOther = messageEditorFormValueSelector(state, 'communicationTypeOther')
  const selectedTemplate = messageEditorFormValueSelector(state, 'template')
  const formValues = getFormValues(formId)(state)
  const isFormInvalid = messageEditorFormIsInvalidSelector(state)

  const storeCommunicationType = createMessageSelectors.getMessageCommunicationType(state)

  const recipientMarketingChannels = createMessageSelectors.getSingleRecipientMarketingChannels(state)
  const recipientMarketingPreferences = createMessageSelectors.getSingleRecipientMarketingPreferences(state)
  let noPreview
  let sendButtonText
  if (formCommunicationType === 'other') {
    noPreview = true
    sendButtonText = translations('Save')
  }

  return {
    messageTemplateDropdownOptions,
    messageTemplates,
    isActionPendingState,
    selectedTemplate,
    messageDetails,
    currentRecipients,
    currentRecipientPreferredLanguage,
    storeId,
    isMessageSending,
    formCommunicationType,
    formCommunicationTypeOther,
    isFormInvalid,
    recipientMarketingChannels,
    recipientMarketingPreferences,
    storeCommunicationType,
    messageContent,
    noPreview,
    sendButtonText,
    formValues
  }
}

const mapDispatchToProps = (dispatch) => {
  const setMessageInRedux = messageFields => {
    dispatch(createMessageActions.updateMessage(messageFields))
  }

  const saveAsPdf = (formValues, currentRecipients, storeId) => {
    const content = _.get(formValues, 'content', [])
    return _.chain(content)
      .map(item => {
        if (item.type === 'image') {
          console.log('upload', item.url)
          return imageUploadService.upload(item.url)
            .then(remoteFileUrl => {
              return {
                ...item,
                url: remoteFileUrl
              }
            })
        }
        return item
      })
      .thru(promises => {
        return Promise.all(promises)
          .catch((error) => {
            toastService.action({
              type: 'error',
              message: translations('Messaging Upload Image - Fail Message'),
              verticalPosition: 'top',
              horizontalPosition: 'right'
            })
            throw error
          })
      })
      .value()
      .then(newContent => {
        const newMessage = {
          ...formValues,
          communicationType: 'other',
          communicationTypeOther: 'pdf',
          content: newContent
        }
        setMessageInRedux(newMessage)
        return sendMessage({ message: newMessage, recipients: currentRecipients, storeId })
      }).then(savedMsg => {
        if (savedMsg.id) {
          const link = `${environment.API_URL}/v2/messages/${savedMsg.id}/pdf`
          window.open(link, '_system')
        }
      })
  }

  const goToPreview = (messageFields, currentRecipients, storeId) => {
    setMessageInRedux(messageFields)

    modalService.open({
      component: MessagePreviewScreen,
      hideBottomBar: true,
      fullScreen: true,
      inModal: true,
      closePreviewScreen: () => modalService.close(),
      exportPdf: () => saveAsPdf(messageFields, currentRecipients, storeId)
    })
  }

  const sendMessage = params => {
    return dispatch(createMessageActions.sendMessage(params))
  }

  return {
    setMessageInRedux,
    saveAsPdf,
    goToPreview,
    sendMessage,
    dispatch
  }
}

// separating this from mapStateToProps to avoid recalculations every time redux store changes
const formatStateProps = withPropsOnChange(
  [
    'formCommunicationType',
    'formCommunicationTypeOther',
    'storeCommunicationType',
    'currentRecipients',
    'isMessageSending',
    'isFormInvalid',
    'createMessage',
    'messageTemplateDropdownOptions',
    'selectedTemplate',
    'recipientMarketingChannels',
    'recipientMarketingPreferences',
    'messageContent'
  ],
  props => {
    const {
      formCommunicationType,
      formCommunicationTypeOther,
      currentRecipients,
      isMessageSending,
      isFormInvalid,
      messageTemplateDropdownOptions,
      selectedTemplate,
      recipientMarketingPreferences,
      recipientMarketingChannels,
      storeCommunicationType,
      messageContent,
      dispatch
    } = props

    const hasRecipients = !!currentRecipients.length
    const hasSingleRecipient = currentRecipients.length === 1

    const optedOutMarketingPreferences = preferenceKeys
      .filter(key => !recipientMarketingPreferences.includes(key))
      .map(key => _.get(preferenceOptionKeys.find(option => option.value === key), 'label'))

    const optedOutMarketingChannels = [...channelKeys, ...channelKeysInDetails]
      .filter(key => !recipientMarketingChannels.includes(key))
      .map(key => _.get(channelOptionKeys.find(option => option.value === key), 'label'))

    const hideSMS = !!(
      hasSingleRecipient &&
      (
        !currentRecipients[0].telephone ||
        !recipientMarketingChannels.includes('smsMarketing')
      )
    )
    const hideEmail = !!(
      hasSingleRecipient &&
      (
        !currentRecipients[0].email ||
        currentRecipients[0].email.match(nullEmailRegex) ||
        !recipientMarketingChannels.includes('emailMarketing')
      )
    )

    const submitDisabled = isFormInvalid || isMessageSending || (hideEmail && hideSMS)
    let communicationTypeOptions = []
    let communicationType = formCommunicationType || storeCommunicationType || 'email'

    if (!hideEmail) {
      communicationTypeOptions.push({ value: 'email', label: translations('Email') })
    }
    communicationTypeOptions.push({ value: 'other', label: translations('other') })

    // if the communication option which is chosen is not available
    // enforces only selectable options are actually selected
    if (communicationTypeOptions.length === 1) {
      communicationType = communicationTypeOptions[0].value
      if (communicationType !== formCommunicationType) {
        dispatch(change(formId, 'communicationType', communicationType))
      }
    }

    const communicationTypeOtherOptions = [
      {value: 'whatsapp', label: translations('Whatsapp')},
      {value: 'phone', label: translations('phone')}
    ]
    if (!hideSMS) {
      communicationTypeOtherOptions.push({value: 'sms', label: translations('SMS')})
    }

    let communicationTypeOther = formCommunicationTypeOther || ''

    const contactDetail = (
      hasSingleRecipient
      ? getContactDetail({ communicationType, currentRecipients })
      : ''
    )

    const initialValues = {
      content: messageContent,
      template: selectedTemplate ? selectedTemplate.id : undefined,
      communicationType,
      communicationTypeOther
    }

    return {
      optedOutMarketingPreferences,
      optedOutMarketingChannels,
      contactDetail,
      hasSingleRecipient,
      hasRecipients,
      submitDisabled,
      initialValues,
      communicationTypeOptions,
      communicationType,
      communicationTypeOther,
      communicationTypeOtherOptions,
      hideSMS,
      hideEmail
    }
  }
)

function getContactDetail ({ communicationType, currentRecipients }) {
  switch (communicationType) {
    case 'sms':
      return `${translations('Phone')}: ${currentRecipients[0].telephone}`
    case 'whatsapp':
      return `${translations('Whatsapp')}: ${currentRecipients[0].telephone}`
    case 'email':
      return `${translations('Email')}: ${currentRecipients[0].email}`
    default:
      return ''
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  formatStateProps,
  withPropsOnChange(['selectedTemplate'], props => {
    const { messageTemplates, selectedTemplate, initialValues, dispatch } = props
    const tpl = _.chain(messageTemplates)
      .find(tpl => tpl.id === selectedTemplate)
      .defaultTo({ config: {} })
      .value()

    if (tpl.config.prefillSubject) {
      dispatch(change(formId, 'subject', tpl.config.prefillSubject))
    }

    if (tpl.config.prefillBody) {
      dispatch(change(formId, 'text', tpl.config.prefillBody))
    }

    return {}
  }
  ),
  withState('isFormLoading', 'setIsformLoading', false),
  withPropsOnChange(['isActionPendingState', 'isFormLoading'], ({ isActionPendingState, isFormLoading }) => {
    return { isLoading: isActionPendingState || isFormLoading }
  }),
  FullScreenLoader,
  reduxForm({ form: formId, enableReinitialize: false }),
  withProps({
    formId
  }),
  withHandlers({
    onSubmit: ({ currentRecipients, setMessageInRedux, sendMessage, storeId }) => message => {
      setMessageInRedux(message)
      return sendMessage({ message, recipients: currentRecipients, storeId })
    },
    onClickPreview: ({ handleSubmit, goToPreview, currentRecipients, storeId }) => () => {
      handleSubmit(messageFields => {
        goToPreview(messageFields, currentRecipients, storeId)
      })()
    },
    onClickExportPdf: ({ saveAsPdf, formValues, currentRecipients, storeId }) => () => {
      saveAsPdf(formValues, currentRecipients, storeId)
    }
  })
)(MessageEditorForm)
