import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../AlertBadge'
import Button from '../../components/Button'
import style from './style'
import { a11y, translations } from '../../config'
import CurrencyMenuButton from '../CurrencyMenu/CurrencyMenuButton'

const Header = ({
  rightElement: RightElement,
  openMenu,
  openCurrencyMenu,
  classes,
  customerModeUpdate,
  networkNotification,
  isAppointmentActive,
  isInsideConsultation,
  isAppointmentComplete,
  hideCurrencySelector,
  currencyCodes,
  getCurrencyCode,
  setCurrency,
  theme
}) => {
  const hideBasket = customerModeUpdate || isAppointmentComplete
  const currencyCode = getCurrencyCode;
  
  useEffect(() => {
    if(hideCurrencySelector) {
      setCurrency({currencyCode: currencyCodes[0].id})
    } 
  }, [])
  
  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      {networkNotification}
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoContainer}>
          <img className={classes.whiteLetters} src={theme.base.headerLogo} alt={a11y.mainLogoAltText} />
        </div>
        {
          (!customerModeUpdate && !(isAppointmentActive && isInsideConsultation)) && (
            <IconButton color='inherit' aria-label='Menu' onClick={openMenu} className={classes.burger}>
              <AlertBadge type='notifications' classes={{ badge: classes.burgerBadge }}>
                <MenuIcon />
              </AlertBadge>
            </IconButton>
          )
        }
        { (!hideBasket && !hideCurrencySelector) && <CurrencyMenuButton currentCurrency={currencyCode} openCurrencyMenu={openCurrencyMenu}/>}
        {
          (!hideBasket && RightElement) &&
            <Button aria-label={translations('Basket')} className={classes.basketIcon}><RightElement /></Button>
        }
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  RightElement: PropTypes.element,
  openMenu: PropTypes.func,
  openCurrencyMenu: PropTypes.func,
  classes: PropTypes.object,
  customerModeUpdate: PropTypes.bool,
  networkNotification: PropTypes.object,
  isAppointmentActive: PropTypes.bool,
  isInsideConsultation: PropTypes.bool,
  isAppointmentComplete: PropTypes.bool,
  currencyCode: PropTypes.string
}

export default withStyles(style)(Header)
