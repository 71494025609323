import React, { Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { getPriceValue } from '@redant/digital-store-prices-chalhoub'
import cx from 'classnames'

import P from '../../../../../../components/P'
import { getImage } from '../../../../../../components/Images'
import { translations, noPrices } from '../../../../../../config'
import currencyFormatter from '../../../../../../formatters/currencyFormatter'
import { Dropdown } from '../../../../../../components/Fields'

import style from './style'

const cf = currencyFormatter.format

const noImagePlaceholder = getImage('imageNotAvailableImg')

const FullProduct = ({ product, classes, onProductClick }) => {
  const imageSrc = _.get(product, 'images.0') || noImagePlaceholder
  // default to false
  const unsold = _.get(product, 'unsold', false)

  const info = []
  info.push(['Variant', _.get(product, 'variant.name')])
  !unsold && info.push(['Quantity', product.quantity])
  if (!noPrices) {
    const priceLabel = (
      _.get(product, 'manualDiscount.type') === 'EDIT'
        ? 'Price (Edited)'
        : 'Price'
    )
    info.push([priceLabel, cf(product.groupPriceToDisplay)])
    !unsold && info.push(['Discount', cf(product.groupDiscountToDisplay)])
    !unsold && info.push(['Subtotal', cf(product.groupSubTotal)])
    if (getPriceValue(product.groupTax) && !unsold) {
      info.push(['Tax', cf(product.groupTax)])
    }
    !unsold && info.push(['Total', cf(product.groupTotal), { type: 'bold' }])
  }

  const containerClasses = cx(classes.fullProductContainer, {
    [classes.op3]: unsold
  })

  return (
    <div className={containerClasses} onClick={onProductClick}>
      <div className={classes.imageContainer}>
        <div className={classes.imageInnerContainer}>
          <img className={classes.singleProductImage} src={imageSrc} />
        </div>
      </div>
      <div className={classes.contentWrapper}>
        {product.brand && <P type='bold' value={product.brand} />}
        <P className={classes.productName} type='bold' value={product.name} />
        <dl className={classes.listContainer}>
          <Grid container spacing={0}>
            {info.map(([label, value, props]) => {
              return <Fragment>
                <Grid item xs={6}><dt><P {...props} value={translations(label) + ':'} /></dt></Grid>
                <Grid item xs={6}><dd><P {...props} value={value} /></dd></Grid>
              </Fragment>
            })}
          </Grid>
        </dl>
      </div>
    </div>
  )
}

FullProduct.propTypes = {
  product: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onProductClick: PropTypes.func.isRequired
}

export default withStyles(style)(FullProduct)
