import { createSelector } from 'reselect'
import _ from 'lodash'
import * as constants from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { getRegionBrand } from '../combinedSelectors/regionsCombinedSelectors'
import { getLanguage } from '../../../config/languages'
import { getFormValues } from 'redux-form'

export const getCurrentRecipients = state => _.get(state.createMessage, 'recipients', [])
export const getCurrentRecipientLanguage = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.details.preferredLanguage')
      ? _.get(recipients, '0.details.preferredLanguage')
      : _.get(recipients, '0.preferredLanguage')
  }
})
export const getMessageDetails = state => state.createMessage.message || {}
export const getMessageTemplates = state => state.createMessage.templates.items || []
export const getMessageTemplateDropdownOptions = createSelector([
  (state) => getRegionBrand(state),
  (state) => _.get(state, 'createMessage.templates.items', []),
  (state, { groupByLanguage }) => groupByLanguage,
  getCurrentRecipientLanguage
], (activeBrand, messageTemplates, groupByLanguage, currentRecipientLanguage) => {
  let options = []
  if (messageTemplates) {
    options = _.map(messageTemplates, messageTemplate => ({
      label: messageTemplate.name,
      value: messageTemplate.id,
      key: getLanguage(messageTemplate.language) || messageTemplate.language,
      brand: _.get(messageTemplate, 'config.brandName')
    })).filter((item) => {
      if (activeBrand) {
        return item.brand === activeBrand
      }

      return !item.brand
    })
  }
  return groupByLanguage
    ? _.groupBy(options, 'key')
    : options
})

export const getCurrentTemplateLanguage = createSelector([
  (state) => getMessageTemplates(state),
  (state) => getFormValues('create-message')(state)
], (messageTemplates, templateFormValues) => {
  if (messageTemplates) {
    const selectedTemplateId = templateFormValues.template
    if (selectedTemplateId) {
      const currentTemplateLanguage = messageTemplates.find(tpl => tpl.id === selectedTemplateId).language
      return currentTemplateLanguage
    }
  }
})

export const getPreviousPath = state => state.createMessage.previousPath
export const getMessageTemplatesAreLoading = state => {
  return state.createMessage.status === 'PENDING'
}
export const getIsMessageSending = state => {
  const messageDetailsAction = _.get(state, 'createMessage.action')
  const messageDetailsStatus = _.get(state, 'createMessage.status')
  return messageDetailsAction === constants.SEND_MESSAGE && messageDetailsStatus === PENDING
}

export const getSingleRecipientMarketingChannels = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.details.customerMarketingChannels')
      ? _.get(recipients, '0.details.customerMarketingChannels', [])
      : _.get(recipients, '0.customerMarketingChannels', [])
  }
  return []
})

export const getSingleRecipientMarketingPreferences = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.details.customerMarketingPreferences')
      ? _.get(recipients, '0.details.customerMarketingPreferences', [])
      : _.get(recipients, '0.customerMarketingPreferences', [])
  }
  return []
})

export const getMessageCommunicationType = createSelector([
  getMessageDetails
], details => {
  return _.get(details, 'communicationType')
})

export const getMessageContent = createSelector([
  getMessageDetails
], message => {
  return _.get(message, 'content', [])
})
