import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { compose } from 'recompose'

import SubHeader from '../../../components/SubHeader'
import FormContentBox from '../../../components/FormContentBox'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'

import WrappedFormContentBox from './WrappedFormContentBox'
import LatestNote from './LatestNote'
import LatestMessage from './LatestMessage'
import RecommendedProducts from './RecommendedProducts'
import Loyalty from './Loyalty/OrderList'
import CommunicationOptions from './CommunicationOptions'
import Wishlist from './Wishlist'
import CustomerWaitlist from './CustomerWaitlist'

import AppointmentsList from '../../ConsultationsV2/Consultation/AppointmentsDashboardScreen/AppointmentsListing/AppointmentsList'

import { translations, getAppConfig } from '../../../config'
import Follow from './Follow'
import TopRightButton from '../../../components/TopRightButton'
import { getCustomerFullName } from '../../../helpers'
import RequiredTextMessage from '../../../components/RequiredTextMessage'

const PersonalDetailsForm = FormContentBox('personal-details')
const CustomerDetailsForm = FormContentBox('customer-details')
const AppointmentsForm = FormContentBox('appointments')

const ViewCustomerFormWrapper = ({
  classes,
  initialValues = {},
  phoneMarketingOptIn,
  isLoadingInitialData,
  onSubmit,
  onSubmitWishlist,
  onSubmitRecommendedProducts,
  onCustomerLoyaltySubmit,
  onCommunicationSubmit,
  onSubmitAppointments,
  isCustomerEditable,
  onCustomerPreferencesSubmit,
  waitlistInitialValues,
  onSubmitWaitlist,
  customerFirstName,
  onActionsClick,
  exit,
  appointments,
  canEditPreferredSA,
  isConsultationMode,
  ...props
}) => {
  const { firstName, lastName, anonymised, email, telephone } = initialValues || {}
  const fullName = getCustomerFullName({ firstName, lastName, anonymised })
  let layout = _.cloneDeep(getAppConfig('CUSTOMER_MANAGEMENT', 'viewCustomerScreenLayout')) 
  let customerSchema =  _.cloneDeep(schema.customer.schema)
  let personalSchema =  useMemo(() => _.cloneDeep(schema.personal.schema), [schema.personal.schema])
  
  if (isConsultationMode) {
    // When viewing the customer profile in consultation mode, areas that open another modal (e.g. notes, recommended products, wishlist etc.) don't work correctly.  
    // As there is a new consulations module in core, rather than fix the old version, we will implement the new core functionality // TODO
    customerSchema.filter((field) => { return field.id !== "preferredSalesAssistant"});
    personalSchema.map((field) => { 
      if (field.id == "email") {
        field.props.showEmailButton = false
        return {
          ...field,
          props: {
            ...field.props,
            disabled: email ? true : false
          }
        }
      } else if (field.id == "telephone") {
        field.props.showSmsButton = false;
        field.props.showPhoneButton = false;
        return {
          ...field,
          props: {
            ...field.props,
            disabled: telephone ? true : false, 
            phoneMarketingOptIn: phoneMarketingOptIn
          }
        }
      } else {
        return field
      }
    } )

    layout.map((field) => {
      if (field.type == "LOYALTY" || field.type == "NOTES" || field.type == "RECOMMENDED_PRODUCTS" || field.type == "ROLEX_PRODUCTS" ||field.type == "WISHLIST" || field.type == "WAITLIST" || field.type == "MESSAGES" || field.type == "APPOINTMENTS_LISTING" ) {
        field.editable = "false";
      }
      return field;
    })
  }

  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar onClick={exit} />
        )}
        centerContent={(
          <H2 value={_.toUpper(fullName)} />
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredFollowButtonContainer}>
          <div className={classes.buttonsContainer}>
            <TopRightButton onClick={onActionsClick}>{translations('Actions')}</TopRightButton>
          </div>
          <RequiredTextMessage />
        </div>

        {layout.map(item => {
          if (!item.enabled) return null
          let itemEditable = isCustomerEditable && (typeof item.editable == "undefined" || item.editable !== "false")

          switch (item.type) {
            case 'PERSONAL_DETAILS':
              return (<WrappedFormContentBox
                component={PersonalDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onSubmit}
                schema={personalSchema}
                layout={schema.personal.layout}
                boxName={translations('Personal Details')}
                formId='personal-details'
                editable={itemEditable}
                />)
                case 'CUSTOMER_PREFERENCES':
              return (customerSchema) && (<WrappedFormContentBox
                component={CustomerDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onCustomerPreferencesSubmit}
                schema={customerSchema}
                layout={schema.customer.layout}
                boxName={translations(item.heading)}
                formId='customer-details'
                editable={isCustomerEditable}
                canEditPreferredSA={canEditPreferredSA}
              />)
            case 'NOTES':
              return (<LatestNote customer={initialValues} editable={itemEditable} />)
            case 'APPOINTMENTS_LISTING':
              return (<AppointmentsList appointments={appointments} noResultsText={translations('No appointments')} editable={itemEditable} />)
            case 'LOYALTY':
              return (<Loyalty onSubmit={onCustomerLoyaltySubmit} initialValues={initialValues} editable={itemEditable} />)
            case 'COMMS_OPTIONS':
              return (<CommunicationOptions
                boxName={translations('Communication Options')}
                formId='communicationOptions'
                initialValues={initialValues}
                onSubmit={onCommunicationSubmit}
                editable={isCustomerEditable}
              />)
            case 'MESSAGES':
              return (<LatestMessage customer={initialValues} editable={itemEditable} />)
            case 'RECOMMENDED_PRODUCTS':
              return (<RecommendedProducts
                boxName={translations(item.heading, { customerFirstName })}
                formId='recommendedProducts'
                initialValues={initialValues}
                onSubmit={onSubmitRecommendedProducts}
                editable={itemEditable}
              />)
            case 'WISHLIST':
              return (<Wishlist
                boxName={translations('Wishlist')}
                formId='wishlist'
                initialValues={initialValues}
                onSubmit={onSubmitWishlist}
                editable={itemEditable}
              />)
            case 'WAITLIST':
              return (<CustomerWaitlist
                boxName={translations('Waitlist')}
                formId='waitlist'
                initialValues={waitlistInitialValues}
                onSubmit={onSubmitWaitlist}
                editable={itemEditable}
              />)
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

ViewCustomerFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default compose(
  FullScreenProgress,
  withStyles(styles)
)(ViewCustomerFormWrapper)
