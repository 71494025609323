import { createSelector } from 'reselect'
import _ from 'lodash'

import { connect } from 'react-redux'
import { compose, withPropsOnChange, branch } from 'recompose'
import { getFormValues, isValid } from 'redux-form'
import { getPriceValue } from '@redant/digital-store-prices-chalhoub'
import withWidth from '@material-ui/core/withWidth'

import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { withOrderActionHandlers } from './actions.js'

import { actions as rolesActions, selectors as rolesSelectors } from '../../../../../store/modules/roles'
import { actions as orderDetailsActions, selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { actions as currentOrderActions, selectors as currentOrderSelectors, constants as currentOrderConstants } from '../../../../../store/modules/currentOrder'

import {
  translations,
  editOrderEnabled,
  refundOrderEnabled,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled
} from '../../../../../config'

import formId from '../formId'
import OrderActions from './OrderActions'

// REDUX STUFF

// this fixes the issue of redux-form.getFormValues not returning
// untouched fields
const getOrderFormValues = createSelector([
  state => _.get(state, ['form', formId, 'registeredFields'], {}),
  getFormValues(formId)
], (registeredFields, values) => {
  return {
    ..._.transform(
      registeredFields,
      (result, value, key) => {
        result[key] = null
      },
      {}
    ),
    ...values
  }
})

// this fixes the issue of redux form validation messing up when we
// switch from desktop to mobile and back, unregistering fields and
// making sync errors not update properly
const getOrderFormIsValid = createSelector([
  isValid(formId),
  orderDetailsSelectors.getOrder
], (doesReduxFormSayItIsValid, order) => {
  let isValid = true
  let nothingPicked = true
  _.forEach(order.products, (product) => {
    if (product.unsold) return
    if (product.picked !== true && product.picked !== false) {
      isValid = false
    } else if (product.picked === true) {
      nothingPicked = false
    }
  })
  return isValid && doesReduxFormSayItIsValid && !nothingPicked
})

const mapStateToProps = (state, ownProps) => {
  const hasBeenRefunded = orderDetailsSelectors.getOrderHasBeenRefunded(state)
  const isFullyRefunded = orderDetailsSelectors.getOrderIsFullyRefunded(state)
  const isLoading = orderDetailsSelectors.getIsLoading(state)
  const failedToLoad = orderDetailsSelectors.getFailedToLoad(state)
  const order = orderDetailsSelectors.getOrder(state)
  const hasRefundPermission = authSelectors.getHasRefund(state)
  const hasExchangePermission = authSelectors.getHasExchange(state)
  const hasReassignOrderPermission = authSelectors.getHasReassignOrder(state)
  const hasCancelOrder = authSelectors.getHasCancelOrder(state)
  const isCancelledOrder = orderDetailsSelectors.getOrderIsCancelled(state)
  const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(state)
  const rolesStoreHasError = rolesSelectors.getHasError(state)
  const orderFormIsValid = getOrderFormIsValid(state)
  const orderFormValues = getOrderFormValues(state)
  const orderHasMissingProducts = orderDetailsSelectors.hasMissingProducts(state)

  const orderHasRefund = orderDetailsSelectors.orderHasRefund(state)
  const orderHasOneOrManyRefundedProducts = orderDetailsSelectors.orderHasOneOrManyRefundedProducts(state)
  const orderHasPaymentToken = orderDetailsSelectors.orderHasPaymentToken(state)
  const loggedInStoreId = authSelectors.getUserSelectedStoreId(state)
  const orderStoreId = orderDetailsSelectors.getOrderStoreId(state)
  const orderStatus = orderDetailsSelectors.getOrderStatus(state)

  const isValidRefund = !!orderFormValues && _.some(orderFormValues)
  const orderIsFromCurrentStoreOrAnonymous = !orderStoreId || orderStoreId === loggedInStoreId
  const isUserAdmin = authSelectors.getIsUserAdmin(state)

  const hasPositiveOrderTotal = getPriceValue(_.get(order, 'subTotal')) > 0
  const isQuantitySelected = !!orderFormValues && _.some(orderFormValues)
  const mappedState = {
    isFullyRefunded,
    hasBeenRefunded,
    order,
    isLoading,
    failedToLoad,
    inEditingStateForThisOrder: !!order.editMode,
    canRefund: !isCancelledOrder && hasRefundPermission && hasPositiveOrderTotal,
    canExchange: !isCancelledOrder && hasExchangePermission,
    canEditOrder: !isCancelledOrder && orderDetailsSelectors.canEditOrder(state),
    canReassignOrder: !isCancelledOrder && hasReassignOrderPermission,
    rolesStoreIsNotLoaded,
    rolesStoreHasError,
    canReassignCustomer: !isCancelledOrder && orderDetailsSelectors.canReassignCustomer(state),
    isPartialRefundState: orderDetailsSelectors.getIsPartialRefundState(state),
    isExchangeState: orderDetailsSelectors.getIsExchangeState(state),
    canCancelOrder: orderDetailsSelectors.canCancelOrder(state) && hasCancelOrder && !hasBeenRefunded,
    orderHasRefund,
    orderHasOneOrManyRefundedProducts,
    isValidRefund,
    isQuantitySelected,
    isInEditMode: currentOrderSelectors.getCurrentOrderEditMode(state),
    orderHasPaymentToken,
    orderFormValues,
    orderFormIsValid,
    isUserAdmin,
    orderIsFromCurrentStoreOrAnonymous,
    orderHasMissingProducts,
    isCancelledOrder,
    orderStatus
  }

  return mappedState
}

const withStandardOrderButtons = withPropsOnChange(
  [
    'canCancelOrder',
    'canEditOrder',
    'canReassignOrder',
    'canRefund',
    'canExchange',
    'inEditingStateForThisOrder',
    'isInEditMode',
    'isPartialRefundState',
    'isValidRefund',
    'isExchangeState',
    'isQuantitySelected',
    'orderHasRefund',
    'hasBeenRefunded',
    'isFullyRefunded',
    'isCancelledOrder',
    'orderStatus'
  ],
  (props) => {
    // from state
    const {
      canCancelOrder,
      canEditOrder,
      canReassignOrder,
      canRefund,
      canExchange,
      inEditingStateForThisOrder,
      isInEditMode,
      isPartialRefundState,
      isValidRefund,
      isExchangeState,
      isQuantitySelected,
      orderHasRefund,
      hasBeenRefunded,
      isFullyRefunded,
      isCancelledOrder,
      orderStatus
    } = props

    // handlers
    const {
      cancelRefundOrderState,
      cancelExchangeOrderState,
      editOrder,
      onCancelOrderClick,
      onAbandonedClick,
      onExchangeOrderClick,
      performRefund,
      performExchange,
      reassignOrder,
      refundOrder,
      stopEditingOrder,
      showOrderBarcode
    } = props

    let buttonsProps = []

    buttonsProps.push({
      children: translations('View order barcode'),
      onClick: showOrderBarcode
    })

    if (isExchangeState) {
      // buttons for when order is in exchange state
      buttonsProps = [
        {
          children: translations('Exchange'),
          onClick: performExchange,
          disabled: !canExchange || !isQuantitySelected
        },
        {
          children: translations('Cancel Exchange'),
          onClick: cancelExchangeOrderState,
          disabled: !canExchange
        }
      ]
    } else
      if (isPartialRefundState) {
        // buttons for when order is in partial refund state
        buttonsProps = [
          {
            children: translations('Refund'),
            onClick: performRefund,
            disabled: !canRefund || !isValidRefund || !isQuantitySelected
          },
          {
            children: translations('Cancel Refund'),
            onClick: cancelRefundOrderState,
            disabled: !canRefund
          }
        ]
      } else {
        // buttons for when a Standard order is not in partial refund state
        // TODO: stop functionality from existing depending on config,
        // (aka, don't just hide buttons lol)
        if (reassignOrderEnabled) {
          buttonsProps.push({
            children: translations('Reassign'),
            onClick: reassignOrder,
            disabled: !canReassignOrder || isInEditMode
          })
        }
        if (editOrderEnabled) {
          buttonsProps.push({
            children: translations(inEditingStateForThisOrder ? 'Stop Edit' : 'Edit'),
            onClick: inEditingStateForThisOrder ? stopEditingOrder : editOrder,
            disabled: !canEditOrder || isInEditMode || hasBeenRefunded
          })
        }
        if (refundOrderEnabled) {
          buttonsProps.push({
            children: translations('Refund'),
            onClick: refundOrder,
            disabled: isInEditMode || isFullyRefunded || !canRefund || orderHasRefund
          })
        }
        if (exchangeOrderEnabled) {
          buttonsProps.push({
            children: translations('Exchange'),
            onClick: onExchangeOrderClick,
            disabled: isInEditMode || isFullyRefunded || !canExchange
          })
        }
        if (cancelOrderEnabled) {
          buttonsProps.push({
            children: translations('Cancel'),
            onClick: (orderStatus === 'awaiting_payment' || orderStatus === "awaiting_picking" || orderStatus === "picking_in_progress") ? onAbandonedClick : onCancelOrderClick,
            disabled: !canCancelOrder || isInEditMode || isCancelledOrder
          })
        }
      }

    return { buttonsProps }
  }
)

const withStoreroomOrderButtons = withPropsOnChange(
  ['order', 'orderFormIsValid'],
  (props) => {
    // from state
    const { order, orderFormIsValid, config } = props
    // handlers
    const { updateOrder } = props

    if (config && config.actions) {
      const actionsForStatus = config.actions[_.get(order, 'status')] || []
      const buttonsProps = actionsForStatus.map(action => {
        const enabled = _.get(action, 'enabled', true)
        if(!enabled) return null 
        return {
          children: translations(action.name),
          onClick: () => {
            return (action.status ? updateOrder(action.status) : Promise.resolve()).then(Promise.all(
              (action.handlers || []).map(handlerName =>
                props[handlerName](_.get(action, `handlerProps[${handlerName}]`, {}))
              )
            ))
          },
          disabled: action.validateForm ? !orderFormIsValid : false
        }
      }).filter(value => value !== null)
      return { buttonsProps }
    }
    return {}
  }
)

// ENHANCER

const enhance = compose(
  connect(mapStateToProps),
  withWidth(),
  compose(
    withOrderActionHandlers,
    withStandardOrderButtons,
    withStoreroomOrderButtons
  )
)

export default enhance(OrderActions)
