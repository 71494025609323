import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'

import { translations } from '../../config'

import { getImage } from '../Images'

import FullScreenProgress from '../FullScreenLoader'
import { Table, TableHeader, TableRow, TableCell } from '../Table'

import style from './style'

const trashIcon = getImage('trashIcon')

class Waitlist extends PureComponent {
  renderTableRows () {
    const { waitlist = [], classes, onClickDelete, onClickSendMessage } = this.props

    return waitlist.map((waitlistItem, index) => {
      const { id, customer, createdAt, product, variant } = waitlistItem
      return (
        <TableRow key={id}>
          <TableCell xs={4} sm={4}>
            <div
              className={classes.nameContainer}
            >
              <div
                className={classes.nameWrapper}
              >
                <Link to={`/customers/${customer.id}`}>{` ${customer.firstName} ${customer.lastName}`}</Link>
              </div>
            </div>
          </TableCell>
          <TableCell xs={3} sm={3}>{moment(createdAt).format('DD/MM/YYYY')}</TableCell>
          <TableCell xs={2} sm={2}>
            <Link to={`/product/${product.id}`}>{`${variant ? variant.name : _.startCase(translations('cta-noSizeSelected'))}`}</Link>
          </TableCell>
          <TableCell xs={3} sm={3} justify={'flex-end'} direction={'row'}>
              <IconButton
                aria-label='Remove from waitlist'
                onClick={() => onClickDelete(id)}
              >
                <img alt="" src={trashIcon} className={classes.trashIconImg} />
              </IconButton>
              <IconButton
                aria-label='Send message'
                onClick={() => onClickSendMessage({ customer, product, variant })}
                className={classes.messageIconButton}
              >
                <img alt="" src={getImage('messageDarkIcon')} className={classes.messageIcon} />
              </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }
  _renderTable () {
    return (
      <Table
        header={(
          <TableHeader>
            <TableCell xs={4} sm={4}>{translations('Customer Name')}</TableCell>
            <TableCell xs={3} sm={3}>{translations('Added')}</TableCell>
            <TableCell xs={2} sm={2}>{translations('Variant')}</TableCell>
          </TableHeader>
        )}
        rows={this.renderTableRows()}
        emptyMessage={translations('No results found')}
      />
    )
  }
  render () {
    const { classes, loadMore, hasMore } = this.props
    return (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        initialLoad={false}
      >
        {this._renderTable()}
      </InfiniteScroll>
    )
  }
}

Waitlist.propTypes = {
  getProductWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(Waitlist)
)
