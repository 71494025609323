import _ from 'lodash'
import { submit, reset, isValid as rfIsValid, getFormError, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import AdvSearchFormContentBox from './AdvSearchFormContentBox'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { formId, editable } = ownProps
  const submitForm = () => dispatch(submit(formId))
  const formError = getFormError(formId)(state)
  const isValid = rfIsValid(formId)(state)
  const isSubmitting = _.get(state, `form.${formId}.submitting`, false)

  return {
    ...ownProps,
    isValid,
    isSubmitting,
    submitForm,
    formError,
    editable,
  }
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AdvSearchFormContentBox)

export default (formId, opts) => reduxForm({
  form: formId,
  ...opts
})(connectedComponent)
