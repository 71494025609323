export default theme => ({
  bubble: {
    backgroundColor: theme.palette.bubbleGrey.main,
    color: theme.palette.bubbleTextGrey.main,
    borderRadius: '10px',
    fontSize: '11px',
    lineHeight: '18px',
    padding: '1px 9px',
    fontWeight: 'bold',
    margin: '5px 0 5px -3px',
    display: 'inline-block',
    textDecoration: 'none'
  },
  noVerticalMargin: {
    marginTop: 0,
    marginBottom: 0
  },
  noMargin: {
    margin: 0
  }
})
