import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Radio, RadioGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'

import styles from './style'

export const Radiolist = ({
  label,
  onChange,
  options,
  value,
  smallLabel,
  classes,
  radioClassName,
  row=true
}) => {
  return (
    <Fragment>
      <FormLabel
        key='label'
        classes={{
          focused: classes.floatingLabelFocusStyle,
          root: smallLabel ? classes.smallLabel : null
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup key='group' onChange={onChange} row={row}>
        {options.map((option, i) => (
          <FormControlLabel
            key={option.label}
            className={option.disabled ? classes.disabled : ''}
            control={
              <Radio
                {...option}
                checked={value ? option.value === value : i === 0}
                className={radioClassName}
              />
            }
            label={translations(option.label)}
          />
        ))}
      </RadioGroup>
    </Fragment>
  )
}

Radiolist.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  })).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  row: PropTypes.bool
}

export default withStyles(styles)(Radiolist)
