import { lighten } from 'polished'
import { theme } from '../../../config/theme'

const border = `1px solid ${theme.palette.contentBoxGrey.main}`

export default (theme) => ({
  container: {
    border,

    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  list: {
    width: '100%',
    paddingTop: 0,
    height: 120,
    overflow: 'auto',

    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: 175
    }
  },
  title: {
    borderBottom: border
  },
  item: {
    width: '100%',
    borderBottom: border,

    [theme.breakpoints.up('m')]: {
      '& button': {
        visibility: 'hidden'
      },

      '&:hover button': {
        visibility: 'visible'
      }
    }
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: 0,
    background: theme.palette.bubbleGrey.main,
    marginLeft: 'auto',
    padding: 0,
    cursor: 'pointer',
    transition: '0.2s background-color',

    '&:hover': {
      background: lighten(0.28, theme.palette.darkGrey.main)
    }
  },
  closeButtonIcon: {
    width: 12,
    height: 12
  }
})
