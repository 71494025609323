import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { translations } from '../../../../config'

import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import SubHeader from '../../../../components/SubHeader'

const Title = styled(H2)`
  text-transform: uppercase;
`

const HeaderBar = ({
  title,
  onBack
}) => {
  return (
    <>
    <h1 className='visuallyhidden'>{translations('Consultation')}</h1>
    <Helmet><title>{translations('Consultation')}</title></Helmet>
    <SubHeader
      centerContent={(
        <Title value={title} />
      )}
    />
    </>
  )
}

export default HeaderBar
