import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Product from './Product'
import { componentConfigs, productDetailsTypes } from '../../../../config'

import currencyFormatter from '../../../../formatters/currencyFormatter'
import { subtractPrices } from '../../../../helpers'
import { selectors as productDetailsSelectors } from '../../../../store/modules/productDetails'

class ProductContainer extends PureComponent {
  _generateProps = () => {
    const { pushToProduct, editing, onClickEnabled, id, link, selectedVariantId, variant } = this.props
    const price = productDetailsSelectors.getPriceDisplayForProduct(this.props)
    return {
      ...this.props,
      price,
      onProductContainerClick: () => {
        if (onClickEnabled && !editing) {
          let variantId = null
          if (selectedVariantId) {
            variantId = selectedVariantId
          } else if (variant) {
            variantId = variant.id
          }
          if (componentConfigs.selectProduct.productDetailsType === productDetailsTypes.PDP) {
            // push
            pushToProduct(id, variantId)
          } else {
            // go to link
            window.open(link, '_blank')
          }
        }
      }
    }
  }

  render() {
    const props = this._generateProps()
    return <Product {...props} />
  }
}

const mapDispatchToProps = dispatch => ({

  pushToProduct: (id, selectedVariantId) => {
    if (selectedVariantId) {
      dispatch(push(`/product/${id}/variant/${selectedVariantId}`))
    } else {
      dispatch(push(`/product/${id}`))
    }
  }
})

export default connect(null, mapDispatchToProps)(ProductContainer)
