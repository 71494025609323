import general from './configFiles/general'

const {
  loginTypes,
  productDetailsTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  productsFilters,
  loyaltyTiers,
  clientReporting,
  salesforceOCAPI = {},
  checkoutModules,
  distantCheckoutModules,
  basketCheckoutModules,
  consultationModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  productDetails,
  viewEvent,
  noPrices,
  products,
  addressLookupCountries,
  retailAnalytics,
  customerSnapshot,
  checkoutBackdateLimit,
  placeholderImage,
  customerRegionEnabled,
  checkoutAllowUnassignedOrder,
  checkoutAllowAnonymous,
  emailBasketEnabled,
  refundBasketEnable,
  saveToProfileEnabled,
  deliveryEnabled,
  registerCustomerTermsCheckboxEnabled,
  consultationsEnabled,
  createAppointmentEnabled,
  showInStoreStockInVariantDropdown,
  isStockExact,
  storeroomRequestEnabled,
  storeroomRequestModules,
  forgotPasswordFormEnabled,
  manualDiscountsEnabled,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  storeroomProductNotPickedReasons,
  isStoreroomProductNotPickedReasonRequired,
  editOrderEnabled,
  refundOrderEnabled,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled,
  addedToBasketModalEnabled,
  scanProductEnabled,
  distanceUnits,
  notificationContentOptions,
  messageContentOptions,
  storeLocationsEnabled,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled,
  customerProfileContentOptions,
  recommendedProductsContentOptions,
  consultationContentOptions,
  tileDimensions,
  apiUpdateSource,
  groupedProductsEnabledPLP,
  plpAddToBasketEnabled,
  paymentOptions,
  storePinMinLength,
  editPriceInCheckout,
  followEnable,
  customerSearchPageSize,
  prioritizedVariants,
  findInOtherLocationsSidebarEnabled,
  pdpQtySelectValidationEnabled,
  offlineCatalogueEnabled
} = general

const nullEmailRegex = new RegExp(/unknown_.*@redant.com/, 'g')

export {
  loginTypes,
  productDetailsTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  productsFilters,
  loyaltyTiers,
  clientReporting,
  salesforceOCAPI,
  checkoutModules,
  distantCheckoutModules,
  basketCheckoutModules,
  consultationModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  productDetails,
  viewEvent,
  noPrices,
  products,
  addressLookupCountries,
  retailAnalytics,
  customerSnapshot,
  checkoutBackdateLimit,
  placeholderImage,
  customerRegionEnabled,
  checkoutAllowUnassignedOrder,
  checkoutAllowAnonymous,
  emailBasketEnabled,
  refundBasketEnable,
  saveToProfileEnabled,
  deliveryEnabled,
  registerCustomerTermsCheckboxEnabled,
  consultationsEnabled,
  createAppointmentEnabled,
  showInStoreStockInVariantDropdown,
  isStockExact,
  storeroomRequestEnabled,
  storeroomRequestModules,
  forgotPasswordFormEnabled,
  manualDiscountsEnabled,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  storeroomProductNotPickedReasons,
  isStoreroomProductNotPickedReasonRequired,
  editOrderEnabled,
  refundOrderEnabled,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled,
  addedToBasketModalEnabled,
  scanProductEnabled,
  distanceUnits,
  notificationContentOptions,
  messageContentOptions,
  storeLocationsEnabled,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled,
  customerProfileContentOptions,
  recommendedProductsContentOptions,
  consultationContentOptions,
  tileDimensions,
  apiUpdateSource,
  groupedProductsEnabledPLP,
  plpAddToBasketEnabled,
  paymentOptions,
  storePinMinLength,
  editPriceInCheckout,
  nullEmailRegex,
  followEnable,
  customerSearchPageSize,
  prioritizedVariants,
  findInOtherLocationsSidebarEnabled,
  pdpQtySelectValidationEnabled,
  offlineCatalogueEnabled
}
