import React from 'react'
import _ from 'lodash'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import { checkoutModules, referralModules, storeroomRequestModules } from '../../config'
import { pathsForCheckoutModule } from '../../helpers'
import { DistantCheckoutScreen } from '../../retailos/DistantCheckoutScreen'
import { BasketCheckoutScreen } from '../../retailos/BasketCheckoutScreen'
import OrderSummary from './OrderSummary'
import SelectCustomer from './SelectCustomer'
import RegisterCustomer from './RegisterCustomer'
import Delivery from './Delivery'
import PaymentDummy from './Payment/PaymentDummy'
import CouponCode from './CouponCode'
import EditBasket from './EditBasket'
import PayPalHere from './PayPalHere'
import PaymentSalesforce from './PaymentSalesforce'
import Deferred from './Deferred'
import ChalhoubPayment from './ChalhoubPayment'
import Billing from './Billing'

const componentForPath = {
  'order-summary': OrderSummary,
  'select-customer': SelectCustomer,
  'register-customer': RegisterCustomer,
  delivery: Delivery,
  'payment-dummy': PaymentDummy,
  'coupon-code': CouponCode,
  'edit-basket': EditBasket,
  'paypal-here': PayPalHere,
  payment: PaymentSalesforce,
  deferred: Deferred,
  'chalhoub-payment': ChalhoubPayment,
  billing: Billing
}

const getRoutesForModules = (modules, pathPrefix) => {
  return _.chain(modules)
    .map((someModule) => pathsForCheckoutModule[someModule.name])
    .compact()
    .flatten()
    .map((path) => {
      return <PrivateRoute key={path} path={`/${pathPrefix}/${path}`} component={TimedLogoutComponent(componentForPath[path])} exact allowOffline />
    })
    .value()
}

const newCheckoutRoutes = [
  <PrivateRoute key='/checkout/basket-checkout' path='/checkout/basket-checkout' exact component={TimedLogoutComponent(BasketCheckoutScreen)} allowOffline />,
  <PrivateRoute key='/checkout/distant-checkout' path='/checkout/distant-checkout' exact component={TimedLogoutComponent(DistantCheckoutScreen)} allowOffline />
]

const routes = [
  ...getRoutesForModules(checkoutModules, 'checkout'),
  ...getRoutesForModules(referralModules, 'referral'),
  ...getRoutesForModules(storeroomRequestModules, 'storeroom-request'),
  ...newCheckoutRoutes
]

export default routes
