import { compose, withState, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'

import { actions as orderDetailsActions } from '../../../../../store/modules/orderDetails'
import Editable from '../../../../../components/Editable'

import OrderNotes, { formId } from './OrderNotes'

export default compose(
  connect(),
  Editable,
  withPropsOnChange(
    ['order'],
    ({ order }) => {
      const notes = _.get(order, 'details.notes', '')
      const initialValues = { notes }
      return {
        initialValues,
        status: order.status
      }
    }
  ),
  withHandlers({
    onSubmit: ({ toggleEdit, dispatch, order }) => (values) => {
      const notes = _.get(values, 'notes')
      const id = _.get(order, 'id')
      dispatch(orderDetailsActions.updateOrder({ id, noSpinner: true, details: { notes } }))
      toggleEdit()
    }
  })
)(OrderNotes)
