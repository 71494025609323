import { connect } from 'react-redux'
import SubCategories from './SubCategories'
import { selectors as categoriesSelectors } from '../../../../store/modules/categories'
import { translations } from '../../../../config'

const mapStateToProps = (state, props) => ({
  categories: [
    ...categoriesSelectors.getChildCategoriesById(state, props.catId),
    { id: `${props.catId}/all`, name: translations('Search all in category', { category: props.category.name}) }
  ]
})

export default connect(
  mapStateToProps
)(SubCategories)
