const getClearButtonCrossLineStyle = angle => ({
  content: '""',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%,-50%) rotate(${angle}deg)`,
  width: 14,
  height: 2,
  borderRadius: 1,
  backgroundColor: 'white'
})

export default theme => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.text.main
    }
  },
  container: {
    position: 'relative'
  },
  inputWrap: {
    position: 'relative',
    marginTop: 16
  },
  paper: {
    position: 'absolute',
    minWidth: '100%',
    // padding: '8px 0', // would be good if this could work with virtualized list
    zIndex: '1300'
  },
  clearButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 5,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  clearButton: {
    width: 20,
    height: 20,
    backgroundColor: 'black',
    borderRadius: 10,
    opacity: 0.25,
    marginTop: -5,
    position: 'relative',
    cursor: 'pointer',
    transition: '0.1s linear opacity',
    '&:hover': {
      opacity: 0.5
    },
    '&:before': getClearButtonCrossLineStyle(45),
    '&:after': getClearButtonCrossLineStyle(135)
  },

  selectRoot: {
    width: '100%',
    paddingRight: 32
  },
  selectInput: {
    cursor: 'pointer'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: '0 12px'
  },
  searchBar: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    position: 'relative',
    flex: 1
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 5,
    width: 20,
    height: 20
  },
  searchInput: {
    padding: '0 10px 0 30px'
  },
  arrowDropDownIcon: {
    // this is pasted straight out of node_modules/material-ui/Select/SelectInput.js
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 12px)',
    color: theme.palette.action.active,
    'pointer-events': 'none'
  },
  noLabelArrow: {
    top: 'calc(50% - 20px)'
  }
})
