import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import AlertBadge from '../../AlertBadge'
import { theme } from '../../../config/theme'

const BadgeContainer = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  top: 2px;
`

const BasketIcon = styled(ShoppingBasket)`
  color: ${props => props.disabled ? '#999999' : theme.palette.headerBgIconColor.main};
`
const BasketCounter = ({
  openBasket,
  disabled
}) => (
  <BadgeContainer>
    <AlertBadge
      onClick={() => {
        if (!disabled) {
          openBasket()
        }
      }}
      type='basket'
    >
      <BasketIcon disabled={disabled}/>
    </AlertBadge>
  </BadgeContainer>
)

BasketCounter.propTypes = {
  openBasket: PropTypes.func.isRequired
}

export default BasketCounter
