import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Heading from '../Heading'
import Button from '../Button'
import ButtonsContainer from '../ButtonsContainer'
import FormSubmit from '../Form/FormSubmit'
import style from './style'
import { translations } from '../../config'

class BasicModalOverlay extends PureComponent {
  _renderButtons = () => {
    const {
      actions,
      submitting
    } = this.props
    return _.chain(actions)
      .partition(action => action.success)
      .thru(([dismissActions, successActions]) => [...successActions, ...dismissActions])
      .map((action) => {
        const { onClick, primary, text, success, ...rest } = action
        if (success) {
          return (
            <FormSubmit
              key={text}
              buttonType={primary ? 'primary' : 'white'}
              noContainer
              onClick={onClick}
              submitting={submitting}
              {...rest}
            >
              {translations(text)}
            </FormSubmit>
          )
        } else {
          return (
            <Button
              key={text}
              onClick={onClick}
              buttonType={primary ? 'primary' : 'white'}
              {...rest}
            >
              {translations(text)}
            </Button>
          )
        }
      })
      .value()
  }
  render () {
    const {
      title,
      text,
      classes,
      children,
      buttonDirection
    } = this.props
    return (
      <Fragment>
        {title ? <Heading component={'h2'} className={classes.title}>{title}</Heading>: null}
        {children || <p className={classes.text}>{text}</p>}
        <ButtonsContainer
          className={classes.buttonsContainer}
          align='right'
          isModal
          buttonDirection={buttonDirection}
        >
          {this._renderButtons()}
        </ButtonsContainer>
      </Fragment>
    )
  }
}

export default withStyles(style)(BasicModalOverlay)
