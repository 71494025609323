import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { translations } from '../../../config'
import SearchInput from '../../../components/SearchInput'
import SearchInputAdvanced from '../../../components/SearchInputAdvanced'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import LoaderContainer from '../../../components/LoaderContainer'

import style from './style'
import CustomerSearchResults from './CustomerSearchResults'
import * as validators from '../../../validators'

const numericValidator = validators.globalPhoneNumber
class CustomerSearch extends Component {
  state = {
    query: {
      firstName: '',
      lastName: '',
      phoneNo: '',
      error: ''
    }
  }
  
  searchCustomerPhone = () => {
    const { searchCustomer } = this.props
    if (this.state.query.phoneNo && this.state.query.phoneNo.length > 1 ) {
      searchCustomer({ query : this.state.query })
      return true
    }
    return false
  }

  searchCustomer = () => {
    const { clearResults, searchCustomer } = this.props
    const query = this.state.query
    const testQuery = Object.keys(query).some((key) => query[key].length > 1)

    if ( query && testQuery ) {
      // Clear results before loading new ones to ensure new results displayed.
      clearResults()
      searchCustomer({ query : this.state.query })
      return true
    }
    return false
  }

  updatePhoneNoQuery = (e)  => {
    const userInput = e.target.value
    const validation = numericValidator(userInput)
    if(validation !== undefined) {
      this.setState({
        query: {
          ...this.state.query,
          'error': validation
        }
      })
    } else {
      this.setState({
        query: {
          ...this.state.query,
          'error': '',
          'phoneNo': e.target.value
        }
      })
    }
  }

  updateQuery = (value, name) => {
      if(name === 'phoneNo' && numericValidator(value) !== undefined) {
        // Don't update state if letters entered into phone number input
        return
      } else {
        this.setState({
          query: {
            ...this.state.query,
            'error': '',
            [name]: value
          }
        })
      }
    } 

  clearQuery = () => {
      this.setState({ query: '' })
      const { searchCustomer, resetSearch } = this.props
      resetSearch()
  }

  renderBackBar () {
    const { query, onBackClick } = this.props
    return (
      <BackBar
        onClick={
          this.state.query
            ? this.clearQuery
            : onBackClick
        }
      />
    )
  }

  renderSearchInput () {
    const { isLoading, openAdvancedSearch, classes } = this.props
    const phoneNo = this.state.query.phoneNo
    const query = this.state.query
    const updatePhoneNoQuery = this.updatePhoneNoQuery
    const searchCustomerPhone = this.searchCustomerPhone
    const updateQuery = this.updateQuery
    const searchCustomer = this.searchCustomer
    const clearQuery = this.clearQuery
    const inputError = query.error !== ''

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SearchInput
            value={phoneNo}
            placeholder={translations('Look up customers')}
            onChange={updatePhoneNoQuery}
            autoFocus={false}
            isSearchButton
            onClick={searchCustomerPhone}
            disabled={isLoading}
            customerSearch={true}
          />
          <p className={`${classes.errorMessage} ${!inputError && classes.hidden}`}>{query.error}</p>
          <SearchInputAdvanced 
            onClick={() => {openAdvancedSearch(query, updateQuery, clearQuery, searchCustomer)}}
          />
        </Grid>
      </Grid>
    )
  }

  renderContent () {
    const { onCustomerClick, children, hasSearched, error, isLoading, searchResultsPresent } = this.props
    if (!searchResultsPresent && isLoading) {
      return <LoaderContainer isLoading={isLoading} />
    } else {
      return (
        hasSearched || error || searchResultsPresent
          ? <CustomerSearchResults onCustomerClick={onCustomerClick} clearQuery={this.clearQuery} />
          : (children || null)
      )
    }
  }

  render () {
    const { classes, className, registerCustomer } = this.props
    const containerClass = classNames(classes.container, className)
    return (
      <div className={containerClass}>
        <SubHeader
          leftContent={this.renderBackBar()}
          centerContent={this.renderSearchInput()}
          fixed
          customerSearch
        />
        {this.renderContent()}
        {registerCustomer && (
          <BottomBar>
            <ButtonsContainer align='center'>
              <Button
                buttonType='primary'
                onClick={registerCustomer}
                big
              >
                {translations('Register Customer')}
              </Button>
            </ButtonsContainer>
          </BottomBar>
        )}
      </div>
    )
  }
}

export default withStyles(style)(CustomerSearch)
