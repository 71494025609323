import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import { translations } from '../../../../config'
import { actions as categoriesActions } from '../../../../store/modules/categories'
import { actions as productsActions } from '../../../../store/modules/products'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'

import ConsultationContentItem from '../../../Consultations/ConsultationContentItem'
import ProductsRouter from '../../../Products/ProductsRouter'
import AddContentButton from '../../../../components/AddContentButton'
import modalService from '../../../../services/modalService'

const CurrentOrderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 180px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #ffffff;
`

const ProductTabs = styled(Tabs)`
  height: 100%;
`

const AddContentGridItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
`

const ItemContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const styles = {
  flexContainer: {
    height: '100%',
    alignItems: 'center'
  },
  indicator: {
    backgroundColor: '#51af33'
  }
}

const ContentTabs = ({ classes }) => {
  const dispatch = useDispatch()
  const showingContent = useSelector(currentAppointmentSelectors.getAppointmentShowingContent)
  const appointmentContents = useSelector(currentAppointmentSelectors.getAppointmentContents)

  const currentTabValue = _.get(showingContent, 'id')

  useEffect(() => {
    dispatch(categoriesActions.fetchAllCategories())
  }, [])

  const handleChange = (tabContentId) => {
    const nextShowingContentId = tabContentId === currentTabValue ? null : tabContentId
    const nextShowingContent = _.find(appointmentContents, (content) => _.get(content, 'details.id') === nextShowingContentId)
    dispatch(currentAppointmentActions.toggleContentIsShowing({ ...nextShowingContent }))
    dispatch(currentAppointmentActions.setStage({ stage: null }))
  }

  const addProductsToAppointment = useCallback(() => {
    dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addProductOrService'))
    dispatch(productsActions.resetStore())
    modalService.open({
      component: ProductsRouter,
      fullScreen: true,
      overflowHidden: true,
      exit: () => {
        modalService.close()
      },
      buttonValue: translations('Select Product'),
      multiSelect: true,
      noRouting: true
    })
  })

  return (
    <CurrentOrderWrapper>
      <AddContentGridItem>
        <AddContentButton onClick={addProductsToAppointment} />
      </AddContentGridItem>
      <ProductTabs
        value={currentTabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        classes={classes}
      >
        {_.map(_.sortBy(appointmentContents, ['details.id']), (content) => {
          if (content.type === 'product' || content.type === 'inspiration') {
            const selected = _.get(content, 'isShowing', false)

            return (
              <ItemContainer>
                <ConsultationContentItem
                  {...content}
                  {...content.details}
                  selected={selected}
                  onClick={() => handleChange(_.get(content, 'details.id'))}
                />
              </ItemContainer>
            )
          } else {
            return null
          }
        })}
      </ProductTabs>
    </CurrentOrderWrapper>
  )
}

export default withStyles(styles)(ContentTabs)