import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import { constants as authConstants } from '../auth'
import { SUCCESS } from '../../middleware/redux-promise'

class RegionsMiddleware {
  loginSuccessFetchRegionsMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.status === SUCCESS & action.type === authConstants.LOGIN && !action.isCustomerMode) {
      dispatch(actions.fetchAllRegions())
    }

    next(action)
  }
  ssoLoginSuccessFetchRegionsMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
      const res = matchPath(action.payload.location.pathname, {
        path: '/sso/success/:token',
        exact: true
      })
      if (res && res.params.token) {
        dispatch(actions.fetchAllRegions())
      }
    }
    next(action)
  }
}

export default new RegionsMiddleware()
