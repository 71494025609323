import React from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { SubmissionError } from 'redux-form'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormError from './FormError'
import FormSubmit from './FormSubmit'
import FormBody from './FormBody'
import style from './style'

const Form = ({
    handleSubmit,
    onSubmit,
    onChange,
    pristine,
    reset,
    submitting,
    invalid,
    children,
    error,
    initialValues,
    editing,
    givenClass,
    classes,
    ...props
}) => {
  const submit = handleSubmit((...args) => {
    const submitResult = onSubmit(...args)
    if (submitResult && submitResult.then) {
      return submitResult
        .catch(error => {
          throw new SubmissionError({ _error: error.message })
        })
    } else {
      return submitResult
    }
  })

  const mappedChildren = _.chain([children])
    .flatten()
    .map(child => {
      switch (child.type) {
        case FormSubmit:
          return React.cloneElement(child, { submitting, invalid, key: 'submit' })
        case FormError:
          return React.cloneElement(child, { error, key: 'error' })
        case FormBody:
          return React.cloneElement(child, { editing, initialValues, key: 'body' })
        default:
          return child
      }
    })
    .value()

  return (
    <form onChange={onChange} onSubmit={submit} className={`${classes.root} ${givenClass}`} style={{ width: '100%' }} autocomplete="off">
      {mappedChildren}
    </form>
  )
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  editing: PropTypes.bool
}

export default compose(
  withStyles(style)
)(Form)
