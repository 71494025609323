import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import cx from 'classnames'

import { getFullName } from '../../helpers'
import style from './style'
import ListItem from '../List/ListItem'
import { translations } from '../../config'

const GroupList = props => {
  const {
    sortBy,
    classes,
    onClickItem,
    data,
    boxStyle,
    noResultsText,
    editable
  } = props
  const listItemProps = _.pick(props, [
    'renderItem', 'ItemComponent', 'narrow', 'noMargin', 'itemProps', 'noBorder', 'boxStyle'
  ])
  const isArray = Array.isArray(data)

  const sortedGroups = (
    isArray
      ? (
        data.map(({ name, items }) => {
          return {
            groupName: name,
            items: _.sortBy(items, sortBy)
          }
        })
      )
      : (
        _.sortBy(Object.keys(data))
          .map(key => {
            return {
              groupName: key,
              items: _.sortBy(data[key], sortBy)
            }
          })
      )
  )

  const groupTitleClass = cx({
    [classes.groupTitle]: !props.boxStyle,
    [classes.boxHeader]: props.boxStyle
  })

  const containerClasses = cx({
    [classes.container]: true,
    [classes.boxContainer]: boxStyle
  })

  const itemsNumber = _.reduce(
    sortedGroups,
    (sum, n) => sum += Number(n.items.length)
    , 0)

  return (
    <section className={classes.containerContainer}>
      {(!itemsNumber && noResultsText) ?
        (
          <h2 className={groupTitleClass}>
            {noResultsText}
          </h2>
        ) : (
          <div className={containerClasses}>
            {sortedGroups
              .filter(({ items }) => !!items.length)
              .map(({ groupName, items }) => {
                return (
                  <div key={groupName}>
                    <div
                      className={classes.groupTitleContainer}
                      id={`GroupList-${groupName}`}
                    >
                      <h2
                        className={groupTitleClass}
                      >
                        {translations(groupName)}
                      </h2>
                    </div>
                    <ul className={classes.itemsContainer}>
                      {
                        items.map((item, index) => {
                          return (
                            <ListItem
                              {...listItemProps}
                              item={item}
                              onClick={onClickItem}
                              key={item.id || index}
                              editable={editable}
                            />
                          )
                        })
                      }
                    </ul>
                  </div>
                )
              })
            }
          </div>
        )}

    </section>
  )
}

GroupList.defaultProps = {
  sortBy: getFullName,
  onClickItem: () => { }
}

GroupList.propTypes = {
  boxStyle: PropTypes.bool
}

export default withStyles(style)(GroupList)
