import _ from 'lodash'
import { translations } from '../../config'
import toastService from '../../services/toastService/toastService'

const throttledLoading = _.throttle((message) => {
  toastService.action({
    type: 'info',
    message: message,
    autoHideDuration: 10 * 1000,
    disableClickAway: true,
    verticalPosition: 'top',
    horizontalPosition: 'right'
  })
}, 10 * 1000, { leading: true, trailing: false })

const throttledError = _.throttle((message) => {
  toastService.action({
    type: 'error',
    message: message,
    autoHideDuration: 10 * 1000,
    disableClickAway: true,
    verticalPosition: 'top',
    horizontalPosition: 'right'
  })
}, 10 * 1000, { leading: true, trailing: false })

export const wrapFetchWithSlowNotification = async (fetchFn, args) => {
  const theURL = args[0]
  const monitorRequests = [
    {
      matchUrl: '/search',
      stillLoadingTimeMsec: 3 * 1000,
      stillLoadingMessage: translations('Results still loading, do not refresh'),
      apiHookFailMessage: translations('Results may be limited, please try again')
    }
  ]
  const reqToMonitor = monitorRequests.find(config => theURL.includes(config.matchUrl))
  if (!reqToMonitor) {
    return fetchFn(...args)
  }

  const timer = setTimeout(() => {
    throttledLoading(reqToMonitor.stillLoadingMessage)
  }, reqToMonitor.stillLoadingTimeMsec)

  try {
    const fetchResult = await fetchFn(...args)
    const response = await fetchResult.text()
    const json = JSON.parse(response)
    // so the sdk can call .json() again
    fetchResult.json = () => Promise.resolve(json)

    clearTimeout(timer)

    const didApiHookFail =
      _.get(json, 'meta.apiHook.replaceHook.success') === false ||
      _.get(json, 'meta.apiHook.afterHook.success') === false

    if (didApiHookFail) {
      throttledError(reqToMonitor.apiHookFailMessage)
    }

    return fetchResult
  } catch (err) {
    clearTimeout(timer)
    throw err
  }
}
