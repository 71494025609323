import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'

import Billing from './Billing'
import withCheckoutFlow from '../withCheckoutFlow'

export default compose(
  withCheckoutFlow,
  withPropsOnChange(
    ['deliveryAddress', 'customer', 'details'],
    props => {
      const {
        deliveryAddress,
        customer = {},
        details = {}
      } = props

      const customerAddress = customer.address
      const billingAddress = details.billing
      const initialAddress = billingAddress || deliveryAddress || customerAddress || {}
      return {
        hasAddressPrefilled: !_.isEmpty(initialAddress),
        initialValues: {
          address:  initialAddress
        }
      }
    }
  ),
  withHandlers({
    onSubmit: ({ updateOrder, next }) => formData => {
      const updateOrderParams = {
        details: {
          billing: formData.address
        }
      }
      updateOrder(updateOrderParams)
      next()
    }
  })
)(Billing)
