import { connect } from 'react-redux'
import { selectors as appSelectors } from '../../../../store/modules/app'
import EditPriceModal, { editPriceType } from './EditPriceModal'

const mapStateToProps = state => {
  const currencyCode = appSelectors.getAppCurrency(state)
  return {
    currencyCode
  }
}
export { editPriceType }
export default connect(mapStateToProps)(EditPriceModal)
