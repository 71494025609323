export default (theme) => ({
    orderLabelContainer: {
        background: '#404040bf',
        padding: 5,
        fontSize: 11,
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        top: '-30px',
        borderRadius: 1,
        width: '80%',
        fontWeight: 600,
    }
})
