import { connect } from 'react-redux'

import { actions as customersActions, selectors as customersSelectors } from '../../../store/modules/customers'
import CustomerSearch from './CustomerSearch'
import AdvancedSearchModal from '../../../components/SearchInputAdvanced/AdvancedSearchModal'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  return {
    hasSearched: customersSelectors.getCustomerHasBeenSearched(state),
    error: customersSelectors.getCustomerListError(state),
    isLoading: customersSelectors.getCustomerListIsLoading(state),
    searchResultsPresent: customersSelectors.getCustomerSearchResultsPresent(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchCustomer: (query) => {
      dispatch(customersActions.searchCustomerFresh(query))
    },
    resetSearch: () => {
      dispatch(customersActions.resetStore())
    },
    clearResults: () => {
      dispatch(customersActions.searchCustomerClearResults())
    },
    openAdvancedSearch: (query, updateQuery, resetQuery, searchCustomer) => {
      modalService.open({
        modalIndex: 3,
        component: AdvancedSearchModal,
        inModal: true,
        onClose: () => {
          modalService.close({ modalIndex: 3 })
        },
        query,
        updateQuery, 
        resetQuery,
        searchCustomer
      })
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch)
