import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'

import ButtonsContainer from '../../../../../components/ButtonsContainer'
import BottomBar from '../../../../../components/BottomBar'
import Button from '../../../../../components/Button'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'
import style from './style'

const openMobileModal = (Buttons) => {
  modalService.open({
    component: Buttons
  })
}

const Buttons = (props) => {
  const { classes, buttonsProps = [], mobile, onClick } = props

  return (
    <ButtonsContainer align='center' innerClassName={classes.mobileButtonContainer}>
      {buttonsProps.map(buttonProps => {
        return <Button
          key={buttonProps.children}
          buttonType='primary'
          big={!mobile}
          {...buttonProps}
          onClick={(...e) => {
            onClick && onClick()
            buttonProps.onClick && buttonProps.onClick(...e)
          }}
        />
      })}
    </ButtonsContainer>
  )
}

const OrderActions = props => {
  const { buttonsProps = [], width } = props
  const mobile = !isWidthUp('sm', width)

  if (buttonsProps.length) {
    return (
      <BottomBar>
        {mobile ? (
          <Button
            big
            buttonType='primary'
            onClick={() => {
              openMobileModal(() => <Buttons {...props} mobile onClick={modalService.close} />)
            }}
          >{translations('Actions')}</Button>
        ) : (
          <Buttons {...props} />
        )}
      </BottomBar>
    )
  } else {
    return null
  }
}

export default withStyles(style)(OrderActions)
