import { connect } from 'react-redux'
import {
  actions as currentOrderActions,
  selectors as currentOrderSelectors,
} from '../../../store/modules/currentOrder'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as appSelectors } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as checkoutFlowActions } from '../../../store/modules/checkoutFlow'

import BasketItem from './BasketItem'
import { compose, withHandlers, withProps } from 'recompose'

const mapStateToProps = state => {
  const discountType = currentOrderSelectors.getDiscountType(state)
  const currencyCode = appSelectors.getAppCurrency(state)
  const hasRefundPermission = authSelectors.getHasRefund(state)
  const consultationMode = !!currentAppointmentSelectors.getAppointmentCustomerId(state)

  return {
    currencyCode,
    discountType,
    hasRefundPermission,
    consultationMode
  }
}
const mapDispatchToProps = dispatch => ({
  changeQuantity: params => dispatch(currentOrderActions.changeProductQuantity(params)),
  removeProduct: params => dispatch(currentOrderActions.removeProduct({ ...params })),
  leaveCheckout: () => dispatch(checkoutFlowActions.end()),
  addManualDiscount: params => dispatch(currentOrderActions.addManualDiscountToProduct(params)),
  removeManualOrderDiscount: () => dispatch(currentOrderActions.removeManualDiscountFromOrder()),
  removeManualItemDiscount: params =>
    dispatch(currentOrderActions.removeManualDiscountFromProduct(params)),
  toggleRefundItem: params => dispatch(currentOrderActions.toggleRefundItem(params)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ itemIndex, selectedLines = {} }) => {
    return { checked: selectedLines[itemIndex] === true }
  }),
  withHandlers({
    toggleChecked: ({ checked, addSelection, removeSelection, itemIndex }) => () => {
      if (checked) {
        removeSelection(itemIndex)
      } else {
        addSelection(itemIndex)
      }
    },
    addDiscount: props => () => {
      // why isn't the product info props.product?
      const product = JSON.parse(JSON.stringify(props))

      props.addDiscountItems(product)
    }
  })
)(BasketItem)
