// this file handles:
// - accessing all stores from redux
// - prefilling store as store the app is currently being used in
// - prefilling delivery details from customer object
// - updating redux store when either of the radio options change value
// so that this is immediately reflected in the basket summary on the
// right of the screen
// - including updating redux store and form values for deliveryOption
// when deliveryType changes (hard)
//
// onsubmit...
// transforming form data into the shape the order api requires
// and making sure only data relevant for chosen deliveryType is used

import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import _ from 'lodash'
import moment from 'moment'

import { translations } from '../../../config'
import { selectors as storeSelectors } from '../../../store/modules/stores'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as appSelectors } from '../../../store/modules/app'
import { actions as customerActions } from '../../../store/modules/customerDetails'
import { selectors as orderOrderSelectors } from '../../../store/modules/currentOrder'
import { getRegionBrand } from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import cf from '../../../formatters/currencyFormatter'
import Delivery, { formId } from './Delivery'
import { personalFieldNames } from './PersonalFields'
import withCheckoutFlow from '../withCheckoutFlow'
import store from '../../../store'

const getDeliveryOptionById = ({ deliveryOptions = [], id, currentCurrency }) => {
  const matched = deliveryOptions.find(option => (
    id === option.id
  ))
  if (!matched) return undefined
  return {
    ...matched,
    price: {
      code: currentCurrency,
      value: _.get(matched, `details.prices[${currentCurrency}]`) || 0
    }
  }
}

const mapDeliveryOptions = ({ id, price, name }) => ({
  value: id,
  label: `${translations(name)} - ${_.get(price, 'value') ? cf.format(price) : _.upperCase(translations('free'))}`
})

export default compose(
  withCheckoutFlow,
  connect(state => {
    return {
      allStores: storeSelectors.getAllStores(state),
      storeDropdownOptions: authSelectors.getUserStoresOptions(state),
      userSelectedStoreId: authSelectors.getUserSelectedStoreId(state),
      deliveryOptionFormValues: getFormValues('delivery-options')(state),
      notesFormValues: getFormValues('notes')(state),
      currentOrderDetails: orderOrderSelectors.getCurrentOrderDetails(state),
      currentCurrency: appSelectors.getAppCurrency(state),
      currentRegionConfig: getRegionBrand(state)
    }
  }),
  withPropsOnChange(
    ['currentCurrency', 'deliveryOptions', 'customer', 'userSelectedStoreId', 'deliveryDetails', 'deliveryAddress', 'deliveryOptionFormValues', 'currentOrderDetails'],
    props => {
      const {
        deliveryOptions = [],
        userSelectedStoreId,
        customer,
        deliveryDetails,
        deliveryAddress,
        deliveryType,
        deliveryOption,
        deliveryOptionFormValues,
        currentOrderDetails,
        currentCurrency,
        currentRegionConfig,
      } = props

      const filterOptionsWithoutCurrency = ({ details }) => details && details.prices && (details.prices[currentCurrency] !== undefined)
      const filterOptionsByBrand = item => currentRegionConfig ? item.details.brandName === currentRegionConfig : !item.details.brandName

      const homeOptions = deliveryOptions
        .filter(filterOptionsByBrand)
        .filter(filterOptionsWithoutCurrency)
        .map(item => {
          return {
            ...item,
            price: {
              code: currentCurrency,
              value: item.details.prices[currentCurrency]
            }
          }
        })
        .filter(({ deliveryType }) => deliveryType === 'home')
        .map(mapDeliveryOptions)

      const storeOptions = deliveryOptions
        .filter(filterOptionsByBrand)
        .filter(filterOptionsWithoutCurrency)
        .map(item => {
          return {
            ...item,
            price: {
              code: currentCurrency,
              value: item.details.prices[currentCurrency]
            }
          }
        })
        .filter(({ deliveryType }) => deliveryType === 'store')
        .map(mapDeliveryOptions)

      // Remove pre-selected title in checkout
      const initialDetails = _.pick(
        { ...customer, ...deliveryDetails, title: null },
        personalFieldNames
      )

      const initialAddress = {
        ..._.get(customer, 'address'),
        ...deliveryAddress
      }

      const deliveryOptionHome = (
        (deliveryType === 'home' && _.get(deliveryOption, 'id')) ||
        _.get(homeOptions, `[0].value`)
      )

      const deliveryOptionStore = (
        (deliveryType === 'store' && _.get(deliveryOption, 'id')) ||
        _.get(storeOptions, `[0].value`)
      )

      return {
        initialValues: {
          deliveryType,
          deliveryOption,
          deliveryOptionHome,
          deliveryOptionStore,
          storeId: userSelectedStoreId,
          ...initialDetails,
          address: initialAddress,
          orderDate: moment().format('DD/MM/YYYY')
        },
        homeOptions,
        storeOptions,
        hasAddressPrefilled: !_.isEmpty(_.get(customer, 'address')),
        hasAddressFormValues: !_.isEmpty(_.get(deliveryOptionFormValues, 'address')),
        customer
      }
    }
  ),
  withHandlers({
    onChangeDeliveryType: ({ updateOrder, deliveryOptions, homeOptions, storeOptions, dispatch, currentCurrency }) => e => {
      const deliveryType = e.target.value
      const deliveryTypeIsHome = deliveryType === 'home'
      const deliveryOption = getDeliveryOptionById({
        currentCurrency,
        deliveryOptions,
        id: (
          deliveryTypeIsHome
            ? _.get(homeOptions, `[0].value`)
            : _.get(storeOptions, `[0].value`)
        )
      })

      dispatch(change(
        formId,
        (
          deliveryTypeIsHome
            ? 'deliveryOptionHome'
            : 'deliveryOptionStore'
        ),
        deliveryOption && deliveryOption.id
      ))

      updateOrder({
        deliveryType,
        deliveryOption
      })
    },
    onChangeDeliveryOption: ({ updateOrder, deliveryOptions, currentCurrency }) => e => {
      updateOrder({
        deliveryOption: getDeliveryOptionById({ deliveryOptions, currentCurrency, id: e.target.value })
      })
    },
    onSaveAddressToProfile: ({ dispatch, deliveryOptionFormValues, customer }) => () => {
      const deliveryAddress = _.get(deliveryOptionFormValues, 'address')

      if (deliveryAddress) {
        dispatch(customerActions.updateCustomer({
          id: customer.id,
          address: deliveryAddress
        }))
      }
    },
    onSubmit: ({
      updateOrder,
      deliveryOptions,
      allStores,
      next,
      currentCurrency,
      notesFormValues
    }) => formData => {
      const {
        deliveryType,
        deliveryOptionHome,
        deliveryOptionStore,
        storeId,
        orderDate,
        ...rest
      } = formData

      const store = (
        storeId
          ? allStores.find(({ id }) => id === storeId)
          : undefined
      )

      const updateOrderParams = {
        deliveryType,
        orderDate,
        deliveryOption: getDeliveryOptionById({
          deliveryOptions,
          id: (deliveryType === 'home' ? deliveryOptionHome : deliveryOptionStore),
          currentCurrency
        }),
        ...(
          deliveryType === 'home'
            ? {
              deliveryAddress: _.get(rest, 'address'),
              deliveryDetails: _.omit(rest, 'address')
            }
            : {
              deliveryDetails: { store: _.omit(store, 'reporting') },
              deliveryAddress: null
            }
        ),
        details: {
          ...notesFormValues
        }
      }
      updateOrder(updateOrderParams)
      next()
    }
  })
)(Delivery)
