import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroller'
import { compose } from 'recompose'

import Heading from '../../../components/Heading'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import DatedList from '../../../components/DatedList'
import FullScreenLoader from '../../../components/FullScreenLoader'

import NotificationSwitch from '../NotificationSwitch'
import NotificationFilters from './NotificationsFilters'

import { translations, viewOtherUsersNotificationsEnabled, sendOtherUsersNotificationsEnabled } from '../../../config'

import style from './style'
import UserDropdown from './UserDropdown/UserDropdown'
import RegistrationButton from '../../../components/RegistrationButton'

class NotificationsScreen extends React.Component {
  renderNotificationSwitchWithStatusToggle = (notification) => {
    const { onToggleStatus, onToggleActioned, currentUserSelected, onViewAttachmentsClick } = this.props

    return (
      <NotificationSwitch
        {...notification}
        currentUserSelected={currentUserSelected}
        onToggleStatus={status => {
          onToggleStatus({ id: notification.notificationId, status })
        }}
        onToggleActioned={({ isActioned }) => {
          onToggleActioned({ id: notification.notificationId, isActioned })
        }}
        onViewAttachmentsClick={onViewAttachmentsClick}
      />
    )
  }

  renderFilters = () => {
    const { notifications, filtersFalse, canViewOtherUsersNotifications, filters,
      userOptions, onUserInputChange, selectedUserId, onFiltersChange, fetchFreshNotifications, classes } = this.props
    return (
      <div className={classes.filterContainer}>
        {canViewOtherUsersNotifications && viewOtherUsersNotificationsEnabled
          ? (
            <UserDropdown
              canViewOtherUsersNotifications={canViewOtherUsersNotifications}
              userOptions={userOptions}
              onUserInputChange={onUserInputChange}
              selectedUserId={selectedUserId}
              fetchFreshNotifications={fetchFreshNotifications}
            />
          ) : null}
        {
          // if no notifications have been loaded and the filters are all false
          filtersFalse && !notifications.length
            ? null
            : (
              <NotificationFilters onFiltersChange={onFiltersChange} filters={filters} />
            )
        }
      </div>
    )
  }

  filterNotifications = (notifications, currentStoreId) => {
    const _filterInStore = (notification) => {
      const filterType = 'waitlistProductBackInStock'
      if (notification.type !== filterType) {
        return notification
      } else if (_.get(notification, 'meta.store.id') === currentStoreId) {
        return notification
      }
    }
    return _.filter(notifications, _filterInStore)
  }

  render() {
    const {
      notifications: allNotifications,
      onClick,
      onToggleStatus,
      hasMore,
      fetchNextNotifications,
      currentUserSelected,
      classes,
      currentStoreId
    } = this.props
    const notifications = this.filterNotifications(allNotifications, currentStoreId)
    return (
      <div className={classes.notificationScreen}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <Heading component={'h1'} uppercase>{translations('Notifications')}</Heading>
          )}
        />
        <div className={classes.container}>
          {this.renderFilters()}
          {
            notifications.length
              ? (
                <InfiniteScroll
                  hasMore={hasMore}
                  initialLoad={false}
                  loadMore={fetchNextNotifications}
                >
                  <DatedList
                    items={notifications}
                    ItemComponent={this.renderNotificationSwitchWithStatusToggle}
                    groupTitleClass={classes.notificationHeaders}
                    onClickItem={notification => (evt) => {
                      onClick(notification)
                      // only trigger read if it is your own notification
                      currentUserSelected && onToggleStatus({ id: notification.notificationId, status: { isRead: true } })
                    }}
                  />
                </InfiniteScroll>
              )
              : (
                <div className={classes.noNotificationsContainer}>{translations('No notifications')}</div>
              )
          }
          {
            sendOtherUsersNotificationsEnabled
            ? <div className={classes.newNotificationButton}>
              <RegistrationButton to='/notifications/new'>
                {translations('Create new notification')}
              </RegistrationButton>
            </div>
            : null
          }
        </div>
      </div>
    )
  }
}

NotificationsScreen.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  hasMore: PropTypes.bool.isRequired,
  fetchNextNotifications: PropTypes.func.isRequired,
  onToggleStatus: PropTypes.func.isRequired,
  onToggleActioned: PropTypes.func.isRequired,
  onViewAttachmentsClick: PropTypes.func.isRequired,
  filtersFalse: PropTypes.bool.isRequired
}

export default compose(
  withStyles(style),
  FullScreenLoader
)(NotificationsScreen)
