import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import { actions as usersActions, selectors as usersSelectors } from '../../../../store/modules/users'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import UsersSearchTable from './UsersSearchTable'

const mapStateToProps = state => {
  const results = usersSelectors.getResults(state)
  const hasMore = usersSelectors.getHasMore(state)
  const isLoading = usersSelectors.getIsLoading(state)
  const hasSearched = usersSelectors.getUsersHasBeenSearched(state)
  const error = usersSelectors.getError(state)
  const userStores = authSelectors.getUserStores(state)

  const noResults = !isLoading && !(results || []).length

  return {
    isLoading,
    hasMore,
    results,
    noResults,
    hasSearched,
    error,
    userStores
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchCustomers: ({ dispatch, isLoading }) => () => {
      if (!isLoading) {
        dispatch(usersActions.searchUsersNext())
      }
    },
    onUserClick: ({ dispatch, onUserClick, disabledUserId }) => user => {
      if (disabledUserId !== user.id) {
        if (onUserClick) {
          onUserClick(user)
        } else {
          dispatch(push(`/users/${user.id}`))
        }
      }
    }
  })
)(UsersSearchTable)
