import { useSelector } from 'react-redux'

import {
  getIsLoading,
  getAppointments,
  getAppointmentsFilters,
  getAppointmentsQuery
} from '../store/modules/appointments/selectors'

/**
 * @description returns boolean
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns appointments
 * @returns
 */
export const useAppointments = () => {
  return useSelector(getAppointments)
}

/**
 * @description returns filters for appointments
 * @returns
 */
export const useAppointmentsFilters = () => {
  return useSelector(getAppointmentsFilters)
}

/**
 * @description returns filters as a query for appointments
 * @returns
 */
export const useAppointmentsQuery = () => {
  return useSelector(getAppointmentsQuery)
}
