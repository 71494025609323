import i18next from 'i18next'
import _ from 'lodash'
import en from './configFiles/translations/en'
import ar from './configFiles/translations/ar'

let languages = []

if (!_.isEmpty(ar)) languages.push('ar')
if (!_.isEmpty(en)) languages.push('en')

i18next.init({
  lng: 'ar',
  debug: false,
  resources: {
    en: { translation: en },
    ar: { translation: ar }
  }
})

export const translations = (key, options) => i18next.t(key, options)
export const getCurrentLanguage = () => i18next.language || 'ar'
export const getAvailableLanguages = () => languages
export const changeLanguage = lang => i18next.changeLanguage(lang)
