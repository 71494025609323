const styles = theme => ({
  label: {
    color: theme.palette.materialUIFormGrey.main
  },
  customerValue: {
    color: theme.palette.text.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    alignItems: 'center'
  },
  searchButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    marginLeft: 5
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteIcon: {
    cursor: 'pointer'
  },
  errorStyle: { color: theme.palette.error.main }
})

export default styles 
