import { translations } from '../config'

export const formattedGroupItemStatus = ({ groupItemStatus = [], quantity, orderStatus}) => {
    let obj = {}
    if(groupItemStatus.length > 0) {
        obj = groupItemStatus.reduce((count, item) => (count[item] = count[item] + 1 || 1, count), {})
    }
    let statusStr = ``
    Object.keys(obj).forEach((key) => {
        statusStr += `${translations(key)} (${obj[key]}), `
    })
    const remainingStatus = quantity - groupItemStatus.length
    if(remainingStatus > 0) statusStr += `${orderStatus} (${remainingStatus}), `
    if(statusStr.length > 0) statusStr = statusStr.slice(0, -2)
    return statusStr
}