import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import _ from 'lodash'
import { Table, TableHeader, TableRow, TableCell } from '../../../../components/Table'
import ButtonLink from '../../../../components/ButtonLink'
import { translations } from '../../../../config'
import LoadMoreButton from '../../../../components/LoadMoreButton'
import LoaderContainer from '../../../../components/LoaderContainer'

import styles from './style'

class CustomerScreen extends Component {

  componentWillUnmount() {
    const { clearQuery } = this.props
    clearQuery()
  }

  renderError = (error) => {
    const { classes } = this.props
    let errMsg
    if (error === 'Internal server error') {
      errMsg = translations('No Results')
    } else {
      errMsg = translations('Sorry, an error has occurred. Please try again.')
    }
    return <div className={classes.content}>{errMsg}</div>
  }

  renderTableRows = () => {
    const { results, classes } = this.props
    return _.chain(results)
      .map(customer => {
        const { firstName, lastName, telephone, address, email, dob, id } = customer
        const { onCustomerClick } = this.props
        // address/dob/telephone is not required
        return (
          <TableRow key={id} onClick={() => onCustomerClick(customer)}>
            <TableCell xs={7} sm={4} md={4}>
              <ButtonLink onClick={() => null}>
                {`${firstName} ${lastName}`}
              </ButtonLink>
            </TableCell>
            <TableCell xs={5} sm={4} md={4} truncate>{telephone || '-'}</TableCell>
            {/* <TableCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{address ? address.postCode : '-'}</TableCell> */}
            <TableCell className={classes.hideExtraSmall} xs={0} sm={4} md={4}>{email || '-'}</TableCell>
            {/* <TableCell className={classes.hideExtraSmall} xs={0} sm={0} md={2}>{dob || '-'}</TableCell> */}
          </TableRow>
        )
      })
      .value()
  }

  render() {
    const { error, noResults, hasSearched, searchCustomers, hasMore, results, isLoading, classes, isHiddenForModal } = this.props
    if (error) {
      return this.renderError(error)
    }
    if (results.length < 1 && isLoading) {
      // Return the loading container within the search results to prevent componentwillupdate firing whenever a new search starts.  Checks above ensure the loader doesn't render when 'loading more'. 
      return <LoaderContainer isLoading={isLoading} />
    } else {
      return (
        // only display table if there are results
        <>
          <div className={classes.loadingBarContainer}>
            {isLoading && <LinearProgress mode='indeterminate' style={{ height: 2 }} />}
          </div>
          <div className={classes.gridContainer}>
            {
              results &&
              <div
                className={classes.container}
              >
                <Table
                  emptyMessage={translations('No results found')}
                  header={(
                    <TableHeader>
                      <TableCell headerCell xs={7} sm={4} md={4}>{translations('Customer Name')}</TableCell>
                      <TableCell headerCell xs={5} sm={4} md={4}>{translations('Customer Telephone')}</TableCell>
                      {/* <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{translations('Customer Postcode')}</TableCell> */}
                      <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={4} md={4}>{translations('Customer Email')}</TableCell>
                      {/* <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={0} md={2}>{translations('DOB')}</TableCell> */}
                    </TableHeader>
                  )}
                  rows={this.renderTableRows()}
                />
                {(results && results.length !== 0) &&
                  <div className={classes.loadMoreContainer}>
                    <LoadMoreButton
                      hasMore={hasMore}
                      isLoading={isLoading}
                      loadNext={searchCustomers}
                    />
                  </div>}
              </div>
            }
          </div>
        </>
      )
    }
  }
}

export default withStyles(styles)(CustomerScreen)
