import React from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import List from '../../../components/List'
import getSelectedCurrencyPrice from '../../../helpers/getSelectedCurrencyPrice'

import WishListItem from '../WishListItem'
import style from './style'

const WishList = props => {
  const {
    editableQuantities,
    editablePrices,
    clickable,
    textType,
    gap,

    products,
    goToProduct,
    classes,

    discountMode,
    addSelection,
    removeSelection,
    selectedLines,
    addDiscountItems,

    hideUnpurchasedItems,
    hideUndo,
    currentCurrency
  } = props

  // Images can't be displayed in the WishlistItem component at present
  const filteredProducts = _.filter(products, product => product.type !== 'image')

  const itemProps = {
    editableQuantities,
    editablePrices,
    textType,
    gap,
    products: filteredProducts,

    discountMode,
    addSelection,
    removeSelection,
    selectedLines,
    addDiscountItems,

    hideUnpurchasedItems,
    hideUndo
  }


  if (filteredProducts && filteredProducts.length) {
    return <List
      items={filteredProducts}
      itemProps={itemProps}
      ItemComponent={WishListItem}
      noMargin
      onClickItem={clickable ? goToProduct : undefined}
    />
  } else {
    return <div className={classes.emptyWishListMessage}>
      {translations('Empty Wish List')}
    </div>
  }
}

export default withStyles(style)(WishList)
