export default theme => ({
  container: {
    height: '100%'
  },
  errorMessage:{
    padding: '5px 0 0 50px',
    textAlign: 'left',
    margin: 0,
    fontSize: theme.searchInput.fontSize,
    fontSize: '14px',
    color: theme.searchInput.textColor,
    fontFamily: theme.base.fontFamily,
    lineHeight: '14px',
    width: '100%'
  },
  hidden:{
    display: 'none',
  }
})
