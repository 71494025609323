import _ from 'lodash'

export const formatNullValues = (input = {}) => {
  // replace empty string values with null
  const result = replaceValues(input, '', null)
  return result
}

function replaceValues(input, find = '', replace = null) {
  const keys = Object.keys(input || {})
  const result = {}

  for (let key of keys) {
    if (typeof input[key] === 'object' && input[key] !== null && !_.isArray(input[key])) { // fixme: doesn't support arrays yet.
      result[key] = replaceValues(input[key], find, replace)
    } else if (input[key] === find) {
      result[key] = replace
    } else {
      result[key] = input[key]
    }
  }

  return result
}

export const nullEmailFallback = (customer) => {
  if (!customer.email && customer.telephone) {
    return `unknown_${customer.telephone ? customer.telephone.replace(/([+, ])+/g, '') : 'email'}@redant.com`
  }

  return customer.email
}
