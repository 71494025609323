import _ from 'lodash'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { compose } from 'recompose'
import EcommerceStock from './EcommerceStock'
import * as variantsCombinedSelectors from '../../../../../store/modules/combinedSelectors/variantsCombinedSelectors'
import { selectors as productDetailSelectors } from '../../../../../store/modules/productDetails'

import { formNames } from '../../../../../config'

const mapStateToProps = (state) => {
  const product = productDetailSelectors.getProduct(state)

  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId', null)

  const productEcommerceStock = _.sumBy(product.variants, (variant) => variant.variantEcommStock && variant.variantEcommStock.stock)
  const variantEcommerceStock = _.get(_.first(variantsCombinedSelectors.getEcommerceStock(state)), 'stock', 0)

  const ecommerceStock = selectedVariantId ? variantEcommerceStock : productEcommerceStock

  return {
    ecommerceStock
  }
}

export default compose(connect(mapStateToProps))(EcommerceStock)
