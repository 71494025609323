import React from 'react'
import AdvSearchFormContentBox from '../AdvSearchFormContentBox'
import FormError from '../../Form/FormError'
import FormBody from '../../Form/FormBody'
import * as validators from '../../../validators'

export const formId = 'customerAdvancedSearch'
const NewSearchFilterForm = AdvSearchFormContentBox(formId)

const numericValidator = validators.globalPhoneNumber
export const advancedSearch = {
    schema: [{
        id: "searchFirstName",
        field: "Input",
        props: {
            label: "First Name",
            name: "firstName",
        }
    },
    {
        id: "searchLastName",
        field: "Input",
        props: {
            label: "Last Name",
            name: "lastName",
        }
    },
    {
        id: "searchPhoneNumber",
        field: "Input",
        props: {
            label: "Phone Number",
            name: "phoneNo",
            validate: [numericValidator]
        }
    }],
    layout: [
        ['firstName', 'lastName'],
        ['telephone'],
    ]
}

const AdvancedSearchModal = ({ onClose, query, updateQuery, resetQuery, searchCustomer }) => {
    return (
        <>
            <NewSearchFilterForm
                initialValues={query}
                onSubmit={searchCustomer}
                editable={true}
                editing={true}
                isSubmitting={_.noop}
                showHeader={false}
                showFooter={true}
                onClose={onClose}
                onFormChange={updateQuery}
                searchCustomer={searchCustomer}
                resetQuery={resetQuery}
            >
                <FormError />
                <FormBody schema={advancedSearch.schema} />
            </NewSearchFilterForm>
        </>
    )
}

export default AdvancedSearchModal