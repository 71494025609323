import { Modules } from '@redant/retailos-ui'
import { stockLevel as stockConstants } from '../../config'
export class ChalhoubProductFunctions extends Modules.Product.Implementation.ProductCatalogueFunctions {
  constructor(httpClientService) {
    super(httpClientService)
  }

  getStatusTypeCM(variantStock, stores) {
    const currentStore = stores.find(store => store.id === variantStock.storeId)

    if (!currentStore) {
      throw new Error('Not able to find current store')
    }

    switch (currentStore.details.locationType) {
      case 'ecommerceStore':
        return 'other'
      default:
        return 'in-store'
    }
  }

  getStatusFromStockCM(data) {
    const stock = data.stock
    if(stock >= stockConstants.inStockLevel) {
      return 'high-stock'
    } else if (stock <= stockConstants.lowStockHighLevel && stock  >= stockConstants.lowStockLowLevel) {
      return 'low-stock'
    } else {
      return 'out-of-stock'
    }
  }

  filterStoresCM(stores, currentStoreId, regionId) {
    return stores.filter(store => store.id !== currentStoreId && store.regionId === regionId)
  }

  mapAllStoresInRegionCM(stores) {
    return stores.map((store) => {
      const type = store.details.locationType === 'ecommerceStore'
        ? 'other'
        : 'in-store'
        
      return {
        type,
        status: 'out-of-stock',
        store,
      }
    })
  }
}
