import { colors } from '../../../../config'

const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200
    },
    alignItems: 'center'
  },
  containerMargin: {
    marginRight: 30
  },
  closeImage: {
    height: 6
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: colors.accent,
    height: 14,
    width: 14
  },
  customer: {
    width: 180,
    height: 100,
    padding: 20,
    border: '1px solid #a8a8a8',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default style
