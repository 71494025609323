import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../../../components/Button'
import { findInOtherLocationsSidebarEnabled, translations } from '../../../../../config'
import style from './style'
import { actions as variantsStockActions } from '../../../../../store/modules/variantsStock'
import FindInOtherLocationsSidebar from '../../../../../retailos/ProductCatalogue/FindInOtherLocationsSideBar'

const StockInOtherStores = (props) => {
  const {
    classes,
    openStoresList,
    openNoVariantSelected,
    selectedVariantId,
    currentStoreId
  } = props
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const closeDrawer  = () => {
    setDrawerOpen(false)
  }

  useEffect(() => {
    if (!selectedVariantId) return
    dispatch(variantsStockActions.fetchVariantStoreStocks({ variantId: selectedVariantId }))
  }, [selectedVariantId])

  const handleSidebar = () => {
    selectedVariantId ? setDrawerOpen(true) : openNoVariantSelected()
  };

  return (
    <>
      <div className={classes.locationContainer}>
        <Button
          onClick={findInOtherLocationsSidebarEnabled ? handleSidebar : openStoresList}
          className={classes.storeLink}
          iconImage={'locationIcon'}
          hyperlink
          children={translations('Find in other locations')}
        />
      </div>
      {findInOtherLocationsSidebarEnabled && (
        <FindInOtherLocationsSidebar selectedVariantId={selectedVariantId} currentStoreId={currentStoreId} drawerOpen={drawerOpen} closeDrawer={closeDrawer} />
      )}
    </>
  )
}

export default withStyles(style)(StockInOtherStores)
