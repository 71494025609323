import { ellipsis } from 'polished'

const styles = {
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '7px 9px'
  },
  left: {
    flex: 1,
    flexShrink: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  right: {
    flexShrink: 0,
    marginLeft: 5
  },
  mainText: {
    ...ellipsis('100%'),
    marginBottom: '3px',
    marginTop: '3px'
  }
}

export default theme => styles
