import _ from 'lodash'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import MessageEditorOptions from './MessageEditorOptions'
import { selectors as createMessageSelectors } from '../../../../../store/modules/createMessage'

const mapStateToProps = (state) => {
  const templateLanguage = createMessageSelectors.getCurrentTemplateLanguage(state)
  return {
    templateLanguage
  }
}

export default compose(
  connect(mapStateToProps),
  mapProps(props => _.pick(props, [
    'communicationType',
    'communicationTypeOther',
    'currentRecipients',
    'messageTemplateDropdownOptions',
    'optedOutMarketingPreferences',
    'optedOutMarketingChannels',
    'contactDetail',
    'hasSingleRecipient',
    'hasRecipients',
    'smsDisabled',
    'emailDisabled',
    'communicationTypeOptions',
    'communicationTypeOtherOptions',
    'currentRecipientPreferredLanguage',
    'templateLanguage'
  ]))
)(MessageEditorOptions)
